import React, { useState, useEffect } from 'react';
import { Card, Collapse, Typography, Upload, Button, notification } from 'antd';
import { UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { enviarFicheroABancos, ObtenerBancos } from "../../../api/bancos";
import { getAccessTokenApi } from '../../../api/auth';

const { Panel } = Collapse;
const { Text } = Typography;

const formatCurrency = (value) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(value);
};

const Bancos = ({ accesToken, monthQuery, yearQuery }) => {
  const [data, setData] = useState(null);
  const [fileList, setFileList] = useState([]); // Estado para almacenar los archivos cargados

  useEffect(() => {
    const fetchBancos = async () => {
      const response = await ObtenerBancos(getAccessTokenApi());
      if (response) {
        setData(response.resultados);
      } else {
        notification.error({ message: 'Error al obtener los datos de bancos.' });
      }
    };

    fetchBancos();
  }, [accesToken, monthQuery, yearQuery]);

  const handleFileChange = (info) => {
    let files = [...info.fileList];
    setFileList(files);
  };

  const handleUpload = async () => {
    if (fileList.length === 0) {
      notification.warning({ message: 'No has seleccionado archivos para subir.' });
      return;
    }

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files', file.originFileObj);
    });

    try {
      let response = await enviarFicheroABancos(getAccessTokenApi(), formData);
      if (response.message) {
        notification.success({ message: 'Archivos subidos correctamente.' });
        setData(response.resultados);
        setFileList([]);
      } else {
        notification.error({ message: 'Error al subir los archivos.' });
      }
    } catch (error) {
      notification.error({ message: 'Error de red al subir los archivos.' });
    }
  };

  const handleDownloadPendientes = () => {
    if (!data || !data.ordenados.Pendiente) return;

    const pendientes = data.ordenados.Pendiente;
    const csvContent = "data:text/csv;charset=utf-8," + 
      ["Fecha,Descripción,Importe,Notas"]
      .concat(pendientes.map(item => 
        `${item.Fecha},${item.Descripción},${item.Importe},${item.Notas}`
      ))
      .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "movimientos_pendientes.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Upload.Dragger
        multiple={true}
        onChange={handleFileChange}
        beforeUpload={() => false}
        accept=".csv,.xlsx, .xls"
        fileList={fileList}
        iconRender={(file, defaultIcon) =>
          file.status === "done" ? <CheckCircleOutlined /> : defaultIcon
        }
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Arrastra aquí los archivos del banco</p>
        <p className="ant-upload-hint">Solo archivos Excel.</p>
      </Upload.Dragger>

      <Button
        type="primary"
        onClick={handleUpload}
        style={{ marginTop: 20 }}
      >
        Subir Archivos
      </Button>

      <Button
        type="default"
        onClick={handleDownloadPendientes}
        style={{ marginTop: 20, marginLeft: 10 }}
      >
        Descargar Movimientos Pendientes
      </Button>

      <div>
        <Card title="Resumen de Bancos" style={{ marginBottom: 16 }}>
        <><b> Pagar a profesionales:</b> <a href="https://docs.google.com/forms/d/e/1FAIpQLSeK1j4CYXFy_px11DHi0I4OBGmOnT1yxKL9cZFQkvuMGkmPgA/viewform">CLICK AQUI</a></>
          <br></br>
          <b> Quiero que se cree factura :</b> <a href="https://docs.google.com/forms/d/e/1FAIpQLSfuqYe2kajyVDleEmnp-qI_IQYm4aWC7QOBcnfNWSldVp1hkw/viewform">CLICK AQUI</a>
          <br></br>
          <b> Insertar Gasto tarjeta credito:</b> <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhYKoMLtv_D5dXGIaXuM2nJmpJiAwAGSgA2NaMY9IkVKw_TA/viewform">CLICK AQUI</a>

          <br></br>
          <Text style={{ color: "red" }} strong>Pendiente: {formatCurrency(data?.sumas?.Pendiente)}</Text><br />
          <Text strong>Conciliado: {formatCurrency(data?.sumas?.Conciliado)}</Text><br />
          <Text strong>Parcial: {formatCurrency(data?.sumas?.Parcial)}</Text>
        </Card>

        <Collapse defaultActiveKey={['1']}>
          <Panel header="Transacciones Pendientes" key="1">
            {data?.ordenados?.Pendiente?.map((item, index) => (
              <Card key={index} style={{ marginBottom: 10 }}>
                <Text>{item.Fecha} - {item.Descripción}</Text><br />
                <Text style={{ color: "red" }}>Importe: {item.Importe}</Text><br />
                <Text>Notas: {item.Notas}</Text><br />
              </Card>
            ))}
          </Panel>

          <Panel header="Transacciones Conciliadas" key="2">
            {data?.ordenados?.Conciliado?.map((item, index) => (
              <Card key={index} style={{ marginBottom: 10 }}>
                <Text>{item.Fecha} - {item.Descripción}</Text><br />
                <Text style={{ color: "red" }}>Importe: {item.Importe}</Text><br />
                <Text>Notas: {item.Notas}</Text><br />
              </Card>
            ))}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Bancos;
