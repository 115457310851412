import { basePath, apiVersion } from "./config";

export function getReservasDuplicadas(accessToken, month2, year2, airbnbFile, bookingFile) {
  const url = `${basePath}/${apiVersion}/reservas_duplicadas`;

  // Creamos un FormData para poder enviar archivos y otros datos
  const formData = new FormData();
  formData.append("year", year2);
  formData.append("month", month2);
  formData.append("fechas", JSON.stringify(["2023-12-1", "2023-12-19"]));

  // Agregar el archivo de Airbnb al FormData (solo uno)
  if (airbnbFile) {
    formData.append("airbnbFile", airbnbFile);
    console.log("airbnbFile", airbnbFile);
  }

  // Agregar el archivo de Booking al FormData (solo uno)
  if (bookingFile) {
    formData.append("bookingFile", bookingFile);
    console.log("bookingFile", bookingFile);
  }

  const params = {
    method: "POST",
    headers: {
      Authorization: accessToken, // Bearer token
      // No es necesario agregar 'Content-Type': 'multipart/form-data'
    },
    body: formData, // Enviamos el FormData con archivos y datos
  };

  return fetch(url, params)
    .then((response) => response.json())
    .catch((err) => err.message);
}
