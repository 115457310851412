import React, { useRef, useEffect } from "react";
import { Button } from "antd";
import SignatureCanvas from "react-signature-canvas";

export default function FirmaDigital({
  onSignatureComplete,
  handleSignatureClean,
}) {
  const signatureRef = useRef();

  const handleClear = () => {
    //console.log("handleClear");
    signatureRef.current.clear();
    handleSignatureClean();
  };

  const handleSign = () => {
    //console.log("handleSign");
    if (signatureRef.current.isEmpty()) {
      //console.log("El canvas está vacío. No se puede firmar.");
    } else {
      const signatureImage = signatureRef.current.toDataURL();

      onSignatureComplete(signatureImage);
    }
  };

  return (
    <div
      style={{ border: "1px solid black", padding: "10px", maxWidth: "400px" }}
    >
      <SignatureCanvas
        ref={signatureRef}
        penColor="black"
        canvasProps={{ width: 380, height: 180, className: "signature-canvas" }}
      />
      <div style={{ marginTop: "10px", textAlign: "center" }}>
        <Button onClick={handleClear}>Limpiar / Clean</Button>
        <Button
          type="primary"
          onClick={handleSign}
          style={{ marginLeft: "10px" }}
        >
          Grabar firma / Record Signature
        </Button>
      </div>
    </div>
  );
}
