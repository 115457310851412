import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Spin,
  notification,
  Radio,
  RadioChangeEvent,
  Divider,
  Row,
  Col,
} from "antd";

import {
  abrirPuertaInquilinoApi,
  abrirPuertaNukiInquilinoApi,
  abrirPuertaYCERRInquilinoApi,
  actualizarUsoEnlaceInquilino,
  numeroPuertasInquilinoApi,
} from "../../../api/inquilino";
import "./Garaje.scss";
import { BrowserRouter } from "react-router-dom";
import { getAccessTokenApi } from "../../../api/auth";
import Modal from "../../../components/Modal";
export default function Garaje({ match }) {
  //Para mas info de esto venir aqui: https://stackblitz.com/edit/react-router-como-pasar-id-a-pagina?file=index.js
  let code = match.params.id;
  //console.log("El codigo es:", code);
  //Creamos unos hooks para guardar el estado del formulario
  const [inputs, setInputs] = useState({
   
    email: "",
    password: "",
    idp: "",
  });
  const [apertura, setApertura] = useState(true);
  //Cuando haya cambios debe actualizar el estado
  const changeForm = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const [puertaCalle, setPuertaCalle] = useState(false);
  const [puertaCasa, setPuertaCasa] = useState(false);
  const [puertaCasaY, setPuertaCasaY] = useState(false);
  const [nombre, setnombre] = useState("");
  const [direccion, setdireccion] = useState("");
  useEffect(() => {
    ////console.log("Hola");
    numeroPuertasInquilinoApi(code).then((response) => {
      if (response.error) {
        notification["error"]({
          message: response.error,
        });
      }
      setPuertaCalle(response["Puerta-calle"] ?? false);
      setPuertaCasa(response["Puerta-casa"] ?? false);
      setPuertaCasaY(response["Puerta-casa-ycerr"] ?? false);
      setdireccion(response.direccion)
      setnombre(response.nombre_piso)
    });
  }, []);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [codigo_abrirPuerta, setcodigo_abrirPuerta] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [entrar, setentrar] = useState(null);
 
  useEffect(() => {
    const handleSubmit = () => {
      setApertura(false);
      //console.log("codigo_abrirPuerta", codigo_abrirPuerta);
      abrirPuertaNukiInquilinoApi(code, codigo_abrirPuerta, true).then(
        (response) => {
          //console.log("Cerrando");
          //console.log(response);
          if (response.message !== "Empuja la puerta / Push the door") {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: response.message,
            });
          }

          setApertura(true);
        }
      );
    };
    const handleSubmit1 = () => {
      setApertura(false);
      //console.log("codigo_abrirPuerta", codigo_abrirPuerta);
      abrirPuertaNukiInquilinoApi(code, codigo_abrirPuerta, false).then(
        (response) => {
          //console.log("Cerrando");
          //console.log(response);
          if (response.message !== "Empuja la puerta / Push the door") {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: response.message,
            });
          }

          setApertura(true);
        }
      );
    };

    const handleSubmit2 = () => {
      setApertura(false);
      //console.log("codigo_abrirPuerta", codigo_abrirPuerta);
      abrirPuertaYCERRInquilinoApi(code, codigo_abrirPuerta).then(
        (response) => {
          //console.log("Cerrando");
          //console.log(response);
          if (response.message !== "Empuja la puerta / Push the door") {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: response.message,
            });
          }

          setApertura(true);
        }
      );
    };

    //console.log("El código de abrir puerta ha cambiado:", codigo_abrirPuerta);
    setModalContent(
      <Form className="login-form" onChange={changeForm} onFinish={() => {}}>
        <h3>Introduce el codigo de la puerta</h3>
        <Form.Item>
          <Input
            //prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            name="door_code"
            placeholder="DOOR CODE / CODIGO DE ACCESO"
            className="login-form__input"
            onChange={(e) => {
              setcodigo_abrirPuerta(e.target.value);
              //console.log("Se esta introduciendo: ", e.target.value);
            }}
          />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <Button type="primary" htmlType="button" onClick={handleSubmit}>
                Open door / Abrir puerta
              </Button>
            </Form.Item>
          </Col>
          {/* {puertaCasaY === false ? ( */}
          <Col>
            <Form.Item span={12}>
              <Button
                type="primary"
                danger
                htmlType="button"
                onClick={handleSubmit1}
              >
                Close door / Cerrar puerta
              </Button>
            </Form.Item>
          </Col>
          {/* ) : (
            <></>)} */}
        </Row>
      </Form>
    );
  }, [codigo_abrirPuerta, isVisibleModal, entrar]);
  
  
  useEffect(()=>{
    actualizarUsoEnlaceInquilino(code, true);
  },[]);

  return (
    <div class="welcome-section">
      <h1>¡Bienvenido a ComoTuCasa.es!</h1>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={600}
      >
        {modalContent}
      </Modal>
      {apertura ? (
        puertaCalle ? (
          <>
            <h1 style={{ color: "black", fontSize: 42 }}>Piso: {nombre}</h1>
            <h1 style={{ color: "black", fontSize: 42 }}>Direccion: {direccion}</h1>
            <Divider style={{ color: "black", fontSize: 22 }}>
              {" "}
              Street Door (Calle)
            </Divider>
            <Button
              className="red-round-button"
              onClick={() => {
                setApertura(false);

                abrirPuertaInquilinoApi(code).then((response) => {
                  //console.log("Cerrando");
                  //console.log(response);
                  

                  if (!response.message) {
                    notification["error"]({
                      message:
                        "Error, contacta a soporte! / Error, contact support!",
                    });
                  } else {
                    notification["success"]({
                      message: response.message,
                    });
                  }
                  setApertura(true);
                });
              }}
            >
              OPEN
            </Button>
          </>
        ) : (
          <></>
        )
      ) : (
        <Spin>Abriendo puerta calle</Spin>
      )}

      {apertura ? (
        puertaCasa ? (
          <>
            <Divider style={{ color: "black", fontSize: 22 }}>
              {" "}
              Home Door (Casa)
            </Divider>
            <Button
              className="red-round-button2"
              onClick={() => {
                setIsVisibleModal(true);
              }}
            >
              2
            </Button>
          </>
        ) : (
          <></>
        )
      ) : (
        <Spin>Abriendo puerta casa</Spin>
      )}

      {/* {apertura ? (
        puertaCasaY ? (
          <>
            <Divider style={{ color: "black", fontSize: 22 }}>
              {" "}
              Home Door (Casa)
            </Divider>
            <Button
              className="red-round-button2"
              onClick={() => {
                setIsVisibleModal(true);
              }}
            >
              2
            </Button>
          </>
        ) : (
          <></>
        )
      ) : (
        <Spin>Abriendo puerta casa</Spin>
      )} */}
    </div>
  );
}
