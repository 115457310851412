import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Divider,
  Checkbox,
} from "antd";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import {
  updateUserApi,
  uploadAvatarApi,
  getAvatarApi,
} from "../../../../api/user";

import { obtenerListaInmuebles } from "../../../../api/inmueble";
import { getAccessTokenApi } from "../../../../api/auth";
import "./EditUserForm.scss";

import DesplegableMultiple from "../../../DesplegableMultiple";

export default function EditUserForm(props) {
  const { user, setIsVisibleModal, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  const [userData, setUserData] = useState({});
  //console.log("EditUserForm");
  //console.log(user.fecha_entrada, user.fecha_salida);
  useEffect(() => {
    setUserData({
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      role: user.role,
      avatar: user.avatar,
      fecha_entrada: user.fecha_entrada,
      fecha_salida: user.fecha_salida,
      pisos_asociados: user.pisos_asociados,
      group_id: user.group_id,
      enviar_correos: user.enviar_correos,
      nombre_facturacion: user.nombre_facturacion,
      id_facturacion: user.id_facturacion,
      direccion_facturacion: user.direccion_facturacion,
      telefono: user.telefono ??""
    });
    //console.log("EDITUSERFORM USERS", user);
  }, [user]);

  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  useEffect(() => {
    if (avatar) {
      setUserData({ ...userData, avatar: avatar.file });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  const updateUser = (e) => {
    //console.log("updateUser");
    //e.preventDefault();
    const token = getAccessTokenApi();
    let userUpdate = userData;

    if (userUpdate.password || userUpdate.repeatPassword) {
      if (userUpdate.password !== userUpdate.repeatPassword) {
        notification["error"]({
          message: "Las contraseñas tienen que ser iguales.",
        });
        return;
      } else {
        delete userUpdate.repeatPassword;
      }
    }

    if (!userUpdate.name || !userUpdate.lastname || !userUpdate.email) {
      notification["error"]({
        message: "El nombre, apellidos y email son obligatorios.",
      });
      return;
    }

    if (typeof userUpdate.avatar === "object") {
      //console.log("updateUserApi3");
      uploadAvatarApi(token, userUpdate.avatar, user._id).then((response) => {
        //console.log("updateUserApi2");
        userUpdate.avatar = response.avatarName;
        updateUserApi(token, userUpdate, user._id).then((result) => {
          notification["success"]({
            message: result.message,
          });
          setIsVisibleModal(false);
          setReloadUsers(true);
        });
      });
    } else {
      //console.log("updateUserApi");
      updateUserApi(token, userUpdate, user._id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setIsVisibleModal(false);
        setReloadUsers(true);
      });
    }
  };
  //console.log("EditUserForm return");

  return (
    <div className="edit-user-form">
      <UploadAvatar avatar={avatar} setAvatar={setAvatar} />
      <EditForm
        userData={userData}
        setUserData={setUserData}
        updateUser={updateUser}
      />
    </div>
  );
}

function UploadAvatar(props) {
  const { avatar, setAvatar } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (avatar) {
      if (avatar.preview) {
        setAvatarUrl(avatar.preview);
      } else {
        setAvatarUrl(avatar);
      }
    } else {
      setAvatarUrl(null);
    }
  }, [avatar]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setAvatar({ file, preview: URL.createObjectURL(file) });
    },
    [setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop,
  });

  return (
    <div className="upload-avatar" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Avatar size={150} src={NoAvatar} />
      ) : (
        <Avatar size={150} src={avatarUrl ? avatarUrl : NoAvatar} />
      )}
    </div>
  );
}

function EditForm(props) {
  const { userData, setUserData, updateUser } = props;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  //console.log("FORMULARIO");
  //console.log("userData", userData);
  //console.log(moment(userData.fecha_entrada, "YYYY-MM-DD"));
  const onChange = (dates) => {
    //console.log("onChange");
    //console.log(dates[0]);
    //console.log(dates[1]);
    setUserData({
      ...userData,
      fecha_entrada: dates[0].format("YYYY-MM-DD"),

      fecha_salida: dates[1].format("YYYY-MM-DD"),
    });
  };
  var nombres_pisos_a = [];
  var ids_pisos_a = {};
  var pisos_ids_a = {};
  const [nombres_pisos, setNombres_pisos] = useState([]);
  const [nombres_pisos_asoc, setnombres_pisos_asoc] = useState([]);
  const [id_nombres_pisos, setIdNombres_pisos] = useState({});
  const [nombres_id_pisos, setNombresIds_pisos] = useState({});
  const [defaultVal, setDefaultVal] = useState([]);
  useEffect(() => {
    const accessToken = getAccessTokenApi();
    //console.log("AddForm1");
    obtenerListaInmuebles(accessToken).then((response) => {
      //console.log(response.Pisos[0].id);
      //console.log("obtenerListaInmuebles", response.Pisos);
      for (let i = 0; i < response.Pisos.length; i++) {
        nombres_pisos_a.push(response.Pisos[i].nombre);
        ids_pisos_a[response.Pisos[i].nombre] = response.Pisos[i].id;
        pisos_ids_a[response.Pisos[i].id] = response.Pisos[i].nombre;
      }

      let nombres = [];
      setNombres_pisos(nombres_pisos_a);
      setIdNombres_pisos(ids_pisos_a);
      setNombresIds_pisos(pisos_ids_a);
      setnombres_pisos_asoc(nombres);

      //console.log("nombres_pisos_a", nombres_pisos_a);
      //console.log("ids_pisos_a", ids_pisos_a);
      //console.log("pisos_ids_a:", pisos_ids_a);
      //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
    });
  }, [userData]);

  const handleSelectChange = (values) => {
    //console.log("id_nombres_pisosHANDEL", id_nombres_pisos);

    setUserData({
      ...userData,
      pisos_asociados: values.map((x) => {
        return id_nombres_pisos[x] ?? "";
      }),
    });
    //console.log(values);
  };

  useEffect(() => {
    //console.log("ENTRAMOSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS");
    if (Object.keys(nombres_id_pisos).length > 0) {
      //console.log("PRUEBA", Object.keys(nombres_id_pisos).length);
      // console.log(
      //   "setDefaultVal",
      //   userData.pisos_asociados.map((x) => {
      //     return nombres_id_pisos[x] ?? "";
      //   })
      // );
      setDefaultVal(
        userData.pisos_asociados.map((x) => {
          return nombres_id_pisos[x] ?? "";
        })
      );
    } else {
      setDefaultVal("");
    }
  }, [userData, nombres_id_pisos]);

  return (
    <Form className="form-edit" onFinish={updateUser}>
      <Row gutter={24}>
        <Col span={24}>
          <span>Group ID: {userData.group_id}</span>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Checkbox
            name="privacyPolicy"
            onChange={(e) => {
              setUserData({ ...userData, enviar_correos: e.target.checked });

              //console.log("Checked: ", e.target.checked);
            }}
            checked={userData.enviar_correos}
          >
            Enviar notificaciones por correo.
          </Checkbox>
        </Col>
      </Row>
      <Divider>Datos de la cuenta</Divider>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="user" />}
              placeholder="Nombre"
              value={userData.name}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="user" />}
              placeholder="Apellidos"
              value={userData.lastname}
              onChange={(e) =>
                setUserData({ ...userData, lastname: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
      <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Telefono"
              value={userData.telefono}
              onChange={(e) =>
                setUserData({ ...userData, telefono: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            <Input
              //    prefix={<Icon type="mail" />}
              placeholder="Correo electronico"
              value={userData.email}
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Select
              placeholder="Seleccióna una rol"
              onChange={(e) => setUserData({ ...userData, role: e })}
              value={userData.role}
            >
              <Option value="Administrador">Administrador</Option>
              <Option value="Gestor">Gestor</Option>
              <Option value="Limpiador">Limpiador</Option>
              <Option value="Propietario">Propietario</Option>
              <Option value="Mantenimiento">Mantenimiento</Option>
              <Option value="Repartidor">Repartidor</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {userData.role === "Propietario" ? (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <DesplegableMultiple
                valores={nombres_pisos}
                defaultValue2={defaultVal}
                onChange={handleSelectChange}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : userData.role === "Gestor" ? (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <DesplegableMultiple
                valores={nombres_pisos}
                defaultValue2={defaultVal}
                onChange={handleSelectChange}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : userData.role === "Limpiador" ? (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <DesplegableMultiple
                valores={nombres_pisos}
                defaultValue2={defaultVal}
                onChange={handleSelectChange}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : userData.role === "Repartidor" ? (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <DesplegableMultiple
                valores={nombres_pisos}
                defaultValue2={defaultVal}
                onChange={handleSelectChange}
              />
            </Form.Item>
          </Col>
        </Row>
      ): userData.role === "Mantenimiento" ? (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <DesplegableMultiple
                valores={nombres_pisos}
                defaultValue2={defaultVal}
                onChange={handleSelectChange}
              />
            </Form.Item>
          </Col>
        </Row>
      ):(
        ""
      )}
      <Row gutter={24}>
        {/* <Col span={12}>
          <Form.Item>
            <Input
              //  prefix={<Icon type="lock" />}
              type="password"
              placeholder="Contraseña"
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //  prefix={<Icon type="lock" />}
              type="password"
              placeholder="Repetir contraseña"
              onChange={(e) =>
                setUserData({ ...userData, repeatPassword: e.target.value })
              }
            />
          </Form.Item>
        </Col> */}
        {userData.role === "Administrador" ? (
          <>
            <Divider>Datos de facturación</Divider>
            <Row gutter={24}>
              <Col
                span={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                NIF, CIF, VAT
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Input
                    //prefix={<Icon type="mail" />}
                    placeholder="Ej: DNI o CIF empresa"
                    value={userData.id_facturacion}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        id_facturacion: e.target.value,
                      })
                    }
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col
                span={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Nombre empresa/persona física
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Input
                    //prefix={<Icon type="mail" />}
                    placeholder="Ej: Amazon S.L. / Pedro Jiménez"
                    value={userData.nombre_facturacion}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        nombre_facturacion: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col
                span={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Dirección de facturación
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Input
                    //prefix={<Icon type="mail" />}
                    placeholder="Ej: Avenida ... Nº .."
                    value={userData.direccion_facturacion}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        direccion_facturacion: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-submit">
          Actualizar Usuario
        </Button>
      </Form.Item>
    </Form>
  );
}
