import React, { useState, useEffect } from "react";
import { obtenerListaInmuebles, getPisoNombre_id, getPisoByIdApi } from "../../../api/inmueble";
import { getAccessTokenApi } from "../../../api/auth";
import "antd/dist/antd.css";
import moment from 'moment';
import "./limpiezapordias.css";
import {
  DatePicker,
  Modal,
  Spin
} from "antd";
import { getInquilinosOneDay } from "../../../api/inquilino";
import AsignarLimpiador from "./Modal_Acciones/Asignar_limpiador";
import Limpiar from "./Modal_Acciones/Limpiar";
import jwtDecode from 'jwt-decode';
import { updateInmuebleApi } from "../../../api/inmueble";
import { getUsersByRoleApi } from "../../../api/user";
import VerInformacion from "./Modal_Acciones/Ver_informacion";


export default function LimpiezaPorDias() {
  const [data1, setdata1] = useState([]);
  const [nombres_id_pisos, setNombresIds_pisos] = useState({});
  const [pisos, setPisos] = useState([]);
  const accessToken = getAccessTokenApi();
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPiso, setCurrentPiso] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [idInquilino, setIdInquilino] = useState();
  const [limpiadores, setLimpiadores] = useState([]);
  const [estado, setEstado] = useState();
  const [informacion, setInformacion] = useState('');
  const [loading, setloading] = useState(true);


  const openModal = (piso, tipo, informacion = '') => {
    //console.log("DE DONDE SACA EL ID", piso);
    setCurrentPiso(piso);
    setModalType(tipo);
    setInformacion(informacion);
    setIsModalVisible(true);
  };

  useEffect(() => {
    const cargarInquilinos = async () => {
      try {
        const accessToken = getAccessTokenApi();
        const fechaFormateada = moment(selectedDate).format("YYYY-MM-DD");
        const response = await getInquilinosOneDay(fechaFormateada, accessToken);
        await obtenerPisosDeInquilinos(response);
        setloading(false);
      } catch (error) {
        //console.log(error);
        setloading(false);
      }
    };
  
    cargarInquilinos();
  }, []);

  useEffect(() => {
    const accessToken = getAccessTokenApi();
    const cargarLimpiadores = async () => {
      const response = await getUsersByRoleApi(accessToken, 'Limpiador');
      if (response) {
        setLimpiadores(response);
      }
     
    };
   
    cargarLimpiadores();
  }, [loading]);
  
  useEffect(() => {
    
  }, [limpiadores]);
  
  useEffect(() => {
     handleLimpiarClick();
  }, [selectedDate]);

  const obtenerNombresLimpiadores = (idsLimpiadores) => {
    //console.log("Limpiadores:", limpiadores.users); // Debugging
    return idsLimpiadores.map(id => {
      const limpiador = limpiadores.users.find(l => l.id === id);
      return limpiador ? limpiador.name : 'Desconocido';
    }).join(', ');
  };


  const handleDateChange = async (date, dateString) => {
    setloading(true);
    setSelectedDate(dateString);
    setloading(false);
  };



  async function obtenerPisosDeInquilinos(inquilinos, filtroEstado) {
    let pisos_aux = [];
    const limpiadorIdActual = jwtDecode(accessToken).role === 'Limpiador' ? jwtDecode(accessToken).id : null;
    for (let idInmueble in inquilinos.inquilinos) {
      let eventosInquilino = inquilinos.inquilinos[idInmueble].eventos;

      try {
        let inmueble = await getPisoByIdApi(idInmueble, accessToken);

        const tieneEntradaYSalida = eventosInquilino.some(evento => evento.tipo === 'entrada') &&
          eventosInquilino.some(evento => evento.tipo === 'salida');
        const eventoSalida = eventosInquilino.find(evento => evento.tipo === 'salida');
        const eventoEntrada = tieneEntradaYSalida ? eventosInquilino.find(evento => evento.tipo === 'entrada') : null;

        if (!eventoSalida) continue; // Si no hay evento de salida, no incluir el piso
        if ((filtroEstado === "Limpio" && eventoSalida.estadoLimpieza !== "Limpio") ||
          (filtroEstado !== "Limpio" && eventoSalida.estadoLimpieza === "Limpio")) {
          continue;
        }

        if (limpiadorIdActual && !inquilinos.inquilinos[idInmueble].limpiadores.includes(limpiadorIdActual)) {
          continue;
        }
        pisos_aux.push({
          inmueble: inmueble,
          eventos: eventosInquilino,
          idEventoSalida: eventoSalida.id, // Pasar el ID del evento de salida
          idEventoEntrada: eventoEntrada ? eventoEntrada.id : null, // Pasar el ID del evento de entrada si existe
          estado: inquilinos.inquilinos[idInmueble].estado,
          limpiadores: inquilinos.inquilinos[idInmueble].limpiadores,
          comentario_limpiador: eventoSalida.comentarioLimpiador,
          comentario_limpieza: eventoSalida.comentarioLimpieza
        });
      } catch (error) {
        console.error("Error al obtener el inmueble para el inquilino con ID:", idInmueble, error);
      }
    }
    //console.log("LIMPIEZAS COSAS", pisos_aux);
    setPisos(pisos_aux);
  }

  const handleLimpiarClick = async () => {
    try {
      const token = await getAccessTokenApi();
      const fechaFormateada = moment(selectedDate).format("YYYY-MM-DD");
      const response = await getInquilinosOneDay(fechaFormateada, token);
      await obtenerPisosDeInquilinos(response, "NoLimpio");

    } catch (error) {
      console.error("Error en showPisos:", error);
    }
  };

  const handleLimpioClick = async () => {
    try {
      setloading(true);
      const token = await getAccessTokenApi();
      const fechaFormateada = moment(selectedDate).format("YYYY-MM-DD");
      const response = await getInquilinosOneDay(fechaFormateada, token);
      //console.log("PISOS LIMPIOS", response)
      await obtenerPisosDeInquilinos(response, "Limpio");
      setloading(false);
    } catch (error) {
      console.error("Error en showPisos:", error);
    }
    //console.log("Botón Limpio presionado");
  };


  const getBackgroundColorClass = (eventos) => {
    let estadoEventoSalida = eventos.find(evento => evento.tipo === 'salida');
    let estado = estadoEventoSalida.estadoLimpieza;
    if (estado === "Limpio") {
      return 'color-limpio';
    }
    if (estado === "Asignado") {
      return 'color-asignado';
    }
    const tipos = eventos.map(evento => evento.tipo);
    //console.log("Tipos", tipos);
    if (tipos.includes('entrada') && tipos.includes('salida')) {
      return 'color-entrada-salida';
    } else if (tipos.includes('salida')) {
      return 'color-salida';
    }
    return 'hide';
  };


  const renderItem = ({ item }) => {
    //Si se cumple esto entonces no hay que limpiar:
    const tieneSoloEntrar = item.eventos.every(evento => evento.tipo === 'entrada'); 
    if (tieneSoloEntrar) {
      return null; // No renderizar este piso si solo tiene eventos de tipo "entrar"
    }
    //console.log("ITEM VEAMOS LIMPIADOR", item);

    const backgroundColorClass = getBackgroundColorClass(item.eventos);

    //console.log("item: ",item)
    const eventoEntrada = item.eventos.find(evento => evento.tipo === 'entrada');
    const eventoSalida = item.eventos.find(evento => evento.tipo === 'salida');
    //console.log("eventoEntrada", eventoEntrada)
    //Ponemos colores:
    let informacion = '';
    if (eventoSalida) {
      if (eventoSalida.estadoLimpieza === "Limpio") {
        informacion = eventoSalida.comentarioLimpiador; // Comentario del limpiador si el estado es 'Limpio'
      } else if (eventoSalida.estadoLimpieza === "Asignado") {
        informacion = eventoSalida.comentarioLimpieza; // Comentario de limpieza si el estado es 'Asignado'
      }
    }

    const nombresLimpiadores = obtenerNombresLimpiadores(eventoSalida.limpiadores);
    const nombre_inquilino = eventoSalida.nombre;
    //console.log("eventoEntrada",eventoEntrada);
    // const nombresLimpiadores = obtenerNombresLimpiadores(item.limpiadores);
    const comentario_limpiador = eventoSalida.comentario_limpiador;
    //console.log('NOMBRE LIMPIADORES de este inmueble', eventoSalida);

    return (
      <div className={`tarjeta-piso ${backgroundColorClass}`}>
        <h2>Inmueble: {item.inmueble.name}</h2>
        {eventoSalida && <p>Hora de salida: {eventoSalida.hora}</p>}
        {eventoEntrada && <p>Hora de entrada: {eventoEntrada.hora}</p>}
        {nombresLimpiadores && <p>Limpiadores: {nombresLimpiadores}</p>}
        {comentario_limpiador && <p>Comentario: {comentario_limpiador}</p>}
        { eventoEntrada ? <p>Nº personas entran: {eventoEntrada.numero_personas}</p> :<p>Nº personas entran: Máximo de personas</p>}
        
        {nombre_inquilino && <p>Nombre Inquilino sale: {nombre_inquilino}</p>}
       { eventoEntrada ? <p>Nombre inquilino entra: {eventoEntrada.nombre}</p> :<p></p>}
        {/* {//console.log("Limpiador : ", item.limpiadores)} */}
        <div>
          {jwtDecode(accessToken).role === ('Administrador') && (
            <button className="boton-estilo" onClick={() => openModal(item, "limpiador")}>Asignar limpiador { }</button>
          )}
          <button className="boton-estilo" onClick={() => openModal(item, "informacion", informacion)}>Información:</button>
          {eventoSalida.estadoLimpieza === "Asignado" && (
            <button className="boton-estilo">Limpiador asignado: {nombresLimpiadores}</button>
          )
          }
        </div>
      </div>
    );
  };


  return (
    <>
    <DatePicker
        defaultValue={moment()} // Puedes establecer un valor predeterminado si lo necesitas
        format={'YYYY/MM/DD'}
        onChange={handleDateChange}
      />
      {/* <button className="boton-estilo" onClick={handleLimpiarClick}>Pisos a limpiar</button> */}
      {jwtDecode(accessToken).role === ('Administrador') && (
        <button className="boton-estilo" onClick={handleLimpioClick}>Pisos limpios</button>
      )}
      
      <div>
        <h2>Pisos a limpiar en el día {selectedDate}</h2>
        {
          loading ?
            <h2>Cargando <Spin /></h2>
            :
            pisos.length === 0 ?
              <h2>No hay pisos limpios este día</h2>
              :
              <>
                {pisos.map((item) => (
                  <div key={item.inmueble._id}>
                    <div>
                      {renderItem({ item, estado: item.estado })}
                    </div>
                  </div>
                ))}
              </>
        }
      </div>

      <Modal
        title={`Información del Piso: ${currentPiso ? currentPiso.inmueble.name : ''}`}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {currentPiso && modalType === 'limpiador' && (
          <div>
            <h3>Asignar Limpiador al Piso: {currentPiso.inmueble.name}</h3>
            {/* {//console.log("VEAMOS PARA CUANDO SE ASIGNA", currentPiso.eventos)} */}
            <AsignarLimpiador token={accessToken} inquilinoId={currentPiso.idEventoSalida} setIsModalVisible={setIsModalVisible} eventos={currentPiso.eventos} /> 
          </div>
        )}
        {currentPiso && modalType === 'informacion' && (
          <div>
            <h3>Información del Piso: {currentPiso.inmueble.name}</h3>
            <VerInformacion informacion={informacion} />
          </div>
        )}
        {currentPiso && modalType === 'limpiar' && (
          <div>
            <h3>Limpieza: {currentPiso.inmueble.name}</h3>
            <Limpiar token={accessToken} currentPiso={currentPiso} updatePiso={updateInmuebleApi} setIsModalVisible={setIsModalVisible} />
          </div>
        )}

      </Modal>
    </>
  );
}
