// src/Footer.js
import React from 'react';

const Footer = () => {
  // Estilos en línea
  const footerStyle = {
    backgroundColor: '#000',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
    
    bottom: '0',
    width: '100%',
    fontSize: '14px',
    boxSizing: 'border-box', // Asegura que el padding no afecte el ancho total
    margin: '10px',
  };

  const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    margin: '0 10px',
  };

  const linkHoverStyle = {
    textDecoration: 'underline',
  };

  return (
    <footer style={footerStyle}>
     
      <div>
     
        <a 
          href="/politicaPrivacidad" 
          style={linkStyle} 
          onMouseOver={e => e.target.style.textDecoration = linkHoverStyle.textDecoration} 
          onMouseOut={e => e.target.style.textDecoration = linkStyle.textDecoration}
        >
          Política de privacidad
        </a>
        |
        <a 
          href="/terminosCondiciones" 
          style={linkStyle} 
          onMouseOver={e => e.target.style.textDecoration = linkHoverStyle.textDecoration} 
          onMouseOut={e => e.target.style.textDecoration = linkStyle.textDecoration}
        >
          Términos y condiciones
        </a>
      </div>
    </footer>
  );
};

export default Footer;
