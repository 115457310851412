import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  notification,
  Radio,
  RadioChangeEvent,
  Modal,
  Spin,
  Divider,
} from "antd";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  EMAIL,
  FUNCIONALIDAES,
} from "../../../utils/constants";
import { signInApi, signInSecondApi, mailRecovery } from "../../../api/user";



import "./LoginForm.scss";

import { basePath, apiVersion } from "../../../api/config";

import "./LoginForm.scss";





function EnviarCorreoCambioPasswordForm() {
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePasswordRecoverySubmit = async () => {
    try {
      setLoading(true);

      const data = { correo: recoveryEmail };
      const response = await mailRecovery(data);

      if (response.message) {
        notification.success({
          message: 'Éxito',
          description: 'Correo enviado con éxito',
        });
        setTimeout(()=> window.open(
          "/"
           
        ));
      } else {
        console.error("Error al enviar el correo de recuperación");
        notification.error({
          message: 'Error',
          description: 'Error al enviar el correo de recuperación' + response.error,
        });
      }
    } catch (error) {
      console.error("Error inesperado al enviar el correo de recuperación", error);
      notification.error({
        message: 'Error inesperado',
        description: 'Ocurrió un error inesperado al enviar el correo de recuperación',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <span>Introduce tu correo: </span>

      <Form className="password-recovery-form" onFinish={handlePasswordRecoverySubmit}>
        <Form.Item
          name="recoveryEmail"
          rules={[
            {
              required: true,
              message: 'Por favor, ingresa tu correo electrónico',
            },
            {
              type: 'email',
              message: 'Ingresa un correo electrónico válido',
            },
          ]}
        >
          <Input
            type="email"
            name="recoveryEmail"
            placeholder="Correo electrónico"
            className="login-input"
            onChange={(e) => setRecoveryEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-button"
            disabled={loading} // Desactivar el botón mientras se carga
          >
            {loading ? <Spin /> : 'Enviar correo de recuperación'}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}


export default function LoginForm() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleModalChangePassword, setIsVisibleModalChangePassword] = useState(false);
  const [isVisibleLoader, setisVisibleLoader] = useState(true);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [recoveryEmail, setRecoveryEmail] = useState(""); // Nuevo estado para el correo de recuperación


  const handleForgotPasswordClick = () => {
    setIsVisibleModalChangePassword(true);
  };
  


  const handlePasswordRecoverySubmit = async () => {
    try {
      const data = { correo: recoveryEmail }; // Utilizar el estado del correo de recuperación
     
      const response = await mailRecovery(data);
  
      if (response.ok) {
        // Lógica en caso de éxito
        //console.log("Correo enviado con éxito");
        notification.success({
          message: 'Éxito',
          description: 'Correo enviado con éxito',
        });
      } else {
        // Lógica en caso de error
        console.error("Error al enviar el correo de recuperación");
        notification.error({
          message: 'Error',
          description: 'Error al enviar el correo de recuperación',
        });
      }
    } catch (error) {
      // Manejo de errores generales
      console.error("Error inesperado al enviar el correo de recuperación", error);
      notification.error({
        message: 'Error inesperado',
        description: 'Ocurrió un error inesperado al enviar el correo de recuperación',
      });
    }
  };
  
  //Creamos unos hooks para guardar el estado del formulario
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    codigo: "",
  });
  const [value, setValue] = useState(1);
  //Cuando haya cambios debe actualizar el estado
  const changeForm = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {}, [isVisibleLoader, isVisibleModal]);

  const login = async (e) => {
    const result = await signInSecondApi(inputs);
    //console.log(result.message, " hola");

    //El backend devuelve message si ha habido algún error por eso este primer if
    //console.log(result);

    if (result.error) {
      notification["error"]({
        message: result.error,
      });
      if (
        result.error ===
        "El tiempo o numero de intentos para ingresar el código ha expirado."
      ) {
        setIsVisibleModal(false);
      }
    } else {
      notification["success"]({
        message: "Login correcto.",
      });
      //console.log(result);
      const { accessToken, refreshToken, funcionalidades } = result;
      //Almacenamos en local la informacion del access token y del refresh token
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      localStorage.setItem(EMAIL, inputs.email);
      localStorage.setItem(FUNCIONALIDAES, funcionalidades);

      //Redirigimos al admin
      ////console.log(basePath)
      window.location.href = "/admin";
      /*  Keyrock(inputs.idp).then((res) => {
        localStorage.setItem(KEYROCK_TOKEN, res);
        window.location.href = res;
      }); */
    }
  };

  const login2 = async (e) => {
    setisVisibleLoader(false);
    const result = await signInApi(inputs);
    //console.log(result.message, " hola");
    setisVisibleLoader(true);
    //El backend devuelve message si ha habido algún error por eso este primer if
    if (result.message) {
      setIsVisibleModal(true);
      notification["success"]({ message: result.message });
      
    } else {
      setIsVisibleModal(false);
      notification["error"]({
        message: result.error ?? "Error Servidor, contacte soporte.",
      });
    }
  };

  return (
    <>
      {!isVisibleModalChangePassword ? (
        
        isVisibleModal ? (
          <Form className="login-form" onChange={changeForm} onFinish={login}>
            <Form.Item>
              <Input
                type="codigo"
                name="codigo"
                placeholder="Código"
                className="login-input"
                value={inputs.codigo}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-button"
              >
                Entrar
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Form className="login-form" onChange={changeForm} onFinish={login2}>
            <Form.Item>
              <Input
                type="email"
                name="email"
                placeholder="Correo electrónico"
                className="login-input"
              />
            </Form.Item>
            <Form.Item>
              <Input
                type="password"
                name="password"
                placeholder="Contraseña"
                className="login-input"
              />
            </Form.Item>
            <a href="#" onClick={handleForgotPasswordClick}>
            ¿Has olvidado tu contraseña?
          </a>
            <Divider />
            {isVisibleLoader ? (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-button"
                >
                  Entrar
                </Button>
              </Form.Item>
            ) : (
              <Spin />
            )}
          </Form>
        )
      ) : (
      //   <Form className="password-recovery-form" onFinish={handlePasswordRecoverySubmit}>
      //   <Form.Item
      //     name="recoveryEmail"
      //     rules={[
      //       {
      //         required: true,
      //         message: 'Por favor, ingresa tu correo electrónico',
      //       },
      //       {
      //         type: 'email',
      //         message: 'Ingresa un correo electrónico válido',
      //       },
      //     ]}
      //   >
      //     <Input
      //       type="email"
      //       name="recoveryEmail"
      //       placeholder="Correo electrónico"
      //       className="login-input"
      //       onChange={(e) => setRecoveryEmail(e.target.value)} // Actualizar el estado del correo de recuperación
      //     />
      //   </Form.Item>
      //   <Form.Item>
          
      //     <Button
      //       type="primary"
      //       htmlType="submit"
      //       className="login-button"
      //     >
      //       Enviar correo de recuperación
      //     </Button>
      //   </Form.Item>
      // </Form>
     < EnviarCorreoCambioPasswordForm/>
      )}
    </>
  );
  
}