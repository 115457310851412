import { Card } from 'antd';
import React from 'react';

const  PoliticaPrivacidad = () => {
  // Estilos en línea
  const containerStyle = {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
  };

  const headingStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  const subheadingStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '20px 0 10px',
  };

  const sectionStyle = {
    marginBottom: '20px',
  };

  return (
    <Card title="Politica de Privacidad" bordered={true} style={{borderColor:"black", margin:"20px"}}>
    <div style={containerStyle}>
     

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}> TRATAMIENTO DE DATOS PERSONALES Y PRIVACIDAD</h2>
        <p>
          La empresa DPD RENTAL MANAGEMENT SL va a tratar sus datos en disposición del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo (en adelante GDPR) de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, y de la Ley Orgánica 3/2018(en adelante LOPDGDD) de 5 de diciembre de 2018, de Protección de Datos Personales y Garantía de los Derechos Digitales.
        </p>
        <p>
          Siendo los datos del Responsable los siguientes:
        </p>
        <h2 style={subheadingStyle}>1. RESPONSABLE DEL TRATAMIENTO DE DATOS</h2>
        <p>Identidad: DPD RENTAL MANAGEMENT SL.</p>
        <p>CIF: B72879711</p>
        <p>Dirección: CAMINO FUENTE JUNQUERA 142 50012 (ZARAGOZA).</p>
        <p>Dirección de correo electrónico: direccion.comotucasa@gmail.com.</p>
        <p>
          Se informa al interesado que DPD RENTAL MANAGEMENT SL ha nombrado un Responsable de Privacidad y/o Seguridad ante el cual podrá poner de manifiesto cualquier cuestión relativa al tratamiento de sus datos personales. El interesado podrá contactar con el Responsable de Privacidad y/o Seguridad a través de los siguientes datos de contacto:
        </p>
        <p>
          - CAMINO FUENTE JUNQUERA 142 50012 (ZARAGOZA).<br />
          - direccion.comotucasa@gmail.com.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>2. FINALIDAD</h2>
        <p>
          En DPD RENTAL MANAGEMENT SL tratamos la información que nos facilitan las personas interesadas con el fin de prestar el servicio de comercio electrónico, así como cualquier otro servicio ofertado por nuestra empresa y el envío de comunicaciones comerciales que nos hayan solicitado.
        </p>
        <p>
          Recabamos su información personal para gestionar sus compras, pedidos o solicitudes que nos realiza, bien sea a través de nuestra tienda online o nuestra web corporativa, por teléfono o en algún formulario en papel.
        </p>
        <p>
          Conforme al artículo 6, apartado 1, párrafo 1, letra A del RGPD, de haber obtenido previamente su consentimiento expreso a la inscripción a nuestro boletín, utilizaremos los datos necesarios para ello para el envío de comunicaciones comerciales de acuerdo a dicho consentimiento.
        </p>
        <p>
          En cumplimiento de los establecido en el RGPD (UE 2016/679) de 26 de abril, le informamos que los datos suministrados, así como aquellos datos derivados de su navegación, podrán ser almacenados en los ficheros de la empresa DPD RENTAL MANAGEMENT SL y tratados para la finalidad de atender su solicitud y el mantenimiento de la relación que se establezca en los formularios que suscriba.
        </p>
        <p>
          Como titulares de los datos personales y la información que nos facilitan, los usuarios deben comprometerse a que éstos sean exactos, completos, veraces, y a comunicarnos cualquier modificación a fin de que la información contenida en nuestro fichero esté en todo momento actualizada y no contenga errores. Los usuarios responderán de los daños y perjuicios que pudiera ocasionar a causa de la cumplimentación de los formularios con datos falsos, incompletos o no actualizados.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>3. ¿CUAL ES LA LEGITIMACION PARA EL TRATAMIENTO DE SUS DATOS?</h2>
        <p>
          Para el tratamiento de su información personal nos basamos en la legitimación por varios motivos:
        </p>
        <ul>
          <li>Para el cumplimiento de un contrato y/o relación comercial.</li>
          <li>Para el cumplimiento de distintas obligaciones legales.</li>
          <li>Por interés legítimo, por ejemplo, por motivos de seguridad, para mejorar nuestros servicios y productos a través de estudios de mercado, o para gestionar las solicitudes, consultas o posibles reclamaciones que pudieran surgir.</li>
          <li>Con su consentimiento, por ejemplo, para el envío de ofertas personalizadas u otras comunicaciones comerciales.</li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>4. ¿POR CUANTO TIEMPO ALMACENAMOS SU INFORMACION PERSONAL?</h2>
        <p>
          Los datos personales proporcionados se conservarán mientras exista una relación contractual y/o comercial con usted y posteriormente durante los plazos legalmente establecidos.
        </p>
        <p>
          En estos casos mantendremos la información debidamente bloqueada, sin darle ningún uso, mientras pueda ser necesaria para el ejercicio o defensa de reclamaciones o pueda derivarse algún tipo de responsabilidad judicial, legal o contractual de su tratamiento, que deba ser atendida y para lo cual sea necesaria su recuperación. En ningún caso se mantendrán sus datos más de 10 años desde la finalización de la relación contractual, plazo de conservación obligatorio establecido por la Ley 10/2010, de 28 de Abril de Prevención de Blanqueo de Capitales y Financiación del Terrorismo.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>5. CONSENTIMIENTO</h2>
        <p>
          El usuario prestará su consentimiento para que DPD RENTAL MANAGEMENT SL pueda hacer uso de sus datos personales a fin de prestar un correcto cumplimiento de los servicios ofrecidos. La cumplimentación del formulario incluido en el sitio web implica el consentimiento expreso del usuario a la inclusión de sus datos de carácter personal en un fichero, titularidad de DPD RENTAL MANAGEMENT SL.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>6. CESION DE DATOS PERSONALES</h2>
        <p>
          DPD RENTAL MANAGEMENT SL no cederá datos de carácter personal sin el consentimiento expreso de sus titulares, siendo solo cedidos con la finalidad expresada y siempre con el consentimiento del usuario o cliente.
        </p>
        <p>
          Cuando sea necesario y en cumplimiento de la ley vigente y sus requerimientos podríamos proceder a compartir su información personal con las siguientes categorías:
        </p>
        <ul>
          <li>Terceras partes proveedoras de servicios que tratan datos personales en nombre de DPD RENTAL MANAGEMENT SL, por ejemplo para tratar tarjetas de crédito y pagos, envíos y entregas, gestionar y dar servicio a nuestros datos, distribuir correos electrónicos, investigar y analizar, gestión y promoción de marcas y producto, así como administrar ciertos servicios y funciones.</li>
          <li>Las Administraciones y Organismos públicos para el cumplimiento de obligaciones directamente exigibles a DPD RENTAL MANAGEMENT SL y/o cuando exista la habilitación legal correspondiente.</li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>7. CONFIDENCIALIDAD Y SECRETO PROFESIONAL</h2>
        <p>
          En cumplimiento del RGPD UE 2016/679, DPD RENTAL MANAGEMENT SL informa que los datos de carácter personal que se recaban directamente del usuario a través de los distintos recursos disponibles en el portal, serán tratados de forma confidencial y quedarán incorporados a los respectivos ficheros de los que DPD RENTAL MANAGEMENT SL es responsable, con las finalidades detalladas no siendo utilizados para finalidades incompatibles con estas.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>8. DERECHOS Y COMO EJERCERLOS</h2>
        <p>
          En cumplimiento de lo dispuesto en el Reglamento Europeo 2016/679, DE Protección de Datos de Carácter Personal, como cliente de DPD RENTAL MANAGEMENT SL podrá en todo momento ejercitar los derechos que detallamos a continuación comunicándolo por escrito a DPD RENTAL MANAGEMENT SL, CAMINO FUENTE JUNQUERA 142 50012 (ZARAGOZA) o a través de nuestra dirección de correo electrónico: direccion.comotucasa@gmail.com.
        </p>
        <ul

>
          <li>Conforme al art.15RGPD, el derecho, en la medida ahí indicada, de obtener información sobre los datos personales que le conciernan y que tratamos;</li>
          <li>Conforme al art.16RGPD, el derecho a obtener sin dilación indebida la rectificación o que se completen los datos personales que le conciernan;</li>
          <li>Conforme al art.17RGPD, el derecho a obtener la supresión de los datos personales que le conciernan, siempre y cuando el tratamiento no sea necesario; para ejercer el derecho a la libertad de expresión o información, para el cumplimiento de una obligación legal, por razones de interés público o para la formulación, el ejercicio o la defensa de reclamaciones;</li>
          <li>Conforme al art.18RGPD, el derecho a obtener la limitación del tratamiento de sus datos cuando; el interesado impugne la exactitud de los datos, el tratamiento sea ilícito y usted se oponga a la supresión de los datos, ya no necesitemos los datos pero usted se haya opuesto al tratamiento en virtud del artículo 21RGPD;</li>
          <li>Conforme al art.20RGPD, el derecho a recibir los datos personales que le incumban y que nos haya facilitado, en un formato estructurado, de uso común y lectura mecánica, o a pedir que se transmitan a otro responsable.</li>
        </ul>
        <p>
          Podrás ejercer tus derechos sin coste alguno, y tendrás derecho a recibir una respuesta en los plazos establecidos por la legislación vigente en materia de protección de datos.
        </p>
        <p>
          Conforme al art.77RGPD, en caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no esté satisfecho con la respuesta del ejercicio de sus derechos, podrá presentar una reclamación ante una autoridad de control. Por regla general puede dirigirse para ello a la autoridad de control de su lugar de residencia o trabajo habituales o de la sede de nuestra empresa.
        </p>
        <p>
          En España:<br />
          Agencia Española de Protección de Datos (AEPD)<br />
          Calle Jorge Juan nº 6<br />
          28001 (Madrid)<br />
          Asimismo si se considera que el tratamiento de sus datos personales vulnera la normativa o sus derechos de privacidad, el afectado podrá presentar una reclamación:<br />
          Ante la Autoridad de Control Competente.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>9. MEDIDAS Y NIVEL DE SEGURIDAD</h2>
        <p>
          En cumplimiento de la normativa vigente, RGPD UE 2016/679 de 26 de abril, DPD RENTAL MANAGEMENT SL ha adoptado las medidas técnicas y organizativas necesarias para mantener el nivel de seguridad requerido en atención a los datos personales tratados. Así se evita la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales que los interesados pudieran facilitar como consecuencia del acceso a las diferentes secciones de nuestra web.
        </p>
        <p>
          Conforme a nuestra garantía de seguridad y confidencialidad estamos especialmente interesados en ofrecerte el más alto nivel de seguridad y proteger la confidencialidad de la información que nos aportas. Por ello, las transacciones comerciales son realizadas en un entorno servidor seguro bajo protocolo SSL (Secure Socket Layer).
        </p>
        <p>
          No obstante, si usted publica información personal en línea que es accesible al público, es posible que usted reciba mensajes no solicitados de otras personas y que sus datos, por tanto, sean conocidos por terceros. Por ello, se le recomienda la máxima diligencia en esta materia y la utilización de todas las herramientas de seguridad que tenga a su alcance.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>AVISO LEGAL</h2>
        <p>
          En cumplimiento del artículo 10 de la Ley 34/2002, del 11 de julio de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE) se exponen a continuación los datos identificativos de la empresa:
        </p>
        <p>
          DPD RENTAL MANAGEMENT SL.<br />
          CAMINO FUENTE LA JUNQUERA 142 50012 (ZARAGOZA).<br />
          CIF: B72879711
        </p>
        <p><strong>PROTECCIÓN DE DATOS</strong></p>
        <p>
          DPD RENTAL MANAGEMENT SL es consciente de la importancia de la privacidad de los datos de carácter personal y por ello, ha implementado una política de tratamiento de datos orientada a proveer la máxima seguridad en el uso y recogida de los mismos, garantizando el cumplimiento de la normativa vigente en la materia y configurando dicha política como uno de los pilares básicos en las líneas de actuación de la entidad.
        </p>
        <p>
          Durante la navegación a través de nuestra web es posible que se soliciten datos de carácter personal a través de diferentes formularios dispuestos al efecto.
        </p>
        <p>
          Dichos datos serán tratados con los criterios establecidos en nuestra Política de Privacidad.
        </p>
        <p><strong>PROPIEDAD INTELECTUAL E INDUSTRIAL</strong></p>
        <p>
          La totalidad del contenido de esta página web, los logotipos, marcas, nombres comerciales, textos, fotografías, diseños, videos, referencias de productos u otros elementos, están protegidos por derechos de propiedad intelectual e industrial titularidad de DPD RENTAL MANAGEMENT SL.
        </p>
        <p>
          Así mismo, será DPD RENTAL MANAGEMENT SL a la que corresponda el ejercicio exclusivo de derechos de explotación de los mismos en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública y transformación, salvo en los casos en los que DPD RENTAL MANAGEMENT SL lo autorice expresamente.
        </p>
        <p>
          La utilización no autorizada de la información contenida en esta Web, así como la lesión de los derechos de Propiedad Intelectual o Industrial de DPD RENTAL MANAGEMENT SL dará lugar a las responsabilidades legalmente establecidas.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>ACCESO Y USO</h2>
        <p>
          La simple y mera utilización de la página otorga la condición de usuario de la página, bien sea persona física o jurídica, y obligatoriamente implica la aceptación completa, plena y sin reservas de todas y cada una de las cláusulas y condiciones generales incluidas en el Aviso Legal. Si el usuario no estuviera conforme con las cláusulas y condiciones de uso de este Aviso Legal, se abstendrá de utilizar la página. Este Aviso está sujeto a cambios y actualizaciones por lo que la versión publicada por DPD RENTAL MANAGEMENT SL puede ser diferente en cada momento en que el usuario acceda al Portal.
        </p>
        <p>
          Las condiciones de acceso y uso del presente sitio web se rigen por la legalidad vigente y por el principio de buena fe comprometiéndose el usuario a realizar un buen uso de la web.
        </p>
        <p>
          No se permiten conductas que vayan contra la ley, los derechos o intereses de terceros. Por tanto, el usuario se compromete a la utilización de los mismos de manera conforme con la ley, la moral y buenas costumbres generalmente aceptadas y el orden público, y se obliga a abstenerse de utilizar el sitio web y servicios con fines o efectos ilícitos, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar el sitio web y/o la imagen de DPD RENTAL MANAGEMENT SL o impedir la normal utilización o disfrute del sitio web por parte del resto de usuarios.
        </p>
        <p>
          Queda prohibido, salvo en los casos que DPD RENTAL MANAGEMENT SL lo autorice expresamente, presentar las páginas de nuestra web, o la información contenida en ellas bajo marcos, signos distintivos, marcas o denominaciones sociales o comerciales de otra persona, empresa o entidad.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>LIMITACION DE RESPONSABILIDAD</h2>
        <p>
          DPD RENTAL MANAGEMENT SL no podrá ser considerada responsable de ningún daño que se derive del uso ilegal o indebido que los usuarios puedan hacer de la información y contenidos presentes en nuestra página.
        </p>
        <p>
          La información está sometida a posibles cambios periódicos sin previo aviso de su contenido por ampliación, mejora, corrección o actualización de los contenidos.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>DISPONIBILIDAD DE LA PAGINA</h2>
        <p>
          DPD RENTAL MANAGEMENT SL se reserva el derecho de suspender o terminar el acceso a su web, sin previo aviso, por razones técnicas o de cualquier otra índole, pudiendo asimismo modificar unilateralmente tanto las condiciones de acceso, como la totalidad o parte de los contenidos en ella incluidos.
        </p>
        <p>
          DPD RENTAL MANAGEMENT SL no garantiza la inexistencia de interrupciones o errores en el acceso a la página, a sus contenidos, ni que éstos se encuentren actualizados, aunque desarrollará sus mejores esfuerzos para, en su caso, subsanarlos y/o actualiz

arlos.
        </p>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>HIPERENLACES</h2>
        <p>
          Los usuarios que se propongan establecer un hiperenlace entre su página web y la nuestra, deberán tener en cuenta lo siguiente:
        </p>
        <ul>
          <li>
            La página web en la que se establezca el hiperenlace no podrá contener ninguna marca, denominación, logotipo, eslogan u otros signos distintivos pertenecientes a DPD RENTAL MANAGEMENT SL, excepto aquellos signos que formen parte del mismo hiperenlace.
          </li>
          <li>
            La página web en la que se establezca el hiperenlace no contendrá informaciones o contenidos ilícitos, ni contrarios a cualesquiera derechos de terceros.
          </li>
          <li>
            El establecimiento del hiperenlace no implica la existencia de relaciones entre DPD RENTAL MANAGEMENT SL y el propietario de la página web en la que se establezca, ni la aceptación o aprobación por parte de DPD RENTAL MANAGEMENT SL de sus contenidos o servicios. Por lo tanto no se declarará ni se dará a entender que DPD RENTAL MANAGEMENT SL ha autorizado expresamente el hiperenlace ni que supervisa o asume ninguno de los servicios ofrecidos.
          </li>
          <li>
            DPD RENTAL MANAGEMENT SL no se responsabiliza ni garantiza la calidad, exactitud, fiabilidad, corrección o moralidad de contenidos o servicios de la página desde la que se establece el hiperenlace. El usuario asume bajo su exclusiva responsabilidad las consecuencias, daños o acciones que pudieran derivarse del acceso a la página web del hiperenlace.
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={subheadingStyle}>LEY APLICABLE Y JURISDICCION</h2>
        <p>
          Las presentes condiciones se rigen por la legislación española. Para cualquier litigio que pudiera surgir relacionado con el sitio web o la actividad que en él se desarrolla serán competentes los Juzgados y Tribunales de Zaragoza, renunciando expresamente el usuario a cualquier otro fuero que pudiera corresponderle.
        </p>
      </div>
    </div>
    </Card>
  );
}
export default  PoliticaPrivacidad