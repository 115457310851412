import React, { useState, useEffect } from "react";
import { obtenerListaInmuebles } from "../../../api/inmueble";
import { getAccessTokenApi } from "../../../api/auth";
import { getComentarioLimpiezaPorPiso, guardarComentarioLimpieza } from "../../../api/inquilino";
import { Table, Select, Button, Row, Col, DatePicker, notification, Modal, Input, Divider } from "antd";
import "antd/dist/antd.css";
import dayjs from "dayjs";
import { EditOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function ComentariosLimpiezaPorPiso({inmueble_id}) {
  const mesActual = dayjs(); // Obtiene la fecha actual con dayjs
  const [lista_pisos, set_lista_pisos] = useState([]);
  const [inmuebleSelected, setInmuebleSelected] = useState(inmueble_id?? "");
  const [comentarios, setComentarios] = useState([]);
  const [monthQuery, setMonthQuery] = useState(mesActual.month() + 1); // Sumamos 1 porque en JavaScript los meses van de 0 a 11
  const [yearQuery, setYearQuery] = useState(mesActual.year());
  const [modalVisible, setModalVisible] = useState(false);
  const [comentarioAEditar, setComentarioAEditar] = useState("");
  const [comentarioEditado, setComentarioEditado] = useState("");
  
  const [reserva_seleccinada, setReservaSeleccionada] = useState("");
  useEffect(() => {
    setInmuebleSelected(inmueble_id)
     }, [inmueble_id]);
  useEffect(() => {
      setInmuebleSelected(inmueble_id)
       }, [comentarioEditado]);
  useEffect(() => {
    const accesToken = getAccessTokenApi();
    getComentarioLimpiezaPorPiso(accesToken, monthQuery, yearQuery, inmuebleSelected)
      .then((response) => {
        setComentarios(response.message);
      })
      .catch((error) => {
        console.error("Error al obtener comentarios:", error);
      });
  }, [inmuebleSelected]);
     
  useEffect(() => {
    const accesToken = getAccessTokenApi();
    obtenerListaInmuebles(accesToken).then((response) => {
      set_lista_pisos(response.Pisos.map((piso) => ({ value: piso.id, label: piso.nombre })));
    });
  }, []);

  function onChange(date, dateString) {
    setMonthQuery(date.month() + 1); // Sumamos 1 porque en JavaScript los meses van de 0 a 11
    setYearQuery(date.year());
  }

  const columns = [
    {
      title: "Fecha de salida",
      dataIndex: "fecha_salida",
      key: "fecha_salida",
    },
    {
      title: "Comentario del limpiador",
      dataIndex: "comentario_limpiador",
      key: "comentario_limpiador",
    },
    {
      title: "Limpiador",
      dataIndex: "limpiador",
      key: "limpiador",
    },
    {
      title: "Anotacion",
      dataIndex: "anotacion_despues_limpieza",
      key: "anotacion_despues_limpieza",
      render: (text, record) => (
        <>
          {text} 
          <Button
              style={{ backgroundColor: 'blue', borderColor: 'blue', color: 'white' }}
               onClick={() => {
              setComentarioAEditar(text);
              
              setModalVisible(true);
              setReservaSeleccionada(record.id);
            }}
          >
            Escribe: 
            <EditOutlined />
          </Button>
        </>
      ),
    },
  ];

  const handleSaveComentario = async () => {
    try {
      const accessToken = getAccessTokenApi();
      await guardarComentarioLimpieza(accessToken, comentarioAEditar, inmuebleSelected, reserva_seleccinada);
      notification.success({
        message: "Comentario guardado exitosamente",
      });
      setModalVisible(false);
    } catch (error) {
      console.error("Error al guardar el comentario:", error);
      notification.error({
        message: "Error al guardar el comentario",
      });
    }
  };

  return (
    <>
    {inmueble_id}
      <div style={{ padding: "50px" }}>
        <Row gutter={24}>
          <Col span={8}>
            <Select
              onChange={(value) => setInmuebleSelected(value)}
              placeholder="Selecciona un inmueble"
              value={inmuebleSelected}
            >
              {lista_pisos.map((piso) => (
                <Option key={piso.value} value={piso.value}>
                  {piso.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <DatePicker onChange={onChange} picker="month" defaultValue={mesActual} />
          </Col>
          <Col span={8}>
            <Button
              onClick={() => {
                const accesToken = getAccessTokenApi();
                getComentarioLimpiezaPorPiso(accesToken, monthQuery, yearQuery, inmuebleSelected)
                  .then((response) => {
                    setComentarios(response.message);
                  })
                  .catch((error) => {
                    console.error("Error al obtener comentarios:", error);
                  });
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </div>
      <div style={{ padding: "50px" }}>
        <Table
          dataSource={comentarios.map((comentario) => ({
            ...comentario,
            limpiador: comentario.limpiadores.join(", "), // Si los limpiadores son un array de nombres, puedes usar join para unirlos
          }))}
          columns={columns}
        />
      </div>
      <Divider></Divider>
      <Modal
        title="Editar Comentario"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleSaveComentario}
      >
        <Input.TextArea
          value={comentarioAEditar}
          onChange={(e) => setComentarioAEditar(e.target.value)}
          rows={20}
        />
      </Modal>
    </>
  );
}
