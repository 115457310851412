import React, { useState, useEffect } from "react";
import {  updateIncidencia } from "../../../../../src/api/incidencia";
import { Input, Checkbox, Select, Button, notification, Row, Col, Divider } from "antd";
import moment from "moment";
import { obtenerListaInmuebles } from "../../../../api/inmueble";
import { getAccessTokenApi } from "../../../../api/auth";
import { getUsersByRoleApi } from "../../../../api/user";

const { Option } = Select;

const IncidenciaEditVistaMantenimiento = ({ setModalIsOpen, setReload, data }) => {
  const [incidenciaData, setIncidenciaData] = useState(data ?? {
    _id: "",
    id_apartamento: "",
    descripcion_tarea: "",
    fechas_disponibles: "", // Establece la fecha actual por defecto
    urgente: "",
    nivel_prioridad: "",
    asignado: "",
    estado: "",
    fecha_creacion: "",
    fecha_resolucion: "",
    persona_asignada: "",
    comentarios: "",
    historial: "",
    comentario_fin_tarea: "",
    precio_venta_publico:0,
    coste_materiales_usados_reparacion:0
  });
  const [inmuebleSelected, setInmuebleSelected] = useState(data?.id_apartamento);
  const [lista_pisos, setListaPisos] = useState([]);
  const [lista_mantenimiento, setListaMantenimiento] = useState([]);
  const [personaMantenimientoSelected, setPersonaMantenimientoSelected] = useState(data?.persona_asignada ?? "");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = getAccessTokenApi();
        const response = await obtenerListaInmuebles(accessToken);
        setListaPisos(response.Pisos.map((piso) => ({ value: piso.id, label: piso.nombre })));
      } catch (error) {
        console.error('Error al obtener la lista de inmuebles:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const obtenerMantenimiento = async () => {
      try {
        const response = await getUsersByRoleApi(getAccessTokenApi(), 'Mantenimiento');
        if (response && response.users) {
          setListaMantenimiento(response.users.map((user) => ({ value: user.id, label: user.name })));
        }
      } catch (error) {
        console.error("Error al obtener limpiadores:", error);
      }
    };
    obtenerMantenimiento();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("handleInputChange",name,value)
    setIncidenciaData({ ...incidenciaData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setIncidenciaData({ ...incidenciaData, [name]: checked });
  };

  const handleSubmit = async () => {
    try {
      const resp = await updateIncidencia(incidenciaData._id,incidenciaData, getAccessTokenApi());
      console.log("Nueva incidencia creada:", resp);
      if (resp.message) {
        notification["success"]({
          message: "Éxito creando incidencia"
        });
        setModalIsOpen(false);
        setReload(true);
      } else {
        notification["error"]({
          message: "Error al crear la incidencia"
        });
      }
    } catch (error) {
      console.error('Error al crear la incidencia:', error);
      notification.error({
        message: "Error al crear la incidencia",
        description: "Ha ocurrido un error al crear la incidencia. Por favor, inténtalo de nuevo."
      });
    }
  };
  useEffect(()=>{console.log(data);
    setIncidenciaData(data);
    setInmuebleSelected(data?.id_apartamento)
},[data])
useEffect(()=>{
    
},[lista_mantenimiento,incidenciaData,lista_pisos])
  return (
    <div>
      <h2>Editar Incidencia</h2>
      <Row gutter={[16, 16]}>
       
        <Divider>Estado</Divider>
        <Col span={12}>
          <Select value={incidenciaData.estado} onChange={(value) => setIncidenciaData({ ...incidenciaData, estado: value })}>
           
            <Option value="por_iniciar">Por Iniciar</Option>
            <Option value="pendiente">Pendiente</Option>
            <Option value="resuelto">Resuelto</Option>
          </Select>
        </Col>
        
        <Col span={12}>
          Fecha resuelto: <Input type="date" name="fecha_resolucion" value={incidenciaData?.fecha_resolucion} onChange={handleInputChange} />
        </Col>
        <Col span={12}>
          <label>Coste materiales usados reparación (€)</label>
          <Input 
            type="number" 
            name="coste_materiales_usados_reparacion" 
            value={incidenciaData.coste_materiales_usados_reparacion} 
            onChange={handleInputChange} 
            addonAfter="€" // Agrega el símbolo de euros al final del campo
          />
        </Col>

                <Col span={24}>
                <Input.TextArea 
                  disabled={true}
                    name="descripcion_tarea" 
                    value={incidenciaData?.descripcion_tarea} 
                    onChange={handleInputChange} 
                    rows={10}
                    placeholder="Describe la incidencia"
                    maxLength={4000}
                />
                </Col>
                <Col span={24}>
                <Input.TextArea 
              
                    name="comentario_fin_tarea" 
                    value={incidenciaData?.comentario_fin_tarea} 
                    onChange={handleInputChange} 
                    rows={10}
                    placeholder="Escribe si está resuelto o que se necesita para terminar"
                    maxLength={4000}
                />
                </Col>
              
      
        <Col span={24}>
          <Button type="primary" onClick={handleSubmit}>Guardar Cambios</Button>
        </Col>
      </Row>
    </div>
  );
};

export default IncidenciaEditVistaMantenimiento;
