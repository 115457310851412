import React, { useState } from "react";
import { Card, Row, Col, Collapse, Divider, Input, Button } from "antd";
import IncidenciasPage from "../../Incidencia/IncidenciaPage";
import dayjs from "dayjs";
import FacturacionMes from "../../FacturacionMes";

const mesActual = dayjs(); // Obtiene la fecha actual con dayjs

export default function CalculosGlobalesPiso({
  texto = "",
  reservas = 0,
  ingreso_propietario = "",
  direccion_facturacion = "",
  id_facturacion = "",
  nombre_facturacion = "",
  iban = "",
  ciudad = "",
  nombre = "",
  tipo_gestion = "",
  Facturacion = 0,
  pago_limpieza = 0,
  Comision = 0,
  dias = 0,
  ComoTuCasa = 0,
  Propietario_gestion = 0,
  Propietario_resto = 0,
  IVA_ComoTuCasa = 0,
  IVA_Reservas_Subarriendo = 0,
  inmueble = "",
  mes = mesActual.month(),
  year = mesActual.year(),
  coste_materiales_usados_reparacion =0,
  precio_venta_publico=0,
  propietario_paga_limpieza = false
}) {
  const [num_tours_v, set_num_tours_v] = useState([]);
  const [precio_mantenimientos, setprecio_mantenimientos] = useState(0);

  const { Panel } = Collapse;

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(value);
  };
  const copiarAlPortapapeles = (record) => {
    console.log("copiarAlPortapapeles", record)
    // Convertir los datos del record a un formato de texto
    // const textoACopiar = `
    //   ${record.iban};;;${record.nombre_facturacion};${record.direccion_facturacion};;${record.ciudad};;;;${record.id_facturacion};${record.ingresoPropietario};`;
    
    // // Copiar el texto al portapapeles
    // navigator.clipboard.writeText(textoACopiar).then(() => {
    //   // message.success('Datos copiados al portapapeles');
    // }).catch(() => {
    //   // message.error('Error al copiar los datos');
    // });
     const textoACopiar = `${record}`;
      
    // Copiar el texto al portapapeles
    navigator.clipboard.writeText(textoACopiar).then(() => {
      // message.success('Datos copiados al portapapeles');
    }).catch(() => {
      // message.error('Error al copiar los datos');
    });
  };
  
  return (
    <Card title={<>Cálculos Globales por Piso: <strong>{nombre}</strong></>} bordered={false} style={{ width: "100%", margin: "auto" }}>
      <Collapse defaultActiveKey={["1","7","3"]}>
        <Panel header={<>Informacion general: <strong>{nombre}</strong></>} key="1">
        <Row>
            <Col span={4}><strong>Nombre piso:</strong></Col>
            <Col span={4}><strong>{nombre}</strong></Col>
          </Row>
          
          <Row>
            <Col span={4}><strong>Ciudad:</strong></Col>
            <Col span={4}>{ciudad}</Col>
          </Row>
          <Row>
            <Col span={4}><strong>Tipo de Gestión:</strong></Col>
            <Col span={4}>{tipo_gestion}</Col>
          </Row>
          <Row>
            <Col span={4}><strong>Propietario piso:</strong></Col>
            <Col span={4}><strong>{nombre_facturacion}</strong></Col>
          </Row>
        </Panel>
        
        <Panel header="Reservas" key="7" style={{ padding: "20px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
        <div style={{ maxWidth: "800px" }}>
          <FacturacionMes mes={mes} year={year} inmueble={inmueble} busqueda_automatica_al_inicio={true}></FacturacionMes>
          </div>
        </Panel> 
        
        <Panel header="Resumen" key="3">
          <Row>
            <Col span={4}>
              {propietario_paga_limpieza ?<>
                <Row><strong>Total a ingresar a Propietario: </strong> <b> {Number(ingreso_propietario).toFixed(2)}</b> 
                <Button
                  onClick={() => copiarAlPortapapeles(Number(Propietario_gestion)-coste_materiales_usados_reparacion-precio_venta_publico + pago_limpieza)}
                >
                  Copiar
                </Button> </Row>
                <Row>Propietario_gestion: <b>{formatCurrency(Number( Propietario_gestion))}</b>
                  <Button
                    onClick={() => copiarAlPortapapeles(Number( Propietario_gestion))}
                  >
                    Copiar
                  </Button>
                </Row>
                <Row>Pago limpieza: <b>{formatCurrency(Number( pago_limpieza))}</b>
                  <Button
                    onClick={() => copiarAlPortapapeles(Number( pago_limpieza))}
                  >
                    Copiar
                  </Button>
                </Row>
                <Row>Precio Salidas mantenimientos: <b>-{formatCurrency(Number( precio_venta_publico))}</b>
                <Button
                  onClick={() => copiarAlPortapapeles(Number( precio_venta_publico))}
                >
                  Copiar
                </Button></Row>
                <Row>Precio Materiales mantenimientos: <b>-{formatCurrency(Number( coste_materiales_usados_reparacion))}</b>
                <Button
                  onClick={() => copiarAlPortapapeles(Number( coste_materiales_usados_reparacion))}
                >
                  Copiar
                </Button></Row>
             
                <Row><strong>Total:</strong> <b>{formatCurrency(Number(Propietario_gestion)-precio_venta_publico-coste_materiales_usados_reparacion + pago_limpieza)}</b>
                <Button
                  onClick={() => copiarAlPortapapeles(Number(Propietario_gestion)-precio_venta_publico-coste_materiales_usados_reparacion + pago_limpieza)}
                >
                  Copiar
                </Button></Row>
                
             
              </>
              :
              <>
              <Row><strong>Total a ingresar a Propietario: </strong> <b> {Number(ingreso_propietario).toFixed(2)}</b>

              <Button
                  onClick={() => copiarAlPortapapeles(Number(Propietario_gestion)-coste_materiales_usados_reparacion-precio_venta_publico )}
                >
                  Copiar
                </Button> 
              
              </Row>
                <Row>Propietario_gestion: <b>{formatCurrency(Number( Propietario_gestion))}</b>
                <Button
                  onClick={() => copiarAlPortapapeles(Number( Propietario_gestion))}
                >
                  Copiar
                </Button> 
              
                
                </Row>
                <Row>Precio Salidas mantenimientos: -<b>{formatCurrency(Number( precio_venta_publico))}</b>
                  <Button
                    onClick={() => copiarAlPortapapeles(Number( precio_venta_publico))}
                  >
                    Copiar
                  </Button> 
                  </Row>
                  <Row>Precio Materiales mantenimientos: -<b>{formatCurrency(Number( coste_materiales_usados_reparacion))}</b>
                  <Button
                    onClick={() => copiarAlPortapapeles(Number( coste_materiales_usados_reparacion))}
                  >
                    Copiar
                  </Button> 
                  </Row>
                <Row><strong>Total:</strong> <b>{formatCurrency(Number(Propietario_gestion)-coste_materiales_usados_reparacion-precio_venta_publico )}</b>

                <Button
                    onClick={() => copiarAlPortapapeles(Number(Propietario_gestion)-coste_materiales_usados_reparacion-precio_venta_publico)}
                  >
                    Copiar
                  </Button> 
                </Row>
                
             
                </>
              }
            </Col>
            <Col span={4}>
            {propietario_paga_limpieza ?
            <>
              <Row>
              <strong>Turistea Factura:</strong>
              </Row>
              <Row>
              BI : <b>{formatCurrency(ComoTuCasa  / 1.21 + Comision / 1.21 + coste_materiales_usados_reparacion/1.21 + precio_venta_publico/1.21)}</b>
              
              <Button
                  onClick={() => copiarAlPortapapeles(Number(ComoTuCasa  / 1.21 + Comision / 1.21 + coste_materiales_usados_reparacion/1.21 + precio_venta_publico/1.21).toFixed(2))}
                >
                  Copiar
                </Button>
              
              </Row>
              <Row>
              IVA: <b>{formatCurrency(IVA_ComoTuCasa  + Comision - Comision / 1.21 + coste_materiales_usados_reparacion- coste_materiales_usados_reparacion/1.21 + precio_venta_publico- precio_venta_publico/1.21)}</b>
              <Button
                  onClick={() => copiarAlPortapapeles(Number(IVA_ComoTuCasa  + Comision - Comision / 1.21 + coste_materiales_usados_reparacion- coste_materiales_usados_reparacion/1.21 + precio_venta_publico- precio_venta_publico/1.21).toFixed(2))}
                >
                  Copiar
                </Button>
              </Row>
              <Row>
              Total: <b>{formatCurrency(ComoTuCasa + Comision / 1.21 + (coste_materiales_usados_reparacion + precio_venta_publico)/1.21+IVA_ComoTuCasa + Comision - Comision / 1.21 + (coste_materiales_usados_reparacion + precio_venta_publico)- (coste_materiales_usados_reparacion + precio_venta_publico)/1.21)}</b>
              
              <Button
                  onClick={() => copiarAlPortapapeles(Number(ComoTuCasa + Comision / 1.21 + (coste_materiales_usados_reparacion + precio_venta_publico)/1.21+IVA_ComoTuCasa + Comision - Comision / 1.21 + (coste_materiales_usados_reparacion + precio_venta_publico)- (coste_materiales_usados_reparacion + precio_venta_publico)/1.21).toFixed(2))}
                >
                  Copiar
                </Button>
                (Comision AyB {formatCurrency(Comision)} + Comision Tursites% {formatCurrency(ComoTuCasa)} + Salidas {formatCurrency(precio_venta_publico)} + Materiales  {formatCurrency(coste_materiales_usados_reparacion)})
             
              </Row>
              </> :
              
              <>  <Row>
             <strong>Turistea Factura:</strong>
              </Row>
              <Row>
              Total: <b>{formatCurrency(ComoTuCasa + pago_limpieza / 1.21 + Comision / 1.21 + (coste_materiales_usados_reparacion + precio_venta_publico)/1.21+IVA_ComoTuCasa + pago_limpieza - pago_limpieza / 1.21 + Comision - Comision / 1.21 + (coste_materiales_usados_reparacion + precio_venta_publico)- (coste_materiales_usados_reparacion + precio_venta_publico)/1.21)}</b>
              <Divider></Divider>
              </Row>
              <Row>
              BI: <b>{formatCurrency(ComoTuCasa + pago_limpieza / 1.21 + Comision / 1.21 + (coste_materiales_usados_reparacion + precio_venta_publico)/1.21)}</b>
              </Row>
              <Row>
              IVA: <b>{formatCurrency(IVA_ComoTuCasa + pago_limpieza - pago_limpieza / 1.21 + Comision - Comision / 1.21 + (coste_materiales_usados_reparacion + precio_venta_publico)- (coste_materiales_usados_reparacion + precio_venta_publico)/1.21)}</b>
              (Limpieza {formatCurrency(pago_limpieza)}+ Comision AyB {formatCurrency(Comision)} + Comision Tursites% {formatCurrency(ComoTuCasa+IVA_ComoTuCasa)} + Salidas {formatCurrency(precio_venta_publico)} + Materiales  {formatCurrency(coste_materiales_usados_reparacion)})
             
              </Row>
             </>
}
              {/* <Row>ComoTuCasa: <b>{formatCurrency(ComoTuCasa)}</b></Row>
              <Row>+ Comisión AyB: <b>{formatCurrency(Comision)}</b></Row>
              <Row>+ Pago Limpieza: <b>{formatCurrency(pago_limpieza)}</b></Row> */}
               </Col>
          </Row>
          
      <Divider>Rendimientos</Divider>
          {/* <Row>
            <Col span={4}><strong>Reservas:</strong></Col>
            <Col span={4}>{reservas}</Col>
          </Row>
          <Row>
            <Col span={4}><strong>Días:</strong></Col>
            <Col span={4}>{formatCurrency(dias)}</Col>
          </Row> */}
          <Row>
            <Col span={4}><strong>Facturación:</strong></Col>
            <Col span={4}><b>{formatCurrency(Facturacion)}</b></Col>
          </Row>
         
          <Row>
            <Col span={4}><>-Comisión Airbnb y Booking IVA Incl.:</></Col>
            <Col span={4}>-{formatCurrency(Number(Comision))} (BI: {formatCurrency(Number(Comision/1.21))} IVA: {formatCurrency(Number(Comision-Comision/1.21))})</Col>
          </Row>
          <Row>
            <Col span={4}><>-Pago Limpieza IVA Incl.:</></Col>
            <Col span={4}>-{formatCurrency(Number(pago_limpieza))} (BI: {formatCurrency(Number(pago_limpieza/1.21))} IVA: {formatCurrency(Number(pago_limpieza-pago_limpieza/1.21))})</Col>
          </Row>
         

          <Row>
            <Col span={4}>
             Comision Turistea (IVA Incl.):
            </Col>
            <Col span={20}>
              -{formatCurrency(Number(ComoTuCasa) + Number(IVA_ComoTuCasa))} (BI: {formatCurrency(Number(ComoTuCasa))} IVA: {formatCurrency(Number(IVA_ComoTuCasa))})
            </Col>
          </Row>
          <Row>
            <Col span={4}><strong>R= Propietario Gestión:</strong></Col>
            <Col span={4}><b>{formatCurrency(Number(Propietario_gestion))}</b></Col>
          </Row>
       
          <Row>
            <Col span={4}><>-Importe Salidas mantenimiento IVA Incl.:</></Col>
            <Col span={4}>-{formatCurrency(Number(precio_venta_publico))} (BI: {formatCurrency(Number(precio_venta_publico/1.21))} IVA: {formatCurrency(Number(precio_venta_publico-precio_venta_publico/1.21))})</Col>
           
          </Row> 
          <Row>
            <Col span={4}><>-Importe Materiales mantenimiento IVA Incl.:</></Col>
            <Col span={4}>-{formatCurrency(Number(coste_materiales_usados_reparacion))} (BI: {formatCurrency(Number(coste_materiales_usados_reparacion/1.21))} IVA: {formatCurrency(Number(coste_materiales_usados_reparacion-coste_materiales_usados_reparacion/1.21))})</Col>
           
          </Row> 
          <Row>
            <Col span={4}><strong>NETO Propietario Gestión:</strong></Col>
            <Col span={4}><b>{formatCurrency(Number(Propietario_gestion- coste_materiales_usados_reparacion- precio_venta_publico).toFixed(2))}</b></Col>
          </Row>
         {propietario_paga_limpieza?
         <>
          <Row>
            <Col span={4}><>+Pago Limpieza IVA Incl.:</></Col>
            <Col span={4}>+{formatCurrency(Number(pago_limpieza))} (BI: {formatCurrency(Number(pago_limpieza/1.21))} IVA: {formatCurrency(Number(pago_limpieza-pago_limpieza/1.21))})</Col>
          </Row>
          <Row>
            <Col span={4}><strong>NETO Propietario Gestión:</strong></Col>
            <Col span={4}><b>{formatCurrency(Number(Propietario_gestion- coste_materiales_usados_reparacion- precio_venta_publico+ pago_limpieza).toFixed(2))}</b></Col>
          </Row>
         </>:
         <>
         
         </>}
        </Panel>

        <Panel header="Mantenimientos del mes" key="6">
          <IncidenciasPage inmueble={[inmueble]} mes={mes+1} year={year} estados={["resuelto"]} cargar_segun_mes ={true}></IncidenciasPage>
        </Panel>

       

        <Panel header="Factura" key="2">
          <Row>
            <Col span={4}><strong>Concepto: {"Abono renta " + nombre + " Mes " + (mes+1) + "/" + year}</strong></Col>
            <Col span={4}>{nombre}</Col>
            <Col span={2}> 
                <Button
                  onClick={() => copiarAlPortapapeles("Abono renta " + nombre + " Mes " + (mes+1) + "/" + year)}
                >
                  Copiar
                </Button>
            </Col>
          </Row>
          <Row>
            <Col span={4}><strong>Nombre Facturación:</strong></Col>
            <Col span={4}>{nombre_facturacion}</Col>
            <Col span={2}> 
                <Button
                  onClick={() => copiarAlPortapapeles(nombre_facturacion)}
                >
                  Copiar
                </Button>
            </Col>
          </Row>
          <Row>
            <Col span={4}><strong>Dirección Facturación:</strong></Col>
            <Col span={4}>{direccion_facturacion}</Col>
            <Col span={2}> 
                <Button
                  onClick={() => copiarAlPortapapeles(direccion_facturacion)}
                >
                  Copiar
                </Button>
            </Col>
          </Row>
          <Row>
            <Col span={4}><strong>ID Facturación:</strong></Col>
            <Col span={4}>{id_facturacion}</Col>
            <Col span={2}> 
                <Button
                  onClick={() => copiarAlPortapapeles(id_facturacion)}
                >
                  Copiar
                </Button>
            </Col>
          </Row>
          <Row>
            <Col span={4}><strong>IBAN:</strong></Col>
            <Col span={4}>{iban}</Col>
            <Col span={2}> 
                <Button
                  onClick={() => copiarAlPortapapeles(iban)}
                >
                  Copiar
                </Button></Col>
           
          </Row>
        </Panel>
      </Collapse>

    </Card>
  );
}
