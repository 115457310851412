import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import ReactDOM from "react-dom";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer"; //https://kags.me.ke/post/generate-dynamic-pdf-incoice-using-react-pdf/
import { Col, Row } from "antd";
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 10,
    width: "100%",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 0,
  },
  title: {
    marginTop: 40,
    fontSize: 25,
    textAlign: "center",
    marginBottom: 20,
  },

  subtitle: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 10,
  },
  date: {
    fontSize: 12,
    textAlign: "right",
    marginBottom: 10,
    marginTop: 10,
    marginRight: 30,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    marginRight: 20,
    marginLeft: 20,
    alignItems: "center",
  },
  row2: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#cccccc",
    backgroundColor: "#0000ff",
    color: "white",
    marginRight: 20,
    marginLeft: 20,
    alignItems: "center",
  },
  label: {
    fontSize: 13,
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
  },
  label2: {
    fontSize: 16,
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
  },
  item: {
    flex: 1,
    textAlign: "left",
  },
  total: {
    flexDirection: "row",
    marginTop: 10,
    borderTopWidth: 1,
    paddingTop: 5,
    borderColor: "#cccccc",
    alignItems: "flex-end",
  },
  totalLabel: {
    fontWeight: "bold",
    textAlign: "right",
    flex: 1,
  },
  totalAmount: {
    fontWeight: "bold",
    textAlign: "right",
    flex: 1,
  },
  senderBox: {
    borderWidth: 2,
    borderColor: "black",
    padding: 5,
    marginBottom: 5,
    // marginLeft: 30,
    // marginRight: 30,
  },
  receiverBox: {
    borderWidth: 2,
    borderColor: "black",
    padding: 5,
    marginBottom: 50,
    // marginLeft: 30,
    // marginRight: 30,
  },
  sender: {
    marginBottom: 10,
  },
  receiver: {},
  company: {
    fontSize: 15,
    marginBottom: 10,
  },
  companyHeader: {
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 10,
    textDecoration: "underline",
  },
});

// Create Document Component
export default function Facturas(props) {
  const {
    datos_facturacion_administrador,
    datos_mes,
    inquilinos,
    datos_facturacion,
  } = props;
  //console.log("Facturas", datos_facturacion);

  const [factura, setFactura] = useState([]);
  const [facturaTotales, setfacturaTotales] = useState([]);
  const [datos_facturacion_empresa, setdatos_facturacion_empresa] = useState(
    {}
  );
  //console.log("datos_mes", datos_mes);
  //console.log("inquilinos", inquilinos);
  const today = new Date();
  const dateString = today.toLocaleDateString();
  useEffect(() => {
    //console.log("datos_facturacionUseEffect", datos_facturacion_empresa);
    setdatos_facturacion_empresa(datos_facturacion);
    //console.log("datos_facturacionUseEffect2", datos_facturacion_empresa);
    let variable = [];
    // //console.log("Ahora3", inquilinos);
    inquilinos.length > 0 ? (
      inquilinos.forEach((inquilino) => {
        // //console.log("Ahora", inquilino);
        variable.push(
          <View style={styles.row}>
            <Text style={styles.label}>{inquilino.name ?? "Nombre"}</Text>
            <Text style={styles.label}>{inquilino.pago ?? "Pago"}</Text>
            <Text style={styles.label}>
              -{inquilino.comision ?? "Comision"}
            </Text>
            <Text style={styles.label}>
              -{inquilino.pago_limpieza ?? "Limpieza"}
            </Text>
            <Text style={styles.label}>
              {(inquilino.pago ??
                0 - inquilino.comision ??
                0 - inquilino.pago_limpieza ??
                0) * 0.19}
            </Text>
          </View>
        );
      })
    ) : (
      <></>
    );
    setFactura(variable);
    variable = [];
    datos_mes.length > 0 ? (
      variable.push(
        <View style={styles.section}>
          <View style={styles.total}>
            <Text style={styles.totalLabel}>Facturacion: </Text>
            <Text style={styles.totalAmount}>{datos_mes[0].Facturacion}</Text>
          </View>
          <View style={styles.total}>
            <Text style={styles.totalLabel}>-Comision: </Text>
            <Text style={styles.totalAmount}>-{datos_mes[0].Comision}</Text>
          </View>
          <View style={styles.total}>
            <Text style={styles.totalLabel}>-Limpieza: </Text>
            <Text style={styles.totalAmount}>
              -{datos_mes[0].pago_limpieza}
            </Text>
          </View>
          <View style={styles.total}>
            <Text style={styles.totalLabel}>-ComoTuCasa.Es (19%): </Text>
            <Text style={styles.totalAmount}>-{datos_mes[0].ComoTuCasa}</Text>
          </View>
          <View style={styles.total}>
            <Text style={styles.totalLabel}>Propietario= </Text>
            <Text style={styles.totalAmount}>{datos_mes[0].Propietario}</Text>
          </View>
        </View>
      )
    ) : (
      <></>
    );

    setfacturaTotales(variable);
  }, [
    inquilinos,
    datos_mes,
    datos_facturacion,
    datos_facturacion_administrador,
  ]);
  const documento = (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Factura ComoTuCasa.Es</Text>
          <Text style={styles.subtitle}>Número de factura: 1234</Text>
          <Text style={styles.date}>Fecha: {dateString}</Text>
          <View style={styles.section}>
            <View style={styles.senderBox}>
              <View style={styles.sender}>
                <Text style={styles.companyHeader}>Datos del gestor:</Text>
                <Text style={styles.company}>
                  {datos_facturacion_administrador.nombre_facturacion ?? ""}
                </Text>
                <Text style={styles.company}>
                  {datos_facturacion_administrador.id_facturacion ?? ""}
                </Text>
                <Text style={styles.company}>
                  {datos_facturacion_administrador.direccion_facturacion ?? ""}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <View style={styles.receiverBox}>
              <View style={styles.receiver}>
                <Text style={styles.companyHeader}>Datos de la Propiedad:</Text>
                <Text style={styles.company}>
                  {datos_facturacion_empresa.nombre_facturacion ?? "Incompleto"}
                </Text>
                <Text style={styles.company}>
                  {datos_facturacion_empresa.id_facturacion ?? "Incompleto"}
                </Text>
                <Text style={styles.company}>
                  {datos_facturacion_empresa.direccion_facturacion ??
                    "Incompleto"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.row2}>
            <Text style={styles.label2}>Referencia</Text>
            <Text style={styles.label2}>Servicio</Text>
            <Text style={styles.label2}>Precio (BI)</Text>
            <Text style={styles.label2}>IVA</Text>
            <Text style={styles.label2}>TOTAL</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>1</Text>
            <Text style={styles.label}>Gestion</Text>
            <Text style={styles.label}>
              {Number(datos_mes[0].ComoTuCasa).toFixed(2)} €
            </Text>
            <Text style={styles.label}>
              {Number(datos_mes[0].ComoTuCasa * 0.21).toFixed(2)} €
            </Text>
            <Text style={styles.label}>
              {Number(datos_mes[0].ComoTuCasa * 1.21).toFixed(2)} €
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>2</Text>
            <Text style={styles.label}>Limpieza</Text>
            <Text style={styles.label}>
              {Number(datos_mes[0].pago_limpieza / 1.21).toFixed(2)} €
            </Text>
            <Text style={styles.label}>
              {Number(
                datos_mes[0].pago_limpieza - datos_mes[0].pago_limpieza / 1.21
              ).toFixed(2)}{" "}
              €
            </Text>
            <Text style={styles.label}>
              {Number(datos_mes[0].pago_limpieza).toFixed(2)} €
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>3</Text>
            <Text style={styles.label}>OTAs (Airbnb, Booking .. )</Text>
            <Text style={styles.label}>
              {Number(datos_mes[0].Comision / 1.21).toFixed(2)} €
            </Text>
            <Text style={styles.label}>
              {Number(
                datos_mes[0].Comision - datos_mes[0].Comision / 1.21
              ).toFixed(2)}{" "}
              €
            </Text>

            <Text style={styles.label}>
              {Number(datos_mes[0].Comision).toFixed(2)} €
            </Text>
          </View>
        </View>
      </Page>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Desglose de este mes</Text>
        </View>

        {/* <View style={styles.row}>
<Text style={styles.item}>Producto 1</Text>
<Text style={styles.item}>1</Text>
<Text style={styles.item}>$10.00</Text>
<Text style={styles.item}>$10.00</Text>
</View> */}

        {facturaTotales}
      </Page>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Detalle de este mes</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Inquilino</Text>
            <Text style={styles.label}>Pago</Text>
            <Text style={styles.label}>Comision</Text>
            <Text style={styles.label}>Limpieza</Text>
            <Text style={styles.label}>Neto</Text>
          </View>
          {factura}
        </View>
      </Page>
    </Document>
  );


  // const formattedDate = moment().format("YYYYMMDD"); // Formatea la fecha como YYYYMMDD

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>

          <PDFDownloadLink
            document={documento}
            fileName={`A.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Generando factura..." : "Descargar factura"
            }
          </PDFDownloadLink>

          <PDFViewer style={{ width: "70vw", height: "100vh" }}>
            {documento}
          </PDFViewer>
        
        </Col>
      </Row>
    </>
  );
}
