import React, { useState, useEffect, useCallback } from "react";

import { obtenerListaInmuebles } from "../../../api/inmueble";

import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  
  Divider,
  Radio,
  Space,
  Rate,
  DatePicker
} from "antd";
import { useDropzone } from "react-dropzone";
import moment from "moment";

import {
  updateInquilinoApi,
  uploadAvatarApi,
  getAvatarApi,
  getInquilinosById,
} from "../../../api/inquilino";
import { getAccessTokenApi } from "../../../api/auth";

import "./EditarInquilinos.scss";
import DesplegableMultiple from "../../../components/DesplegableMultiple";
import { getUsersByRoleApi } from "../../../api/user";
import Desplegable_activable from "./Desplegable_activable";
export default function EditForm(props) {
    const { userData, setUserData, setReloadUsers, cerrarModalEditar } = props;
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const [value, setValue] = useState(1);

    const [limpiadores_id, setlimpiadores_id] = useState({});
    const [limpiadores_id_desplegable, setlimpiadores_id_desplegable] = useState([]);


    const onChange = (dates) => {
      setUserData({
        ...userData,
        fecha_entrada: dates[0].format("YYYY-MM-DD"),
  
        fecha_salida: dates[1].format("YYYY-MM-DD"),
      });
      setReloadUsers(true);
    };
    const [num_tours_v, set_num_tours_v] = useState([]);
    const [lista_id_piso, set_lista_id_piso] = useState({});
  
    useEffect(() => {
      const accessToken = getAccessTokenApi();
     

      obtenerListaInmuebles(accessToken).then((response) => {
        //console.log(response.Pisos[0].id);
        var num_tours_v2 = [];
        //console.log("1");
        //console.log("LONGITUD", response.Pisos.length);
        for (let i = 0; i < response.Pisos.length; i++) {
          //console.log("1.1");
          //console.log(response.Pisos[i]);
          num_tours_v2.push(
            <Option value={response.Pisos[i].id}>
              {response.Pisos[i].nombre}
            </Option>
          );
        }
        set_num_tours_v(num_tours_v2);
        set_lista_id_piso(response.Pisos2);
        //console.log(userData);
        //console.log(lista_id_piso);
      });
    }, []);
 useEffect(() => {
    const fetchLimpiadores = async () => {
      try {
        const limpiadores = await getUsersByRoleApi(getAccessTokenApi(), "Limpiador");
        let limpiadores_por_id_aux = {};
        limpiadores.users.forEach(limpiador => {
          limpiadores_por_id_aux[limpiador.id] = limpiador.name;
        });
        //console.log("limpiadores_por_id_aux",limpiadores_por_id_aux)
        let vect_aux = []
        Object.keys(limpiadores_por_id_aux).forEach(element => {
          vect_aux.push({value:element, label:limpiadores_por_id_aux[element]})
        });
        //console.log("vect_aux_1",vect_aux)
        setlimpiadores_id_desplegable(vect_aux)
        setlimpiadores_id(limpiadores_por_id_aux);
      } catch (error) {
        console.error("Error al obtener los limpiadores:", error);
      }
    };
  
    fetchLimpiadores();
  }, []);
  useEffect(()=>{

  },[limpiadores_id])
    const updateUser = (e) => {
      //console.log("updateUser");
  
      const token = getAccessTokenApi();
      let userUpdate = userData;
  
      if (
        !userUpdate.name ||
        !userUpdate.telefono ||
        !userUpdate.inmueble ||
        !userUpdate.fecha_entrada ||
        !userUpdate.fecha_salida
      ) {
        notification["error"]({
          message: "El nombre, apellidos y email son obligatorios.",
        });
        setReloadUsers(true);
        return;
      }
      
      if (userData.valorado === "No se va a valorar" || userData.valorado === "Valorado") {
        userData.valorado = true;
      } else {
        userData.valorado = false;
      }
     
  
      updateInquilinoApi(token, userUpdate, userUpdate._id).then((response) => {
        notification["success"]({
          message: "Actualizado",
        });
        cerrarModalEditar();
        setReloadUsers(true);
      });
      // }
    };
    return (
      <Form className="form-edit" onFinish={updateUser}>
        <span>Group ID: {userData.group_id}</span>
        <Divider>Fecha de Limpieza</Divider>
        <Row gutter={24}>
          <Col span={12}>Fecha Limpieza</Col>
          <Col span={12}>
            <Form.Item>
            <DatePicker
              renderExtraFooter={() => "extra footer"}
              value={moment(userData.limpieza?.fecha_limpieza ?? "2024-01-01")}
              onChange={(date, dateString) => {
                //console.log("onChange", date, dateString);
  
                setUserData({
                  ...userData,
                  limpieza: { ...userData.limpieza, fecha_limpieza: dateString },
                });
              }}
            />
  
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
            Estado: <Radio.Group
              onChange={(e) => {
                setUserData({
                  ...userData,
                  limpieza: {
                    ...userData.limpieza,
                    estado: e.target.value
                  }
                });
                setValue(e.target.value);
              }}
              value={userData?.limpieza?.estado ?? "Sin asignar"}
            >
             <Space direction="horizontal">
                <Radio value={"Sin asignar"}>Sin asignar</Radio>
                <Radio value={"Asignado"}>Asignado</Radio>
                <Radio value={"Limpio"}>Limpio</Radio>
              </Space>
            </Radio.Group>

            </Form.Item>
          </Col>
         
        </Row>
        <Row>
        Limpiador: {limpiadores_id?.[userData?.limpieza?.limpiadores?.[0]] ?? "No encontrado"}
        <Desplegable_activable record={{ limpiador: limpiadores_id?.[userData?.limpieza?.limpiadores?.[0]] ?? "hola"}} limpiadores_id_desplegable ={limpiadores_id_desplegable } inquilino_id={userData._id}/>
       

        </Row>
        {/* <Row gutter={24}>
          <Col span={12}>Limpio</Col>
          <Col span={12}>
            <Form.Item>
            <DesplegableMultiple
                valores={["Limpio","Sucio"]}
                onChange={(values) => {
                  
                  // setUserData({
                  //   ...userData,
                  //   pisos_asociados: values.map((x) => {
                  //     return id_nombres_pisos[x];
                  //   }),
                  // });

                  // //console.log(userData);
                }}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Divider>Fecha de entrada</Divider>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Date Range">
              {moment(userData.fecha_entrada, "YYYY-MM-DD").isValid() ? (
                <RangePicker
                  renderExtraFooter={() => "extra footer"}
                  onChange={onChange}
                  value={[
                    userData.fecha_entrada
                      ? moment(userData.fecha_entrada)
                      : null,
                    userData.fecha_salida ? moment(userData.fecha_salida) : null,
                  ]}
                />
              ) : (
                <></>
              )}
            </Form.Item>
          </Col>
          <Divider>Sobre el inquilino</Divider>
          <Col span={12}>
            <Form.Item>
              <Input
                //prefix={<Icon type="user" />}
                placeholder="Nombre y apellidos Inquilino"
                value={userData.name}
                onChange={(e) =>
                  setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item>
              <Input
                //prefix={<Icon type="mail" />}
                placeholder="Telefono"
                value={userData.telefono}
                onChange={(e) =>
                  setUserData({ ...userData, telefono: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
  
        
  
      
      
     
     
        <Divider>Idioma para el cliente</Divider>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Radio.Group
                onChange={(e) => {
                  setUserData({ ...userData, idioma_mensaje: e.target.value });
                }}
                value={userData.idioma_mensaje}
              >
                <Space direction="horizontal">
                  <Radio value={"ES"}>Español</Radio>
                  <Radio value={"EN"}>Ingles</Radio>
                  <Radio value={"FR"}>Frances</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider>Tipo de cliente</Divider>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Radio.Group
                onChange={(e) => {
                  setUserData({ ...userData, tipo_inquilino: e.target.value });
                  setValue(e.target.value);
                }}
                value={userData.tipo_inquilino}
              >
                <Space direction="horizontal">
                  <Radio value={"Inquilino"}>Inquilino</Radio>
                  <Radio value={"Propietario"}>Propietario</Radio>
                  <Radio value={"Limpiador"}>Limpiador</Radio>
                  <Radio value={""}>
                    Otro More...
                    {value === "" ? (
                      <Input
                        value={userData.tipo_inquilino}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            tipo_inquilino: e.target.value,
                          })
                        }
                        style={{ width: 100, marginLeft: 10 }}
                      />
                    ) : null}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider>Valoracion estancia </Divider>
        <Row gutter={24}>
        <Col span={12}> Limpieza: </Col>
          <Col span={12}>
              <Rate allowHalf count={5} style={{ fontSize: '1.5em', color: 'red'}}
              value={Number(userData.valoracion_limpieza)}
              onChange={ (value) => {
                setUserData({
                  ...userData,
                  valoracion_limpieza: String(value),
                });
              }}
              />
          </Col>
        </Row>
        <Divider>Limpieza Valoracion</Divider>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Radio.Group
                onChange={(e) => {
                  setUserData({ ...userData,
                     valorado: e.target.value });
                  setValue(e.target.value);
                }}
                value={userData.valorado}
              >
                <Space direction="horizontal">
                  <Radio value={"Valorado"}>Valorado</Radio>
                  <Radio value={"Sin valorar"}>Sin valorar</Radio>
                  <Radio value={"No se va a valorar"}>No se va a valorar</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        
        <Divider></Divider>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="btn-submit">
            Actualizar Inquilino
          </Button>
        </Form.Item>
      </Form>
    );
  }
  