import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { getInmueblesApi, updateInmuebleApi } from "../../../api/inmueble";
import ListInmuebles from "../../../components/Admin/Users/Inmuebles/ListInmueble";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import { Button, Tabs, Input, notification, Divider, Checkbox, Row, Modal, Spin } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

import "./Inmuebles.scss";
import { Pagination } from "antd";
import Map from "../../../components/Admin/Map_puntos/Map_puntos";
import Puntuaciones from "../Puntuaciones/Puntuaciones";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  StopOutlined,
  CheckOutlined,
  CalendarOutlined,
  CommentOutlined,
  LineChartOutlined,
  QrcodeOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import Dispositivos from "../../../components/Admin/Dispositivos";
import Calendario from "../Calendario";
import DesplegableMultiple from "../../../components/DesplegableMultiple";
import StockCheckbox from "../../../components/StockCheckbox";
import ComentariosLimpiezaPorPiso from "../Limpieza/ComentariosLimpiezaPorPiso";
import Tabla_stock from "./Tabla_stock";
import { getProximaFechaDisponiblev1 } from "../../../api/inquilino";
export default function Inquilinos() {
  const [usersActive, setUsersActive] = useState([]);
  const [modalVisible, setmodal_visible] = useState(false);
  const [usersInactive, setUsersInactive] = useState([]);
  const [numeroInmuebles, setNumeroInmuebles] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [mostrarStockFaltante, setMostrarStockFaltante] = useState(false);
  const token = getAccessTokenApi();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [viewUsersActives, setViewUsersActives] = useState(true);
  const [editMode, setEditMode] = useState(false); // Estado para controlar el modo de edición
  const [editedData, setEditedData] = useState({}); // Estado para almacenar los datos editados
  const [editedRowIndex, setEditedRowIndex] = useState(null); // Estado para almacenar el índice de la fila editada
  const [vistaMapa, setVistaMapa] = useState(false);
  const [lista_ciudades, setlista_ciudades] = useState([]);
  const [ciudades_seleccionadas, setciudades_seleccionadas] = useState([]);
  const [todos_los_pisos, settodos_los_pisos] = useState([]);


  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

  const handleCheckboxChange = (recordId, event) => {
    setSelectedCheckboxes({
      ...selectedCheckboxes,
      [recordId]: event.target.checked,
    });
  };

useEffect(()=>{},[modalVisible, editedData])
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setReloadUsers(true);
  };

  useEffect(() => {
    setCurrentPage(1);
    setReloadUsers(true);
  }, [viewUsersActives]);

  useEffect(() => {
    if (viewUsersActives) {
      getInmueblesApi(token, currentPage, true).then((response) => {

        const ciudadesUnicas = new Set(response.Pisos.map(item => item.ciudad));
        //console.log("ciudadesUnicas",ciudadesUnicas)
        setlista_ciudades([ "TODAS",...ciudadesUnicas]);
        console.log("LISTA DE CIUDADES", lista_ciudades)
        settodos_los_pisos(response?.Pisos)

        setTotalItems(response?.totalItems);
        setPageSize(response?.perPage);
      });
    } else {
      getInmueblesApi(token, currentPage, false).then((response) => {
        setUsersInactive(response.Pisos);
        setNumeroInmuebles(response.Pisos.length);
        setTotalItems(response.totalItems);
        setPageSize(response.perPage);
      });
    }

    setReloadUsers(false);
  }, [token, reloadUsers]);

  const isStockOptimal = (currentStock, optimalStock) => {
    const porcentaje = currentStock / optimalStock;
    if (porcentaje < 0.33) return "stock-bajo";
    if (porcentaje < 1) return "stock-medio";
    return "";
  };

  const calcularStockFaltante = (stockActual, stockOptimo) => {
    return stockOptimo - stockActual;
  };

  const toggleMostrarStockFaltante = () => {
    setMostrarStockFaltante(!mostrarStockFaltante);
  };

  const renderStock = (stockActual, stockOptimo) => {
    if (stockOptimo === 0) {
      return "-";
    } else if (isNaN(stockActual) || isNaN(stockOptimo)) {
      return "-";
    } else if (stockActual < 0 || stockOptimo < 0) {
      return "-";
    } else {
      return mostrarStockFaltante ? calcularStockFaltante(stockActual, stockOptimo) : `${stockActual}/${stockOptimo}`;
    }
  };

  const renderStock2 = (stockActual, stockOptimo) => {
    if (stockOptimo === 0) {
      return "-";
    } else if (isNaN(stockActual) || isNaN(stockOptimo)) {
      return "-";
    } else if (stockActual < 0 || stockOptimo < 0) {
      return "-";
    } else {
      return calcularStockFaltante(stockActual, stockOptimo);
    }
  };


  const handleEdit = (index) => {
    setEditedData(sortedUsers[index]);
    setEditedRowIndex(index);
    setEditMode(true);
  };

  const handleVerComentarios = (index) => {
    console.log(sortedUsers[index])
    setEditedData(sortedUsers[index]);
    setmodal_visible(true)
  };

  const handleCancelEdit = () => {
    setEditedData({});
    setEditedRowIndex(null);
    setEditMode(false);
  };

  const handleUpdate = async () => {
    // Aquí puedes enviar los datos editados al servidor para actualizarlos
    // Por ahora, solo imprimirlos en la consola
    //console.log("Datos actualizados:", editedData);

    let resp = await updateInmuebleApi(getAccessTokenApi(),editedData, editedData._id);
    if(resp.error){
      notification["error"]({
        message: resp.error,
      });
    }else{
      notification["success"]({
        message: "Actualizado con exito"
      });
    setEditedData({});
    setEditedRowIndex(null);
    setEditMode(false);
    setReloadUsers(true)
    }
  };

  const handleChange = (e, key) => {
    const value = e.target.value;
    setEditedData((prevData) => ({
      ...prevData,
      [key]: Number(value),
    }));
  };
  const [fechasDisponibles, setFechasDisponibles] = useState({});
  useEffect(()=>{},
  [
    fechasDisponibles
  ])

  useEffect(()=>{
    console.log("todos_los_pisos",todos_los_pisos)
    if(!ciudades_seleccionadas.includes("TODAS")){
      setUsersActive(todos_los_pisos?.sort((a, b) => a.name.localeCompare(b.name))?.filter(item => ciudades_seleccionadas.includes(item.ciudad)));
      setNumeroInmuebles(todos_los_pisos?.length??0);
    }else if(ciudades_seleccionadas.includes("TODAS")){
    setUsersActive(todos_los_pisos?.sort((a, b) => a.name.localeCompare(b.name)));
    setNumeroInmuebles(todos_los_pisos?.length??0);
  }

  },[lista_ciudades, todos_los_pisos, ciudades_seleccionadas]);

  const [sortConfig, setSortConfig] = useState({ key: 'totalToSend', direction: 'descending' });
  useEffect(()=>{},[sortConfig])
  const getTotalToSend = (piso) => {
    const stockSabanas150 = renderStock2(piso?.stock_sabanas_150, piso?.stock_optimo_sabanas_150);
    const stockSabanas90 = renderStock2(piso?.stock_sabanas_90, piso?.stock_optimo_sabanas_90);
    const stockAlmohadas = renderStock2(piso?.stock_almohadas, piso?.stock_optimo_almohadas);
    const stockEdredones150 = renderStock2(piso?.stock_edredones_150, piso?.stock_optimo_edredones_150);
    const stockEdredones90 = renderStock2(piso?.stock_edredones_90, piso?.stock_optimo_edredones_90);
    const stockToallasCuerpo = renderStock2(piso?.stock_toallas_cuerpo, piso?.stock_optimo_toallas_cuerpo);
    const stockToallasMano = renderStock2(piso?.stock_toallas_mano, piso?.stock_optimo_toallas_mano);
    const stockAlfombrines = renderStock2(piso?.stock_alfombrines, piso?.stock_optimo_alfombrines);

    const isValidStock = (stock) => stock !== "-" && stock >= 0;

    return (isValidStock(stockSabanas150) ? stockSabanas150 : 0) +
      (isValidStock(stockSabanas90) ? stockSabanas90 : 0) +
      (isValidStock(stockAlmohadas) ? stockAlmohadas : 0) +
      (isValidStock(stockEdredones150) ? stockEdredones150 : 0) +
      (isValidStock(stockEdredones90) ? stockEdredones90 : 0) +
      (isValidStock(stockToallasCuerpo) ? stockToallasCuerpo : 0) +
      (isValidStock(stockToallasMano) ? stockToallasMano : 0) +
      (isValidStock(stockAlfombrines) ? stockAlfombrines : 0);
  };

  const sortedUsers = [...usersActive].map(piso => ({
    ...piso,
    totalToSend: getTotalToSend(piso)
  })).sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = 'descending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  useEffect(() => {
    
    const obtenerFechasDisponibles = async () => {
      const nuevasFechas = {};
      console.log("PISO",todos_los_pisos)
      for (const piso of todos_los_pisos) {
        console.log(piso)
        const fechaDisponible = await getProximaFechaDisponiblev1(token, moment().format("YYYY-MM-DD"), piso._id);
        nuevasFechas[piso._id] = fechaDisponible;
      }
      
      setFechasDisponibles(nuevasFechas);
    };
  
    obtenerFechasDisponibles();
  }, [todos_los_pisos]); // Ejecuta cada vez que sortedUsers cambie
  


  return (
    <>
    <DesplegableMultiple
                valores={lista_ciudades}
                // defaultValue2 = {ciudades_seleccionadas}
                onChange={(values) => {
                  console.log("DesplegableMultiple", values)
                  setciudades_seleccionadas(values)
                }}
              />
      <Tabs defaultActiveKey="1" onChange={() => {}}>
        <Tabs.TabPane tab="Inmuebles" key="1">
          <div className="users">
            <b>
              <span>Numero inmuebles: {numeroInmuebles}</span>
            </b>
            <Pagination
              current={currentPage}
              total={totalItems}
              pageSize={pageSize}
              onChange={handlePageChange}
            />
            <ListInmuebles
              usersActive={usersActive}
              usersInactive={usersInactive}
              setReloadUsers={setReloadUsers}
              setViewUsersActives={setViewUsersActives}
              viewUsersActives={viewUsersActives}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Stock" key="2">
          <div>

          {/* <div className="switch">
              <label>                
                <input type="checkbox" checked={vistaMapa} onChange={() => setVistaMapa(!vistaMapa)} />
                <span className="lever"> </span>
                Ver limpiezas anteriores por piso
              
              </label>
            </div>
            {vistaMapa ? (
              <>
                  <Calendario/>
                  
                  </>
            ):<></>} */}
           
            <h1 className="titulo-tabla-stock">
              {mostrarStockFaltante ? "Stock Faltante para Stock Óptimo" : "Stock de Elementos por Piso"}
            </h1>
            <button onClick={toggleMostrarStockFaltante}>
              {mostrarStockFaltante ? "Mostrar Stock Normal" : "Mostrar Stock Faltante"}
            </button>
            <Divider type="vertical"></Divider>
            
            <button onClick={  () => {
                    //  //console.log("handleDownloadPDF");

                    //  // Crear un nuevo documento jsPDF
                    //  const doc = new jsPDF({ orientation: 'landscape' });
                 
                    //  // Título del documento
                    //  doc.text("Stock en cada piso", 10, 10);
                 
                    //  // Obtener el contenido HTML de la tabla
                    //  const table = document.getElementById('managerTable_1');
                 
                    //  // Generar el PDF a partir del contenido de la tabla
                    //  doc.autoTable({
                    //      html: table,
                    //      horizontalPageBreak: true,
                    //      styles: { // Estilos para la tabla
                    //          cellWidth: 'wrap', // Ajustar el ancho de la celda automáticamente
                    //          valign: 'middle', // Alinear verticalmente el contenido de la celda al centro
                    //          halign: 'center', // Alinear horizontalmente el contenido de la celda al centro
                    //      },
                    //      margin: { top: 20 }, // Margen superior para dejar espacio para el título
                         
                    //      didParseCell: function (data) {
                    //          // Rotar cada celda 90 grados
                    //          if (typeof data.cell.raw === 'string' || typeof data.cell.raw === 'number') {
                    //              const cell = data.cell;
                    //              const text = data.cell.raw.toString();
                    //              data.cell.text = ''; // Limpiar el texto original
                    //              doc.text(text, cell.x + cell.width / 2, cell.y + cell.height / 2, {
                    //                  align: 'center',
                    //                  valign: 'middle',
                    //                  angle: 90
                    //              });
                    //          }
                    //      }
                    //  });
                 
                    //  // Guardar el PDF con un nombre específico
                    //  doc.save('tabla_inmuebles.pdf');
                      // Filtrar los pisos seleccionados
                    const selectedPisos = sortedUsers.filter(piso => selectedCheckboxes[piso._id]);

                    // Crear un nuevo documento jsPDF
                    const doc = new jsPDF({ orientation: 'landscape' });

                    // Título del documento
                    doc.text("Stock en cada piso", 10, 10);

                    // Crear las filas y columnas para autoTable
                    const tableColumn = [
                      "Piso", "Sábana 150", "Sábana 90", "Almohada", 
                      "Edredon 150", "Edredon 90", "Toalla cuerpo", 
                      "Toallas mano", "Alfombrin"
                    ];

                    const tableRows = selectedPisos.map(piso => mostrarStockFaltante ? [
                      piso.direccion_inmueble,
                      piso.stock_optimo_sabanas_150 - piso.stock_sabanas_150,
                      piso.stock_optimo_sabanas_90 - piso.stock_sabanas_90,
                      piso.stock_optimo_almohadas - piso.stock_almohadas,
                      piso.stock_optimo_edredones_150 - piso.stock_edredones_150,
                      piso.stock_optimo_edredones_90 - piso.stock_edredones_90,
                      piso.stock_optimo_toallas_cuerpo - piso.stock_toallas_cuerpo,
                      piso.stock_optimo_toallas_mano - piso.stock_toallas_mano,
                      piso.stock_optimo_alfombrines - piso.stock_alfombrines
                    ] :[
                      piso.direccion_inmueble,
                      `${piso.stock_sabanas_150} / ${piso.stock_optimo_sabanas_150}`,
                      `${piso.stock_sabanas_90} / ${piso.stock_optimo_sabanas_90}`,
                      `${piso.stock_almohadas} / ${piso.stock_optimo_almohadas}`,
                      `${piso.stock_edredones_150} / ${piso.stock_optimo_edredones_150}`,
                      `${piso.stock_edredones_90} / ${piso.stock_optimo_edredones_90}`,
                      `${piso.stock_toallas_cuerpo} / ${piso.stock_optimo_toallas_cuerpo}`,
                      `${piso.stock_toallas_mano} / ${piso.stock_optimo_toallas_mano}`,
                      `${piso.stock_alfombrines} / ${piso.stock_optimo_alfombrines}`
                    ] );

                    // Generar el PDF a partir del contenido de la tabla
                    doc.autoTable({
                      head: [tableColumn],
                      body: tableRows,
                      styles: { // Estilos para la tabla
                        cellWidth: 'wrap', // Ajustar el ancho de la celda automáticamente
                        valign: 'middle', // Alinear verticalmente el contenido de la celda al centro
                        halign: 'center', // Alinear horizontalmente el contenido de la celda al centro
                      },
                      margin: { top: 20 }, // Margen superior para dejar espacio para el título
                    });

                    // Guardar el PDF con un nombre específico
                    doc.save('tabla_inmuebles.pdf');
                 
                }}>Descargar PDF</button>
            <Divider></Divider>
            <Tabla_stock sortedUsers={sortedUsers}  selectedCheckboxes={selectedCheckboxes}  handleCheckboxChange={handleCheckboxChange}  handleEdit={handleEdit}  handleUpdate={handleUpdate}  handleCancelEdit={handleCancelEdit}  handleVerComentarios={handleVerComentarios}  editedRowIndex={editedRowIndex}  editedData={editedData}  handleChange={handleChange}  requestSort={requestSort}  renderStock2={renderStock2} renderStock={renderStock} isStockOptimal={isStockOptimal} />
         
            {/* <Calendario/> */}
            <div id="managerTable_1_id">
               <table className="table-stock" id="managerTable_1">
                <thead className="thead-stock">
                  <tr>
                    <th className="th-stock">Seleccionar</th>
                    <th className="th-stock">Accion</th>
                    <th className="th-stock">Piso</th>
                    <th className="th-stock" onClick={() => requestSort('totalToSend')}>Total a enviar</th>
                    <th className="th-stock" onClick={() => requestSort('totalToSend')}>Fecha Disponible</th>            
                    <th className="th-stock">Sábana 150</th>
                    <th className="th-stock">Sábana 90</th>
                    <th className="th-stock">Almohada</th>
                    <th className="th-stock">Edredon 150</th>
                    <th className="th-stock">Edredon 90</th>
                    <th className="th-stock">Toalla cuerpo</th>
                    <th className="th-stock">Toallas mano</th>
                    <th className="th-stock">Alfombrin</th>
                   
                  </tr>
                </thead>
                <tbody>
                  {sortedUsers?.map((piso, index) => (
                    <tr key={piso?._id}>
                       <StockCheckbox
                          checked={selectedCheckboxes[piso._id] || false}
                          onChange={(e) => {
                            handleCheckboxChange(piso._id, e); 
                            handleVerComentarios(index);}}
                        />
                          <td className="td-stock">
                        {editedRowIndex === index ? (
                          <Button type="primary" onClick={handleUpdate}>
                            <CheckOutlined />Actualizar
                          </Button>
                        ) : (
                          <Row>
                            <Button type="primary" onClick={() => handleEdit(index)}>
                              <EditOutlined /> Editar 
                            </Button>
                            <Divider></Divider>
                              <Button style={{backgroundColor:"green", borderColor:"green", color:"white"}} onClick={() => handleVerComentarios(index)}>
                              <CommentOutlined />Notas limpieza
                            </Button>
                       
                          </Row>
                        )}
                        {editedRowIndex === index && (
                          <Button type="danger" onClick={handleCancelEdit}>
                            <StopOutlined />Cancelar
                          </Button>
                        )}
                      </td>
                      <td className="td-stock"><b>{piso?.direccion_inmueble}</b><br></br>({piso?.name})</td>
                      
                      <td className="td-stock"> 
                          {
                            (() => {
                              const stockSabanas150 = renderStock2(piso?.stock_sabanas_150, piso?.stock_optimo_sabanas_150);
                              const stockSabanas90 = renderStock2(piso?.stock_sabanas_90, piso?.stock_optimo_sabanas_90);
                              const stockAlmohadas = renderStock2(piso?.stock_almohadas, piso?.stock_optimo_almohadas);
                              const stockEdredones150 = renderStock2(piso?.stock_edredones_150, piso?.stock_optimo_edredones_150);
                              const stockEdredones90 = renderStock2(piso?.stock_edredones_90, piso?.stock_optimo_edredones_90);
                              const stockToallasCuerpo = renderStock2(piso?.stock_toallas_cuerpo, piso?.stock_optimo_toallas_cuerpo);
                              const stockToallasMano = renderStock2(piso?.stock_toallas_mano, piso?.stock_optimo_toallas_mano);
                              const stockAlfombrines = renderStock2(piso?.stock_alfombrines, piso?.stock_optimo_alfombrines);

                              const isValidStock = (stock) => stock !== "-" && stock >= 0;

                              return (isValidStock(stockSabanas150) ? stockSabanas150 : 0) +
                                    (isValidStock(stockSabanas90) ? stockSabanas90 : 0) +
                                    (isValidStock(stockAlmohadas) ? stockAlmohadas : 0) +
                                    (isValidStock(stockEdredones150) ? stockEdredones150 : 0) +
                                    (isValidStock(stockEdredones90) ? stockEdredones90 : 0) +
                                    (isValidStock(stockToallasCuerpo) ? stockToallasCuerpo : 0) +
                                    (isValidStock(stockToallasMano) ? stockToallasMano : 0) +
                                    (isValidStock(stockAlfombrines) ? stockAlfombrines : 0);
                            })()
                          }
                        </td>
                        <td>
                          { fechasDisponibles[piso._id] ? (
                            (!fechasDisponibles[piso._id].proximaFechaSame && !fechasDisponibles[piso._id].proximaFecha) ? (
                              <b>Hoy se puede</b>
                            ) : (
                              <>
                                {fechasDisponibles[piso._id].proximaFechaSame ? "Hoy se puede" : ""}
                                <br></br>
                                <br></br>
                                <b>{fechasDisponibles[piso._id].proximaFecha ? "Prox. fecha: " + fechasDisponibles[piso._id].proximaFecha : ""}</b>
                              </>
                            )
                          ) : (
                            <Spin></Spin>
                          )}
                        </td>
                      <td className={isStockOptimal(piso?.stock_sabanas_150, piso?.stock_optimo_sabanas_150)}>
                        {editedRowIndex === index ? (
                          <Input value={editedData.stock_sabanas_150} onChange={(e) => handleChange(e, 'stock_sabanas_150')} />
                        ) : (
                          renderStock(piso?.stock_sabanas_150, piso?.stock_optimo_sabanas_150)
                        )}
                      </td>
                      {/* Repite lo mismo para las demás columnas */}
                      <td className={isStockOptimal(piso?.stock_sabanas_90, piso?.stock_optimo_sabanas_90)}>
                        {editedRowIndex === index ? (
                          <Input value={editedData.stock_sabanas_90} onChange={(e) => handleChange(e, 'stock_sabanas_90')} />
                        ) : (
                          renderStock(piso?.stock_sabanas_90, piso?.stock_optimo_sabanas_90)
                        )}
                      </td>

                          {/* Repite lo mismo para las demás columnas */}
                      <td className={isStockOptimal(piso?.stock_almohadas, piso?.stock_optimo_almohadas)}>
                        {editedRowIndex === index ? (
                          <Input value={editedData.stock_almohadas} onChange={(e) => handleChange(e, 'stock_almohadas')} />
                        ) : (
                          renderStock(piso?.stock_almohadas, piso?.stock_optimo_almohadas)
                        )}
                      </td>
                      <td className={isStockOptimal(piso?.stock_edredones_150, piso?.stock_optimo_edredones_150)}>
                        {editedRowIndex === index ? (
                          <Input value={editedData.stock_edredones_150} onChange={(e) => handleChange(e, 'stock_edredones_150')} />
                        ) : (
                          renderStock(piso?.stock_edredones_150, piso?.stock_optimo_edredones_150)
                        )}
                      </td>
                      <td className={isStockOptimal(piso?.stock_edredones_90, piso?.stock_optimo_edredones_90)}>
                        {editedRowIndex === index ? (
                          <Input value={editedData.stock_edredones_90} onChange={(e) => handleChange(e, 'stock_edredones_90')} />
                        ) : (
                          renderStock(piso?.stock_edredones_90, piso?.stock_optimo_edredones_90)
                        )}
                      </td>
                      <td className={isStockOptimal(piso?.stock_toallas_cuerpo, piso?.stock_optimo_toallas_cuerpo)}>
                        {editedRowIndex === index ? (
                          <Input value={editedData.stock_toallas_cuerpo} onChange={(e) => handleChange(e, 'stock_toallas_cuerpo')} />
                        ) : (
                          renderStock(piso?.stock_toallas_cuerpo, piso?.stock_optimo_toallas_cuerpo)
                        )}
                      </td>
                      <td className={isStockOptimal(piso?.stock_toallas_mano, piso?.stock_optimo_toallas_mano)}>
                        {editedRowIndex === index ? (
                          <Input value={editedData.stock_toallas_mano} onChange={(e) => handleChange(e, 'stock_toallas_mano')} />
                          
                        ) : (
                          renderStock(piso?.stock_toallas_mano, piso?.stock_optimo_toallas_mano)
                        )}
                      </td>
                      {/* Repite lo mismo para las demás columnas */}
                      <td className={isStockOptimal(piso?.stock_alfombrines, piso?.stock_optimo_alfombrines)}>
                        {editedRowIndex === index ? (
                          <Input value={editedData.stock_alfombrines} onChange={(e) => handleChange(e, 'stock_alfombrines')} />
                        ) : (
                          renderStock(piso?.stock_alfombrines, piso?.stock_optimo_alfombrines)
                        )}
                      </td>

                    
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

               
        </Tabs.TabPane>
        <Tabs.TabPane tab="Camas" key="8">
          <div>

         
           
            <h1 className="titulo-tabla-stock">
              {mostrarStockFaltante ? "Stock Faltante para Stock Óptimo" : "Stock de Elementos por Piso"}
            </h1>
            
            <Divider type="vertical"></Divider>
            
            <Divider></Divider>
            {/* <Calendario/> */}
           
            <div id="managerTable_1_id">
              <table className="table-stock" id="managerTable_1">
                <thead className="thead-stock">
                  <tr>
                    <th className="th-stock">Piso</th>
                    <th className="th-stock">Alq. Subarriendo</th>
                    <th className="th-stock">G. Suministros</th>
                   
                    <th className="th-stock">Cama 150</th>
                    <th className="th-stock">Sofa Cama 150</th>
                    <th className="th-stock">Cama 90</th>
                    <th className="th-stock">Sofa Cama 90</th>
                    <th className="th-stock">Nº habs</th>
                  </tr>
                </thead>
                <tbody>
                  {usersActive?.map((piso, index) => (
                    
                    <tr key={piso?._id}>
                      <td className="td-stock"><b>{piso?.name}</b><br></br>({piso?.direccion_inmueble})</td>
                      <td style={{ textAlign: 'center', border:'1px solid black'  }}>
                        {editedRowIndex === index ? (
                          <Input value={piso?.gastos_fijos_subarriendo} onChange={(e) => handleChange(e, 'gastos_fijos_subarriendo')} />
                        ) : (
                          piso.gastos_fijos_subarriendo ? Number(piso.gastos_fijos_subarriendo).toFixed(2)+"€" : "-" 
                        )}
                      </td>
                      <td style={{ textAlign: 'center', border:'1px solid black' }}>
                        {editedRowIndex === index ? (
                          <Input value={piso?.gastos_estimados_suministros} onChange={(e) => handleChange(e, 'gastos_estimados_suministros')} />
                        ) : (
                          piso?.gastos_estimados_suministros ? piso?.gastos_estimados_suministros+"€" : "-" 
                          
                        )}
                      </td>
                      <td style={{ textAlign: 'center', border:'1px solid black' }}>
                        {editedRowIndex === index ? (
                          <Input value={piso?.numero_camas_150} onChange={(e) => handleChange(e, 'numero_camas_150')} />
                        ) : (
                          piso?.numero_camas_150
                        )}
                      </td>
                    
                     
                     <td style={{ textAlign: 'center', border:'1px solid black' }}>
                        {editedRowIndex === index ? (
                          <Input value={piso?.numero_sofas_cama_150} onChange={(e) => handleChange(e, 'numero_sofas_cama_150')} />
                        ) : (
                          piso?.numero_sofas_cama_150
                        )}
                      </td>

                     <td style={{ textAlign: 'center', border:'1px solid black' }}>
                        {editedRowIndex === index ? (
                          <Input value={piso?.numero_camas_90} onChange={(e) => handleChange(e, 'numero_camas_90')} />
                        ) : (
                          piso?.numero_camas_90
                        )}
                      </td>
                     <td style={{ textAlign: 'center', border:'1px solid black' }}>
                        {editedRowIndex === index ? (
                          <Input value={piso?.numero_sofas_cama_90} onChange={(e) => handleChange(e, 'numero_sofas_cama_90')} />
                        ) : (
                          piso?.numero_sofas_cama_90
                        )}
                      </td>
                      
                      <td style={{ textAlign: 'center', border:'1px solid black' }}>
                        {editedRowIndex === index ? (
                          <Input value={piso?.numero_habitaciones} onChange={(e) => handleChange(e, 'numero_habitaciones')} />
                        ) : (
                          piso?.numero_habitaciones
                        )}
                      </td>
                     {/* <td><Button onClick={handleEdit(index)}></Button></td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Mapa" key="3">
          <div className="mapa">
          <h1>Pisos y nombres</h1>
          
            <h1>Mapa</h1>
            <Map inmuebles={usersActive}></Map>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Puntuaciones" key="4">
          <div className="puntuaciones">
            <Puntuaciones />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Dispositivos" key="5">
          <div className="mapa"> 
          <Dispositivos inmuebles={usersActive}></Dispositivos>
          </div>
        </Tabs.TabPane>
      
      </Tabs>
      <Modal
        visible={modalVisible}
        onCancel={() => setmodal_visible(false)}
        footer={null}
        width="70%"
      >
        <ComentariosLimpiezaPorPiso  inmueble_id={editedData._id}/>
      </Modal>

    </>
  );
}
