import React from 'react';
import { Checkbox } from 'antd';

const StockCheckbox = ({ checked, onChange }) => {
  return (
    <td className="td-stock">
      <Checkbox checked={checked} onChange={onChange}></Checkbox>
    </td>
  );
};

export default StockCheckbox;
