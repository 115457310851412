import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import { useAuth } from "../../../hooks";
import { checkUserLogin } from "../../../providers/AuthProvider";
// import {useAuth} from "../../../hooks/useAuth"
import {
  MenuUnfoldOutlined,
  LineChartOutlined,
  UserOutlined,
  MenuOutlined,
  ReadOutlined,
  EditOutlined,
  UploadOutlined,
  AimOutlined,
  MobileOutlined,
  SafetyOutlined,
  SearchOutlined,
  SendOutlined,
  EuroCircleOutlined,
  IdcardOutlined,
  ShopOutlined,
  ContainerOutlined,
  EuroOutlined,
  SkinOutlined,
  ClearOutlined,
  ToolOutlined,
  HomeOutlined,
  CalculatorOutlined,
  CloseSquareOutlined
} from "@ant-design/icons";

import "./MenuSider.scss";

import Collapse from "react-bootstrap/Collapse";
import jwtDecode from "jwt-decode";
import { getFuncionalidadesApi } from "../../../api/auth";

function onClickMenu(setUser) {
  try {
    checkUserLogin(setUser);
  } catch (e) {
    //console.log(e);
  }
}

function MenuSider(props) {
  const { setUser, users } = useAuth();

  const { menuCollapsed, location } = props;

  const { Sider } = Layout;
  // let funcionalidades = JSON.parse(getFuncionalidadesApi());
  let funcionalidades = {};


  let menu = [
    {
      key: "/admin/home",
      icon: <HomeOutlined />,
      nombre: "Home",
      roles: {
        Administrador: true,
        Gestor: true,
        Propietario: true,
        Limpiador: false,
        Mantenimiento: false
      },
      modulo:"modulo_mantenimiento_activado"
    },
    {
      key: "/admin/users",
      icon: <UserOutlined />,
      nombre: "Users",
      roles: {
        Administrador: true,
        Gestor: true,
        Propietario: false,
        Limpiador: false,
        Mantenimiento: false
      },
      modulo:"modulo_usuarios_activado"
    },
   
    // {
    //   key: "/admin/calendario",
    //   icon: <UserOutlined />,
    //   nombre: "Calendario",
    //   roles: {
    //     Administrador: true,
    //     Gestor: true,
    //     Propietario: false,
    //     Limpiador: false,
    //     Mantenimiento: false
    //   },
    // },
    // {
    //   key: "/admin/lavanderia",
    //   icon: <UserOutlined />,
    //   nombre: "Lavanderia",
    //   roles: {
    //     Administrador: true,
    //     Gestor: true,
    //     Propietario: true,
    //     Limpiador: true,
    //   },
    // },
    {
      key: "/admin/inquilinos",
      icon: <IdcardOutlined />,
      nombre: "Inquilinos",
      roles: {
        Administrador: true,
        Gestor: true,
        Propietario: false,
        Limpiador: false,
        Mantenimiento: false
      },
      modulo:"modulo_inquilinos_activado"
    },
    {
      key: "/admin/inmuebles",
      icon: <ShopOutlined />,
      nombre: "Inmuebles",
      roles: {
        Administrador: true,
        Gestor: true,
        Propietario: false,
        Limpiador: false,
        Mantenimiento: false
      },
      modulo:"modulo_inmuebles_activado"
    },
    {
      key: "/admin/Cancelaciones",
      icon: <CloseSquareOutlined />,
      nombre: "Cancelaciones",
      roles: {
        Administrador: true,
        Gestor: true,
        Propietario: false,
        Limpiador: false,
        Mantenimiento: false
      },
      modulo:"modulo_cancelaciones_activado"
    },
    // {
    //   key: "/admin/gastos",
    //   icon: <EuroOutlined />,
    //   nombre: "Gastos",
    //   roles: {
    //     Administrador: true,
    //     Gestor: true,
    //     Propietario: true,
    //     Limpiador: false, 
    //     Mantenimiento: false
    //   },
    //   modulo:"modulo_gastos_activado"
    // },
    {
      key: "/admin/graph",
      icon: <LineChartOutlined />,
      nombre: "Analiticas",
      roles: {
        Administrador: false,
        Gestor: false,
        Propietario: true,
        Limpiador: false,
        Mantenimiento: false
      },
      modulo:"modulo_analiticas_piso_activado"
    },
    {
      key: "/admin/graph_total",
      icon: <LineChartOutlined />,
      nombre: "Analisis global",
      roles: {
        Administrador: false,
        Gestor: false,
        Propietario: false,
        Limpiador: false,
        Mantenimiento: false
      },
      modulo:"modulo_analiticas_activado"
    },
    {
      key: "/admin/facturas",
      icon: <ContainerOutlined />,
      nombre: "Facturas",
      roles: {
        Administrador: true,
        Gestor: true,
        Propietario: true,
        Limpiador: false,
        Mantenimiento: false
      },
      modulo:"modulo_facturas_activado"
    },
    // {
    //   key: "/admin/alta_piso",
    //   icon: <LineChartOutlined />,
    //   nombre: "Alta Piso",
    //   roles: {
    //     Administrador: true,
    //     Gestor: true,
    //     Propietario: false,
    //     Limpiador: false,
    //     Mantenimiento: false
    //   },
    // },
    {
      key: "/admin/precios",
      icon: <EuroCircleOutlined />,
      nombre: "Precios",
      roles: {
        Administrador: true,
        Gestor: false,
        Propietario: false,
        Limpiador: false,
        Mantenimiento: false
      },
      modulo:"modulo_precios_activado"
    },
    {
      key: "/admin/Limpieza",
      icon: <ClearOutlined />,
      nombre: "Limpieza",
      roles: {
        Administrador: true,
        Gestor: true,
        Propietario: false,
        Limpiador: true,
        Mantenimiento: false
      },
       modulo:"modulo_limpieza_activado"
    },
    {
      key: "/admin/Mantenimiento",
      icon:<ToolOutlined />,
      nombre: "Mantenimiento",
      roles: {
        Administrador: true,
        Gestor: true,
        Propietario: false,
        Limpiador: false,
        Mantenimiento: true
      },
      modulo:"modulo_mantenimiento_activado"
    },
    {
      key: "/admin/Dispositivos",
      icon: <MobileOutlined />,
      nombre: "Dispositivos",
      roles: {
        Administrador: true,
        Gestor: true,
        Propietario: false,
        Limpiador: false,
        Mantenimiento: true
      },
      modulo:"modulo_dispositivos_activado"
    },
    {
      key: "/admin/bancos",
      icon: <CalculatorOutlined />,
      nombre: "Bancos",
      roles: {
        Administrador: true,
        Gestor: true,
        Propietario: false,
        Limpiador: false,
        Mantenimiento: true
      },
      modulo:"modulo_dispositivos_activado"
    },
  ];

  return (
    <Sider className="admin-sider" /* collapsed={menuCollapsed} */>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        //defaultSelectedKeys={["/admin/users"]}
        //items={items}
        //onClick={ window.location.href(key)}
      >
        {menu.map((x) => {
          return x.roles[users.user.role] ? (
            // funcionalidades[x.modulo] ? (
            <Menu.Item key={x.key}>
              <Link to={x.key} onClick={() => onClickMenu(setUser)}>
                {x.icon}
                <span className="nac-text">{x.nombre}</span>
              </Link>
            </Menu.Item> 
            // ) 
          //   : 
          //   <Menu.Item key={x.key}>
          //   <Link to={x.key} onClick={() => onClickMenu(setUser)}>
          //     {x.icon}
          //     <span className="nac-text">{x.nombre}</span>
          //   </Link>
          // </Menu.Item>
          ) : (
            <></>
          );
        })}
      </Menu>
    </Sider>
  );
}

export default withRouter(MenuSider);
