import React, { useState, useEffect } from 'react';
import { getAccessTokenApi } from '../../../../api/auth';
import { saveCalculatorApi, getCalculatorApi } from '../../../../api/calculadora_rentabilidad';
import { notification } from 'antd';

const Calculadora_rentabilidad = () => {
  const [categories, setCategories] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const userId = "123456"; // El id del usuario puede variar según la autenticación


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCalculatorApi(getAccessTokenApi());
        if (data) {
          setCategories(data.categories || []);
          setMetrics(data.metrics || []);
        }
      } catch (error) {
        console.error('Error al cargar la calculadora:', error);
      }
    };

    fetchData();
  }, []); // Asegúrate de pasar accessToken como dependencia si cambia

  // Guardar la estructura actual en el servidor
  const saveCalculator = async () => {
    const calculatorData = {
      userId,
      categories,
      metrics,
    };

    try {
      const response = await saveCalculatorApi(getAccessTokenApi(), calculatorData);
      if(response.message){
        notification["success"]({
          message: "Guardado correctamente"
        });
      }
      console.log("Calculadora guardada con éxito:", response);
    } catch (error) {
      console.error("Error al guardar la calculadora:", error);
    }
  };
  // Actualizar la estructura existente en el servidor
  const updateCalculator = () => {
    const calculatorData = {
      categories,
      metrics
    };

    fetch(`http://localhost:3977/api/v1/calculator/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAccessTokenApi()
      },
      body: JSON.stringify(calculatorData)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Calculadora actualizada:', data);
    })
    .catch(error => console.error('Error al actualizar la calculadora:', error));
  };

  // Agregar Categoría
  const addCategory = (categoryName) => {
    // if (categoryName && !categories.find(c => c.name === categoryName)) {
    //   setCategories([...categories, { name: categoryName, subcategories: [], isOpen: false }]);
    // }
    if (categoryName && !categories.find(c => c.name === categoryName)) {
      const updatedCategories = [...categories, { name: categoryName, subcategories: [], isOpen: false }];
      setCategories(updatedCategories);
      updateCalculator(); // Actualiza en el servidor después de agregar
    }
  };

  // Editar Categoría
  const editCategory = (index, newCategoryName) => {
    const updatedCategories = [...categories];
    updatedCategories[index].name = newCategoryName;
    setCategories(updatedCategories);
  };

  // Eliminar Categoría
  const deleteCategory = (index) => {
    const updatedCategories = categories.filter((_, i) => i !== index);
    setCategories(updatedCategories);
    updateCalculator(); // Actualiza en el servidor después de eliminar
  };

  // Agregar Subcategoría
  const addSubcategory = (categoryName, subcategoryName) => {
    setCategories(categories.map(category => {
      if (category.name === categoryName) {
        return {
          ...category,
          subcategories: [
            ...category.subcategories,
            { name: subcategoryName, items: [] }
          ],
        };
      }
      return category;
    }));
  };

  // Editar Subcategoría
  const editSubcategory = (categoryIndex, subcategoryIndex, newSubcategoryName) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories[subcategoryIndex].name = newSubcategoryName;
    setCategories(updatedCategories);
  };

  // Eliminar Subcategoría
  const deleteSubcategory = (categoryIndex, subcategoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories = updatedCategories[categoryIndex].subcategories.filter(
      (_, i) => i !== subcategoryIndex
    );
    setCategories(updatedCategories);
  };

  // Agregar Ítem
  const addItem = (categoryName, subcategoryName, itemName, value, type) => {
    setCategories(categories.map(category => {
      if (category.name === categoryName) {
        return {
          ...category,
          subcategories: category.subcategories.map(subcategory => {
            if (subcategory.name === subcategoryName) {
              return {
                ...subcategory,
                items: [...subcategory.items, { name: itemName, value: parseFloat(value), type }],
              };
            }
            return subcategory;
          }),
        };
      }
      return category;
    }));
  };

  // Editar Ítem
  const editItem = (categoryIndex, subcategoryIndex, itemIndex, newItem) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories[subcategoryIndex].items[itemIndex] = newItem;
    setCategories(updatedCategories);
  };

  // Eliminar Ítem
  const deleteItem = (categoryIndex, subcategoryIndex, itemIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories[subcategoryIndex].items = updatedCategories[categoryIndex].subcategories[subcategoryIndex].items.filter(
      (_, i) => i !== itemIndex
    );
    setCategories(updatedCategories);
  };

  // Calcular total por categoría
  const calculateCategoryTotal = (category) => {
    return category.subcategories.reduce((acc, subcategory) => {
      return acc + subcategory.items.reduce((itemAcc, item) => {
        return item.type === 'sumar' ? itemAcc + item.value : itemAcc - item.value;
      }, 0);
    }, 0);
  };

  // Copiar estructura JSON
  const copyToClipboard = () => {
    const jsonStr = JSON.stringify(categories, null, 2);
    navigator.clipboard.writeText(jsonStr).then(() => {
      alert("Estructura copiada al portapapeles");
    });
  };

  // Alternar collapse de categoría
  const toggleCollapse = (index) => {
    const updatedCategories = [...categories];
    updatedCategories[index].isOpen = !updatedCategories[index].isOpen;
    setCategories(updatedCategories);
  };

  // Función para agregar una métrica
  const addMetric = (metricName, variable1, operation, variable2) => {
    const newMetric = { name: metricName, variable1, operation, variable2 };
    setMetrics([...metrics, newMetric]);
  };

  // Calcular el valor de una métrica
  const calculateMetric = (metric) => {
    const { variable1, operation, variable2 } = metric;

    const value1 = getVariableValue(variable1);
    const value2 = getVariableValue(variable2);

    switch (operation) {
      case 'sumar':
        return value1 + value2;
      case 'restar':
        return value1 - value2;
      case 'multiplicar':
        return value1 * value2;
      case 'dividir':
        return value2 !== 0 ? value1 / value2 : 'N/A';
      default:
        return 0;
    }
  };

  // Obtener el valor de una variable (categoría, subcategoría o total)
  const getVariableValue = (variable) => {
    if (variable === 'total') {
      return calculateTotal();
    }
    const category = categories.find(c => c.name === variable);
    if (category) {
      return calculateCategoryTotal(category);
    }
    return 0;
  };

  // Calcular el total general
  const calculateTotal = () => {
    return categories.reduce((acc, category) => {
      return acc + calculateCategoryTotal(category);
    }, 0);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Calculadora de Rentabilidad</h1>
      <AddCategoryForm addCategory={addCategory} />
      <CategoryList 
        categories={categories} 
        addSubcategory={addSubcategory}
        addItem={addItem}
        editCategory={editCategory}
        deleteCategory={deleteCategory}
        editSubcategory={editSubcategory}
        deleteSubcategory={deleteSubcategory}
        editItem={editItem}
        deleteItem={deleteItem}
        toggleCollapse={toggleCollapse}
        calculateCategoryTotal={calculateCategoryTotal}
      />
      <Total categories={categories} />
      <h2 style={styles.title}>Métricas</h2>
      <AddMetricForm categories={categories} addMetric={addMetric} />
      <MetricList metrics={metrics} calculateMetric={calculateMetric} />
      
     
     
      <button style={styles.copyButton} onClick={copyToClipboard}>Copiar Estructura JSON</button>
      <button onClick={saveCalculator} style={styles.button}>Guardar Calculadora</button>
    </div>
  );
};

// Formulario para agregar categorías
const AddCategoryForm = ({ addCategory }) => {
  const [categoryName, setCategoryName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addCategory(categoryName);
    setCategoryName('');
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.formGroup}>
        <input
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          placeholder="Nueva Categoría"
          style={styles.input}
          required
        />
      </div>
      <button type="submit" style={styles.button}>Agregar Categoría</button>
    </form>
  );
};

// Lista de Categorías
const CategoryList = ({ categories, addSubcategory, addItem, editCategory, deleteCategory, editSubcategory, deleteSubcategory, editItem, deleteItem, toggleCollapse, calculateCategoryTotal }) => {
  return (
    <div style={styles.itemList}>
      {categories.map((category, categoryIndex) => (
        <div key={categoryIndex} style={styles.category}>
          <div style={styles.categoryHeader} onClick={() => toggleCollapse(categoryIndex)}>
            <h2>{category.name} Total: <b>{formatCurrency(calculateCategoryTotal(category))}</b></h2>
            <button style={styles.collapseButton}>
              {category.isOpen ? "▲" : "▼"}
            </button>
          </div>
          {category.isOpen && (
            <div style={styles.categoryContent}>
              <div style={styles.actionButtons}>
                <button onClick={() => editCategory(categoryIndex, prompt("Nuevo nombre de la categoría:"))}>Editar</button>
                <button onClick={() => deleteCategory(categoryIndex)}>Eliminar</button>
              </div>
              <AddSubcategoryForm categoryName={category.name} addSubcategory={addSubcategory} />
              {category.subcategories.map((subcategory, subIndex) => (
                <div key={subIndex} style={styles.subcategory}>
                  <h3> - {subcategory.name}</h3>
                  <div style={styles.actionButtons}>
                    <button onClick={() => editSubcategory(categoryIndex, subIndex, prompt("Nuevo nombre de la subcategoría:"))}>Editar</button>
                    <button onClick={() => deleteSubcategory(categoryIndex, subIndex)}>Eliminar</button>
                  </div>
                  <AddItemForm 
                    categoryName={category.name} 
                    subcategoryName={subcategory.name} 
                    addItem={addItem} 
                  />
                  <ul style={styles.ul}>
                    {subcategory.items.map((item, itemIndex) => (
                      <li key={itemIndex} style={styles.li}>
                        <span>{item.name}</span>
                        <span style={styles.value}>
                          {item.type === 'sumar' ? '+' : '-'}${Math.abs(item.value)}
                        </span>
                        <div style={styles.actionButtons}>
                          <button onClick={() => editItem(categoryIndex, subIndex, itemIndex, {
                            ...item, 
                            name: prompt("Nuevo nombre del ítem:", item.name),
                            value: parseFloat(prompt("Nuevo valor del ítem:", item.value)),
                            type: prompt("Tipo (sumar/restar):", item.type)
                          })}>Editar</button>
                          <button onClick={() => deleteItem(categoryIndex, subIndex, itemIndex)}>Eliminar</button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

// Formulario para agregar subcategorías
const AddSubcategoryForm = ({ categoryName, addSubcategory }) => {
  const [subcategoryName, setSubcategoryName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addSubcategory(categoryName, subcategoryName);
    setSubcategoryName('');
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.formGroup}>
        <input
          type="text"
          value={subcategoryName}
          onChange={(e) => setSubcategoryName(e.target.value)}
          placeholder="Nueva Subcategoría"
          style={styles.input}
          required
        />
      </div>
      <button type="submit" style={styles.button}>Agregar Subcategoría</button>
    </form>
  );
};

// Formulario para agregar ítems
const AddItemForm = ({ categoryName, subcategoryName, addItem }) => {
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [type, setType] = useState('sumar');

  const handleSubmit = (e) => {
    e.preventDefault();
    addItem(categoryName, subcategoryName, name, value, type);
    setName('');
    setValue('');
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.formGroup}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nuevo ítem"
          style={styles.input}
          required
        />
      </div>
      <div style={styles.formGroup}>
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Valor"
          style={styles.input}
          required
        />
      </div>
      <div style={styles.formGroup}>
        <select value={type} onChange={(e) => setType(e.target.value)} style={styles.select}>
          <option value="sumar">Sumar</option>
          <option value="restar">Restar</option>
        </select>
      </div>
      <button type="submit" style={styles.button}>Agregar Ítem</button>
    </form>
  );
};

// Formulario para agregar métricas
const AddMetricForm = ({ categories, addMetric }) => {
  const [metricName, setMetricName] = useState('');
  const [variable1, setVariable1] = useState('total');
  const [operation, setOperation] = useState('sumar');
  const [variable2, setVariable2] = useState('total');

  const handleSubmit = (e) => {
    e.preventDefault();
    addMetric(metricName, variable1, operation, variable2);
    setMetricName('');
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.formGroup}>
        <input
          type="text"
          value={metricName}
          onChange={(e) => setMetricName(e.target.value)}
          placeholder="Nueva Métrica"
          style={styles.input}
          required
        />
      </div>
      <div style={styles.formGroup}>
        <select value={variable1} onChange={(e) => setVariable1(e.target.value)} style={styles.select}>
          <option value="total">Total General</option>
          {categories.map((category, index) => (
            <option key={index} value={category.name}>{category.name}</option>
          ))}
        </select>
      </div>
      <div style={styles.formGroup}>
        <select value={operation} onChange={(e) => setOperation(e.target.value)} style={styles.select}>
          <option value="sumar">Sumar</option>
          <option value="restar">Restar</option>
          <option value="multiplicar">Multiplicar</option>
          <option value="dividir">Dividir</option>
        </select>
      </div>
      <div style={styles.formGroup}>
        <select value={variable2} onChange={(e) => setVariable2(e.target.value)} style={styles.select}>
          <option value="total">Total General</option>
          {categories.map((category, index) => (
            <option key={index} value={category.name}>{category.name}</option>
          ))}
        </select>
      </div>
      <button type="submit" style={styles.button}>Agregar Métrica</button>
    </form>
  );
};

// Lista de Métricas
const MetricList = ({ metrics, calculateMetric }) => {
  return (
    <div style={styles.itemList}>
      {metrics.map((metric, index) => (
        <div key={index} style={styles.metric}>
          <h3>{metric.name}: {calculateMetric(metric)}</h3>
        </div>
      ))}
    </div>
  );
};
const formatCurrency = (value) => {
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(value);
};
// Componente para calcular el total general
const Total = ({ categories }) => {
  const calculateTotal = () => {
    return categories.reduce((acc, category) => {
      return acc + category.subcategories.reduce((subAcc, subcategory) => {
        return subAcc + subcategory.items.reduce((itemAcc, item) => {
          return item.type === 'sumar' ? itemAcc + item.value : itemAcc - item.value;
        }, 0);
      }, 0);
    }, 0);
  };

  return (
    <div style={styles.totalContainer}>
      <h2 style={styles.total}>Total General: {formatCurrency(calculateTotal())}</h2>
    </div>
  );
};

// Estilos para los componentes
const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    borderRadius: '8px',
    backgroundColor: '#f4f4f4',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center',
    color: '#333',
    fontSize: '2rem',
    marginBottom: '20px',
  },
  form: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formGroup: {
    marginBottom: '15px',
    flex: 1,
    marginRight: '10px',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    marginTop: '5px',
  },
  select: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    marginTop: '5px',
  },
  button: {
    backgroundColor: '#28a745',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    flexShrink: 0,
  },
  itemList: {
    marginBottom: '20px',
  },
  ul: {
    listStyleType: 'none',
    paddingLeft: '0',
  },
  li: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderBottom: '1px solid #ccc',
    marginBottom: '5px',
  },
  value: {
    fontWeight: 'bold',
    color: '#333',
  },
  actionButtons: {
    display: 'flex',
    gap: '10px',
  },
  category: {
    marginBottom: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px',
    backgroundColor: '#fff',
  },
  categoryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  collapseButton: {
    backgroundColor: '#ddd',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  categoryContent: {
    padding: '10px 0',
  },
  totalContainer: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#28a745',
    borderRadius: '5px',
    marginTop: '20px',
  },
  total: {
    color: 'white',
    fontSize: '1.8rem',
  },
  copyButton: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

export default Calculadora_rentabilidad;
