// import React, { useState } from "react";
// import { Form, Input, Button, notification } from "antd";
// import axios from "axios"; // Asegúrate de tener axios instalado
// import { getAccessTokenApi } from "../../../api/auth";
// import { enviar_password_nueva } from "../../../api/user";

// export default function RecuperarPassword({ match }) {
//   const TOKEN_AUX = match.params.token;

//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");

//   const handlePasswordChangeSubmit = async () => {
//     try {
//       if (password !== confirmPassword) {
//         // Si las contraseñas no coinciden, muestra una notificación de error
//         notification.error({
//           message: "Error",
//           description: "Las contraseñas no coinciden",
//         });
//         return;
//       }

//       const response = await enviar_password_nueva(TOKEN_AUX, password);

//       if (response.message) {
//         // Lógica en caso de éxito
//         //console.log("Contraseña cambiada con éxito");
//         notification.success({
//           message: "Éxito",
//           description: "Contraseña cambiada con éxito"+response.message,
//         });
//       } else {
//         // Lógica en caso de error
//         console.error("Error al cambiar la contraseña");
//         notification.error({
//           message: "Error",
//           description: "Error al cambiar la contraseña" + response.error,
//         });
//       }
//     } catch (error) {
//       // Manejo de errores generales
//       console.error("Error inesperado al cambiar la contraseña", error);
//       notification.error({
//         message: "Error inesperado",
//         description: "Ocurrió un error inesperado al cambiar la contraseña",
//       });
//     }
//   };

//   return (
//     <Form onFinish={handlePasswordChangeSubmit}>
//       <Form.Item
//         name="password"
//         rules={[
//           {
//             required: true,
//             message: "Por favor, ingresa tu nueva contraseña",
//           },
//         ]}
//       >
//         <Input.Password
//           placeholder="Nueva contraseña"
//           onChange={(e) => setPassword(e.target.value)}
//         />
//       </Form.Item>

//       <Form.Item
//         name="confirmPassword"
//         rules={[
//           {
//             required: true,
//             message: "Por favor, confirma tu nueva contraseña",
//           },
//         ]}
//       >
//         <Input.Password
//           placeholder="Confirmar contraseña"
//           onChange={(e) => setConfirmPassword(e.target.value)}
//         />
//       </Form.Item>

//       <Form.Item>
//         <Button type="primary" htmlType="submit">
//           Cambiar Contraseña
//         </Button>
//       </Form.Item>
//     </Form>
//   );
// }


import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import axios from "axios"; // Asegúrate de tener axios instalado
import { getAccessTokenApi } from "../../../api/auth";
import { enviar_password_nueva } from "../../../api/user";

export default function RecuperarPassword({ match }) {
  const TOKEN_AUX = match.params.token;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlePasswordChangeSubmit = async () => {
    try {
      if (password !== confirmPassword) {
        // Si las contraseñas no coinciden, muestra una notificación de error
        notification.error({
          message: "Error",
          description: "Las contraseñas no coinciden",
        });
        return;
      }

      const response = await enviar_password_nueva(TOKEN_AUX, password);

      if (response.message) {
        // Lógica en caso de éxito
        //console.log("Contraseña cambiada con éxito");
        notification.success({
          message: "Éxito",
          description: "Contraseña cambiada con éxito" + response.message,
        });
        window.open(
          "/"
        );
      } else {
        // Lógica en caso de error
        console.error("Error al cambiar la contraseña");
        notification.error({
          message: "Error",
          description: "Error al cambiar la contraseña" + response.error,
        });
      }
    } catch (error) {
      // Manejo de errores generales
      console.error("Error inesperado al cambiar la contraseña", error);
      notification.error({
        message: "Error inesperado",
        description:
          "Ocurrió un error inesperado al cambiar la contraseña",
      });
    }
  };

  return (
    <Form
      onFinish={handlePasswordChangeSubmit}
      style={{ maxWidth: "400px", margin: "auto", marginTop: "50px" }}
    >
      Escribe tu nueva contraseña
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Por favor, ingresa tu nueva contraseña",
          },
        ]}
      >
        <Input.Password
          placeholder="Nueva contraseña"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: "Por favor, confirma tu nueva contraseña",
          },
        ]}
      >
        <Input.Password
          placeholder="Confirmar contraseña"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Cambiar Contraseña
        </Button>
      </Form.Item>
    </Form>
  );
}
