import React, { useState } from "react";
import { Table } from "antd";

export default function TablePlot(props) {
  const { data, columns, myexpandable,rowKey,onChange } = props;
  if (!myexpandable) {
    return null; // Devuelve null si no es expandible
  }

  return (
    <Table
      dataSource={data}
      columns={columns}
      expandable={myexpandable}
      rowKey={rowKey}
      onChange={onChange}
      pagination={{
        defaultPageSize: 50,
        showSizeChanger: true,
        pageSizeOptions: ["2", "5", "10", "20", "30"],
      }}
      // Descomentar si necesitas scroll
      // scroll={{ y: 560, x: 200 }}
    />
  );

}

// var dataSource = [
//   {
//     name: "Furgoneta1",
//     owner: "Miguel",
//     status: "Offline",
//     model: "Furgoneta",
//     update: false,
//   },
//   {
//     name: "Bici1",
//     owner: "Juan",
//     status: "Online",
//     model: "Bici de carga",
//     update: false,
//   },
// ];

// const columns = [
//   {
//     title: "Device Name",
//     dataIndex: "name",
//     key: "name",
//   },
//   {
//     title: "Device Owner",
//     dataIndex: "owner",
//     key: "owner",
//   },
//   {
//     title: "Status",
//     dataIndex: "status",
//     key: "status",
//   },
//   {
//     title: "Device Model",
//     dataIndex: "model",
//     key: "model",
//   },
//   {
//     title: "Last Updated",
//     dataIndex: "update",
//     key: "update",
//   },
// ];
