import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { getUsersActiveApi, Keyrock } from "../../../api/user";
import ListUsers from "../../../components/Admin/Users/ListUsers";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";

import "./Users.scss";

export default function Users() {
  const [usersActive, setUsersActive] = useState([]); //Todos los usuarios
  const [usersInactive, setUsersInactive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);

  const token = getAccessTokenApi();

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("useEffect responseActive")
        const responseActive = await getUsersActiveApi(token, true);
        setUsersActive(responseActive.users);
  
        const responseInactive = await getUsersActiveApi(token, false);
        setUsersInactive(responseInactive.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setReloadUsers(false);
      }
    };
  
    fetchData();
  }, [token, reloadUsers]); // Solo se refresca si cambian estos
  

  return (
    <div className="users">
      <ListUsers
        usersActive={usersActive}
        usersInactive={usersInactive}
        setReloadUsers={setReloadUsers}
        reloadUsers={reloadUsers}
      />
    </div>
  );
}
