import { basePath, apiVersion } from "./config";
// 1- Crear un inmueble nuevo
// 2- Leer todos los inmuebles
// 3- Eliminar un inmueble
// 4- Actualizar un inmueble
// 5- Dada una casa leer la temperatura de la casa
// 6- Dada una casa leer la potencia de la casa
// 7- Dada una casa Activar shelly 1,2 o 3 de la casa
// 8- Obtener los status del shelly 1,2 o 3 de la casa

export async function createInmueble(accessToken, data) {
  try {
    //console.log("formData");
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    //console.log(formData);
    const url = `${basePath}/${apiVersion}/Inmueble`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();

    return result;
  } catch (error) {
    //console.log(error);
  }
}
//El token es necesario para poder atacar al backend
export function getUsersApi(token) {
  const url = `${basePath}/${apiVersion}/users`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}


export function getInmuebleDatosFacturacion(token, id) {
  const url = `${basePath}/${apiVersion}/inmueble/datos_facturacion/${id}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getInmuebleTipoGestion(token, id) {
  const url = `${basePath}/${apiVersion}/inmueble/tipo_gestion/${id}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}


export function getInmueblesActiveApi(token, status) {
  const url = `${basePath}/${apiVersion}/Inmuebles-active?active=${status}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getInmueblesApi(token, page, status) {
  const url = `${basePath}/${apiVersion}/inmuebles?active=${status}&page=${page}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}


export function getInmueblesApiBooking(token, status) {
  const url = `${basePath}/${apiVersion}/inmuebles-booking?active=${status}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}


export function uploadAvatarApi(token, avatar, userId) {
  const url = `${basePath}/${apiVersion}/upload-avatar/${userId}`;

  const formData = new FormData();
  formData.append("avatar", avatar, avatar.name);

  const params = {
    method: "PUT",
    body: formData,
    headers: {
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getAvatarApi(avatarName) {
  const url = `${basePath}/${apiVersion}/get-avatar/${avatarName}`;

  return fetch(url)
    .then((response) => {
      return response.url;
    })
    .catch((err) => {
      return err.message;
    });
}

export function updateInmuebleApi(token, user, userId) {
  const url = `${basePath}/${apiVersion}/Inmueble/${userId}`;

  const params = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(user),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function activateInmuebleApi(token, userId, status) {
  const url = `${basePath}/${apiVersion}/activate-Inmueble/${userId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      active: status,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function deleteUserApi(token, userId) {
  const url = `${basePath}/${apiVersion}/user/${userId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function signUpAdminApi(token, data) {
  const url = `${basePath}/${apiVersion}/sign-up-admin`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getBookingPuntuaciones(token, enlaces) {
  const url = `${basePath}/${apiVersion}/puntuacion-booking`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(enlaces),
  };

  return await fetch(url, params)
    .then(async (response) => {
      return await response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function deleteInmuebleApi(token, userId) {
  const url = `${basePath}/${apiVersion}/Inmueble/${userId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}
export function desactivateInmuebleApi(token, userId) {
  const url = `${basePath}/${apiVersion}/inmueble/desactivate/${userId}`;

  const params = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}
export function abrirPuertaInmuebleApi(code) {
  const url = `${basePath}/${apiVersion}/Inmueble-abrir`;
  //console.log("abrirPuertaInmuebleApi", code);

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code: code,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getInmuebleTemperatura(code_casa, accessToken) {
  const url = `${basePath}/${apiVersion}/inmueble/${code_casa}/temperature`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify({
    //   code: code,
    // }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function getPostSalidaInmueble(code_casa, accessToken, idioma) {
  const url = `${basePath}/${apiVersion}/inmueble/${code_casa}/mensaje-post-salida?idioma=${idioma}`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify({
    //   code: code,
    // }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function getPreentradaInmueble(code_casa, accessToken, idioma) {
  const url = `${basePath}/${apiVersion}/inmueble/${code_casa}/mensaje-pre-entrada?idioma=${idioma}`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify({
    //   code: code,
    // }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function getDescriptionInmueble(code_casa, accessToken, idioma) {
  const url = `${basePath}/${apiVersion}/inmueble/${code_casa}/description?idioma=${idioma}`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify({
    //   code: code,
    // }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function getNombreInmueble(code_casa, accessToken) {
  const url = `${basePath}/${apiVersion}/inmueble/${code_casa}/nombre`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify({
    //   code: code,
    // }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function getPrecioLimpiadorInmueble(id, accessToken) {
  const url = `${basePath}/${apiVersion}/inmueble/${id}/precio`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify({
    //   code: code,
    // }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function getPoliciaIdInmueble(code_casa, accessToken) {
  const url = `${basePath}/${apiVersion}/inmueble/${code_casa}/policia_id`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify({
    //   code: code,
    // }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function verTemperaturaCaldera(accessToken, id) {
  const url = `${basePath}/${apiVersion}/inmueble/${id}/temperatura`;
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}


export function comprobarStockPiso(accessToken, id, cantidadArmario) {
  const url = `${basePath}/${apiVersion}/inmueble/${id}/checkStock`;
  //console.log("cantidadArmario", cantidadArmario);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(cantidadArmario) // Corregido aquí
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}


export function encenderApagarCaldera(accessToken, id, estado) {
  const url = `${basePath}/${apiVersion}/inmueble/${id}/potencia/${estado}`;
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function conmutarInmuebleRele(
  code_casa,
  code_sensor,
  estado,
  accessToken
) {
  const url = `${basePath}/${apiVersion}/inmueble/${code_casa}/cambiar-estado/${code_sensor}/${estado}`;
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify({
    //   code: code,
    // }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function obtenerListaInmuebles(accessToken) {
  //TODO: modificar el access token

  const url = `${basePath}/${apiVersion}/inmueble/lista_inmuebles`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export const getPisoByIdApi = (id, token) => {
  const url = `${basePath}/${apiVersion}/inmueble/${id}/completo`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Error al obtener los detalles del inmueble');
    })
    .then((data) => {
      if (!data || !data.inmueble) {
        //console.log('Respuesta vacía o sin el inmueble');
        return null; // Devuelve null si no se encuentra el inmueble
      }
      return data.inmueble;
    })
    .catch((error) => {
      console.error("Error en getPisoByIdApi: ", error);
      throw error;
    });
};
export const getPisoNombre_id = (id, token) => {
  const url = `${basePath}/${apiVersion}/inmueble/${id}/nombre-id`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Error al obtener los detalles del inmueble');
    })
    .then((data) => {
      if (!data || !data.inmueble) {
        //console.log('Respuesta vacía o sin el inmueble');
        return null; // Devuelve null si no se encuentra el inmueble
      }
      return data.inmueble;
    })
    .catch((error) => {
      console.error("Error en getPisoByIdApi: ", error);
      throw error;
    });
};
export function getInmuebleName(id, token) {
  const url = `${basePath}/${apiVersion}/inmueble/${id}/nombre`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
