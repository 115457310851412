import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../../api/auth";
import {
  getSeries,
  createSerie,
  updateSerie, // Función de actualización
  deleteSerie
} from "../../../../api/series";
import {
  Button,
  List,
  Modal as ModalAntd,
  Input,
  Form,
  notification,
  Card,
  Divider
} from "antd";
import Boton_peticion_handler_v2 from "../../../../components/Admin/boton_peticion_handler_v2/boton_peticion_handler_v2";
import "./Series.scss";

export default function Series() {
  const [usersActive, setUsersActive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal state
  const [isEditMode, setIsEditMode] = useState(false); // Estado para modo edición
  const [selectedSerie, setSelectedSerie] = useState(null); // Serie seleccionada para editar
  const [nombreSerie, setNombreSerie] = useState("");
  const [numeroSerieGlobal, setNumeroSerieGlobal] = useState("");
  const [nombreLomo, setNombreLomo] = useState("");

  const token = getAccessTokenApi();

  useEffect(() => {
    getSeries(token).then((response) => {
      setUsersActive(response.series);
    });
    setReloadUsers(false);
  }, [token, reloadUsers]);

  // Función para manejar la creación de una nueva serie o editar una existente
  const handleSubmitSerie = async () => {
    if (!nombreSerie || !numeroSerieGlobal || !nombreLomo) {
      notification["error"]({
        message: "Todos los campos son obligatorios",
      });
      return;
    }

    try {
      let result;

      if (isEditMode && selectedSerie) {
        // Modo de edición: actualizar serie existente
        result = await updateSerie(
          token,
          selectedSerie._id,
          nombreSerie,
          numeroSerieGlobal,
          nombreLomo
        );
      } else {
        // Modo de creación: crear nueva serie
        result = await createSerie(
          token,
          nombreSerie,
          numeroSerieGlobal,
          nombreLomo
        );
      }

      if (result.message) {
        notification["success"]({
          message: isEditMode
            ? "Serie actualizada correctamente"
            : "Serie creada correctamente",
        });

        // Cerrar el modal y resetear los campos del formulario
        setIsModalVisible(false);
        setReloadUsers(true);

        // Resetear los campos del formulario
        setNombreSerie("");
        setNumeroSerieGlobal("");
        setNombreLomo("");
        setIsEditMode(false); // Salir del modo de edición
      } else {
        notification["error"]({
          message: isEditMode
            ? "Error al actualizar la serie"
            : "Error al crear la serie",
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Error",
        description: error.message,
      });
    }
  };

  // Función para abrir el modal en modo edición con los datos de la serie seleccionada
  const handleEditSerie = (serie) => {
    setSelectedSerie(serie);
    setNombreSerie(serie.nombre_serie);
    setNumeroSerieGlobal(serie.numero_serie_global);
    setNombreLomo(serie.nombre_lomo);
    setIsEditMode(true); // Cambiar a modo edición
    setIsModalVisible(true); // Mostrar modal
  };

  const SeriesActive = ({ item }) => (
    <Card>
      <List.Item
        actions={[
          // <Button type="primary" onClick={() => handleEditSerie(item)}>
          //   Editar
          // </Button>,
          <Button
            type="danger"
            onClick={async () => {
              let resp = await deleteSerie(token, item._id);
              if (resp.message) {
                notification["success"]({
                  message: "Información " + resp.message,
                });
                setReloadUsers(true);
              } else {
                notification["error"]({
                  message: "Error: " + resp.error,
                });
              }
            }}
          >
            Desactivar
          </Button>,
        ]}
      >
        {item.nombre_serie}
        <Divider type="vertical" />
        {item.numero_serie_global}
        <Divider type="vertical" />
        {item.nombre_lomo}
      </List.Item>
    </Card>
  );

  // Render principal
  return (
    <>
      <Boton_peticion_handler_v2
        cuerpo={"Crear nueva serie"}
        handler={() => {
          setIsEditMode(false); // Cambiar a modo creación
          setIsModalVisible(true);
        }} // Mostrar modal al hacer clic
      />

      {/* Modal para crear/editar una serie */}
      <ModalAntd
        title={isEditMode ? "Editar Serie" : "Crear Nueva Serie"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)} // Cerrar modal
        footer={null}
      >
        <Form>
          <Form.Item label="Nombre de la Serie">
            <Input
              value={nombreSerie}
              onChange={(e) => setNombreSerie(e.target.value)}
              placeholder="Ingresa el nombre de la serie"
            />
          </Form.Item>

          <Form.Item label="Número Serie Global">
            <Input
              value={numeroSerieGlobal}
              onChange={(e) => setNumeroSerieGlobal(e.target.value)}
              placeholder="Ingresa el número global"
            />
          </Form.Item>

          <Form.Item label="Nombre Lomo">
            <Input
              value={nombreLomo}
              onChange={(e) => setNombreLomo(e.target.value)}
              placeholder="Ingresa el nombre del lomo"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" onClick={handleSubmitSerie}>
              {isEditMode ? "Actualizar Serie" : "Crear Serie"}
            </Button>
          </Form.Item>
        </Form>
      </ModalAntd>

      {/* Lista de series activas */}
      <List
        itemLayout="horizontal"
        dataSource={usersActive}
        renderItem={(item) => <SeriesActive item={item} />}
      />
    </>
  );
}
