import React, { useState } from "react";

export default function CopyToClipboard({ text, BUTTON_TEXT }) {
  const [copySuccess, setCopySuccess] = useState(false);

  function copyToClipboard() {
    navigator.clipboard.writeText(text);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000); // Esperar 2 segundos antes de volver a false
  }

  return (
    <div>
      <button onClick={copyToClipboard}>
        {copySuccess ? "Copied!" : "Copy" + (BUTTON_TEXT ?? "")}
      </button>
    </div>
  );
}
