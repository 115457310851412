import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
// import { getUsersActiveApi, Keyrock } from "../../../api/user";
// import ListUsers from "../../../components/Admin/Users/ListUsers";
// import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
// import { DownOutlined } from "@ant-design/icons";
import { Modal, List } from "antd";
import esES from "antd/lib/locale/es_ES";
import {
  abrirPuertaInquilinoApi,
  getInquilinosApi,
} from "../../../api/inquilino";
import { editLimpieza } from "../../../api/limpieza";
import { getInmueblesApi, getNombreInmueble, obtenerListaInmuebles } from "../../../api/inmueble";
import "./Calendario.scss";
import moment from "moment";
// import 'moment/locale/es'; // Importa el idioma español

// import { BadgeProps } from "antd";
import { Badge, Calendar,ConfigProvider  } from "antd";
import { Dayjs } from "dayjs";
import {
  Switch,
  Avatar,
  Select,
  Button,
  Modal as ModalAntd,
  notification,
  DatePicker,
  Space,
  Row,
  Col,
  Form,
  Divider,
  TimePicker,
  Input,
} from "antd";
import DesplegableMultiple from "../../../components/DesplegableMultiple";
import TextArea from "antd/lib/input/TextArea";
// const getListData = (Dayjs) => {
//   let listData;
//   switch (Dayjs.date()) {
//     case 8:
//       listData = [
//         { type: "warning", content: "This is warning event." },
//         { type: "success", content: "This is usual event." },
//       ];
//       break;
//     case 10:
//       listData = [
//         { type: "warning", content: "This is warning event." },
//         { type: "success", content: "This is usual event." },
//         { type: "error", content: "This is error event." },
//       ];
//       break;
//     case 15:
//       listData = [
//         { type: "warning", content: "This is warning event" },
//         { type: "success", content: "This is very long usual event。。...." },
//         { type: "error", content: "This is error event 1." },
//         { type: "error", content: "This is error event 2." },
//         { type: "error", content: "This is error event 3." },
//         { type: "error", content: "This is error event 4." },
//       ];
//       break;
//     default:
//   }
//   return listData || [];
// };
moment.locale('es', {
  week: {
    dow: 1, // Establece lunes como el primer día de la semana
    doy: 4, // La semana que contiene el 4 de enero es la primera semana del año
  },
});
const getListData = (Dayjs, days) => {
  ////console.log("Eo2", days);
  let listData = [];
  days.map((element) => {
    // //console.log("Dia", element[0]);
    // //console.log("Dia", Dayjs.date());
    if (Dayjs.year() === element[2]) {
      if (Dayjs.month() === element[1]) {
        // //console.log("Mes", element[1]);
        // //console.log("Mes", Dayjs.month());
        if (Dayjs.date() === element[0]) {
          ////console.log("Estoy dentro");
          let string = "Limpiar " + element[3];
          listData.push({ type: "warning", content: string });
        }
      }
    }
  });

  return listData || [];
};
const getListData2 = (Dayjs, days) => {
  ////console.log("Eo2", days);
  let listData = [];
  days.map((element) => {
    if (Dayjs.year() === element[6]) {
      if (Dayjs.month() === element[5]) {
        // //console.log("Mes", element[1]);
        // //console.log("Mes", Dayjs.month());
        if (Dayjs.date() === element[4]) {
          let string = "Entrada " + element[3];
          listData.push({ type: "success", content: string });
        }
      }
    }
  });

  return listData || [];
};
const getMonthData = (Dayjs) => {
  if (Dayjs.month() === 8) {
    return 1394;
  }
};

export default function Calendario() {
  const [userData, setUserData] = useState({});
  const [mis_days, setMisDays] = useState([]); //Todos los usuarios
  const [isVisible, setIsVisibleModal] = useState(false); //Todos los usuarios
  const [reload, setReloadUsers] = useState(false); //Todos los usuarios
  const [estado_limpieza, setestado_limpieza] = useState(["Pendiente"]); //Todos los usuarios
  const [inmuebles_listado, setinmuebles_listado] = useState({}); //Todos los usuarios
  const [lista_ciudades, setlista_ciudades] = useState([]);
  const [ciudades_seleccionadas, setciudades_seleccionadas] = useState([]);
  const [todos_los_pisos, settodos_los_pisos] = useState([]);
  // const updateUser = (e) => {
  //   ////console.log("userData", e);
  //   // //console.log("updateUser");
  //   //e.preventDefault();
  //   const token = getAccessTokenApi();
  //   let userUpdate = userData;

  //   if (!userUpdate.status || !userUpdate.description || !userUpdate.duration) {
  //     notification["error"]({
  //       message: "Hay campos sin rellenar",
  //     });
  //     return;
  //   }

  //   editLimpieza(token, userData, "").then((result) => {
  //     notification["success"]({
  //       message: result.message,
  //     });
  //     setIsVisibleModal(false);
  //     setReloadUsers(true);
  //   });
  // };
  useEffect(()=>{
     
  },[lista_ciudades, todos_los_pisos, ciudades_seleccionadas]);

  useEffect(() => {
    const fetchData = async () => {
      const inmuebles_lista = await obtenerListaInmuebles(getAccessTokenApi());
      setinmuebles_listado(inmuebles_lista?.Pisos2);
      //console.log(inmuebles_lista);
    };
  
    fetchData();
  
  }, []);
  useEffect(() => {
    const token = getAccessTokenApi();
    getInquilinosApi(token, true).then((response) => {
      // //console.log("calendario");
      let days = [];

      const promises = response.users.filter(item => ciudades_seleccionadas.includes(inmuebles_listado[item.inmueble]?.ciudad)).map((item) => {
        // //console.log(item.fecha_entrada);
       
        // return getNombreInmueble(item.inmueble, token).then((response) => {
          return [
            moment(item.fecha_salida, "YYYY-MM-DD").date(),
            moment(item.fecha_salida, "YYYY-MM-DD").month(),
            moment(item.fecha_salida, "YYYY-MM-DD").year(),
            inmuebles_listado[item?.inmueble]?.nombre ?? "",
            moment(item.fecha_entrada, "YYYY-MM-DD").date(),
            moment(item.fecha_entrada, "YYYY-MM-DD").month(),
            moment(item.fecha_entrada, "YYYY-MM-DD").year(),
          ];
        // });
      });

      Promise.all(promises).then((values) => {
        days = values;
        setMisDays(days);
      });
    });
  }, [reload, inmuebles_listado,ciudades_seleccionadas]);
  const monthCellRender = (Dayjs) => {
    const num = getMonthData(Dayjs);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const dateCellRender = (Dayjs) => {
    const listData = getListData(Dayjs, mis_days);
    const listData2 = getListData2(Dayjs, mis_days);

    const showModal = (setUserData2, userData2) => {
      Modal.info({
        title: "Eventos",
        content: (
          <>
            {listData.length === 0 ? (
              <></>
            ) : (
              <List
                size="small"
                bordered
                dataSource={listData}
                renderItem={(item) => (
                  <List.Item>
                    <Badge
                      status={item.type}
                      text={item.content}
                      onClick={() => {
                        // Modal_Limpiar(item.content, setUserData2, userData2);
                      }}
                    />
                  </List.Item>
                )}
              />
            )}
            {listData2.length === 0 ? (
              <></>
            ) : (
              <List
                size="small"
                bordered
                dataSource={listData2}
                renderItem={(item) => (
                  <List.Item>
                    <Badge
                      status={item.type}
                      text={item.content}
                      onClick={() => {
                        // Modal_Limpiar(item.content, setUserData2, userData2);
                      }}
                    />
                  </List.Item>
                )}
              />
            )}
          </>
        ),
      });
    };

    let numero_limpiezas = 0;
    // //console.log("listData2", listData2);
    // //console.log("listData", listData);
    
    return (
      <>
      
        <div
          onClick={() => showModal(setUserData, userData)}
          style={{ width: "100%", height: "100%" }}
        >
          {listData.length <= 0 && listData.length > 0 ? (
            <div
              //className={`date-cell-container ${hasOverflow ? "has-overflow" : ""}`}
              className={`has-overflow2`}
            >
              <ul className="events">
                {listData.map((item, index) => {
                  return (
                    <li key={item?.content}>
                      <Badge status={item?.type} text={item?.content} />
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : listData.length >= 1 ? (
            <div
              //className={`date-cell-container ${hasOverflow ? "has-overflow" : ""}`}
              className={`has-overflow`}
            >
              <span>{listData.length} limpiezas</span>
            </div>
          ) : (
            <div></div>
          )}
          {listData2.length <= 0 && listData2.length > 0 ? (
            <div
              //className={`date-cell-container ${hasOverflow ? "has-overflow" : ""}`}
              className={`date-cell-container has-overflow2`}
            >
              <ul className="events">
                {listData2.map((item, index) => {
                  return (
                    <li key={item.content}>
                      <Badge status={item.type} text={item.content} />
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : listData2.length >= 1 ? (
            <div
              //className={`date-cell-container ${hasOverflow ? "has-overflow" : ""}`}
              className={`has-overflow2`}
            >
              <span>{listData2.length} entradas</span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </>
    );
  };
  const locale = {
    lang: {
      locale: 'es_ES',
      // Aquí puedes agregar más configuraciones si lo necesitas
    },
  // <  timePickerLocale: {
  //     placeholder: 'Seleccionar hora',
  //   },>
  };

  useEffect(() => {
   
      getInmueblesApi(getAccessTokenApi(), 1, true).then((response) => {

        const ciudadesUnicas = new Set(response.Pisos.map(item => item.ciudad));
        //console.log("ciudadesUnicas",ciudadesUnicas)
        setlista_ciudades([...ciudadesUnicas]);
        settodos_los_pisos(response?.Pisos)

      
      })},[])

  return (
    <> <DesplegableMultiple
    valores={lista_ciudades}
    // defaultValue2 = {ciudades_seleccionadas}
    onChange={(values) => {
      //console.log("DesplegableMultiple", values)
      setciudades_seleccionadas(values)
    }}
  />
   {/* <ConfigProvider locale={locale}> */}
    <Calendar
      // value={moment()}
      locale={locale}
      dateCellRender={dateCellRender}
      monthCellRender={monthCellRender}
      fullscreen={true}
      
    />
    {/* </ConfigProvider> */}
    </>
  );
}



// import React, { useState, useEffect } from 'react';
// import { DayPilot, DayPilotCalendar } from "@daypilot/daypilot-lite-react";

// function Calendar() {
//     const [calendar, setCalendar] = useState(null);
//     const [events, setEvents] = useState([]);

//     useEffect(() => {
//         setEvents([
//             {
//                 id: 1,
//                 text: "Event 1",
//                 start: "2024-09-07T10:30:00",
//                 end: "2024-09-07T13:00:00"
//             },
//             {
//                 id: 2,
//                 text: "Event 2",
//                 start: "2024-09-08T09:30:00",
//                 end: "2024-09-08T11:30:00",
//                 barColor: "#6aa84f"
//             },
//         ]);
//     }, []);

//     const onEventClick = async args => {
//         if (!calendar) return; // Ensure calendar is set

//         const modal = await DayPilot.Modal.prompt("Update event text:", args.e.text());
//         if (!modal.result) { return; }
//         const e = args.e;
//         e.data.text = modal.result;
//         calendar.events.update(e);
//     };

//     return (
//         <DayPilotCalendar
//             viewType={"Week"}
//             startDate={"2024-09-07"}
//             timeRangeSelectedHandling={"Enabled"}
//             events={events}
//             onEventClick={onEventClick}
//             controlRef={setCalendar}
//         />
//     );
// }

// export default Calendar;