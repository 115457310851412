import React, { useState, useEffect } from 'react';
import { Select, DatePicker, Button, Table, Row, Col } from 'antd';
import moment from 'moment';
import { getAccessTokenApi } from '../../../api/auth';
import jwtDecode from 'jwt-decode';
import { getPisosLimpiosPorMesLimpiadorById } from '../../../api/inquilino';
import { getUsersByRoleApi } from '../../../api/user';
import { getInmuebleName, getPrecioLimpiadorInmueble } from '../../../api/inmueble';
import "./registroLimpiezas.css";


const { Option } = Select;
const { MonthPicker } = DatePicker;

const RegistroLimpiezasLimpiador = () => {
    const accessToken = getAccessTokenApi();
    const [selectedMonth, setSelectedMonth] = useState(moment());
    const [limpiadores, setLimpiadores] = useState([]);
    const [pisos, setPisos] = useState([]);
    const [tablaDatos, setTablaDatos] = useState([]);
    const [totalPago, setTotalPago] = useState(0);
    const [nota_media_limpieza, setnota_media_limpieza] = useState(0);

    useEffect(() => {
        const obtenerLimpiadores = async () => {
            try {
                const response = await getUsersByRoleApi(accessToken, 'Limpiador');
                if (response && response.users) {
                    setLimpiadores(response.users);
                }
            } catch (error) {
                console.error("Error al obtener limpiadores:", error);
            }
        };
        obtenerLimpiadores();
    }, [accessToken]);


    const handleDateChange = (date, dateString) => {
        setSelectedMonth(dateString);
    };

    const handleBuscarClick = async () => {
        try {
            const token = await getAccessTokenApi();
            const fechaFormateada = moment(selectedMonth).format("YYYY-MM");
            const selectedLimpiador = jwtDecode(token).id;
            const response = await getPisosLimpiosPorMesLimpiadorById(fechaFormateada, selectedLimpiador, token);
            if (response && response.inquilinos) {
                procesarDatosParaTabla(response.inquilinos);
            }
        } catch (error) {
            console.error("Error en buscar pisos:", error);
        }
    };

    const procesarDatosParaTabla = async (datos) => {
        const datosTabla = [];
        let total = 0;
        let nota_media_limpieza = 0;
        let contador = 0;

        for (const idInmueble of Object.keys(datos)) {
            for (const reserva of datos[idInmueble]) {
                // Calcular el pago dividido
                //console.log("QUE TIENES", reserva.valoracion_limpieza);
                const precios_limpieza = await getPrecioLimpiadorInmueble(idInmueble, accessToken);
                let precio_limpiador = Number(precios_limpieza.precio_limpiador);
                const precio_suplemento = Number(precios_limpieza.suplemento_festivo);
                const cantidadLimpiadores = reserva.limpieza.limpiadores.length;
                let pagoDividido = cantidadLimpiadores > 0 ? (precio_limpiador / cantidadLimpiadores).toFixed(2) : precio_limpiador;

                // Formatear la fecha y verificar si es domingo
                const fechaSalida = moment(reserva.fecha_salida);
                const esDomingo = fechaSalida.day() === 0; // 0 es domingo
                if (esDomingo) {
                    pagoDividido = (parseFloat(pagoDividido) + precio_suplemento).toFixed(2);
                    precio_limpiador = precio_limpiador + precio_suplemento;
                }

                // Obtener el nombre del inmueble
                const nombreInmueble = await getInmuebleName(idInmueble, accessToken);


                datosTabla.push({
                    key: reserva._id,
                    inmueble: nombreInmueble.message, // Usar el nombre del inmueble o el ID si no se encuentra
                    fechaSalida: fechaSalida.format('dddd, DD-MM-YYYY'),
                    esDomingo,
                    cantidadLimpiadores: cantidadLimpiadores,
                    pagoPorLimpiador: parseFloat(pagoDividido),
                    comentario: reserva.limpieza.comentario_limpiador,
                    pagoLimpieza: precio_limpiador,
                    valoracion_limpieza: Number(reserva.valoracion_limpieza*2) ?? "No encuestado"
                });
                //console.log("PAGO QUE RECIBEN ", pagoDividido);
                total += parseFloat(pagoDividido);
                if (reserva.valoracion_limpieza) {
                    nota_media_limpieza = nota_media_limpieza + reserva.valoracion_limpieza;
                    contador++;
                }

            }

        }

        setnota_media_limpieza(nota_media_limpieza / contador);
        setTablaDatos(datosTabla);
        setTotalPago(parseFloat(total.toFixed(2)));
    };

    // Usar rowClassName para cambiar el color de fondo
    const rowClassName = (record) => {
        return record.esDomingo ? 'fila-domingo' : '';
    };

    const columnas = [
        {
            title: 'Inmueble',
            dataIndex: 'inmueble',
            key: 'inmueble',
        },
        {
            title: 'Fecha de Limpieza',
            dataIndex: 'fechaSalida',
            key: 'fechaSalida',
        },
        {
            title: 'Numero Limpiadores',
            dataIndex: 'cantidadLimpiadores',
            key: 'cantidadLimpiadores',
        },
        {
            title: 'Pago a cobrar',
            dataIndex: 'pagoPorLimpiador',
            key: 'pagoPorLimpiador',
        },
        {
            title: 'Comentario',
            dataIndex: 'comentario',
            key: 'comentario',
        },
        {
            title: 'Pago Total de Limpieza',
            dataIndex: 'pagoLimpieza',
            key: 'pagoLimpieza',
        },
        {
            title: 'Puntuacion limpieza',
            dataIndex: 'valoracion_limpieza',
            key: 'valoracion_limpieza',
        }
    ];

    return (
        <div>
            <div className="total-pago">
                Total a Pagar: {totalPago} €
            </div>
            <div className="total-pago">
                Nota Media: {nota_media_limpieza}
            </div>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <MonthPicker
                        placeholder="Selecciona el mes y año"
                        onChange={handleDateChange}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <Button type="primary" onClick={handleBuscarClick} style={{ width: '100%' }}>
                        Buscar
                    </Button>
                </Col>
            </Row>
            <Table columns={columnas} dataSource={tablaDatos} rowClassName={rowClassName} />


        </div>
    );
};

export default RegistroLimpiezasLimpiador;