import React, { useState, useEffect } from "react";
import { createIncidencia } from "../../../../src/api/incidencia";
import { Form, Input, Checkbox, Select, Button, notification, Row, Col } from "antd";
import moment from "moment";
import { obtenerListaInmuebles } from "../../../api/inmueble";
import { getAccessTokenApi } from "../../../api/auth";
import { getUsersByRoleApi } from "../../../api/user";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;

const IncidenciaForm = ({data, setModalIsOpen, setReload}) => {

  const [form] = Form.useForm();  // Inicializa el hook useForm de Ant Design

  const [incidenciaData, setIncidenciaData] = useState({
    id_apartamento: null,
    descripcion_tarea: "",
    fechas_disponibles: "", // Añade campo para fechas disponibles
    urgente: false, // Añade campo para urgente
    nivel_prioridad: "1", // Añade campo para nivel de prioridad
    asignado: false, // Añade campo para asignado
    estado: "no_asignado", // Añade campo para estado
    fecha_creacion: moment().toISOString(), // Añade campo para fecha de creación
    fecha_resolucion: "", // Añade campo para fecha de resolución
    persona_asignada: "", // Añade campo para persona asignada
    comentarios: [], // Añade campo para comentarios
    historial: [], // Añade campo para historial
    precio_venta_publico:0,
    coste_materiales_usados_reparacion:0,
  });
  const [inmuebleSelected, setInmuebleSelected] = useState("");
  const [lista_pisos, set_lista_pisos] = useState([]);
  const [lista_mantenimiento, set_lista_mantenimiento] = useState([]);
  const [persona_mantenimiento_selected, setpersona_mantenimiento_selected] = useState("");
  useEffect(() => {
  
  }, [lista_pisos,inmuebleSelected,incidenciaData,lista_mantenimiento]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accesToken = getAccessTokenApi();
        const response = await obtenerListaInmuebles(accesToken);
        set_lista_pisos(response.Pisos.map((piso) => ({ value: piso.id, label: piso.nombre })));
      } catch (error) {
        console.error('Error al obtener la lista de inmuebles:', error);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    const obtenerMantenimiento = async () => {
        try {
            const response = await getUsersByRoleApi(getAccessTokenApi(), 'Mantenimiento');
            if (response && response.users) {
              set_lista_mantenimiento(response.users.map((user) => ({ value: user.id, label: user.name })));
            }
        } catch (error) {
            console.error("Error al obtener limpiadores:", error);
        }
    };
    obtenerMantenimiento();
}, []);
  const handleSubmit = async (values) => {
    try {
      if(!values.precio_venta_publico){
        notification["error"]({
          message: "Falta coste servicio"
        });
        return;
      }
      const dataToSend = {
        ...values,
        precio_venta_publico: Number(values.precio_venta_publico),
        coste_materiales_usados_reparacion: Number(values.coste_materiales_usados_reparacion),
      };
    
      const resp = await createIncidencia(dataToSend ,getAccessTokenApi());
      console.log("Nueva incidencia creada:", resp);
      if (resp.message) {
        //console.log("Hola exito")
        notification["success"]({
          message: "Exito creando incidencia"
        });
        form.resetFields();  // Resetea los campos del formulario
        setModalIsOpen(false);
        setReload(true);
      } else {
        notification["e"]({
          message: "Error al crear la incidencia"
        });
      }
    } catch (error) {
      console.error('Error al crear la incidencia:', error);
      notification.error({
        message: "Error al crear la incidencia",
        description: "Ha ocurrido un error al crear la incidencia. Por favor, inténtalo de nuevo."
      });
    }}

  return (
    <div>
      <h2>Crear Nueva Incidencia</h2>
      <Form
        onFinish={handleSubmit}
        initialValues={incidenciaData}
        layout="vertical"
      >
        <Form.Item label="Fechas Disponibles" name="fechas_disponibles" required>
          <Input type="date" />
        </Form.Item>
        
        <Form.Item label="persona_asignada" name="persona_asignada">
        <Select
              onChange={(value) => setpersona_mantenimiento_selected(value)}
              placeholder="Selecciona un mantenimiento"
            >
              {lista_mantenimiento?.map((users) => (
                <Option key={users?.value} value={users?.value}>
                  {users?.label}
                </Option>
              ))}
            </Select>
        </Form.Item>
        <Form.Item
          label="Id apartamento"
          name="id_apartamento"
          rules={[{ required: true, message: "Seleccione un nivel" }]}
        >
         
            <Select
              onChange={(value) => setInmuebleSelected(value)}
              placeholder="Selecciona un inmueble"
            >
              {lista_pisos?.map((piso) => (
                <Option key={piso?.value} value={piso?.value}>
                  {piso?.label}
                </Option>
              ))}
            </Select>
            </Form.Item> 
            <Row gutter={16}>
               <Col span={12}>
                <Form.Item
                  label="Nivel de Prioridad"
                  name="nivel_prioridad"
                  rules={[{ required: true, message: "Seleccione un nivel" }]}
                >
                  <Select>
                    <Option value="1">1 (Prioridad Baja)</Option>
                    <Option value="2">2 (Prioridad Medio-baja)</Option>
                    <Option value="3">3 (Prioridad Medio)</Option>
                    <Option value="4">4 (Prioridad Medio-alta)</Option>
                    <Option value="5">5 (Prioridad Alta)</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Urgente" name="urgente" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>
             
            </Row>

           
        {/* <Form.Item label="Asignado" name="asignado" valuePropName="checked">
          <Checkbox />
        </Form.Item> */}
        
        <Form.Item label="Estado" name="estado">
          <Select>
            <Option value="no_asignado">No Asignado</Option>
            <Option value="por_iniciar">por_iniciar</Option>
            <Option value="pendiente">Pendiente</Option>
            <Option value="resuelto">Resuelto</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Fecha de Resolución" name="fecha_resolucion">
          <Input type="date" />
        </Form.Item>
        <Form.Item label="Coste servicio" name="precio_venta_publico" required>
          <Input type="number" 
          addonAfter="€"/>
        </Form.Item>
        {/* <Form.Item label="Coste materiales para reforma" name="coste_materiales_usados_reparacion">
          <Input type="number" 
          addonAfter="€"/>
        </Form.Item> */}
      
        
        <Form.Item label="Descripcion tarea Asignada" name="descripcion_tarea">
          <TextArea />
        </Form.Item>
        {/* Añade campos para comentarios y historial si es necesario */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Crear Incidencia
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default IncidenciaForm;
