import React, { useState, useEffect } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import "./Mapa.scss";
import "leaflet/dist/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { getAccessTokenApi } from "../../../api/auth";
import { getCurrentLocation } from "../../../api/map";
import { abrirPuertaUsuarioApi } from "../../../api/inquilino";
import { Divider, Row, notification } from "antd";
import { encenderApagarCaldera, getInmueblesApi, verTemperaturaCaldera } from "../../../api/inmueble";
import Desplegable_activable from "../../../pages/Admin/Limpieza/Desplegable_activable";
import { getUsersByRoleApi } from "../../../api/user";

// Importar icono personalizado para los marcadores
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: icon,
  shadowUrl: iconShadow,

});

export default function Mapa({lista_puntos}) {
  const [inmuebles, setUsersActive] = useState([]);
  const [temperaturaActual, setTemperaturaActual] = useState('');
  const [limpiadores_info_pisos,set_limpiadores_info_pisos] = useState({})
  useEffect(() => {
      getInmueblesApi(getAccessTokenApi(), 1, true).then((response) => 
      {
        setUsersActive(response.Pisos);
      
      });
   
  }, [lista_puntos]);

  const [limpiadores_id, setlimpiadores_id] = useState({});
    const [limpiadores_id_desplegable, setlimpiadores_id_desplegable] = useState([]);
    useEffect(() => {
      const fetchLimpiadores = async () => {
        try {
          const limpiadores = await getUsersByRoleApi(getAccessTokenApi(), "Limpiador");
          let limpiadores_por_id_aux = {};
          let limpiadores_inmuebles_ids = {};
          limpiadores.users.forEach(limpiador => {
            limpiadores_por_id_aux[limpiador.id] = limpiador.name;
            limpiadores_inmuebles_ids[limpiador.id] = limpiador.pisos_asociados;
          });
          //console.log("limpiadores_por_id_aux",limpiadores_por_id_aux)
          let vect_aux = []
          Object.keys(limpiadores_por_id_aux).forEach(element => {
            vect_aux.push({value:element, label:limpiadores_por_id_aux[element], pisos: limpiadores_inmuebles_ids[element]})
          });
            //console.log("vect_aux_1",vect_aux)
            setlimpiadores_id_desplegable(vect_aux)
          //console.log("vect_aux_1",vect_aux)
          set_limpiadores_info_pisos(limpiadores)
          setlimpiadores_id(limpiadores_por_id_aux);
        } catch (error) {
          console.error("Error al obtener los limpiadores:", error);
        }
      };
    
      fetchLimpiadores();
    }, []);
    useEffect(()=>{
  
    },[limpiadores_id])

  const [coordenadas, setCoordenadas] = useState([]);
  useEffect(() => {

 
}, [coordenadas]);
  useEffect(() => {
    const fetchData = async () => {
      const newCoordenadas = [];
      for (const element of inmuebles) {
        if (element.latitud) {
          // const temperaturaActual = (element.id_sensor_temperatura && element.id_sensor_temperatura != "" ) ? await verTemperaturaCaldera_aux(element.name, element.direccion_inmueble, element.id_sensor_temperatura) : "";
          if(Object.keys(lista_puntos).includes(element._id)){
            //console.log("element",element, lista_puntos[element._id])
           
          newCoordenadas.push({
            id_inquilino: lista_puntos[element._id].id_inquilino,
            nombre: element.name,
            inmueble_id : element._id,
            color: lista_puntos[element._id].estado_limpieza === "Limpio" ? "green": lista_puntos[element._id].estado_limpieza === "Sin asignar" ? "purple": "red",
            limpiador: lista_puntos[element._id].limpiador ,
            limpiador_id: lista_puntos[element._id].limpiador_id,
            punto: [element.latitud ?? 0, element.longitud ?? 0],
            direccion: element.direccion_inmueble,
            dispositivo: element.id_electronica_portal ?? 0,
            caldera: element.id_actuador_caldera ?? '',
            temperatura: element.id_sensor_temperatura ?? '',
            temperaturaActual: temperaturaActual ?? ""
          })
          console.log("cosas", limpiadores_id_desplegable.filter((limpiador) => 
            limpiador.pisos.includes(newCoordenadas[0].inmueble_id)
          ))
          ;}
        }
      }
      setCoordenadas(newCoordenadas);
    };
    fetchData();
  }, [inmuebles, lista_puntos]);
  const [vistaMapa, setVistaMapa] = useState(true);


  

  const abrirPuerta = async (nombre, direccion, dispositivo) => {
    // Aquí puedes implementar la lógica para abrir la puerta utilizando el dispositivo
    //console.log("Abriendo la puerta de:", nombre, "en", direccion, "con el dispositivo", dispositivo);
    let resp = await abrirPuertaUsuarioApi( getAccessTokenApi(),dispositivo);
    if(resp.message){
      //console.log("Hola exito")
      notification["success"]({
        message: "Exito en abrir la puerta en " + nombre
      });
    }else{
      notification["error"]({
        message: "Error"
      });
    }
   
  };

 
  const encenderCaldera = async (nombre, direccion, dispositivo, estado) => {
    // Aquí puedes implementar la lógica para abrir la puerta utilizando el dispositivo
    //console.log("Abriendo la puerta de:", nombre, "en", direccion, "con el dispositivo", dispositivo);
    let resp = await encenderApagarCaldera( getAccessTokenApi(),dispositivo, estado);
    if(resp.message){
      //console.log("Hola exito")
      notification["success"]({
        message: "Exito con la caldera"
      });
    }else{
      notification["error"]({
        message: "Error"
      });
    }
   
  };

  const verTemperaturaCaldera_aux = async (nombre, direccion, dispositivo) => {
    try {
      const resp = await verTemperaturaCaldera(getAccessTokenApi(), dispositivo);
      if (resp.message) {
        //console.log("Éxito al obtener la temperatura en", nombre);
        notification["success"]({
          message: "Éxito al obtener la temperatura en " + nombre
        });
        // Actualizar el estado de coordenadas con la nueva temperatura
        setCoordenadas(prevCoordenadas => {
          const newCoordenadas = [...prevCoordenadas];
          const index = newCoordenadas.findIndex(coordenada => coordenada.nombre === nombre);
          if (index !== -1) {
            newCoordenadas[index].temperaturaActual = resp.body.temperatura;
          }
          return newCoordenadas;
        });
        return resp.body.temperatura;
      } else {
        console.error("Error al obtener la temperatura en", nombre);
        notification["error"]({
          message: "Error al obtener la temperatura en " + nombre
        });
        return null;
      }
    } catch (error) {
      console.error("Error al obtener la temperatura:", error);
      notification["error"]({
        message: "Error al obtener la temperatura"
      });
      return null;
    }
  };
  
  return (
    <>
      <div className="switch">
        <label>
          
          <input type="checkbox" checked={vistaMapa} onChange={() => setVistaMapa(!vistaMapa)} />
          <span className="lever"> </span>
          Mapa Botones
         
        </label>
      </div>
      {vistaMapa ? (
        <MapContainer center={[41.658102, -0.874650]} zoom={13} scrollWheelZoom={true} className="map-container">
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {coordenadas.map((coordenada, index) => (
                 
                 <Marker position={coordenada.punto} key={index} icon={L.divIcon({
                  html: "<div style='width: 100%; height: 0; border-left: 50% solid transparent; border-right: 50% solid transparent; border-top: 100px solid "+coordenada.color+"; border-radius: 0%;'></div>", // Modificación aquí
                  iconSize: [60, 80], // Ajusta el tamaño del icono aquí
                  iconAnchor: [30, 80], // Ajusta el anclaje del icono si es necesario
                  iconUrl: icon, // URL del icono
                  shadowUrl: iconShadow // URL de la sombra del icono
               
                })}>
             
                
                
                
                
              <Popup >
                <div>
                  <h3>{coordenada.nombre}</h3>
                  <p>Dirección: {coordenada.direccion}</p>
                 
                  <Row>Limpiador: <Desplegable_activable record={{limpiador: coordenada?.limpiador_id ?? ""}} 
                  limpiadores_id_desplegable ={ limpiadores_id_desplegable.filter((limpiador) => 
                    limpiador.pisos.includes(coordenada.inmueble_id)
                  )} inquilino_id={coordenada.id_inquilino}/>
       </Row>
       <Divider></Divider>
                  <button className="button" onClick={() => abrirPuerta(coordenada.nombre, coordenada.direccion, coordenada.dispositivo)}>Abrir Puerta</button>
                 
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      ) : (
       <></>
      )}
    </>
  );
}