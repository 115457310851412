import React, { useState, useEffect, createContext } from "react";
import { ACCESS_TOKEN, REFRESH_TOKEN, EMAIL, FUNCIONALIDAES } from "../utils/constants";
import jwtDecode from "jwt-decode";
import {
  getAccessTokenApi,
  getRefreshTokenApi,
  refreshAccessTokenApi,
  logout,
  getEmail,
} from "../api/auth";

export const AuthContext = createContext();

//Checkeamos si el usuario está registrado
export async function checkUserLogin(setUser) {
  ////console.log("checkUserLogin");
  //Obtengo el access token: Será null si caduca pronto o no existe
  const accessToken_ = getAccessTokenApi();
  const refreshToken_ = getRefreshTokenApi();
  console.log("check nuevo",accessToken_, refreshToken_)
  // console.log("AuthProvider accessToken_", accessToken_);
  //console.log("AuthProvider refreshToken_", refreshToken_);
  //Si no hay accessToken y no hay refreshToken -> logout
  //Si no hay accessToken y hay refreshToken -> Se refresca -> Si el refreshtoken esta caducado ->logout
  //Si hay acessToken -> Se refresca -> Si el refreshtoken esta caducado ->logout
  //Si hay accessToken y está correcto -> Se sigue en la app

  //No hay acessToken
  
  if (!accessToken_) {
    console.log("No hay AT")
    //No hay refreshToken o caduca pronto
    if (!refreshToken_) {
      console.log("No hay RT")
      //console.log("AuthProvider !refreshToken_");
      setUser({
        user: null,
        isLoading: false,
        email: null,
      });
      logout(); //TODO LOGOUT PUEDE SER QUE INCORRECTO
    } else {
      
      //Hay refresToken
      const response = await refreshAccessTokenApi(refreshToken_);
      const result = await response.json();
      console.log("SI hay RT",result)

      //console.log("Mensaje Status:", response.status);
      if (response.status !== 200) {
        //RefresToken caducado
        setUser({
          user: null,
          isLoading: false,
          email: null,
        });
        logout();
      } else {

        //RefresToken correcto
        const email = getEmail();
        const { accessToken, refreshToken, funcionalidades } = result;
        console.log("email result",email, accessToken, refreshToken, funcionalidades)
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        localStorage.setItem(EMAIL, email);
        localStorage.setItem(FUNCIONALIDAES, funcionalidades);
        console.log("ESTAMOS DENTRO COMO TOCA")
       
        //const accessToken = getAccessTokenApi();
        //console.log("AT---4.1");
        setUser({
          isLoading: false,
          user: jwtDecode(accessToken),
          email: email,
        });
      }
    }
  } else {
    //hay acessToken y no caduca pronto

    const email = getEmail();
    setUser({
      isLoading: false,
      user: jwtDecode(accessToken_),
      email: email,
    });
  }
}

export default function AuthProvider(props) {
  //Las props del AuthProvider es el hijo (lo que envuelves con él, en App.js vemos que sería el Router)

  //console.log("function AuthProvider");
  const { children } = props;
  const [users, setUser] = useState({
    user: null,
    isLoading: true,
    email: null,
  });
  console.log("Esto es el paso 1")
  ////console.log("useEffect");
  //Siempre se va a ejecutar si está registrado
  useEffect(() => {
    
  }, [users]);
  
  useEffect(() => {
    console.log("Esto es el paso 2")
    try {
      checkUserLogin(setUser);
    } catch (e) {
      //console.log(e);
    }
  }, []);

  const data = {
    users,
    setUser,
  };

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
  // Aqui lo que estamos haciendo es envolver todo el Router de App.js que es lo que es el children y le estamos pasando el valor de user a toda la web Ej ver LayoutAdmin como consume del contexto el user
}
