import React from "react";
import { Column } from "@ant-design/charts";

// const data_aux = [
    const HourlyGroupedBarChart = ({data_externa}) => {
  //   {
  //     piso_name: "piso1",
  //     hora: "hora_entrada",
  //     hora2: "15:00",
  //   },
  //   {
  //     piso_name: "piso1",
  //     hora: "hora_se_van",
  //     hora2: "08:00",
  //   },

  //   {
  //     piso_name: "piso2",
  //     hora: "hora_entrada",
  //     hora2: "21:00",
  //   },

  //   {
  //     piso_name: "piso2",
  //     hora: "hora_se_van",
  //     hora2: "10:00",
  //   },
  // ];
  // //console.log(data_externa);
  const generateData = (data_aux) => {
    const data = [];
    for (let i = 0; i < 25; i++) {
      const hour =i.toString().padStart(2, "0") + ":00";
      data.push({
        piso_name: "pisoGen",
        hora: "hora_ent",
        hora2: hour,
      });
      const hour2 = i.toString().padStart(2, "0") + ":30";
      data.push({
        piso_name: "pisoGen",
        hora: "hora_ent",
        hora2: hour2,
      });
    }
    for (let i = 0; i < data_aux.length; i++) {
      data.push(data_aux[i]);
    }
    return data;
  };

  const data = generateData(data_externa);

  const config = {
    data: data,
    xField: "piso_name",
    yField: "hora2",
    seriesField: "hora",
    isGroup: false,
    label: {
      position: "top",
      //layout: [{ type: "adjust-color" }],
      style: {
        fill: "#000000", // Color del texto (negro)
        fontWeight: "bold", // Texto en negrita
        backgroundColor: "#ffffff", // Fondo blanco
        padding: 10, // Espaciado interno
        borderRadius: 10, // Bordes redondeados
      },
    },
    color: (datum) => {
      return datum.hora === "hora_entrada" ? "#52c41a" : "#eb2f96";
    },
  };

  return <Column {...config} />;
};

export default HourlyGroupedBarChart;
