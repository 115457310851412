import React, { useState, useEffect } from 'react';
import { Layout, Typography, Button, notification, Select, Table } from 'antd';
import { getAccessTokenApi } from '../../../api/auth'; // Ajusta la ruta de importación
import { getFacturacionTotalTablaReporte } from '../../../api/facturacion'; // Ajusta la ruta de importación
import DemoPie from './DemoPie'; // Ajusta la ruta de importación
import moment from 'moment';
import "./tabla_global.scss"
import DataSource from './data_source';
const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const Tabla_global = ({monthQuery,yearQuery}) => {
  const [datosPorTipoGestion, setDatosPorTipoGestion] = useState({});
  const [tipoGestionSeleccionado, setTipoGestionSeleccionado] = useState('');

  useEffect(() => {
    fetchData();
  }, [monthQuery,yearQuery]);

  const fetchData = async () => {
    const accesToken = getAccessTokenApi();
    
    try {
      const response = await getFacturacionTotalTablaReporte(accesToken, monthQuery ?? (moment().month() - 2), yearQuery ?? (moment().year()));

      if (response.error) {
        notification.error({
          message: response.error
        });
        return;
      }

      const contabilidad = response.contabilidad[0];
      let datosTipoGestionTemp = {};

      Object.keys(contabilidad).forEach(ciudad => {
        console.log("CIUDAD", ciudad);
        const ciudadData = contabilidad[ciudad];
        
        Object.keys(ciudadData).forEach(tipo => {
          console.log("ciudadData1", ciudadData)
          if (!datosTipoGestionTemp[tipo]) {
            console.log("ciudadData", ciudadData)
            datosTipoGestionTemp[tipo] = {};
          }
          const tipoData = ciudadData[tipo];
          
          // Agregar datos de cada ciudad bajo el tipo de gestión
          datosTipoGestionTemp[tipo][ciudad] = {
            Facturacion: parseFloat(tipoData.Facturacion) || 0,
            Pago_Limpieza: parseFloat(tipoData.pago_limpieza) || 0,
            Comision_subarriendo: parseFloat(tipoData.Comision_subarriendo) || 0,
            Comision_gestion_bruta: parseFloat(tipoData.Comision_gestion_bruta) || 0,

            ComoTuCasa: parseFloat(tipoData.ComoTuCasa) || 0,
            Propietario_Gestion: parseFloat(tipoData.Propietario_gestion) || 0,
            Propietario_Resto: parseFloat(tipoData.Propietario_resto) || 0,
            IVA_ComoTuCasa: parseFloat(tipoData.IVA_ComoTuCasa) || 0,
            gastos_estimados_suministros: parseFloat(tipoData.gastos_estimados_suministros) || 0,
            IVA_Comision_Subarriendo: parseFloat(tipoData.IVA_Comision_Subarriendo) || 0,

            IVA_Comision_gestion_bruta: parseFloat(tipoData.IVA_Comision_gestion_bruta) || 0,

            IVA_Suministros_Subarriendo:parseFloat(tipoData.IVA_Suministros_Subarriendo) || 0,
            IVA_Reservas_Subarriendo: parseFloat(tipoData.IVA_Reservas_Subarriendo) || 0,
            IVA_Alquileres_Subarriendo: parseFloat(tipoData.IVA_Alquileres_Subarriendo) || 0,
            IVA_pago_limpieza_gestion_bruta: parseFloat(tipoData.IVA_pago_limpieza_gestion_bruta) || 0,
            
          };
          // datosTipoGestionTemp[ciudad][tipo] = {
          //   Facturacion: parseFloat(tipoData.Facturacion) || 0,
          //   Pago_Limpieza: parseFloat(tipoData.pago_limpieza) || 0,
          //   Comision_subarriendo: parseFloat(tipoData.Comision_subarriendo) || 0,
          //   Comision_gestion_bruta: parseFloat(tipoData.Comision_gestion_bruta) || 0,

          //   ComoTuCasa: parseFloat(tipoData.ComoTuCasa) || 0,
          //   Propietario_Gestion: parseFloat(tipoData.Propietario_gestion) || 0,
          //   Propietario_Resto: parseFloat(tipoData.Propietario_resto) || 0,
          //   IVA_ComoTuCasa: parseFloat(tipoData.IVA_ComoTuCasa) || 0,
          //   gastos_estimados_suministros: parseFloat(tipoData.gastos_estimados_suministros) || 0,
          //   IVA_Comision_Subarriendo: parseFloat(tipoData.IVA_Comision_Subarriendo) || 0,

          //   IVA_Comision_gestion_bruta: parseFloat(tipoData.IVA_Comision_gestion_bruta) || 0,

          //   IVA_Suministros_Subarriendo:parseFloat(tipoData.IVA_Suministros_Subarriendo) || 0,
          //   IVA_Reservas_Subarriendo: parseFloat(tipoData.IVA_Reservas_Subarriendo) || 0,
          //   IVA_Alquileres_Subarriendo: parseFloat(tipoData.IVA_Alquileres_Subarriendo) || 0,
          //   IVA_pago_limpieza_gestion_bruta: parseFloat(tipoData.IVA_pago_limpieza_gestion_bruta) || 0,
            
          // };
        });
      });

      setDatosPorTipoGestion(datosTipoGestionTemp);
      const primerosTipos = Object.keys(datosTipoGestionTemp);
      setTipoGestionSeleccionado(primerosTipos[0]);
    } catch (error) {
      notification.error({
        message: 'Error al obtener datos',
      });
    }
  };

  const handleTipoGestionChange = (tipoGestion) => {
    setTipoGestionSeleccionado(tipoGestion);
  };

  // Función para formatear números como moneda en euros
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(value);
  };


  

// Configuración de columnas para la tabla
const columns = [
  {
    title: 'Categoría',
    dataIndex: 'categoria',
    key: 'categoria',
    render: (text) => {
      if (text.includes("Bruto") || text.includes("Beneficio Operativo") || text.includes("Facturacion")) {
        return <span style={{ color: 'white' }}>{text}</span>;
      }
      return text;
    },
  },
  {
    title: 'TotalSuma',
    dataIndex: 'TotalSuma',
    key: 'TotalSuma',
    render: (text, record) => {
      // Estilo condicional para la columna de Total
      if (record.categoria.includes("Bruto") || record.categoria.includes("Facturacion") || record.categoria.includes("Beneficio Operativo")) {
        return <span style={{ color: 'white' }}>{text}</span>;
      }
      return text;
    },
  },
  {
    title: 'Total',
    dataIndex: 'Total',
    key: 'Total',
    render: (text, record) => {
      // Estilo condicional para la columna de Total
      if (record.categoria.includes("Bruto") || record.categoria.includes("Facturacion") || record.categoria.includes("Beneficio Operativo")) {
        return <span style={{ color: 'white' }}>{text}</span>;
      }
      return text;
    },
  },
  ...Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => ({
    title: ciudad,
    dataIndex: ciudad,
    key: ciudad,
    render: (text, record) => {
      // Marcar en rojo las categorías relacionadas con "Bruto" y "Beneficio Operativo"
      if (record.categoria.includes("Bruto") || record.categoria.includes("Facturacion") || record.categoria.includes("Beneficio Operativo")) {
        return <span style={{ color: 'white' }}>{text}</span>;
      }
      return text;
    },
  })),

  
  // {
  //   title: 'Separacion',
  //   dataIndex: 'Total2',
  //   key: 'Total2',
  //   render: (text, record) => {
  //     // Estilo condicional para la columna de Total
  //     if (record.categoria.includes("Bruto") || record.categoria.includes("Facturacion") || record.categoria.includes("Beneficio Operativo")) {
  //       return <span style={{ color: 'white' }}>{text}</span>;
  //     }
  //     return text;
  //   },
  // },
  // ...Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).map(ciudad => ({
  //   title: ciudad,
  //   dataIndex: ciudad,
  //   key: ciudad,
  //   render: (text, record) => {
  //     // Marcar en rojo las categorías relacionadas con "Bruto" y "Beneficio Operativo"
  //     if (record.categoria.includes("Bruto") || record.categoria.includes("Facturacion") || record.categoria.includes("Beneficio Operativo")) {
  //       return <span style={{ color: 'white' }}>{text}</span>;
  //     }
  //     return text;
  //   },
  // })),
];
console.log("Columns", columns)

  // Datos de la tabla
// Datos de la tabla

function sumando(){
  let tipoGestionSeleccionado = "Subarriendo";
  let suma= Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
    const facturacion_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
    const alquileres_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
    const alquileres2_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
    const IVA_Reservas_Subarriendo_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
    const gastos_estimados_suministros_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
    const comision_subarriendo_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0;
    const comision_gestion_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0;
   
    
    const pagoLimpieza_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Pago_Limpieza || 0;
    const totalBruto = facturacion_ - IVA_Reservas_Subarriendo_ - alquileres_ - alquileres2_ - gastos_estimados_suministros_;
    const totalBrutoTrasComisiones = totalBruto - comision_subarriendo_ - comision_gestion_;
    const beneficioOperativo = totalBrutoTrasComisiones - pagoLimpieza_;
    
    //APARTADO DE IVAS
    const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
    const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0;
    const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0;
    
    const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0;
    const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0;
    const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0;
    const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
    const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
    
    const total_iva_s = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
    
    return acc + beneficioOperativo+ total_iva_r + total_iva_s;
  }, 0)
  tipoGestionSeleccionado = "Gestion_bruta";
  suma = suma + Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
    const facturacion_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
    const alquileres_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
    const alquileres2_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
    const IVA_Reservas_Subarriendo_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
    const gastos_estimados_suministros_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
    const comision_subarriendo_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0;
    const comision_gestion_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0;
   
    
    const pagoLimpieza_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Pago_Limpieza || 0;
    const totalBruto = facturacion_ - IVA_Reservas_Subarriendo_ - alquileres_ - alquileres2_ - gastos_estimados_suministros_;
    const totalBrutoTrasComisiones = totalBruto - comision_subarriendo_ - comision_gestion_;
    const beneficioOperativo = totalBrutoTrasComisiones - pagoLimpieza_;
    
    //APARTADO DE IVAS
    const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
    const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0;
    const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0;
    
    const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0;
    const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0;
    const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0;
    const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
    const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
    
    const total_iva_s = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
    
    return acc + beneficioOperativo+ total_iva_r + total_iva_s;
  }, 0)
  return  formatCurrency(Number(suma).toFixed(2))
}
  // Datos de la tabla con filas de porcentaje
// Datos de la tabla con filas de suma total
const dataSource = [
  
  
  {
    categoria: "Facturacion con IVA",
    TotalSuma: formatCurrency(Number(Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.Facturacion || 0), 0
    ) + Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.Facturacion || 0), 0
    ) ).toFixed(2)),

    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0)]
    ))
  },
  { 
    categoria: "IVA_Reservas_Subarriendo",
    TotalSuma: formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Reservas_Subarriendo || 0), 0
      ) + 
      Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Reservas_Subarriendo || 0), 0
      )
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => 
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0)]
    )) 
  },
  {
    categoria: "Facturacion sin IVA",
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
      const IVA_Reservas_Subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      return acc + (facturacion - IVA_Reservas_Subarriendo);
    }, 0),
      TotalSuma:  formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => {
        const facturacion = datosPorTipoGestion["Subarriendo"][ciudad]?.Facturacion || 0;
        const alquileres = datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Gestion || 0;
        const alquileres2 = datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Resto || 0;
        const IVA_Reservas_Subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Reservas_Subarriendo || 0;
        const gastos_estimados_suministros = datosPorTipoGestion["Subarriendo"][ciudad]?.gastos_estimados_suministros || 0;
        const comision_subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_subarriendo || 0;
        const comision_gestion = datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_gestion_bruta || 0;
       
        
        const pagoLimpieza = datosPorTipoGestion["Subarriendo"][ciudad]?.Pago_Limpieza || 0;
        const totalBruto = facturacion - IVA_Reservas_Subarriendo ;
        const totalBrutoTrasComisiones = totalBruto ;
        const beneficioOperativo = totalBrutoTrasComisiones ;
        return acc + beneficioOperativo;
      }, 0) + 
      Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => {
        const facturacion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Facturacion || 0;
        const alquileres = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Gestion || 0;
        const alquileres2 = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Resto || 0;
        const IVA_Reservas_Subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Reservas_Subarriendo || 0;
        const gastos_estimados_suministros = datosPorTipoGestion["Gestion_bruta"][ciudad]?.gastos_estimados_suministros || 0;
        const comision_subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_subarriendo || 0;
        const comision_gestion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_gestion_bruta || 0;
       
        
        const pagoLimpieza = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Pago_Limpieza || 0;
        const totalBruto = facturacion - IVA_Reservas_Subarriendo ;
        const totalBrutoTrasComisiones = totalBruto ;
        const beneficioOperativo = totalBrutoTrasComisiones ;
        return acc + beneficioOperativo;
      }, 0)
    ).toFixed(2)),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
      const IVA_Reservas_Subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const totalBruto = facturacion - IVA_Reservas_Subarriendo;
      return [ciudad, formatCurrency(totalBruto)];
    }))
  },
  {
    categoria: "Propietario Gestión",
    TotalSuma: formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Gestion || 0), 0
      ) + Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Gestion || 0), 0
      )
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0)]
    ))
  },
  {
    categoria: "IVA Factura CTC a Propietario",
    TotalSuma: formatCurrency(Number(Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_ComoTuCasa || 0), 0
    ) + Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_ComoTuCasa || 0), 0
    )).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0)]
    ))
  },
  {
    categoria: "Propietario Resto",
    TotalSuma: formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Resto || 0), 0
      ) + 
      Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Resto || 0), 0
      )
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0)]
    ))
  },
  {
    categoria: "IVA_Alquileres_Subarriendo",
    TotalSuma: formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Alquileres_Subarriendo || 0), 0
    ) +  Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Alquileres_Subarriendo || 0), 0
    )
  
  
  ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0)]
    ))
  },
  
 
  {
    categoria: "Total Bruto",
    TotalSuma: formatCurrency(Number().toFixed(2)),
    TotalSuma:  formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => {
        const facturacion = datosPorTipoGestion["Subarriendo"][ciudad]?.Facturacion || 0;
        const alquileres = datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Gestion || 0;
        const alquileres2 = datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Resto || 0;
        const IVA_Reservas_Subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Reservas_Subarriendo || 0;
        const gastos_estimados_suministros = datosPorTipoGestion["Subarriendo"][ciudad]?.gastos_estimados_suministros || 0;
        const comision_subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_subarriendo || 0;
        const comision_gestion = datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_gestion_bruta || 0;
       
        
        const pagoLimpieza = datosPorTipoGestion["Subarriendo"][ciudad]?.Pago_Limpieza || 0;
        const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 ;
        const totalBrutoTrasComisiones = totalBruto ;
        const beneficioOperativo = totalBrutoTrasComisiones ;
        return acc + beneficioOperativo;
      }, 0) + 
      Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => {
        const facturacion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Facturacion || 0;
        const alquileres = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Gestion || 0;
        const alquileres2 = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Resto || 0;
        const IVA_Reservas_Subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Reservas_Subarriendo || 0;
        const gastos_estimados_suministros = datosPorTipoGestion["Gestion_bruta"][ciudad]?.gastos_estimados_suministros || 0;
        const comision_subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_subarriendo || 0;
        const comision_gestion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_gestion_bruta || 0;
       
        
        const pagoLimpieza = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Pago_Limpieza || 0;
        const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 ;
        const totalBrutoTrasComisiones = totalBruto ;
        const beneficioOperativo = totalBrutoTrasComisiones ;
        return acc + beneficioOperativo;
      }, 0)
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
      const IVA_Reservas_Subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      let totalBruto = facturacion - IVA_Reservas_Subarriendo;
      totalBruto = totalBruto - alquileres - alquileres2 ;
      return acc + totalBruto;
    }, 0),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
      const IVA_Reservas_Subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      let totalBruto = facturacion - IVA_Reservas_Subarriendo;
      totalBruto = totalBruto - alquileres - alquileres2 ;
      return [ciudad, formatCurrency(totalBruto)];
    }))
  },
  {
    categoria: "Comision_subarriendo",
    TotalSuma: formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_subarriendo || 0), 0
      ) + Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_subarriendo || 0), 0
      )
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0)]
    ))
  },
  {
    categoria: "IVA Comision Subarriendo",
    TotalSuma: formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Comision_Subarriendo || 0), 0
      ) + Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Comision_Subarriendo || 0), 0
      )
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0)]
    ))
  },
  {
    categoria: "Comision_gestion_bruta",
    TotalSuma: formatCurrency(Number(

      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_gestion_bruta || 0), 0
      ) + Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_gestion_bruta || 0), 0
      )


    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0)]
    ))
  },
  {
    categoria: "IVA Comision Gestion Bruta",
    TotalSuma: formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Comision_gestion_bruta || 0), 0
      ) +  Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Comision_gestion_bruta || 0), 0
      )



    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0)]
    ))
  },
  {
    categoria: "Total Bruto tras Comisiones",
    TotalSuma:  formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => {
        const facturacion = datosPorTipoGestion["Subarriendo"][ciudad]?.Facturacion || 0;
        const alquileres = datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Gestion || 0;
        const alquileres2 = datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Resto || 0;
        const IVA_Reservas_Subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Reservas_Subarriendo || 0;
        const gastos_estimados_suministros = datosPorTipoGestion["Subarriendo"][ciudad]?.gastos_estimados_suministros || 0;
        const comision_subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_subarriendo || 0;
        const comision_gestion = datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_gestion_bruta || 0;
       
        
        const pagoLimpieza = datosPorTipoGestion["Subarriendo"][ciudad]?.Pago_Limpieza || 0;
        const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 ;
        const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
        const beneficioOperativo = totalBrutoTrasComisiones ;
        return acc + beneficioOperativo;
      }, 0) + 
      Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => {
        const facturacion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Facturacion || 0;
        const alquileres = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Gestion || 0;
        const alquileres2 = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Resto || 0;
        const IVA_Reservas_Subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Reservas_Subarriendo || 0;
        const gastos_estimados_suministros = datosPorTipoGestion["Gestion_bruta"][ciudad]?.gastos_estimados_suministros || 0;
        const comision_subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_subarriendo || 0;
        const comision_gestion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_gestion_bruta || 0;
       
        
        const pagoLimpieza = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Pago_Limpieza || 0;
        const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 ;
        const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
        const beneficioOperativo = totalBrutoTrasComisiones ;
        return acc + beneficioOperativo;
      }, 0)
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
      const IVA_Reservas_Subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
      const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0;
      
      const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 ;
      const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
      return acc + totalBrutoTrasComisiones;
    }, 0),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
      const IVA_Reservas_Subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
      const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0;
      const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 ;
      const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
      return [ciudad, formatCurrency(totalBrutoTrasComisiones)];
    }))
  },
  {
    categoria: "Suministros",
    TotalSuma: formatCurrency(Number(Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.gastos_estimados_suministros || 0), 0
    ) + Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.gastos_estimados_suministros || 0), 0
    )).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0)]
    ))
  },
  {
    categoria: "IVA_Suministros_Subarriendo",
    TotalSuma: formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Suministros_Subarriendo || 0), 0
      ) + Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Suministros_Subarriendo || 0), 0
      )
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad =>
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0)]
    ))
  },
  {
    categoria: "Total Bruto tras Suministros",
    
    TotalSuma:  formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => {
        const facturacion = datosPorTipoGestion["Subarriendo"][ciudad]?.Facturacion || 0;
        const alquileres = datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Gestion || 0;
        const alquileres2 = datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Resto || 0;
        const IVA_Reservas_Subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Reservas_Subarriendo || 0;
        const gastos_estimados_suministros = datosPorTipoGestion["Subarriendo"][ciudad]?.gastos_estimados_suministros || 0;
        const comision_subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_subarriendo || 0;
        const comision_gestion = datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_gestion_bruta || 0;
       
        
        const pagoLimpieza = datosPorTipoGestion["Subarriendo"][ciudad]?.Pago_Limpieza || 0;
        const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 - gastos_estimados_suministros;
        const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
        const beneficioOperativo = totalBrutoTrasComisiones ;
        return acc + beneficioOperativo;
      }, 0) + 
      Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => {
        const facturacion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Facturacion || 0;
        const alquileres = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Gestion || 0;
        const alquileres2 = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Resto || 0;
        const IVA_Reservas_Subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Reservas_Subarriendo || 0;
        const gastos_estimados_suministros = datosPorTipoGestion["Gestion_bruta"][ciudad]?.gastos_estimados_suministros || 0;
        const comision_subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_subarriendo || 0;
        const comision_gestion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_gestion_bruta || 0;
       
        
        const pagoLimpieza = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Pago_Limpieza || 0;
        const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 - gastos_estimados_suministros;
        const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
        const beneficioOperativo = totalBrutoTrasComisiones ;
        return acc + beneficioOperativo;
      }, 0)
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
      const IVA_Reservas_Subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
      const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0;
      
      const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 - gastos_estimados_suministros;
      const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
      return acc + totalBrutoTrasComisiones;
    }, 0),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
      const IVA_Reservas_Subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
      const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0;
      const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 - gastos_estimados_suministros;
      const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
      return [ciudad, formatCurrency(totalBrutoTrasComisiones)];
    }))
  },
  {
    categoria: "Pago Limpieza",
    TotalSuma: formatCurrency(Number(Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.Pago_Limpieza || 0), 0
    ) + Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.Pago_Limpieza || 0), 0
    ),).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Pago_Limpieza || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => 
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Pago_Limpieza || 0)]
    ))
  },
  {
    categoria: "IVA Limpieza",
    TotalSuma: formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0), 0
      ) + Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => 
        acc + (datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0), 0
      )
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => 
      acc + (datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0), 0
    ),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => 
      [ciudad, formatCurrency(datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0)]
    ))
  },
  {
    categoria: "Beneficio Operativo",
    TotalSuma:  formatCurrency(Number(
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => {
        const facturacion = datosPorTipoGestion["Subarriendo"][ciudad]?.Facturacion || 0;
        const alquileres = datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Gestion || 0;
        const alquileres2 = datosPorTipoGestion["Subarriendo"][ciudad]?.Propietario_Resto || 0;
        const IVA_Reservas_Subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Reservas_Subarriendo || 0;
        const gastos_estimados_suministros = datosPorTipoGestion["Subarriendo"][ciudad]?.gastos_estimados_suministros || 0;
        const comision_subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_subarriendo || 0;
        const comision_gestion = datosPorTipoGestion["Subarriendo"][ciudad]?.Comision_gestion_bruta || 0;
       
        
        const pagoLimpieza = datosPorTipoGestion["Subarriendo"][ciudad]?.Pago_Limpieza || 0;
        const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 - gastos_estimados_suministros;
        const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
        const beneficioOperativo = totalBrutoTrasComisiones - pagoLimpieza;
        return acc + beneficioOperativo;
      }, 0) + 
      Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => {
        const facturacion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Facturacion || 0;
        const alquileres = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Gestion || 0;
        const alquileres2 = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Propietario_Resto || 0;
        const IVA_Reservas_Subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Reservas_Subarriendo || 0;
        const gastos_estimados_suministros = datosPorTipoGestion["Gestion_bruta"][ciudad]?.gastos_estimados_suministros || 0;
        const comision_subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_subarriendo || 0;
        const comision_gestion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Comision_gestion_bruta || 0;
       
        
        const pagoLimpieza = datosPorTipoGestion["Gestion_bruta"][ciudad]?.Pago_Limpieza || 0;
        const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 - gastos_estimados_suministros;
        const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
        const beneficioOperativo = totalBrutoTrasComisiones - pagoLimpieza;
        return acc + beneficioOperativo;
      }, 0)
    ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
      const IVA_Reservas_Subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
      const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0;
     
      
      const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Pago_Limpieza || 0;
      const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 - gastos_estimados_suministros;
      const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
      const beneficioOperativo = totalBrutoTrasComisiones - pagoLimpieza;
      return acc + beneficioOperativo;
    }, 0),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
      const IVA_Reservas_Subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
      const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0;
     
      const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Pago_Limpieza || 0;
      const totalBruto = facturacion - IVA_Reservas_Subarriendo - alquileres - alquileres2 - gastos_estimados_suministros;
      const totalBrutoTrasComisiones = totalBruto - comision_subarriendo - comision_gestion;
      const beneficioOperativo = totalBrutoTrasComisiones - pagoLimpieza;
      return [ciudad, formatCurrency(beneficioOperativo)];
    }))
  },
 
  {
    categoria: "Ivas Repercutidos (Gestion: Comision +limpieza + Comision AyB // Subarriendo: 10% Reservas)",
    TotalSuma:formatCurrency(Number(
    
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision_subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
      
      const total_iva_s = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      
      return acc + total_iva_r ;
    }, 0)

    +
    Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision_subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
      
      const total_iva_s = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      
      return acc + total_iva_r ;
    }, 0)
  ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      
      const total_iva = (-facturacion - alquileres2 - pagoLimpieza - comision_gestion) ;
       return acc + total_iva;
    }, 0),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      
      const total_iva = (-facturacion - alquileres2 - pagoLimpieza - comision_gestion ) ;
      return [ciudad, formatCurrency(total_iva)];
    }))
  },
  {
    categoria: "Ivas soportados (Gestion: Comision AyB // Subarriendo: Suministros y Alquileres)",
    TotalSuma:formatCurrency(Number(
    
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision_subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
      
      const total_iva_s = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      
      return acc +  total_iva_s;
    }, 0)

    +
    Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision_subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
      
      const total_iva_s = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      
      return acc +  total_iva_s;
    }, 0)
  ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0;

      const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      
      const total_iva =  + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion;
      return acc + total_iva;
    }, 0),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      
      const total_iva = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      return [ciudad, formatCurrency(total_iva)];
    }))
  },
  {
    categoria: "Diferencia IVAS",
    TotalSuma:formatCurrency(Number(
    
      Object.keys(datosPorTipoGestion["Subarriendo"] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision_subarriendo = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion["Subarriendo"][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
      
      const total_iva_s = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      
      return acc + total_iva_r + total_iva_s;
    }, 0)

    +
    Object.keys(datosPorTipoGestion["Gestion_bruta"] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision_subarriendo = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion["Gestion_bruta"][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
      
      const total_iva_s = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      
      return acc + total_iva_r + total_iva_s;
    }, 0)
  ).toFixed(2)),
    Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
      
      const total_iva_s = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      
      return acc + total_iva_r + total_iva_s;
    }, 0),
    ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => {
      const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
      const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0;
      const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0;
      
      const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0;
      const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0;
      const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0;
      const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      
      const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
      const total_iva_s =  alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      
      return [ciudad, formatCurrency(total_iva_r + total_iva_s)];
    }))
  },
  // {
  //   categoria: "Total Bruto FUERA CAJA IVAS",
  //   TotalSuma:sumando(),
  //   Total: Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).reduce((acc, ciudad) => {
  //     const facturacion_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
  //     const alquileres_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
  //     const alquileres2_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
  //     const IVA_Reservas_Subarriendo_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
  //     const gastos_estimados_suministros_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
  //     const comision_subarriendo_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0;
  //     const comision_gestion_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0;
     
      
  //     const pagoLimpieza_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Pago_Limpieza || 0;
  //     const totalBruto = facturacion_ - IVA_Reservas_Subarriendo_ - alquileres_ - alquileres2_ - gastos_estimados_suministros_;
  //     const totalBrutoTrasComisiones = totalBruto - comision_subarriendo_ - comision_gestion_;
  //     const beneficioOperativo = totalBrutoTrasComisiones - pagoLimpieza_;
      
  //     //APARTADO DE IVAS
  //     const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
  //     const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0;
  //     const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0;
      
  //     const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0;
  //     const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0;
  //     const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0;
  //     const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
  //     const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
      
  //     const total_iva_s = + alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      
  //     return acc + beneficioOperativo+ total_iva_r + total_iva_s;
  //   }, 0),
  //   ...Object.fromEntries(Object.keys(datosPorTipoGestion[tipoGestionSeleccionado] || {}).map(ciudad => {
  //     const facturacion_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Facturacion || 0;
  //     const alquileres_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Gestion || 0;
  //     const alquileres2_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Propietario_Resto || 0;
  //     const IVA_Reservas_Subarriendo_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
  //     const gastos_estimados_suministros_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.gastos_estimados_suministros || 0;
  //     const comision_subarriendo_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_subarriendo || 0;
  //     const comision_gestion_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Comision_gestion_bruta || 0;
     
      
  //     const pagoLimpieza_ = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.Pago_Limpieza || 0;
  //     const totalBruto = facturacion_ - IVA_Reservas_Subarriendo_ - alquileres_ - alquileres2_ - gastos_estimados_suministros_;
  //     const totalBrutoTrasComisiones = totalBruto - comision_subarriendo_ - comision_gestion_;
  //     const beneficioOperativo = totalBrutoTrasComisiones - pagoLimpieza_;
  //     //APARTADO DE IVAS
      
  //     const facturacion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Reservas_Subarriendo || 0;
  //     const alquileres = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Alquileres_Subarriendo || 0;
  //     const alquileres2 = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_ComoTuCasa || 0;
      
  //     const gastos_estimados_suministros = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Suministros_Subarriendo || 0;
  //     const comision_subarriendo = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_Subarriendo || 0;
  //     const comision_gestion = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_Comision_gestion_bruta || 0;
  //     const pagoLimpieza = datosPorTipoGestion[tipoGestionSeleccionado][ciudad]?.IVA_pago_limpieza_gestion_bruta || 0;
      
  //     const total_iva_r = -(facturacion + alquileres2 + pagoLimpieza + comision_gestion) ;
  //     const total_iva_s =  alquileres  + gastos_estimados_suministros + comision_subarriendo + comision_gestion ;
      
  //     return [ciudad, formatCurrency(beneficioOperativo + total_iva_r + total_iva_s)];
  //   }))
  // },
];

// Formatear la columna de Total
dataSource.forEach(item => {
  item.Total = formatCurrency(item.Total);
});



  const filteredData = dataSource.filter(item => item.categoria === "Total Bruto tras Suministros");
console.log("filteredData", filteredData)


const handleCopy = () => {
  // Formatear los datos de la tabla en un string
  let copyText = columns.map(col => col.title).join('\t') + '\n'; // Encabezados
  dataSource.forEach(record => {
    copyText += columns.map(col => record[col.dataIndex]).join('\t') + '\n';
  });

  // Copiar al portapapeles
  navigator.clipboard.writeText(copyText).then(() => {
    alert('Datos copiados al portapapeles');
  }).catch(err => {
    console.error('Error al copiar al portapapeles: ', err);
  });
};
  return (
    <Layout style={{ padding: '0 24px', minHeight: '100vh' }}>
      <Header style={{ background: '#fff', padding: 0 }}>
      <h2 style={{ color: '#000', padding: 0 }} >BENEFICIO TOTAL ESTE MES: { filteredData[0].TotalSuma}</h2>
        
        {/* <Button type="primary" onClick={fetchData}>Actualizar Datos</Button> */}
      </Header>
      <Content style={{ padding: '0 50px', marginTop: 24 }}>
      
        <Title level={3}>Selecciona un Tipo de Gestión</Title>
        
        <Select
          style={{ width: '200px', marginBottom: '16px' }}
          onChange={handleTipoGestionChange}
          value={tipoGestionSeleccionado}
        >
          {Object.keys(datosPorTipoGestion).map(tipo => (
            <Option key={tipo} value={tipo}>{tipo}</Option>
          ))}
        </Select>
        <Button onClick={handleCopy}>Copiar Tabla</Button>
        {tipoGestionSeleccionado && datosPorTipoGestion[tipoGestionSeleccionado] && (
          <div>
           
           
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              size="small"
              bordered
              rowClassName={(record) => {
                if (record.categoria.includes("Bruto") 
                  || record.categoria.includes("Beneficio Operativo")
                || record.categoria.includes("Facturacion")) {
                  return 'highlight-row';  // Clase CSS para resaltar filas
                }
                return '';
              }}
            />
          
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default Tabla_global;
