import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { queryInquilinosCanceladosApi} from "../../../api/inquilino";
import ListInquilinos from "../../../components/Admin/Users/Listinquilinos";
import moment from "moment";
import { Button, Col, DatePicker, Radio, Row } from "antd";

import "./Cancelaciones.scss";

export default function Cancelaciones(props) {
  
  const [usersActive, setUsersActive] = useState([]);
  const [usersInactive, setUsersInactive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [selectedValues2, setSelectedValues2] = useState(["Inquilino"]);
  const [radioButtonSelection, setRadioButtonSelection] = useState("entranHoy");
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY/MM/DD"));
  const [searchDate, setSearchDate] = useState(moment().format("YYYY/MM/DD")); 

  const token = getAccessTokenApi();

  const handleDateChange = (date, dateString) => {
    setSearchDate(dateString); // Actualiza searchDate en lugar de selectedDate
  };




  useEffect(() => {

  }, [usersActive, usersInactive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await queryInquilinosCanceladosApi(token);
        setUsersActive(response.users);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  
    setReloadUsers(false);
  }, [token, reloadUsers, radioButtonSelection, selectedValues2]);
  
  return (
    <div className="users">
     
     
      
      <ListInquilinos
        usersActive={usersActive}
        usersInactive={usersInactive}
        setReloadUsers={setReloadUsers}
        setSelectedValues={setSelectedValues2}
        selectedValues2={selectedValues2}
      />
    </div>
  );
}
