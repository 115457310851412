import { basePath, apiVersion } from "./config";

export async function createGasto(accessToken, data, file) {
  try {
    const formData = new FormData();

    // Agregar datos a formData
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    // Agregar archivo si existe
    if (file && file.length > 0) {
      file.forEach((fileItem) => {
        formData.append("file", fileItem.originFileObj);
      });
    }

    const url = `${basePath}/${apiVersion}/gasto`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json(); // Asegúrate de manejar la respuesta como JSON
    return result;
  } catch (error) {
    console.error("Error en createGasto:", error);
    throw error; // Lanza el error para que pueda ser manejado por quien llame a la
  }
}

//El token es necesario para poder atacar al backend
export function getGastosApi(token, fechas) {
  const url = `${basePath}/${apiVersion}/gastos`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      fechas: fechas,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
export function getGastosApiMes(token, inmuebleSelected, month, year) {
  const url = `${basePath}/${apiVersion}/gastos-month-year/${inmuebleSelected}`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      month: month,
      year: year,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
export function getGastosRecurrentesApi(token, fechas) {
  const url = `${basePath}/${apiVersion}/gastos-recurrentes`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      fechas: fechas,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function updateGastoApi(token, data, id, file) {
  const url = `${basePath}/${apiVersion}/gasto/${id}`;
  const formData = new FormData();

  //console.log("API UPDATE GASTO", data)

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  // Agregar archivo si existe
  if (file && file.length > 0) {
    file.forEach((fileItem) => {
      formData.append("file", fileItem.originFileObj);
    });
  }

  const params = {
    method: "PUT",
    headers: {
      Authorization: token,
    },

    body: formData,

  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function deleteGastoApi(token, id) {
  const url = `${basePath}/${apiVersion}/gasto/${id}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function asociarGastoUsuario(token, formData, id) {
  const url = `${basePath}/${apiVersion}/asociar-gasto/${id}`;

  const params = {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: formData,
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function verGastos(accessToken, id, nombreArchivo) {
  const url = `${basePath}/${apiVersion}/ver-gasto-usuario/${id}/${nombreArchivo}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: accessToken,
    },
  };

  return fetch(url, params)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      return URL.createObjectURL(blob);
    })
    .catch(err => {
      console.error('Error al obtener el archivo:', err);
      throw err;
    });
}
