import React, { useState, useEffect } from "react";

import { obtenerListaInmuebles } from "../../../api/inmueble";
import { getAccessTokenApi } from "../../../api/auth";
import TablePlot from "../../../components/Admin/Graphs/Table";
import "antd/dist/antd.css";
import dayjs from "dayjs";
import moment from 'moment';
import LimpiezaPorDias from "./LimpiezaPorDias";
import LimpiezaPorDiasLimpiador from "./LimpiezaPorDiasLimpiador";
import jwtDecode from 'jwt-decode';

import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Radio,
  Space,
  Divider,
  Modal,
  Table,
  Tabs,
  Statistic,
  Card 
} from "antd";
import { RightOutlined, DeleteOutlined,LeftOutlined,CloseSquareOutlined,CheckSquareOutlined, EditOutlined } from "@ant-design/icons"; // Importa los íconos de flecha
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import { useReactToPrint } from 'react-to-print';
import { FilePdfOutlined } from "@ant-design/icons";
import {
  getFacturacion,
  getFacturacionTotal,
  getFacturacionDatos,
} from "../../../api/facturacion";
import Listinquilinosfacturacion from "../../../components/Admin/Users/Listinquilinosfacturacion";
import DemoPie from "../../../components/Admin/Graphs/Pie";
import html2canvas from 'html2canvas';
import Facturas from "../../../components/Facturas";
import LectorDni from "../../../components/LectorDni";
import { getGastosApi, getGastosApiMes } from "../../../api/gastos";
import ListGasto from "../../../components/Admin/Users/Gastos/ListGasto/ListGasto";
import { deleteInquilinoApi, getComentarioLimpiezaPorPiso, getInquilinosById, getInquilinosOneDay, getLimpiezasOneDay } from "../../../api/inquilino";
import GraphBarrasApiladas from "../../../components/GraphBarrasApiladas";
import RegistroLimpiezasPorLimpiador from "./RegistroLimpiezasPorLimpiador";
import RegistroLimpiezasLimpiador from "./RegistroLimpiezasLimpiador";
import ComentariosLimpiezaPorPiso from "./ComentariosLimpiezaPorPiso";
import Boton_peticion_handler from "../../../components/Admin/boton_peticion_handler/boton_peticion_handler";

import EditarInquilino from './EditarInquilino';
import { getUsersByRoleApi } from "../../../api/user";
import Mapa from "../../../components/Admin/Mapa";
import Limpiar from "./Modal_Acciones/Limpiar";
import { createStockPisoApi } from "../../../api/stockPiso";
import Boton_peticion_handler_v2 from "../../../components/Admin/boton_peticion_handler_v2";
import DesplegableMultiple from "../../../components/DesplegableMultiple";
import Desplegable_activable from "./Desplegable_activable";
import Tabla_limpiadores from "./Tabla_limpiadores/Tabla_limpiadores";
import Exportar_pdf_limpiezas from "./Exportar_pdf_limpiezas";
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Limpieza() {
  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [limpiezas, setlimpiezas] = useState([]);
  const [comentarios, setcomentarios] = useState([]);
  const [nombres_id_pisos, setNombresIds_pisos] = useState({});
  const [userRole, setUserRole] = useState('');
  const [reload, setreload] = useState(false);
  const [editarVisible, setEditarVisible] = useState(false);
  const [limpiadores_id, setlimpiadores_id] = useState({});
  const [limpiadores_id_desplegable, setlimpiadores_id_desplegable] = useState([]);
  const [usuario_seleccionado,set_usuario_seleccionado] = useState("")
  const [limpiadores_info_pisos,set_limpiadores_info_pisos] = useState({})
  const [pisos_unicos, setpisos_unicos]= useState([])
  const [pisos_limpios, setpisos_limpios]= useState(0)
  const [pisos_no_asignados, setpisos_no_asignados]= useState(0)
  const [pisos_sucios, setpisos_sucios]= useState(0)
  const [pisos_limpiar_id_lista, setpisos_limpiar_id_lista]= useState({})
  const [ModalLimpioVisible, setModalLimpioVisible]= useState(true)
 const [inquilinoSeleccionado, setInquilinoSeleccionado] = useState(null); // Estado para almacenar la información del inquilino seleccionado
 const [vistaMapa, setVistaMapa] = useState(false);
 const [vistaMapa2, setVistaMapa2] = useState(false);
 
 
 const { confirm } = Modal;

 
 const showDeleteConfirm = (inquilino_id, inquilino_name) => {
  const accesToken = getAccessTokenApi();

  confirm({
    title: "Eliminando usuario",
    content: `¿Estas seguro que quieres eliminar a ${inquilino_name}?`,
    okText: "Eliminar",
    okType: "danger",
    cancelText: "Cancelar",
    onOk() {
      deleteInquilinoApi(accesToken, inquilino_id)
        .then((response) => {
          notification["success"]({
            message: "Inquilino borrado correctamente",
          });
          // setReloadUsers(true);
        })
        .catch((err) => {
          notification["error"]({
            message: "Error al borrar el inquilino" + err,
          });
        });
    },
  });
};

  useEffect(() => {
    const token = getAccessTokenApi();
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserRole(decodedToken.role); // Asegúrate de que el rol está incluido en el token
    }
    setreload(false)
  }, [reload]);

  useEffect(() => {
    const accessToken = getAccessTokenApi();
    obtenerListaInmuebles(accessToken).then((response) => {
      //console.log(response.Pisos[0].id);
      //console.log("obtenerListaInmuebles", response.Pisos);
      var ids_pisos_a = {};
      for (let i = 0; i < response.Pisos.length; i++) 
      {
        ids_pisos_a[response.Pisos[i].id] = response.Pisos[i].nombre;
      }

      setNombresIds_pisos(ids_pisos_a);
    });
    setreload(false)
  }, [reload]);

  useEffect(() => {
    const fetchLimpiadores = async () => {
      try {
        const limpiadores = await getUsersByRoleApi(getAccessTokenApi(), "Limpiador");
        let limpiadores_por_id_aux = {};
        let limpiadores_inmuebles_ids = {};
        limpiadores.users.forEach(limpiador => {
          limpiadores_por_id_aux[limpiador.id] = limpiador.name;
          limpiadores_inmuebles_ids[limpiador.id] = limpiador.pisos_asociados;
        });
        //console.log("limpiadores_por_id_aux",limpiadores_por_id_aux)
        let vect_aux = []
        Object.keys(limpiadores_por_id_aux).forEach(element => {
          vect_aux.push({value:element, label:limpiadores_por_id_aux[element], pisos: limpiadores_inmuebles_ids[element]})
        });
        //console.log("vect_aux_1",vect_aux)
        set_limpiadores_info_pisos(limpiadores)
        setlimpiadores_id_desplegable(vect_aux)
        setlimpiadores_id(limpiadores_por_id_aux);
      } catch (error) {
        console.error("Error al obtener los limpiadores:", error);
      }
    };
  
    fetchLimpiadores();
    setreload(false)
  }, [reload]);

  useEffect(() => {
    
  }, [reload, limpiadores_id, pisos_limpios, pisos_sucios, pisos_limpiar_id_lista, limpiadores_id_desplegable]);
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY/MM/DD"));

  const handleDateChange = (date, dateString) => {
    // date: Moment object
    // dateString: String value of the selected date

    // Actualiza la variable de estado con la fecha seleccionada
    setSelectedDate(dateString);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = getAccessTokenApi();
        const resp = await getInquilinosOneDay(selectedDate, accessToken);
        setdata2(resp.inquilinos);
        //console.log("UEEEE")
        
        //console.log("cosas", resp);
        let pisos_limpiados_hoy = 0;
        let pisos_sucios_hoy = 0;
        const data2_aux = Object.entries(resp.inquilinos).flatMap(([inmuebleId, data]) => {
          // Mapear cada evento a la estructura deseada
   
         
          return data.eventos.map((evento) => ({
            inmueble_id: inmuebleId,
            piso_name: nombres_id_pisos[inmuebleId] ?? "",
            hora: evento?.tipo === "entrada" ? "hora_entrada" : "hora_salida",
            hora2: evento?.hora,
            nombre: evento?.nombre,
            numero_personas: evento?.numero_personas,
            estadoLimpieza: evento?.estadoLimpieza??"-",
            fecha_limpieza: evento?.fecha_limpieza,
            fecha_salida:evento?.fecha_salida,
            fecha_entrada: evento?.fecha_entrada,
            id_inquilino:evento?.id??"",
            limpiador: limpiadores_id[evento?.limpiadores[0]]??"",
            comentarioLimpiador : evento?.comentarioLimpiador,
            anotacion_despues_limpieza: evento?.anotacion_despues_limpieza


          }));
        });
        const pisos_a_limpiar = Object.keys(resp.inquilinos);
        //console.log("data2_aux", data2_aux)
        //console.log("UNICOS",resp)
        //console.log("UNICOS2",pisos_a_limpiar)
        let botones_comentarios = [];
        pisos_a_limpiar.map(x => {
          botones_comentarios.push(<div>       </div>)
        })
        //console.log("setdata1", data2_aux);
        
        setdata1(data2_aux);
        setpisos_unicos(pisos_a_limpiar)
       
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

  //   const fetchComentarios = async () => {
  //     try {
  //       const accessToken = getAccessTokenApi();
  //       const resp = await getComentarioLimpiezaPorPiso(accessToken,2,2024,"6530fd2bcb0b92bc35024115");
  //       //console.log("comentarios", resp)
  //       setcomentarios(resp.message)
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  // fetchComentarios(); // Llamada a la función asíncrona

    fetchData(); // Llamada a la función asíncrona
   
    setreload(false)

  }, [reload,selectedDate, nombres_id_pisos]);

  const fetchData = async () => {
    try {
      //Limpiezas hoy
      let vector_pisos_unicos = {}
      const accessToken = getAccessTokenApi();
      const resp = await getLimpiezasOneDay(selectedDate, accessToken);
      //console.log("UEEEE")

      //console.log("cosas", resp);
      let pisosLimpiadosHoy = 0;
      let pisosSuciosHoy = 0;
      let pisosSinAsignarHoy = 0;
  
      const data22 = Object.entries(resp.inquilinos).flatMap(([inmuebleId, data]) => {
        //console.log("Esta es la data y el inmuebleID: ", inmuebleId, data)
        // Mapear cada evento a la estructura deseada

        console.log(data)
        // return data.eventos.map((evento) => {
          let evento = data.eventos.find(evento => evento.tipo === "salida");
          let evento_entrada = data.eventos.find(evento => evento.tipo === "entrada");

          // Incrementar el contador de pisos limpios o sucios según el estado de limpieza
          if (evento.estadoLimpieza === "Limpio") {
            //console.log("evento.estadoLimpieza Limpio",evento.nombre, evento.estadoLimpieza)
            pisosLimpiadosHoy++;
          } else if (evento.estadoLimpieza === "Asignado") {
            //console.log("evento.estadoLimpieza Sucio",evento.nombre, evento.estadoLimpieza)
            pisosSuciosHoy++;
          }else{
            pisosSinAsignarHoy++;
          }
          vector_pisos_unicos[inmuebleId] = {estado_limpieza: evento.estadoLimpieza ?? "", limpiador: limpiadores_id[evento.limpiadores[0]], limpiador_id:evento.limpiadores[0],  id_inquilino: evento?.id ?? "hola2" };
          return {
            // entra_alguien_hoy

            inmueble_id: inmuebleId,
            piso_name: nombres_id_pisos[inmuebleId] ?? "",
            tipo: evento.tipo === "entrada" ? "hora_entrada" : "hora_salida",
            hora_salida: evento.hora,
            hora_entrada : evento_entrada.hora,
            

            nombre: evento.nombre,
            numero_personas: evento.numero_personas,
            estadoLimpieza: evento.estadoLimpieza ?? "",
            fecha_limpieza: evento.fecha_limpieza,
            fecha_salida: evento.fecha_salida,
            fecha_entrada: evento_entrada.fecha_entrada,
            id_inquilino: evento?.id ?? "",
            limpiador: limpiadores_id[evento.limpiadores[0]],
            comentarioLimpiador: evento?.comentarioLimpiador,
            anotacion_despues_limpieza: evento?.anotacion_despues_limpieza
          };
        // });
        
      });
      setpisos_limpiar_id_lista(vector_pisos_unicos)
      setpisos_limpios(pisosLimpiadosHoy);
      setpisos_sucios(pisosSuciosHoy);
      setpisos_no_asignados(pisosSinAsignarHoy);
      // const data2 = Object.entries(resp.inquilinos).flatMap(([inmuebleId, data]) => {
      //   // Mapear cada evento a la estructura deseada
 
      //   //console.log("Data2: ",data);
      //   return data.eventos.map((evento) => ({
      //     piso_name: nombres_id_pisos[inmuebleId] ?? "",
      //     hora: evento.tipo === "entrada" ? "hora_entrada" : "hora_salida",
      //     hora2: evento.hora,
      //     nombre: evento.nombre,
      //     numero_personas: evento.numero_personas,
      //     estadoLimpieza: evento.estadoLimpieza??"-",
      //     fecha_limpieza: evento.fecha_limpieza,
      //     fecha_salida:evento.fecha_salida,
      //     fecha_entrada: evento.fecha_entrada,
      //     id_inquilino:evento.id??"",
      //     limpiador: limpiadores_id[evento.limpiadores[0]]

      //   }));
      // });
      const pisos_a_limpiar = Object.keys(resp.inquilinos);
      //console.log("data2", data22)
      //console.log("UNICOS",resp)
      //console.log("UNICOS2",pisos_a_limpiar)
      let botones_comentarios = [];
      pisos_a_limpiar.map(x => {
        botones_comentarios.push(<div>       </div>)
      })

      setlimpiezas(data22);
      setpisos_unicos(pisos_a_limpiar)
     
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
   

    // const fetchComentarios = async () => {
    //   try {
    //     const accessToken = getAccessTokenApi();
    //     const resp = await getComentarioLimpiezaPorPiso(accessToken,2,2024,"6530fd2bcb0b92bc35024115");
    //     //console.log("comentarios", resp)
    //     setcomentarios(resp.message)
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };


    //AQUI SE EJECUTA LA LLAMADA PRICNCIPAL

    fetchData(); // Llamada a la función asíncrona
    // fetchComentarios(); // Llamada a la función asíncrona
    setreload(false)

  }, [reload, nombres_id_pisos,limpiadores_id, data2]);

  const handleLimpiarClick = () => {
    // Lógica para "Limpiar"
    //console.log("Botón Limpiar presionado");
  };

  const handleLimpioClick = () => {
    // Lógica para "Limpio"
    //console.log("Botón Limpio presionado");
  };

  const columns = [
    {
      title: "Limpio/Sucio",
      dataIndex: "estadoLimpieza",
      key: "estadoLimpieza",
      render: (text, record) => {
        if (record.hora === "hora_entrada" && text !== "Limpio") {
          return <> - </>;
        } else {
          // Renderiza el ícono correspondiente al estado de limpieza
          return text !== "Limpio" ? (
            <><CloseSquareOutlined style={{ color: "red" }} /> SUCIO </> 
          ) : (
            <><CheckSquareOutlined style={{ color: "green" }} /> LIMPIO</>
          );
        }
      },
    },
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "Tipo",
      render: (text, record) => {
        if (record.fecha_salida === record.fecha_limpieza) {
          return <>Normal</>;
        } else {
          return <>Pendiente</>;
        }
      },
    },
    {
      title: "NOMBRE PISO",
      dataIndex: "piso_name",
      key: "piso_name",
    },
    
    {
      title: "Limpiador",
      dataIndex: "limpiador",
      key: "limpiador",
      render: (text, record) => {
        // Renderiza la flecha verde hacia la derecha si el tipo es "hora_entrada"
        // y la flecha roja hacia la izquierda si el tipo es "hora_salida"
        //console.log("record limpiador",record)
        console.log("limpiadores_info_pisos", limpiadores_info_pisos);
        console.log("limpiadores_id_desplegable", limpiadores_id_desplegable);
        
        // Filtra los limpiadores basados en record.inmueble_id
        const filteredLimpiadores = limpiadores_id_desplegable.filter((limpiador) => 
          limpiador.pisos.includes(record.inmueble_id)
        );
    
        return text === "hora_entrada" ? (
          <></>
        ) : (
          <>
            <b>{record.limpiador}</b>
            <Divider />
            <Desplegable_activable 
              setreload={setreload} 
              record={record} 
              limpiadores_id_desplegable={filteredLimpiadores} 
              inquilino_id={record.id_inquilino} 
            />
          </>
        );
      },
    },
    {
      title: "comentarioLimpiador",
      dataIndex: "comentarioLimpiador",
      key: "comentarioLimpiador",
    },
//     {
//       title: "Observacion",
//       dataIndex: "comentarioLimpiador",
//       key: "comentarioLimpiador",
//       render:
//         <Button
//         onClick={() => {
//           setComentarioAEditar(text);
          
//           setModalVisible(true);
//           setReservaSeleccionada(record.id);
//         }}
//       >
//     <EditOutlined />
//   </Button>
// },
    {
      title: "fecha_entrada",
      dataIndex: "fecha_entrada",
      key: "fecha_entrada",
      render: (text, record) => {
        // Renderiza la flecha verde hacia la derecha si el tipo es "hora_entrada"
        // y la flecha roja hacia la izquierda si el tipo es "hora_salida"
        let persona_entra = data2[record?.inmueble_id]?.eventos.find(evento => evento.tipo === "entrada");
        //console.log("Aqui va",persona_entra )
        return(
          <>   
          
          {persona_entra ? <> {persona_entra.nombre} ({persona_entra.numero_personas} huespedes) <b>{persona_entra.hora}</b> <RightOutlined style={{ color: "green" }} />{persona_entra.fecha_entrada}</> : null}
          <Divider></Divider>  
          {record?.nombre} ({record.numero_personas} huespedes)  <b>  {record.hora_salida}<LeftOutlined style={{ color: "red" }} /> </b>{record.fecha_salida} 
       
          </>)
      },
    },
    
    {
      title: "Fecha_limpieza",
      dataIndex: "fecha_limpieza",
      key: "fecha_limpieza",
    },
    
    // {
    //   title: "Nombre Inquilino",
    //   dataIndex: "nombre",
    //   key: "nombre",
    // },
    // {
    //   title: "Nº inquilinos",
    //   dataIndex: "numero_personas",
    //   key: "numero_personas",
    // },
    {
      title: "Inquilino",
      dataIndex: "id_inquilino",
      key: "id_inquilino",
      render: (text, record) => {
        // Renderiza la flecha verde hacia la derecha si el tipo es "hora_entrada"
        // y la flecha roja hacia la izquierda si el tipo es "hora_salida"
      
        return <>
       
        <Row> 
          <Button type="danger" onClick={()=>showDeleteConfirm(text, record.nombre)}>
        <DeleteOutlined />
      </Button>
      <Boton_peticion_handler cuerpo={<EditOutlined/>} 
        handler={async()=>{ 
          const accessToken = getAccessTokenApi();
          const resp = await getInquilinosById(accessToken,text );
          if(resp.message){
            set_usuario_seleccionado( resp.message);

            setEditarVisible(true);
          }else{
            notification["error"]({
              message: "Errir con el inquilino"
            });
          }
          
        }}/>  </Row></>        
      },
    },
  ];
  // const exportPDF = () => {
  //   const input = document.getElementById('hola');
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF('landscape', 'mm', 'a4');
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     pdf.save('tabla.pdf');
  //   });
  // };
  
  // Función para abrir el modal y cargar la información del inquilino
  const mostrarModalEditar = (inquilino) => {
    setInquilinoSeleccionado(inquilino);
    setEditarVisible(true);
  };

  // Función para cerrar el modal
  const cerrarModalEditar = () => {
    setInquilinoSeleccionado(null);
    setEditarVisible(false);
  };
  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? 'white-row' : 'grey-row';
  };







  return (
    <>
   
      <Tabs defaultActiveKey="1" onChange={() => { }}>
      
        {(userRole === 'Administrador' || userRole === 'Gestor') && (
          
          <Tabs.TabPane tab="Horarios" key="1">
          <DatePicker
              defaultValue={moment()} // Puedes establecer un valor predeterminado si lo necesitas
              format={'YYYY/MM/DD'}
              onChange={handleDateChange}
            />
            <Boton_peticion_handler_v2  cuerpo={"Buscar"} handler={fetchData} />
            <Row gutter={16}>
            <Col span={8}>
              <Card bordered={false}>
                <Statistic
                  title="Pisos sucios hoy"
                  value={pisos_sucios}
                  precision={0}
                  valueStyle={{ color: '#cf1322' }}
                  // prefix={<ArrowUpOutlined />}
                  // suffix="%"
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false}>
                <Statistic
                  title="Pisos limpios hoy"
                  value={pisos_limpios}
                  precision={0}
                  valueStyle={{ color: '#3f8600' }}
                  // prefix={<ArrowDownOutlined />}
                  // suffix="%"
                />
              </Card>
            </Col>
            <Col span={8}>
            <Card bordered={false}>
                <Statistic
                  title="Pisos sin asignar"
                  value={pisos_no_asignados}
                  precision={0}
                  valueStyle={{ color: 'orange' }}
                  // prefix={<ArrowDownOutlined />}
                  // suffix="%"
                />
              </Card>
            </Col>
          
            </Row>
            <Divider></Divider>
            <Tabla_limpiadores pisos_limpiar_hoy={pisos_limpiar_id_lista} lista_inmuebles={nombres_id_pisos}/>
            <Divider></Divider>
            <div className="switch">
              <label>                
                <input type="checkbox" checked={vistaMapa} onChange={() => setVistaMapa(!vistaMapa)} />
                <span className="lever"> </span>
                Ver limpiezas anteriores por piso
              
              </label>
            </div>
            {vistaMapa ? (
              <>
                  <ComentariosLimpiezaPorPiso/>
                  
                  </>
            ):<></>}


        <div className="switch">
        <label>
          
          <input type="checkbox" checked={vistaMapa2} onChange={() => setVistaMapa2(!vistaMapa2)} />
          <span className="lever"> </span>
          Ver mapa de horas
         
        </label>
      </div>
      {vistaMapa2 ? (
           <GraphBarrasApiladas data_externa={data1} />
      ):<></>}



            <Mapa lista_puntos = {pisos_limpiar_id_lista} />
            {/* <Divider>PISOS A LIMPIAR</Divider>
            {pisos_unicos.map(x => {
              //console.log("pisos_unicos",x)
              return <li>{nombres_id_pisos[x]}</li>;
            })}
            <Divider>HORAS</Divider> */}
          
           
            <Divider>Limpiezas hoy</Divider>
          
           
                <Exportar_pdf_limpiezas columns={columns} dataSource={limpiezas} pagination={false} rowClassName={getRowClassName} data2={data2} />
                {/* <Table columns={columns} dataSource={limpiezas} pagination={false} rowClassName={getRowClassName}  /> */}
                
            
            {/* <Divider>Entradas/salidas hoy</Divider>
            <Table columns={columns} dataSource={data1} pagination={false} /> */}
           
            <Modal
              title="Editar Inquilino"
              visible={editarVisible}
              onCancel={cerrarModalEditar}
              footer={null}
            >
            
            <EditarInquilino userData={usuario_seleccionado} setUserData={set_usuario_seleccionado} setReloadUsers={setreload} cerrarModalEditar={cerrarModalEditar}/>
            </Modal>

            {/* <Modal
              title="Editar Inquilino"
              visible={editarVisible}
              onCancel={cerrarModalEditar}
              footer={null}
            >
              <Limpiar token={getAccessTokenApi()} currentPiso={{id:""}} updatePiso={set_usuario_seleccionado} setIsModalVisible={setModalLimpioVisible} crearGastoStock={createStockPisoApi} fecha= {selectedDate} piso = {usuario_seleccionado.inmueble.id}/>
            </Modal> */}
           
            





          </Tabs.TabPane>
        )}
        {(userRole === 'Administrador' || userRole === 'Gestor') &&(
          <Tabs.TabPane tab="Limpiezas por dias" key="2">
            <LimpiezaPorDias />
          </Tabs.TabPane >
        )}
        {(userRole === 'Limpiador') &&(
          <Tabs.TabPane tab="Limpiezas por dias" key="3">
            <LimpiezaPorDiasLimpiador/>
          </Tabs.TabPane >
        )}
        {(userRole === 'Administrador' || userRole === 'Gestor') &&(
          <Tabs.TabPane tab=" Registro Pisos por limpiador" key="4">
              <RegistroLimpiezasPorLimpiador />
          </Tabs.TabPane >
        )}
         
        {userRole === 'Limpiador' && (
          <Tabs.TabPane tab=" Registro Pisos por limpiador" key="5">
              <RegistroLimpiezasLimpiador />
          </Tabs.TabPane >
        )}

      </Tabs >
    </>
  );
}

