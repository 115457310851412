import React, { useState, useEffect } from "react";
import moment from "moment";
import "./buscador.scss";
import Boton_peticion_handler_v2 from "../boton_peticion_handler_v2";
import { getAccessTokenApi } from "../../../api/auth";
import { getInquilinosByTlf } from "../../../api/inquilino";
import { Input } from "antd";
import Listinquilinos from "../Users/Listinquilinos";

export default function Buscador() {
  const [telefono_buscado, setTelefono_buscado] = useState("");
  const [usersActive, setUsersActive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const token = getAccessTokenApi();

  const handleSearchClick = async () => {
    const fetchData = async () => {
      try {
        const token = getAccessTokenApi();
        let radioButtonSelection = ""; // Este valor debería venir de algún lugar de la UI
        const response = await getInquilinosByTlf(token, telefono_buscado);
        console.log(response)
        setUsersActive(response)
        // // const resp = await getInquilinosOneDayList(selectedDate, token);
        // if (radioButtonSelection === "entranSalenHoy") {
        //   // Lógica para "entranSalenHoy"
        // } else if (radioButtonSelection === "entranHoy") {
        //   // Lógica para "entranHoy"
        // } else if (radioButtonSelection === "salenHoy") {
        //   // Lógica para "salenHoy"
        // } else if (radioButtonSelection === "completoDnies") {
        //   // Lógica para "completoDnies"
        // } else if (radioButtonSelection === "incompletoDnies") {
        //   // Lógica para "incompletoDnies"
        // } else if (radioButtonSelection === "inactivos") {
        //   // Lógica para "inactivos"
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  };
  useEffect(() => {
   
  }, [token, usersActive]);

  return (
    <>
    <div className="users">
      <Input 
        value={telefono_buscado}
        onChange={(e) => setTelefono_buscado(e.target.value)}
        placeholder="Buscar teléfono"
      />
      <Boton_peticion_handler_v2 cuerpo={"Buscar"} handler={handleSearchClick} />
   
    </div>

        
     </>
  );
}
