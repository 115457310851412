// import React, { useState, useEffect } from "react";
// import { getAccessTokenApi } from "../../../../api/auth";
// import { eliminarFacturaAsociada, obtenerFacturasFecha, verFacturas } from "../../../../api/series";
// import { Button, List, DatePicker, Card, Select, Modal } from "antd";
// import jwt_decode from "jwt-decode";
// import moment from 'moment';
// import "./Facturas.scss";
// import { getInmueblesApi } from "../../../../api/inmueble";
// import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

// export default function Facturas() {
//   const [selectedDate, setSelectedDate] = useState(moment());
//   const [selectedInmueble, setSelectedInmueble] = useState(null);
//   const [inmuebles, setInmuebles] = useState([]);
//   const [pdfs, setPdfs] = useState([]);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [currentPdfUrl, setCurrentPdfUrl] = useState("");

//   const accessToken = getAccessTokenApi();
//   const userId = jwt_decode(accessToken).id;

//   useEffect(() => {
//     async function fetchData() {
//       await getInmueblesApi(accessToken, 1, true).then(response => {
//         if (response.Pisos) {
//           setInmuebles(response.Pisos);
//         }
//       });
//     }
//     fetchData();
//   }, [accessToken]);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleInmuebleChange = (value) => {
//     setSelectedInmueble(value);
//   };

//   const handleSearch = async () => {
//     const formattedDate = selectedDate.format("YYYY-MM");
//     const response = await obtenerFacturasFecha(accessToken, selectedInmueble, formattedDate);
//     //console.log(response.error)
//     if (response.error) {
//       //console.log('ERROR')
//       setPdfs();
//     }
//     if (response && response.archivos) {
//       setPdfs(response.archivos);

//     }
//   };

//   const handleDeletePdf = async (pdfName) => {
//     try {
//       await eliminarFacturaAsociada(accessToken, selectedInmueble, pdfName);
//       handleSearch();
//     } catch (error) {
//       console.error("Error al eliminar archivo PDF:", error);
//     }
//   };

//   const handleViewPdf = async (pdfName) => {
//     try {
//       const pdfUrl = await verFacturas(accessToken, selectedInmueble, pdfName);
//       setCurrentPdfUrl(pdfUrl);
//       setIsModalVisible(true);
//     } catch (error) {
//       console.error("Error al visualizar el archivo PDF:", error);
//     }
//   };

//   return (
//     <div>
//       <Select
//         placeholder="Selecciona un inmueble"
//         style={{ width: 200 }}
//         onChange={handleInmuebleChange}
//       >
//         {inmuebles.map((inmueble) => (
//           <Select.Option key={inmueble._id} value={inmueble._id}>
//             {inmueble.name}
//           </Select.Option>
//         ))}
//       </Select>
//       <DatePicker.MonthPicker
//         defaultValue={moment()}
//         onChange={handleDateChange}
//       />
//       <Button type="primary" onClick={handleSearch}>Buscar</Button>
//       {/* {//console.log(pdfs)}        */}
//       <List
//         itemLayout="horizontal"
//         dataSource={pdfs}
//         renderItem={(pdfName) => (
//           <List.Item
//           actions={[
//             jwt_decode(accessToken).role === "Administrador" ? (
//               <Button
//                 type="primary"
//                 onClick={() => handleDeletePdf(pdfName)}
//               >
//                 <DeleteOutlined />
//               </Button>
//             ) : <></>,
//               <Button
//                 type="primary"
//                 onClick={() => {
//                   handleViewPdf(pdfName);
//                 }}
//               >
//                 Ver <EyeOutlined />
//               </Button>
              
//               // Puedes añadir más botones de acción aquí si es necesario
//             ]}
//           >
//             <Card>
//               {/* Puedes ajustar el contenido del Card según tus necesidades */}
//               <Card.Meta
//                 title={`Archivo: ${pdfName}`}
//               // Aquí puedes añadir más metadatos si lo deseas
//               />
//             </Card>
//           </List.Item>
//         )}
//       />
//       <Modal
//         title="Visualización de PDF"
//         visible={isModalVisible}
//         onCancel={() => setIsModalVisible(false)}
//         footer={null}
//         width="80%"
//       >
//         <iframe
//           src={currentPdfUrl}
//           style={{ width: '100%', height: '800px' }}
//           frameBorder="0"
//         />
//       </Modal>
//     </div>
//   );
// }
import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../../api/auth";
import { eliminarFacturaAsociada, obtenerFacturasFecha, verFacturas } from "../../../../api/series";
import { Button, List, DatePicker, Card, Select, Modal } from "antd";
import jwt_decode from "jwt-decode";
import moment from 'moment';
import "./Facturas.scss";
import { getInmueblesApi } from "../../../../api/inmueble";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;  // Usar RangePicker para seleccionar el rango de fechas

export default function Facturas() {
  const [dateRange, setDateRange] = useState([null, null]); // Almacenar el rango de fechas
  const [selectedInmueble, setSelectedInmueble] = useState(null);
  const [inmuebles, setInmuebles] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");

  const accessToken = getAccessTokenApi();
  const userId = jwt_decode(accessToken).id;

  useEffect(() => {
    async function fetchData() {
      await getInmueblesApi(accessToken, 1, true).then(response => {
        if (response.Pisos) {
          setInmuebles(response.Pisos);
        }
      });
    }
    fetchData();
  }, [accessToken]);

  // Actualiza el rango de fechas seleccionado
  const handleDateChange = (dates) => {
    setDateRange(dates);  // Se almacenan las dos fechas seleccionadas (inicio y fin)
  };

  const handleInmuebleChange = (value) => {
    setSelectedInmueble(value);
  };

  // Función de búsqueda que llama a la API con el inmueble y rango de fechas
  const handleSearch = async () => {
    const [startDate, endDate] = dateRange || [];
    const formattedStartDate = startDate ? startDate.format("YYYY-MM-DD") : null;
    const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : null;
    console.log("formattedStartDate",formattedStartDate,"formattedEndDate", formattedEndDate)
    const response = await obtenerFacturasFecha(accessToken, selectedInmueble, formattedStartDate, formattedEndDate);
    
    if (response.error) {
      setPdfs([]);
    } else if (response && response.archivos) {
      setPdfs(response.archivos);
    }
  };

  const handleDeletePdf = async (pdfName) => {
    try {
      await eliminarFacturaAsociada(accessToken, selectedInmueble, pdfName);
      handleSearch();  // Actualiza la lista tras eliminar un PDF
    } catch (error) {
      console.error("Error al eliminar archivo PDF:", error);
    }
  };

  const handleViewPdf = async (pdfName) => {
    try {
      const pdfUrl = await verFacturas(accessToken, selectedInmueble, pdfName);
      setCurrentPdfUrl(pdfUrl);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error al visualizar el archivo PDF:", error);
    }
  };

  return (
    <div>
      <Select
        placeholder="Selecciona un inmueble"
        style={{ width: 200 }}
        onChange={handleInmuebleChange}
      >
        {inmuebles.map((inmueble) => (
          <Select.Option key={inmueble._id} value={inmueble._id}>
            {inmueble.name}
          </Select.Option>
        ))}
      </Select>

      {/* Componente RangePicker para seleccionar el rango de fechas */}
      <RangePicker
        format="YYYY-MM-DD"
        onChange={handleDateChange}
      />

      <Button type="primary" onClick={handleSearch}>Buscar</Button>

      {/* Lista de PDFs */}
      <List
        itemLayout="horizontal"
        dataSource={pdfs}
        renderItem={(pdfName) => (
          <List.Item
            actions={[
              jwt_decode(accessToken).role === "Administrador" && (
                <Button
                  type="primary"
                  onClick={() => handleDeletePdf(pdfName)}
                >
                  <DeleteOutlined />
                </Button>
              ),
              <Button
                type="primary"
                onClick={() => {
                  handleViewPdf(pdfName);
                }}
              >
                Ver <EyeOutlined />
              </Button>
            ]}
          >
            <Card>
              <Card.Meta title={`Archivo: ${pdfName}`} />
            </Card>
          </List.Item>
        )}
      />

      {/* Modal para visualizar el PDF */}
      <Modal
        title="Visualización de PDF"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="80%"
      >
        <iframe
          src={currentPdfUrl}
          style={{ width: '100%', height: '800px' }}
          frameBorder="0"
        />
      </Modal>
    </div>
  );
}
