import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Checkbox, InputNumber, Select, List } from 'antd';

const { Option } = Select;

const InventoryList = ({ initialData, onInventoryChange }) => {
  const [inventoryData, setInventoryData] = useState(initialData ?? {});
  const [currentItem, setCurrentItem] = useState({ name: '', type: 'checkbox', value: null });

  useEffect(() => {
    if (onInventoryChange) {
      onInventoryChange(inventoryData);
    }
  }, [inventoryData]);

  useEffect(() => {
    setInventoryData(initialData);
  }, [initialData]);

  const handleAddItem = () => {
    if (currentItem.name) {
      const newItems = { ...inventoryData, [currentItem.name]: { type: currentItem.type, value: currentItem.value || false } };
      setInventoryData(newItems);
      setCurrentItem({ name: '', type: 'checkbox', value: null });
    }
  };

  const handleDeleteItem = (itemName) => {
    const { [itemName]: _, ...newItems } = inventoryData;
    setInventoryData(newItems);
  };

  const handleTypeChange = value => {
    setCurrentItem({ ...currentItem, type: value, value: value === 'checkbox' ? false : 0 });
  };

  const handleInputChange = (e) => {
    setCurrentItem({ ...currentItem, name: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setCurrentItem({ ...currentItem, value: e.target.checked });
  };

  const handleNumberChange = (value) => {
    setCurrentItem({ ...currentItem, value });
  };

  return (
    <div>
      <Row>
        <Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Nombre del ítem
        </Col>
        <Col span={12}>
          <Input
            placeholder="Nombre del ítem"
            value={currentItem.name}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Tipo del ítem
        </Col>
        <Col span={12}>
          <Select style={{ width: '100%' }} value={currentItem.type} onChange={handleTypeChange}>
            <Option value="checkbox">Checkbox</Option>
            <Option value="number">Number</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Valor del ítem
        </Col>
        <Col span={12}>
          {currentItem.type === 'checkbox' ? (
            <Checkbox checked={currentItem.value} onChange={handleCheckboxChange}>
              Check
            </Checkbox>
          ) : (
            <InputNumber min={0} value={currentItem.value} onChange={handleNumberChange} style={{ width: '100%' }} />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}>
          <Button type="primary" onClick={handleAddItem}>
            Añadir ítem
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginTop: '16px' }}>
          <List
            bordered
            dataSource={Object.keys(inventoryData??{})}
            renderItem={key => (
              <List.Item
                actions={[<Button type="link" onClick={() => handleDeleteItem(key)}>Borrar</Button>]}
              >
                {key} - {inventoryData[key].type === 'checkbox' ? (inventoryData[key].value ? 'Checked' : 'Unchecked') : inventoryData[key].value}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default InventoryList;
