import React, { useState, useEffect } from "react";
import { Spin, Button } from "antd";

export default function Boton_peticion_handler(props) {
  const { isLoading, cuerpo, handler, style } = props;

  useEffect(() => {}, [isLoading]);

  const content = isLoading ? (
    <Spin />
  ) : (
    <Button
      onClick={handler}
      type="primary"
      label="Abrir enlace en nueva pestaña"
      style={style}
    >
      {cuerpo}
    </Button>
  );

  return content;
}
