import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Row, Col, notification } from "antd";
import { createUser } from "../../../../api/user";
import { getAccessTokenApi } from "../../../../api/auth";
import { obtenerListaInmuebles } from "../../../../api/inmueble";
import "./AddUserForm.scss";
import DesplegableMultiple from "../../../DesplegableMultiple";

export default function EditUserForm(props) {
  const { setIsVisibleModal, setReloadUsers } = props;
  const [userData, setUserData] = useState({});

  const addUser = (event) => {
    //console.log("addUser");
    // event.preventDefault();
    //console.log("aDDUSER USERDATA", userData);
    if (
      !userData.name ||
      !userData.lastname ||
      !userData.role ||
      !userData.email ||
      !userData.password ||
      !userData.repeatPassword
    ) {
      notification["error"]({
        message: "Todos los campos son obligatorios.",
      });
    } else if (userData.password !== userData.repeatPassword) {
      notification["error"]({
        message: "Las contraseñas tienen que ser iguale.",
      });
    } else {
      const accesToken = getAccessTokenApi();
      //console.log(userData);
      createUser(accesToken, userData)
        .then((response) => {
          if (response.status !== 201 || response.status !== 200) {
            notification["success"]({
              message: "Usuario Cread",
            });
            setIsVisibleModal(false);
            setReloadUsers(true);
            setUserData({});
          } else {
            notification["error"]({
              message: "Error!",
            });
            setIsVisibleModal(false);
            setReloadUsers(true);
            setUserData({});
          }
        })
        .catch((err) => {
          //console.log("err", err);
          notification["error"]({
            message: "Error: " + err.error,
          });
          //console.log("Fin err");
        });
    }
  };

  return (
    <div className="add-user-form">
      <AddForm
        userData={userData}
        setUserData={setUserData}
        addUser={addUser}
      />
    </div>
  );
}

function AddForm(props) {
  const { userData, setUserData, addUser } = props;
  const { Option } = Select;
  var nombres_pisos_a = [];
  var ids_pisos_a = {};
  const [nombres_pisos, setNombres_pisos] = useState([]);
  const [id_nombres_pisos, setIdNombres_pisos] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  useEffect(() => {
    const accessToken = getAccessTokenApi();
    //console.log("AddForm1");
    obtenerListaInmuebles(accessToken).then((response) => {
      //console.log(response.Pisos[0].id);
      //console.log("obtenerListaInmuebles", response.Pisos);
      for (let i = 0; i < response.Pisos.length; i++) {
        nombres_pisos_a.push(response.Pisos[i].nombre);
        ids_pisos_a[response.Pisos[i].nombre] = response.Pisos[i].id;
      }
      setNombres_pisos(nombres_pisos_a);
      setIdNombres_pisos(ids_pisos_a);
    });
  }, []);
  return (
    <Form className="form-add" onFinish={addUser}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="user" />}
              placeholder="Nombre"
              value={userData.name}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="user" />}
              placeholder="Apellidos"
              value={userData.lastname}
              onChange={(e) =>
                setUserData({ ...userData, lastname: e.target.value })
              }
            />
          </Form.Item>
        </Col>

      </Row>
      <Row>
      <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Telefono"
              value={userData.telefono}
              onChange={(e) =>
                setUserData({ ...userData, telefono: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Correlo electronico"
              value={userData.email}
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Select
              placeholder="Seleciona un rol"
              onChange={(e) => setUserData({ ...userData, role: e })}
              value={userData.role}
            >
              <Option value="Administrador">Administrador</Option>
              <Option value="Gestor">Gestor</Option>
              <Option value="Limpiador">Limpiador</Option>
              <Option value="Repartidor">Repartidor</Option>
              <Option value="Mantenimiento">Mantenimiento</Option>
              <Option value="Propietario">Propietario</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {userData.role === "Propietario" ? (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <DesplegableMultiple
                valores={nombres_pisos}
                onChange={(values) => {
                  //console.log(values);
                  console.log(
                    "pisos",
                    values.map((x) => {
                      return id_nombres_pisos[x];
                    })
                  );
                  setSelectedValues(values);
                  setUserData({
                    ...userData,
                    pisos_asociados: values.map((x) => {
                      return id_nombres_pisos[x];
                    }),
                  });
                  //console.log(userData);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : userData.role === "Gestor" ? (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <DesplegableMultiple
                valores={nombres_pisos}
                onChange={(values) => {
                  setSelectedValues(values);
                  console.log(
                    "pisos",
                    values.map((x) => {
                      return id_nombres_pisos[x];
                    })
                  );
                  setUserData({
                    ...userData,
                    pisos_asociados: values.map((x) => {
                      return id_nombres_pisos[x];
                    }),
                  });

                  //console.log(userData);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : userData.role === "Limpiador" ? (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <DesplegableMultiple
                valores={nombres_pisos}
                onChange={(values) => {
                  setSelectedValues(values);
                  console.log(
                    "pisos",
                    values.map((x) => {
                      return id_nombres_pisos[x];
                    })
                  );
                  setUserData({
                    ...userData,
                    pisos_asociados: values.map((x) => {
                      return id_nombres_pisos[x] ?? "";
                    }),
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : userData.role === "Repartidor" ? (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <DesplegableMultiple
                valores={nombres_pisos}
                onChange={(values) => {
                  setSelectedValues(values);
                  console.log(
                    "pisos",
                    values.map((x) => {
                      return id_nombres_pisos[x];
                    })
                  );
                  setUserData({
                    ...userData,
                    pisos_asociados: values.map((x) => {
                      return id_nombres_pisos[x] ?? "";
                    }),
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        ): userData.role === "Mantenimiento" ? (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item>
                <DesplegableMultiple
                  valores={nombres_pisos}
                  onChange={(values) => {
                    setSelectedValues(values);
                    console.log(
                      "pisos",
                      values.map((x) => {
                        return id_nombres_pisos[x];
                      })
                    );
                    setUserData({
                      ...userData,
                      pisos_asociados: values.map((x) => {
                        return id_nombres_pisos[x] ?? "";
                      }),
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          ): (
        ""
      )}

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="lock" />}
              type="password"
              placeholder="Contraseña"
              value={userData.password}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="lock" />}
              type="password"
              placeholder="Repetir contraseña"
              value={userData.repeatPassword}
              onChange={(e) =>
                setUserData({ ...userData, repeatPassword: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-submit">
          Crear Usuario 2
        </Button>
      </Form.Item>
    </Form>
  );
}
