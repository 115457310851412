import React, { useState, useEffect } from 'react';
import { Col, Table, Modal, Button, Space, notification, Spin, DatePicker, Row, Collapse } from 'antd';
import IncidenciaForm from './IncidenciaForm';
import { getAllIncidencias, deleteIncidencia, getIncidenciasMesYear } from '../../../../src/api/incidencia';
import { obtenerListaInmuebles } from '../../../api/inmueble';
import { getAccessTokenApi } from '../../../api/auth';
import { getUsersByRoleApi } from '../../../api/user';
import IncidenciaEdit from './IncidenciaEdit';
import IncidenciaEditVistaMantenimiento from './Incidencia_Por_Mantenimiento/IncidenciaEditVistaMantenimiento';
import { getProximaFechaDisponiblev1 } from '../../../api/inquilino';
import moment from "moment";
import dayjs from "dayjs";
import Boton_peticion_handler from '../../../components/Admin/boton_peticion_handler/boton_peticion_handler';
import Boton_peticion_handler_v2 from '../../../components/Admin/boton_peticion_handler_v2/boton_peticion_handler_v2';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';


const mesActual = dayjs(); // Obtiene la fecha actual con dayjs
 
const IncidenciasPage = ({inmueble, mes, year, estados, cargar_segun_mes=false}) => {
  const [incidencias, setIncidencias] = useState([]);
  const [lista_pisos, setlista_pisos] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false);
  const [elemento_editar_selected, setelemento_editar_selected] = useState({});
  const [filteredValue, setFilteredValue] = useState(estados??['no_asignado', 'pendiente', 'por_iniciar']);
  const [filteredValuePisos, setFilteredValuePisos] = useState(inmueble??[]);
  const [numero_incidencias, setnumero_incidencias] = useState(0);
  const [data_inmuebles, set_data_inmuebles] = useState([]);
  const [monthQuery, setMonthQuery] = useState(mes ?? mesActual.month());
  const [yearQuery, setYearQuery] = useState(year ?? mesActual.year());

  const [fechasDisponibles, setFechasDisponibles] = useState({});

  function onChange(date, dateString) {
    setMonthQuery(date.month()+1);
    setYearQuery(date.year());
    //console.log(monthQuery, dateString);
    //console.log(yearQuery, dateString);
  }
  const handleChange = (pagination, filters, sorter) => {
    console.log("Mis filtros",filters)
    setFilteredValue(filters.estado);
    setFilteredValuePisos(filters.id_apartamento)

  };

  function handleSearchClick(){
    getIncidenciasMesYear(
      getAccessTokenApi(),

      monthQuery,
      yearQuery
    ).then((response) => {
      if(Array.isArray(response) ){
        setIncidencias(response)
      setnumero_incidencias(response.length) 
      notification["success"]({
        message: "Incidencias encontradas (Resueltas + No resueltas): " +  response.length
      });
      }else{
        setIncidencias([])
        setnumero_incidencias(0) 
        notification["error"]({
          message: "No hay incidencias en esta fecha"
        });
      }
     
    });
  }

  const [reload, setReload] = useState(false);
  const [lista_mantenimiento, set_lista_mantenimiento] = useState({});
  useEffect(()=>{    
    setnumero_incidencias(
      data_inmuebles?.filter(
        item => item.estado && 
        typeof item.estado === 'string' && 
        filteredValue?.includes(item.estado) &&
        filteredValuePisos?.includes(item.id_apartamento)
).length)


},[filteredValue, filteredValuePisos])

  useEffect(()=>{console.log("filteredValue",filteredValue)},[filteredValue,lista_mantenimiento, elemento_editar_selected])
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const data = await getAllIncidencias(getAccessTokenApi());
        console.log("data.message: ",data.message)
        console.log("filteredValues", filteredValue, filteredValuePisos)
        set_data_inmuebles(data.message)
        if (Array.isArray(data.message) && data.message.every(item => typeof item === 'object')) {
          setIncidencias(data.message);
        } else {
          console.error('El resultado de getAllIncidencias() no es un array de objetos:', data);
        }
      } catch (error) {
        console.error('Error al obtener las incidencias:', error);
      }
    };
    if(cargar_segun_mes){
      handleSearchClick();
      setReload(false);
    }else{
      fetchData();
      setReload(false)
    }
    
  }, [reload]);
  useEffect(() => {
    const fetchFechasDisponibles = async () => {
      try {
        const accesToken = getAccessTokenApi();
        const fechasDisponibles_aux = {};
  
        const fechasDisponiblesPromises = incidencias.map(async (incidencia) => {
          const fechaDisponible = await getProximaFechaDisponiblev1(accesToken, moment().format("YYYY-MM-DD"), incidencia.id_apartamento);
          fechasDisponibles_aux[incidencia.id_apartamento] = fechaDisponible;
        });
  
        await Promise.all(fechasDisponiblesPromises);
  
        setFechasDisponibles(fechasDisponibles_aux);
      } catch (error) {
        console.error('Error al obtener las fechas disponibles:', error);
      }
    };
  
    fetchFechasDisponibles();
  }, [incidencias]);
  
  
  useEffect(() => {
    const obtenerMantenimiento = async () => {
        try {
            const response = await getUsersByRoleApi(getAccessTokenApi(), 'Mantenimiento');
            if (response && response.users) {
             
              let var_aux = {}
              response?.users.forEach(element => {
                var_aux[element.id] = element.name??""
              });
              set_lista_mantenimiento(var_aux)
            }
        } catch (error) {
            console.error("Error al obtener limpiadores:", error);
        }
    };
    obtenerMantenimiento();
}, []);
  const handleOpenModal = () => {
    setModalIsOpen(true);
  };
  
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const handleOpenModalEdit = (record) => {
    console.log(record)
    setelemento_editar_selected(record);
    setModalIsOpenEdit(true);
  };
  
  const handleCloseModalEdit = () => {
    setModalIsOpenEdit(false);
  };
  useEffect(()=>{
    setnumero_incidencias(
      data_inmuebles?.filter(
        item => item.estado && 
        typeof item.estado === 'string' &&
        filteredValue?.includes(item.estado) &&
        filteredValuePisos?.includes(item.id_apartamento)
  ).length)
  },[data_inmuebles])
  useEffect(()=>{},[numero_incidencias])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accesToken = getAccessTokenApi();
        const response = await obtenerListaInmuebles(accesToken);
        let var_aux = {}
        response?.Pisos.forEach(element => {
          var_aux[element.id] = element.nombre??""
        });
        setlista_pisos(var_aux);
      } catch (error) {
        console.error('Error al obtener la lista de inmuebles:', error);
      }
    };
  
    fetchData();
  }, []);
  const handleDeleteRow = (id, description_incidencia) => {
    Modal.confirm({
      title: "Eliminando usuario",
      content: `¿Estás seguro que quieres eliminar la incidencia: ${description_incidencia}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: async () => {
        try {
          const response = await deleteIncidencia(id, getAccessTokenApi());
          if (response.status === 200) {
            notification["success"]({
              message: "Incidencia eliminada con éxito"
            });
            setReload(true);
          } else {
            notification["error"]({
              message: "Error al eliminar la incidencia"
            });
            setReload(true);
          }
        } catch (error) {
          console.error('Error al eliminar la incidencia:', error);
          notification["error"]({
            message: "Error al eliminar la incidencia"
          });
        }
      },
    });
  };
  



  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: '_id',
    //   key: '_id',
    //   sorter: (a, b) => a._id.localeCompare(b._id),
    //   sortDirections: ['ascend', 'descend'],
    // },
    // {
    //   title: 'Piso',
    //   dataIndex: 'id_apartamento',
    //   key: 'id_apartamento',
    //   render: (id_apartamento) => lista_pisos[id_apartamento] ?? 'No disponible',
    //   sorter: (a, b) => a.id_apartamento.localeCompare(b.id_apartamento),
    //   sortDirections: ['ascend', 'descend'],
      
    // },
    {
      title: 'Piso',
      dataIndex: 'id_apartamento',
      key: 'id_apartamento',
      render: (id_apartamento) => lista_pisos[id_apartamento] ?? 'No disponible',
      // sorter: (a, b) => a.id_apartamento.localeCompare(b.id_apartamento),
      // sortDirections: ['ascend', 'descend'],
      filters: Object.entries(lista_pisos)?.map(([key, value]) => ({
        text: value,  // El nombre del piso a mostrar en el filtro
        value: key,   // El valor del filtro basado en la clave del objeto (id_apartamento)
      })),
      onFilter: (value, record) => {
        console.log("FILTRO", value.toString(), record.id_apartamento.toString()); // Verificación de los valores
        return record.id_apartamento.toString() === value.toString(); // Convertir a string para comparación
      },
      filteredValue: filteredValuePisos || null,
    },
    
    
    {
      title: 'Persona Asignada',
      dataIndex: 'persona_asignada',
      key: 'persona_asignada',
      render: (persona_asignada) => lista_mantenimiento[persona_asignada] ?? 'No disponible',
      sorter: (a, b) => a.persona_asignada.localeCompare(b.persona_asignada),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Descripción de la tarea',
      dataIndex: 'descripcion_tarea',
      key: 'descripcion_tarea',
      sorter: (a, b) => a.descripcion_tarea.localeCompare(b.descripcion_tarea),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Fechas Disponibles',
      dataIndex: 'id_apartamento',
      key: 'id_apartamento',
      // sorter: (a, b) => new Date(a.fechaDisponible) - new Date(b.fechaDisponible),
      // sortDirections: ['ascend', 'descend'],
      render: (id_apartamento) => {

        // Aquí puedes formatear la fecha como prefieras
        console.log(fechasDisponibles[id_apartamento])
        return <>
        {!fechasDisponibles[id_apartamento]?.proximaFechaSame && !fechasDisponibles[id_apartamento]?.proximaFecha ? 
       
        ( <Spin></Spin>)
        :
        (
          <>
          {fechasDisponibles[id_apartamento]?.proximaFechaSame ? moment(fechasDisponibles[id_apartamento]?.proximaFechaSame).format("YYYY-MM-DD"):"No hay fecha"}
          <br></br><br></br>
          <b>{fechasDisponibles[id_apartamento]?.proximaFecha ? moment(fechasDisponibles[id_apartamento]?.proximaFecha).format("YYYY-MM-DD") : "No hay reservas futuras"}</b>
          </>
        )
        }
         </>
        
      }
    },
    {
      title: 'Urgente',
      dataIndex: 'urgente',
      key: 'urgente',
      render: (urgente) => (urgente ? 'Sí' : 'No'),
      filters: [
        { text: 'Sí', value: true },
        { text: 'No', value: false },
      ],
      onFilter: (value, record) => record.urgente === value,
    },
    {
      title: 'Nivel de Prioridad',
      dataIndex: 'nivel_prioridad',
      key: 'nivel_prioridad',
      sorter: (a, b) => a.nivel_prioridad.localeCompare(b.nivel_prioridad),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Asignado',
      dataIndex: 'asignado',
      key: 'asignado',
      render: (asignado) => (asignado ? 'Sí' : 'No'),
      filters: [
        { text: 'Sí', value: true },
        { text: 'No', value: false },
      ],
      onFilter: (value, record) => record.asignado === value,
    },
    {
      title: 'Precio servicio',
      dataIndex: 'precio_venta_publico',
      key: 'precio_venta_publico',
      sorter: (a, b) => new Date(a.precio_venta_publico) - new Date(b.precio_venta_publico),
      sortDirections: ['ascend', 'descend'],
      // render: (asignado) => (asignado ? 'Sí' : 'No'),
      // filters: [
      //   { text: 'Sí', value: true },
      //   { text: 'No', value: false },
      // ],
      // onFilter: (value, record) => record.asignado === value,
    },
    {
      title: 'Coste Materiales',
      dataIndex: 'coste_materiales_usados_reparacion',
      key: 'coste_materiales_usados_reparacion',
      sorter: (a, b) => new Date(a.coste_materiales_usados_reparacion) - new Date(b.coste_materiales_usados_reparacion),
      sortDirections: ['ascend', 'descend'],
      // render: (asignado) => (asignado ? 'Sí' : 'No'),
      // filters: [
      //   { text: 'Sí', value: true },
      //   { text: 'No', value: false },
      // ],
      // onFilter: (value, record) => record.asignado === value,
    },

    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      filters: [
        { text: 'No asignado', value: "no_asignado" },
        { text: 'Pendiente', value: "pendiente" },
        { text: 'por_iniciar', value: "por_iniciar" },
        { text: 'Resuelto', value: "resuelto" },
      ],
      onFilter: (value, record) => record.estado === value,
      // Aplicamos el valor filtrado desde el estado
      filteredValue: filteredValue || null,

    },
    {
      title: 'Fechas de Creación',
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      sorter: (a, b) => new Date(a.fecha_creacion) - new Date(b.fecha_creacion),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        // Formatea la fecha en el formato que desees, por ejemplo:
        return new Date(text).toLocaleDateString(); // Esto mostrará la fecha en formato 'DD/MM/AAAA'
      }
    },
    {
      title: 'Fechas de Resolución',
      dataIndex: 'fecha_resolucion',
      key: 'fecha_resolucion',
      sorter: (a, b) => new Date(a.fecha_resolucion) - new Date(b.fecha_resolucion),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        // Formatea la fecha en el formato que desees, por ejemplo:
        return new Date(text).toLocaleDateString(); // Esto mostrará la fecha en formato 'DD/MM/AAAA'
      }
    },
    
    
    {
      title: 'comentario_fin_tarea',
      dataIndex: 'comentario_fin_tarea',
      key: 'comentario_fin_tarea',
      sorter: (a, b) => new Date(a.comentario_fin_tarea) - new Date(b.comentario_fin_tarea),
      sortDirections: ['ascend', 'descend'],
    },
   
    {
      title: 'Acciones',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          {/* <Button type="danger" onClick={() => handleEditRow(record._id)}>Editar</Button> */}
          
          <Button type="primary" onClick={()=>handleOpenModalEdit(record)}>Editar</Button>
          <Button type="danger" onClick={() => handleDeleteRow(record._id, record.descripcion_tarea)}>Eliminar</Button>
        </Space>
      ),
    },
  ];
  
  return (
    <div>
      <h2>Incidencias: {numero_incidencias}</h2>
      
      <Row gutter={24}>
      <Collapse >
      <Collapse.Panel header = "Buscar por fechas">
          <DatePicker
              onChange={onChange}
              picker="month"
              defaultValue={mesActual}
            />
          <Boton_peticion_handler_v2 cuerpo={"Buscar por mes"} handler={handleSearchClick} />
          </Collapse.Panel>
          </Collapse>

      </Row>
      <Row gutter={24} style={{justifyContent:'right'}}><Button type="primary" onClick={handleOpenModal}>Añadir Incidencia</Button>
      </Row>
      
            
      <Table 
        dataSource={incidencias??[]} 
        columns={columns??[]} 
        rowKey="_id" 
        onChange={handleChange}
        
      />
       <Modal visible={modalIsOpen} onCancel={handleCloseModal} footer={null}>
        <IncidenciaForm setModalIsOpen={setModalIsOpen} setReload={setReload} />
        <Button onClick={handleCloseModal}>Cerrar</Button>
      </Modal>
      <Modal visible={modalIsOpenEdit} onCancel={handleCloseModalEdit} footer={null}>
        <IncidenciaEdit setModalIsOpen={setModalIsOpenEdit} setReload={setReload} data={elemento_editar_selected} />
        <Button onClick={handleCloseModalEdit}>Cerrar</Button>
      </Modal>
    </div>
  );
};

export default IncidenciasPage;
