import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { getGastosApi, getGastosRecurrentesApi } from "../../../api/gastos";
import moment from "moment";
import ListGastos from "../../../components/Admin/Users/Gastos/ListGasto";
import "./Gastos.scss";

export default function Gastos(props) {
  const { fecha_inicio, fecha_fin,  visible = true, buscar=true  } = props;
  const [usersActive, setUsersActive] = useState([]);
  const [usersInactive, setUsersInactive] = useState([]);
  const [numeroInmuebles, setNumeroInmuebles] = useState(0);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [gastosActualizados, setGastosActualizados] = useState(0);
  const [fechas_busqueda, setFechasBusqueda] = useState([
    fecha_inicio || moment().subtract(30, "days"),
    fecha_fin || moment(),
  ]);
  const [isListVisible, setIsListVisible] = useState(visible); // Estado para controlar la visibilidad de la lista de gastos

  const token = getAccessTokenApi();
  
  useEffect(() => {
    //console.log("Gastos fecha_inicio ",fecha_inicio);
    //console.log("Gastos fecha_fin ",fecha_fin);

    getGastosApi(token, fechas_busqueda).then((response) => {
      setUsersActive(response.gastos);
      setNumeroInmuebles(response.gastos.length);
      const sumaGastosEmpresa = response.gastos.reduce((total, gasto) => {
        return total + parseFloat(gasto.gasto_empresa);
      }, 0);
      setGastosActualizados(sumaGastosEmpresa);
    });

    getGastosRecurrentesApi(token, fechas_busqueda).then((response) => {
      setUsersInactive(response.gastos);
    });

    setReloadUsers(false);
  }, [token, reloadUsers]);

  // Función para alternar la visibilidad de la lista de gastos
  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  return (
    <div className="users">
      <b>
        <span>Numero gastos registrados: {numeroInmuebles}</span>
        <br />
        <span>Suma de gastos: {gastosActualizados}euros</span>
        
      </b>
      <br />
      <button onClick={toggleListVisibility}>Desplegar Lista de Gastos</button>
      <br />

      {/* Botón clickable para alternar la visibilidad de la lista de gastos */}
      {isListVisible?
      <>
     
    
      <br></br>
      {/* Mostrar la lista de gastos solo si isListVisible es true */}
      {isListVisible && (
        <ListGastos
        buscar_visible = {buscar}
          usersActive={usersActive}
          usersInactive={usersInactive}
          setReloadUsers={setReloadUsers}
          fechas_busqueda={fechas_busqueda}
          setFechasBusqueda={setFechasBusqueda}
        />
      )}</>
      :<></>}
    </div>
  );
}
