import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../../api/auth";
import { eliminar_inquilino_ha_pedido_factura, obtener_inquilinos_a_los_que_generar_factura } from "../../../../api/inquilino";
import { Card, Col, Collapse, Button, Modal, Row, Typography, Divider, notification, Empty  } from "antd";
import { getInmuebleDatosFacturacion, getInmuebleTipoGestion, obtenerListaInmuebles } from "../../../../api/inmueble";
import { getSeriesInmueble } from "../../../../api/series";
import { getFacturacionDatos } from "../../../../api/facturacion";
import { DeleteOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Panel } = Collapse;

export default function FacturasClientesConcretos({inmueble}) {
  const [inquilinos, setInquilinos] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);


 
  const [nombres_pisos, setNombres_pisos] = useState([]);
  const [nombres_pisos_asoc, setnombres_pisos_asoc] = useState([]);
  const [id_nombres_pisos, setIdNombres_pisos] = useState({});
  const [nombres_id_pisos, setNombresIds_pisos] = useState({});
  const [defaultVal, setDefaultVal] = useState([]);
  useEffect(() => {
    var nombres_pisos_a = [];
    var ids_pisos_a = {};
    var pisos_ids_a = {};
    const accessToken = getAccessTokenApi();
    //console.log("AddForm1");
    obtenerListaInmuebles(accessToken).then((response) => {
      //console.log(response.Pisos[0].id);
      //console.log("obtenerListaInmuebles", response.Pisos);
      for (let i = 0; i < response.Pisos.length; i++) {
        nombres_pisos_a.push(response.Pisos[i].nombre);
        ids_pisos_a[response.Pisos[i].nombre] = response.Pisos[i].id;
        pisos_ids_a[response.Pisos[i].id] = response.Pisos[i].nombre;
      }

      let nombres = [];
      setNombres_pisos(nombres_pisos_a);
      setIdNombres_pisos(ids_pisos_a);
      setNombresIds_pisos(pisos_ids_a);
      setnombres_pisos_asoc(nombres);

      //console.log("nombres_pisos_a", nombres_pisos_a);
      //console.log("ids_pisos_a", ids_pisos_a);
      //console.log("pisos_ids_a:", pisos_ids_a);
      //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
    });
  }, []);



  useEffect(() => {
    const accesToken = getAccessTokenApi();
    obtener_inquilinos_a_los_que_generar_factura(accesToken).then((response) => {
      if (response && response.message === "Exito") {
        console.log("RESPONSEEEEEE", response)
        const inquilinosFiltrados = inmueble
        ? response.inquilinos.filter((inquilino) => inquilino.inmueble === inmueble)
        : response.inquilinos;
        setInquilinos(inquilinosFiltrados);
      }
    });
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showConfirm = (code, eliminar_inquilino_ha_pedido_factura) => {
    Modal.confirm({
      title: '¿Estás seguro que deseas eliminar este usuario?',
      content: 'Esta acción no se puede deshacer.',
      okText: 'Sí, eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        try {
          // Llamada para eliminar el inquilino
          const response = await eliminar_inquilino_ha_pedido_factura(getAccessTokenApi(), code);
  
          if (response.message) {
            notification["success"]({
              message: "Usuario eliminado correctamente."
            });
          } else {
            notification["error"]({
              message: "Problema eliminando usuario."
            });
          }
        } catch (error) {
          console.error("Error al eliminar el usuario:", error);
          notification.error({
            message: "Error al eliminar el usuario",
            description: "Hubo un error al intentar eliminar el usuario. Por favor, inténtelo de nuevo."
          });
        }
      },
    });
  };
  return (
    <div style={{ padding: '20px' }}>
      {inquilinos.length <1 ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh", flexDirection: "column" }}>
          <Empty description={<span>No hay inquilinos que hayan solicitado factura.</span>} />
          <p style={{ color: "#555", marginTop: "20px", textAlign: "center" }}>Revisa más tarde para ver si hay nuevas solicitudes.</p>
        </div>:
      <>
      <Row gutter={[16, 16]}>
        {inquilinos.map((inquilino) => (
          <Col xs={24} sm={12} md={8} lg={6} key={inquilino._id}>
            <Card 
              title={inquilino.name} 
              bordered={true}
              extra={[
                  
                  <Button
                    style={{ background: "pink" }}
                    onClick={async () => {
                      try {
                        // Obtener la serie del inmueble
                        const response = await getSeriesInmueble(getAccessTokenApi(), inquilino.inmueble);
                        let serie_a_enviar = "";
                        if (response.message) {
                          serie_a_enviar = response.message.serie_propietario_a_inquilino_pide_factura;
                        }

                        // Obtener el tipo de gestión del inmueble
                        const resp = await getInmuebleTipoGestion(getAccessTokenApi(), inquilino.inmueble);
                        console.log("RESP", resp)
                        if (resp.message) {
                          if (resp.tipo_gestion === "Subarriendo") {
                            const queryString = new URLSearchParams({
                              nombreEmisor: "DPD RENTAL MANAGEMENT SL",
                              nifEmisor: "B72879711",
                              direccionEmisor: "CAMINO FUENTE DE LA JUNQUERA 142",
                              cantidad: "1",
                              precioUd: Number((Number(inquilino.pago) / 1.1)).toFixed(2),
                              descripcionItem: `Reserva del ${inquilino.fecha_entrada} al ${inquilino.fecha_salida} en el inmueble ${nombres_id_pisos[inquilino.inmueble] ?? "***"}`,
                              nombreReceptor: inquilino.nombre_factura,
                              direccionReceptor: inquilino.direccion_factura,
                              nifReceptor: inquilino.nif_cif_factura,
                              taxPercentage: "10%",
                              taxNumber: Number(Number(inquilino.pago) - Number(inquilino.pago) / 1.1).toFixed(2),
                              inmueble: inquilino.inmueble,
                              serie_id: serie_a_enviar,
                              retencion: 0,
                              enviar_propietario: "",
                              correo_envio: inquilino.email_factura
                            }).toString();

                            // Abrir la URL en una nueva pestaña
                            const url = `/admin/facturas?${queryString}`;
                            window.open(url, '_blank');
                          } else if (resp.tipo_gestion === "Gestion_bruta") {
                            // Obtener datos adicionales de facturación
                            const datos_facturacion = await getInmuebleDatosFacturacion(getAccessTokenApi(), inquilino.inmueble);
                            console.log("datos_facturacion",datos_facturacion)
                            const queryString = new URLSearchParams({
                              nombreEmisor: datos_facturacion.datos.nombre_facturacion,
                              nifEmisor: datos_facturacion.datos.id_facturacion,
                              direccionEmisor: datos_facturacion.datos.direccion_facturacion,
                              cantidad: "1",
                              precioUd: Number(inquilino.pago).toFixed(2),
                              descripcionItem: `Reserva del ${inquilino.fecha_entrada} al ${inquilino.fecha_salida} en el inmueble ${nombres_id_pisos[inquilino.inmueble] ?? "***"}`,
                              nombreReceptor: inquilino.nombre_factura,
                              direccionReceptor: inquilino.direccion_factura,
                              nifReceptor: inquilino.nif_cif_factura,
                              taxPercentage: "0%",
                              taxNumber: 0,
                              inmueble: inquilino.inmueble,
                              serie_id: serie_a_enviar,
                              retencion: 0,
                              enviar_propietario: "",
                              correo_envio: inquilino.email_factura
                            }).toString();

                            // Abrir la URL en una nueva pestaña
                            const url = `/admin/facturas?${queryString}`;
                            window.open(url, '_blank');
                          }
                        }
                      } catch (error) {
                        console.error("Error al crear la factura:", error);
                        notification.error({
                          message: "Error al crear la factura",
                          description: "Hubo un error al intentar crear la factura. Por favor, inténtelo de nuevo."
                        });
                      }
                    }}
                  >
                    Crear Factura
                  </Button>,

                  <Divider type="vertical"></Divider>,

                  <Button
                    style={{ background: "red", color: "white" }}
                    onClick={() => showConfirm(inquilino.code, eliminar_inquilino_ha_pedido_factura)}
                  >
                    <DeleteOutlined />
                  </Button>
              ]
              }
            >
              <Divider>Información del Inquilino</Divider>
             
              <Text>
                <strong>Inmueble:</strong> {nombres_id_pisos[inquilino.inmueble] || "Inmueble no disponible"}
              </Text>
              <br />
              <Text><strong>Pago:</strong> {inquilino.pago}</Text>
              <br />
              <Text><strong>Plataforma:</strong> {inquilino.plataforma}</Text>
              <Divider>Datos para factura</Divider>
              <Text><strong>Nombre Facturación:</strong> {inquilino.nombre_factura}</Text>
              <br />
              <Text><strong>NIF/CIF Facturación:</strong> {inquilino.nif_cif_factura}</Text>
              <br />
              <Text><strong>Dirección Facturación:</strong> {inquilino.direccion_factura}</Text>
              <br />
              <Text><strong>Teléfono Facturación:</strong> {inquilino.telefono_contacto_factura}</Text>
              <br />
              <Text><strong>Email Facturación:</strong> {inquilino.email_factura}</Text>
              <Collapse style={{ marginTop: '10px' }}>
                <Panel header="Información Adicional" key="1">
                  <Text><strong>ID Avaibook:</strong> {inquilino.id_avaibook}</Text>
                  <br />
                  <Text><strong>Email:</strong> {inquilino.email}</Text>
                  <br />
                  <Text><strong>Teléfono:</strong> {inquilino.telefono}</Text>
                  <br />
                 
                  <Text><strong>Fecha de Entrada:</strong> {inquilino.fecha_entrada}</Text>
                  <br />
                  <Text><strong>Fecha de Salida:</strong> {inquilino.fecha_salida}</Text>
                  <br />
                  <Text><strong>Solicita Factura:</strong> {inquilino.solicita_factura ? "Sí" : "No"}</Text>
                  <br />
                  <Text><strong>Estado de Factura:</strong> {inquilino.enviada_la_factura ? "Enviada" : "No Enviada"}</Text>
                </Panel>
              </Collapse>
            </Card>
          </Col>
        ))}
      </Row>
      
      {/* Modal para crear facturas */}
      <Modal
        title="Crear Factura"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Aquí puedes agregar el formulario o funcionalidad para crear facturas.</p>
      </Modal>
      </>}
    </div>
  );
}
