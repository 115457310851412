import React from 'react';
import { Collapse, List, Typography } from 'antd';
import FacturacionMes from '../../FacturacionMes/FacturacionMes';
import dayjs from 'dayjs';
import moment from "moment"
const { Panel } = Collapse;
const { Text } = Typography;

const ReservasDuplicadas = ({ data, mes, year }) => {
  const { inquilinosDuplicados, numeroInquilinosDuplicados } = data || {};

// Obtener el mes y el año desde moment, o establecer un valor por defecto si no existen
const mesMoment = mes || moment().month(); // Si mes no existe, usa el mes actual
const yearMoment = year || moment().year(); // Si year no existe, usa el año actual

  const mesDayjs = dayjs().month(mesMoment).month() ; // 1-12, añade +1 para coincidir con el formato esperado
  const yearDayjs = dayjs().year(yearMoment).year(); // Año completo
  
  return (
    <Collapse>
      <Panel header={`Reservas DUPLICADAS (${numeroInquilinosDuplicados})`}>
        {inquilinosDuplicados && inquilinosDuplicados.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={inquilinosDuplicados}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  title={
                    <Text strong>Inmueble: {item.inmueble} </Text>
                  
                  }
                  description={
                    <div>
                     
                        <Text >
                      Inquilino: {item.inquilino1}
                    </Text>
                     
                      <br />
                      <Text>Duplicado con: {item.inquilino2}</Text>
                      <Collapse>
                      <Panel header={`Ver el duplicado`}>
                      <FacturacionMes mes={mesDayjs} year={yearDayjs} inmueble={item.inmueble_id} busqueda_automatica_al_inicio={true} />

                      </Panel>
                      </Collapse>
                    </div>
                  
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <Text>No hay inquilinos duplicados.</Text>
        )}
      </Panel>
    </Collapse>
  );
};

export default ReservasDuplicadas;
