import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { queryInquilinosApi, queryInquilinosApiDNI, queryInquilinosApiFecha, queryInquilinosApiFechaSegunEstado, queryInquilinosApiSinValorar, getInquilinosOneDayList, queryInquilinosApiInactivos, queryInquilinosApiInactivosFecha } from "../../../api/inquilino";
import ListInquilinos from "../../../components/Admin/Users/Listinquilinos";
import moment from "moment";
import "./inquilinos.scss";
import { Button, Col, DatePicker, Radio, Row, Tabs } from "antd";
import Buscador from "../../../components/Admin/buscador/buscador";

export default function Inquilinos() {
  const [usersActive, setUsersActive] = useState([]);
  const [usersInactive, setUsersInactive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [selectedValues2, setSelectedValues2] = useState(["Inquilino"]);
  const [radioButtonSelection, setRadioButtonSelection] = useState("entranHoy");
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY/MM/DD"));
  // const [searchDate, setSearchDate] = useState(moment().format("YYYY/MM/DD")); 

  const token = getAccessTokenApi();

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString); // Actualiza searchDate en lugar de selectedDate
  };

  const handleSearchClick = async () => {
    const fetchData = async () => {
      try {
        const token = getAccessTokenApi();
        // const resp = await getInquilinosOneDayList(selectedDate, accessToken);
        if (radioButtonSelection === "entranSalenHoy") {
          queryInquilinosApiFecha(token, true, selectedValues2, selectedDate).then(response => {
            setUsersActive(response.users);
          });
        } else if (radioButtonSelection === "entranHoy") {
          queryInquilinosApiFechaSegunEstado(token, true, selectedValues2, selectedDate, "entrada").then(response => {
            setUsersActive(response.users);
          });
        } else if (radioButtonSelection === "salenHoy") {
          queryInquilinosApiFechaSegunEstado(token, true, selectedValues2, selectedDate, "salida").then(response => {
            setUsersActive(response.users);
          });
        } else if (radioButtonSelection === "completoDnies") {
          queryInquilinosApiDNI(token, true, selectedValues2, "completo").then(response => {
            setUsersActive(response.users);
          });
        } else if (radioButtonSelection === "incompletoDnies") {
          queryInquilinosApiDNI(token, true, selectedValues2, "incompleto").then(response => {
            setUsersActive(response.users);
          });
        }else if (radioButtonSelection === "inactivos") {
          queryInquilinosApiInactivosFecha(token, false, selectedValues2,selectedDate).then(response => {
                setUsersActive(response.users ?? []);
              });
        }
        //console.log("UEEEE")

        // setUsersActive(resp.users);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Llamada a la función asíncrona
  };

  const onChangeRadio = e => {
    setRadioButtonSelection(e.target.value);
  };
  useEffect(() => {

  }, [usersActive, usersInactive]);

  // useEffect(() => {
  //   console.log("cambios");
  
  //   const fetchData = async () => {
  //     try {
  //       const token = getAccessTokenApi();
  
  //       let response;
  //       if (radioButtonSelection === "entranSalenHoy") {
  //         response = await queryInquilinosApiFecha(token, true, selectedValues2, selectedDate);
  //       } else if (radioButtonSelection === "entranHoy") {
  //         response = await queryInquilinosApiFechaSegunEstado(token, true, selectedValues2, selectedDate, "entrada");
  //       } else if (radioButtonSelection === "salenHoy") {
  //         response = await queryInquilinosApiFechaSegunEstado(token, true, selectedValues2, selectedDate, "salida");
  //       } else if (radioButtonSelection === "completoDnies") {
  //         response = await queryInquilinosApiDNI(token, true, selectedValues2, "completo");
  //       } else if (radioButtonSelection === "incompletoDnies") {
  //         response = await queryInquilinosApiDNI(token, true, selectedValues2, "incompleto");
  //       } else if (radioButtonSelection === "inactivos") {
  //         response = await queryInquilinosApiInactivosFecha(token, false, selectedValues2, selectedDate);
  //       }
  
  //       if (response && response.users) {
  //         setUsersActive(response.users);
  //       } else {
  //         setUsersActive([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  
  //   fetchData(); // Llamada a la función asíncrona
  //   console.log(reloadUsers);
  //   setReloadUsers(false);
  // }, [reloadUsers, radioButtonSelection, selectedValues2, selectedDate]);
  useEffect(() => {
    
  
    const fetchData = async () => {
      console.log("cambios Inquilinos");
      try {
        const token = getAccessTokenApi();
        let response;
  
        switch (radioButtonSelection) {
          case "entranSalenHoy":
            response = await queryInquilinosApiFecha(token, true, selectedValues2, selectedDate);
            break;
          case "entranHoy":
            response = await queryInquilinosApiFechaSegunEstado(token, true, selectedValues2, selectedDate, "entrada");
            break;
          case "salenHoy":
            response = await queryInquilinosApiFechaSegunEstado(token, true, selectedValues2, selectedDate, "salida");
            break;
          case "completoDnies":
            response = await queryInquilinosApiDNI(token, true, selectedValues2, "completo");
            break;
          case "incompletoDnies":
            response = await queryInquilinosApiDNI(token, true, selectedValues2, "incompleto");
            break;
          case "inactivos":
            response = await queryInquilinosApiInactivosFecha(token, false, selectedValues2, selectedDate);
            break;
          default:
            response = { users: [] };
            break;
        }
  
        if (response && response.users) {
          setUsersActive(response.users);
        } else {
          setUsersActive([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setReloadUsers(false);
      }
    };
  
    fetchData(); // Llamada a la función asíncrona
  
  }, [reloadUsers, radioButtonSelection, selectedValues2, selectedDate]);
  
  // useEffect(() => {
  //   if (radioButtonSelection === "activos") {
  //     queryInquilinosApi(token, true, selectedValues2).then(response => {
  //       setUsersActive(response.users);
  //     });
  //   } else if (radioButtonSelection === "inactivos") {
  //     queryInquilinosApi(token, false, selectedValues2).then(response => {
  //       setUsersActive(response.users);
  //     });
  //   } else if (radioButtonSelection === "pendiente") {
  //     queryInquilinosApiSinValorar(token, selectedValues2,moment().format("YYYY-MM-DD"), false).then(response => {
  //       setUsersActive(response.users);
  //     });
  //   } else if (radioButtonSelection === "entranSalenHoy") {
  //     queryInquilinosApiFecha(token, true, selectedValues2, moment().format("YYYY-MM-DD")).then(response => {
  //       setUsersActive(response.users);
  //     });
  //   } else if (radioButtonSelection === "entranHoy") {
  //     queryInquilinosApiFechaSegunEstado(token, true, selectedValues2, moment().format("YYYY-MM-DD"), "entrada").then(response => {
  //       setUsersActive(response.users);
  //     });
  //   } else if (radioButtonSelection === "salenHoy") {
  //     queryInquilinosApiFechaSegunEstado(token, true, selectedValues2, moment().format("YYYY-MM-DD"), "salida").then(response => {
  //       setUsersActive(response.users);
  //     });
  //   } else if (radioButtonSelection === "completoDnies") {
  //     queryInquilinosApiDNI(token, true, selectedValues2, "completo").then(response => {
  //       setUsersActive(response.users);
  //     });
  //   } else if (radioButtonSelection === "incompletoDnies") {
  //     queryInquilinosApiDNI(token, true, selectedValues2, "incompleto").then(response => {
  //       setUsersActive(response.users);
  //     });
  //   }
  //   setReloadUsers(false);
  // }, [token, reloadUsers, radioButtonSelection, selectedValues2]);

  return (
    <div className="users">
    <Tabs defaultActiveKey="inquilinos_fecha" onChange={() => {}}>
    <Tabs.TabPane tab="Inquilinos Fecha" key="inquilinos_fecha">
      <Row gutter={16}>
      <Col>
          <Button type="primary" onClick={handleSearchClick}>
            Buscar
          </Button>
        </Col>
        <Col>
          <DatePicker
            defaultValue={moment()} // Puedes establecer un valor predeterminado si lo necesitas
            format={'YYYY/MM/DD'}
            onChange={handleDateChange}
          />
        </Col>
        <Col>      <Radio.Group onChange={onChangeRadio} value={radioButtonSelection}>
        <Row>
          <Col span={6}>
            <Radio value="entranSalenHoy">Usuarios que entran y salen </Radio>
          </Col>
          <Col span={6}>
            <Radio value="entranHoy">Usuarios que entran </Radio>
          </Col>
          <Col span={6}>
            <Radio value="salenHoy">Usuarios que salen </Radio>
          </Col>
          {/* <Col span={6}>
            <Radio value="inactivos">Usuarios inactivos</Radio>
          </Col> */}
          {/* <Col span={6}>
            <Radio value="activos">Todos los usuarios activos</Radio>
          </Col>
          <Col span={6}>
            <Radio value="completoDnies">Dnies completados</Radio>
          </Col>
          <Col span={6}>
            <Radio value="incompletoDnies">Dnies incompletos</Radio>
          </Col>
         
          <Col span={6}>
            <Radio value="pendiente">Pendiente de valoración</Radio>
          </Col> */}
        </Row>
      </Radio.Group>
      </Col>

      </Row>
      
      <ListInquilinos
        usersActive={usersActive}
        usersInactive={usersInactive}
        setReloadUsers={setReloadUsers}
        setSelectedValues={setSelectedValues2}
        selectedValues2={selectedValues2}
        selectedDate={selectedDate}
      />
      </Tabs.TabPane>
      
     <Tabs.TabPane tab="Buscador" key="buscador">
        <Buscador></Buscador>

     </Tabs.TabPane>
     </Tabs>

    </div>
  );
}
