import React, { useState, useEffect } from "react";

import QRCode from "qrcode.react";
import {
  Switch,
  List,
  Avatar,
  Button,
  Modal as ModalAntd,
  notification,
  Calendar,
  DatePicker,
  Space,
  Row,
  Col,
  Divider,
  Tabs,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import dayjs from "dayjs";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  StopOutlined,
  CheckOutlined,
  CalendarOutlined,
  CommentOutlined,
  LineChartOutlined,
  QrcodeOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import NoAvatar from "../../../../../assets/img/png/no-avatar.png";
import Modal from "../../../../Modal";
import EditInmuebleForm from "../EditGastoForm";
import AddGastoForm from "../AddGastoForm";
import {
  getAvatarApi,
  updateInmuebleApi,
  getInmuebleTemperatura,
  deleteInmuebleApi,

  desactivateInmuebleApi,
} from "../../../../../api/inmueble";
import { getAccessTokenApi } from "../../../../../api/auth";

import "./ListGasto.scss";
import { deleteGastoApi, verGastos } from "../../../../../api/gastos";
import EditGastoForm from "../AddGastoForm/AddGastoForm";
import jwtDecode from "jwt-decode";
import { basePathFrontend } from "../../../../../api/config";

const { confirm } = ModalAntd;

export default function ListGasto(props) {
  const {
    usersActive,
    usersInactive,
    setReloadUsers,
    fechas_busqueda,
    setfechas_busqueda,
    buscar_visible = true
  } = props;
  const [viewUsersActives, setViewUsersActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const { RangePicker } = DatePicker;
  const accessToken = getAccessTokenApi();
  const addUserModal = () => {
    setIsVisibleModal(true);

    setModalTitle("Nuevo gasto");
    setModalContent(
      <AddGastoForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
  };
  const { TabPane } = Tabs;

  return (
    <div className="list-users">
      <div className="list-users__header">
        <div className="list-users__header-switch">
          {/* <Switch
            defaultChecked
            onChange={() => setViewUsersActives(!viewUsersActives)}
          />
          <span>
            {viewUsersActives ? "Inmuebles Activos" : "Inmuebles Inactivos"}
          </span> */}
          <RangePicker
            renderExtraFooter={() => "extra footer"}
            value={fechas_busqueda}
            onChange={(dates) => {
              //console.log("onChange", fechas_busqueda);
              setfechas_busqueda([
                moment(dates[0].format("YYYY-MM-DD")),
                moment(dates[1].format("YYYY-MM-DD")),
              ]);
            }}
          />
          <Divider type="vertical"></Divider>
          {buscar_visible ? 
          <Button
            type="primary"
            onClick={() => {
              setReloadUsers(true);
            }}
          >
            Buscar 
          </Button> : <></>
}
        </div>
        {jwtDecode(accessToken).role === "Administrador" && (
          <Button type="primary" onClick={addUserModal}>
            Nuevo gasto
          </Button>
        )}
      </div>
      <Tabs type="card">
      <TabPane tab={<span>Balance</span>} key="0">
          <UsersActive
            usersActive={usersActive}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setReloadUsers={setReloadUsers}
          />
        </TabPane>
      <TabPane tab={<span>Ingresos totales</span>} key="1">
          <UsersActive
            usersActive={usersActive}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setReloadUsers={setReloadUsers}
          />
        </TabPane>
        <TabPane tab={<span>Ingresos recurrentes</span>} key="2">
          <UsersActive
            usersActive={usersInactive}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setReloadUsers={setReloadUsers}
          />
        </TabPane>
        <TabPane tab={<span>Gastos totales</span>} key="3">
          <UsersActive
            usersActive={usersActive}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setReloadUsers={setReloadUsers}
          />
        </TabPane>
        <TabPane tab={<span>Gastos recurrentes</span>} key="4">
          <UsersActive
            usersActive={usersInactive}
            setIsVisibleModal={setIsVisibleModal}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setReloadUsers={setReloadUsers}
          />
        </TabPane>
      </Tabs>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function UsersActive(props) {
  const {
    usersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadUsers,
  } = props;

  const editUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.name ? user.name : "..."} ${user.lastname ? user.lastname : "..."
      }`
    );
    setModalContent(
      <EditInmuebleForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
  };
  const seeCalendar = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.name ? user.name : "..."} ${user.lastname ? user.lastname : "..."
      }`
    );

    setModalContent();
  };

  return (
    <List
      className="users-active"
      itemLayout="horizontal"
      dataSource={usersActive}
      renderItem={(user) => (
        <UserActive
          seeCalendar={seeCalendar}
          user={user}
          editUser={editUser}
          setReloadUsers={setReloadUsers}
          setIsVisibleModal={setIsVisibleModal}
          setModalContent={setModalContent}
        />
      )}
    />
  );
}

function UserActive(props) {
  const {
    user,
    editUser,
    setReloadUsers,
    seeCalendar,
    setModalContent,
    setIsVisibleModal,
  } = props;
  const accessToken = getAccessTokenApi();
  const [avatar, setAvatar] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");

  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const desactivateUser = () => {
    const accesToken = getAccessTokenApi();

    updateInmuebleApi(accesToken, user._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accesToken = getAccessTokenApi();

    confirm({
      title: "Eliminando usuario",
      content: `¿Estas seguro que quieres eliminar a ${user.nombre_del_gasto}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        // deleteInmuebleApi(accesToken, user._id)
        //   .then((response) => {
        //     notification["success"]({
        //       message: response,
        //     });
        //     setReloadUsers(true);
        //   })
        //   .catch((err) => {
        //     notification["error"]({
        //       message: err,
        //     });
        //   });
        deleteGastoApi(accesToken, user._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadUsers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const generarQR = (qrData, nombre_Piso, ssid, password) => {

    let url = basePathFrontend+"/limpieza/inmueble/" + qrData;

    const wifiInfo = `WIFI:T:WPA2;S:${ssid};P:${password};;`;
    const qrStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    setModalContent(
      <Col>
        <Divider>{nombre_Piso}</Divider>
        <Divider>Limpieza</Divider>

        <Row style={qrStyle}>
          <QRCode value={url} level="L" />
          {/* <QRCode value={url} size={256} level="L" /> */}
        </Row>
        <Divider>Wifi</Divider>
        <Row style={qrStyle}>
          <QRCode
            value={wifiInfo}
            icon="../../../../../assets/img/png/logo512-blanco.png"
          />
        </Row>
      </Col>

    );
    setIsVisibleModal(true);
  };


  const handleViewPdf = async (id, pdfName) => {
    try {

      const pdfUrl = await verGastos(accessToken, id, pdfName);
      setCurrentPdfUrl(pdfUrl);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error al visualizar el archivo PDF:", error);
    }
  };

  const showStatus = async () => {
    const accesToken = await getAccessTokenApi();

    const temperatura = await getInmuebleTemperatura(
      user.code,
      user.id_actuador_caldera,
      accesToken
    );

    //console.log("accesToken", accesToken);
    confirm({
      title: "Status de la casa",
      content: `Code: ${user.code}
      Dueño: ${user.owner}
      Temperatura: ${JSON.stringify(temperatura.Temperatura)}
      
      `,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteInmuebleApi(accesToken, user._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadUsers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };
  const { RangePicker } = DatePicker;
  return (
    <>
      <List.Item
        actions={[
          <span>{user.origen_gasto}</span>,
          <span>{user.frecuencia_gasto}</span>,
          <span>{user.id_inmueble_asociado}</span>,
        
          <span>{user.nombre_archivos}</span>,
          <span>{user.descripcion_del_gasto.slice(0, 35)}</span>,
          jwtDecode(accessToken).role === "Administrador" ? (
            <Button type="primary" onClick={() => editUser(user)}>
              <EditOutlined />
            </Button>
          ) : <></>,
      
          // Condición para mostrar botón de borrar si es administrador
          jwtDecode(accessToken).role === "Administrador" ? (
            <Button type="danger" onClick={showDeleteConfirm}>
              <DeleteOutlined />
            </Button>
          ) : <></>,
          <Button
            type="primary"
            onClick={() => {
              handleViewPdf(user._id, user.nombre_archivos);
            }}
          >
            Ver <EyeOutlined />
          </Button>
        ]}
      >
        <List.Item.Meta
          avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
          title={`
          ${user.fecha_creacion ? user.fecha_creacion : "..."} - 
          ${user.gasto_empresa ? user.gasto_empresa : "..."}€ - 
                ${user.nombre_del_gasto ? user.nombre_del_gasto : "..."} 
                
            `}
          description={user.email}
        />
      </List.Item>
      <Modal
        title="Visualización de PDF"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="80%"
      >
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          src={currentPdfUrl}
          style={{ width: '100%', height: '800px' }}
          frameBorder="0"
        />
      </Modal>
    </>
  );
}

function UsersInactive(props) {
  const {
    usersInactive,
    setModalTitle,
    setReloadUsers,
    seeCalendar,
    setModalContent,
    setIsVisibleModal,
  } = props;

  const editUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.nombre_del_gasto ? user.nombre_del_gasto : "..."} ${user.lastname ? user.lastname : "..."
      }`
    );
    setModalContent(
      <EditGastoForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
  };
  return (
    <List
      className="users-active"
      itemLayout="horizontal"
      dataSource={usersInactive}
      renderItem={(user) => (
        <UserInactive
          seeCalendar={seeCalendar}
          user={user}
          editUser={editUser}
          setReloadUsers={setReloadUsers}
          setIsVisibleModal={setIsVisibleModal}
          setModalContent={setModalContent}
        />
      )}
    />
  );
}

function UserInactive(props) {
  const {
    user,
    editUser,
    setReloadUsers,
    seeCalendar,
    setModalContent,
    setIsVisibleModal,
  } = props;
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const activateUser = () => {
    const accesToken = getAccessTokenApi();

    updateInmuebleApi(accesToken, user._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accesToken = getAccessTokenApi();

    confirm({
      title: "Eliminando usuario",
      content: `¿Estas seguro que quieres eliminar a ${user.name}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteGastoApi(accesToken, user._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadUsers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List.Item
      actions={[
        <Button type="primary" onClick={editUser}>
          <CheckOutlined />
        </Button>,
        <Button type="danger" onClick={showDeleteConfirm}>
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
        title={`
        ${user.fecha_creacion ? user.fecha_creacion : "..."} - 
        ${user.gasto_empresa ? user.gasto_empresa : "..."}€ - 
              ${user.nombre_del_gasto ? user.nombre_del_gasto : "..."} 
              
          `}
      />
    </List.Item>
  );
}
