import React, { useState, useEffect } from "react";
import { obtenerListaInmuebles, getPisoByIdApi, getInmueblesApi } from "../../../api/inmueble";
import { getAccessTokenApi } from "../../../api/auth";
import "antd/dist/antd.css";
import moment from 'moment';
import "./limpiezapordias.css";
import {
  DatePicker,
  Modal,
  Spin,
  notification
} from "antd";
import { abrirPuertaUsuarioApi, getInquilinosAsignados } from "../../../api/inquilino";
import AsignarLimpiador from "./Modal_Acciones/Asignar_limpiador";
import Limpiar from "./Modal_Acciones/Limpiar";
import jwtDecode from 'jwt-decode';
import { updateInmuebleApi } from "../../../api/inmueble";
import { getUsersByRoleApi } from "../../../api/user";
import VerInformacionLimpiador from "./Modal_Acciones/Ver_informacion_limpiador";
import { createStockPisoApi } from "../../../api/stockPiso";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
// Importar icono personalizado para los marcadores
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: icon,
  shadowUrl: iconShadow,
});


export default function LimpiezaPorDiasLimpiador() {
  const [data1, setdata1] = useState([]);
  const [nombres_id_pisos, setNombresIds_pisos] = useState({});
  const [pisos, setPisos] = useState([]);
  const accessToken = getAccessTokenApi();
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPiso, setCurrentPiso] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [idInquilino, setIdInquilino] = useState();
  const [limpiadores, setLimpiadores] = useState([]);
  const [loading, setloading] = useState(true);
  const [num_pisos, set_num_pisos] = useState(0);
const [pisos_asignados_ids, setpisos_asignados_ids] =useState({})
useEffect(() => {},[pisos,num_pisos,pisos,selectedDate,currentPiso,loading,pisos_asignados_ids]);
  const openModal = (piso, tipo) => {
    setCurrentPiso(piso);
    setModalType(tipo);
    setIsModalVisible(true);
  };
  const [inmuebles, setUsersActive] = useState([]);
  
  useEffect(() => {
      getInmueblesApi(getAccessTokenApi(), 1, true).then((response) => 
      {
        setUsersActive(response.Pisos);
        //console.log("Estos son los pisos: ", response.Pisos)
      
      });
   
  }, []);

  const [coordenadas, setCoordenadas] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const newCoordenadas = [];
      for (const element of inmuebles) {
        if (element.latitud) {
          // const temperaturaActual = (element.id_sensor_temperatura && element.id_sensor_temperatura != "" ) ? await verTemperaturaCaldera_aux(element.name, element.direccion_inmueble, element.id_sensor_temperatura) : "";
          //console.log("Pisos keys: ", pisos.keys)
          if( Object.keys(pisos_asignados_ids).includes(element._id)) {
            newCoordenadas.push({
              nombre: element.name,
              color: "rojo",
              punto: [element.latitud ?? 0, element.longitud ?? 0],
              direccion: element.direccion_inmueble,
              elemento_eventos: pisos_asignados_ids[element._id],
              dispositivo: element.id_electronica_portal ?? 0,
            });
          }
          
        }
      }
      setCoordenadas(newCoordenadas);
    };
    fetchData();
  }, [inmuebles,pisos_asignados_ids]);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        setloading(true);
        const accessToken = getAccessTokenApi();
        const fechaFormateada = moment(selectedDate).format("YYYY-MM-DD");
        const response = await getInquilinosAsignados(fechaFormateada, accessToken);
        //console.log("RESPUESTA: ", response?.inquilinos);
        
        const numPisos = response?.inquilinos ? Object.keys(response.inquilinos).length : 0;
        set_num_pisos(numPisos);
        obtenerPisosDeInquilinos(response);
        setloading(false);
      } catch (error) {
        //console.log(error);
        setloading(false);
      }
    };
    cargarDatos();
  }, [selectedDate]);
 
  useEffect(() => {
    const accessToken = getAccessTokenApi();
    const cargarLimpiadores = async () => {
      const response = await getUsersByRoleApi(accessToken, 'Limpiador');
      if (response) {
        setLimpiadores(response);
        //console.log("Devuelve el nombre de los limpiadores: ", response)
      }
    };
  
    cargarLimpiadores();
  }, []);
  

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };


  async function obtenerPisosDeInquilinos(inquilinos, filtroEstado) {
    let pisos = [];
    let ids_pisos_asignados_aux = {}
    
    for (let idInmueble in inquilinos.inquilinos) {
      try {
        if ((filtroEstado === "Limpio" && inquilinos.inquilinos[idInmueble].estado !== "Limpio") ||
          (filtroEstado !== "Limpio" && inquilinos.inquilinos[idInmueble].estado === "Limpio")) {
          continue;
        }

        //TODO: Hay que optimizar esta funcion
        let inmueble = await getPisoByIdApi(idInmueble, accessToken); //Devuelve el nombre del inmueble
        
        //console.log("_________________________",inquilinos.inquilinos[idInmueble].eventos)





        let eventosInquilino = inquilinos.inquilinos[idInmueble].eventos.map(entrada => ({
          hora: entrada.hora,
          tipo: entrada.tipo,
          idInquilino: entrada.id,
          nombre: entrada.nombre,
          comentario: inquilinos.inquilinos[idInmueble].comentario,
          hora_entrada_inquilinos : entrada.hora_entrada_inquilinos,
          numero_personas: entrada.numero_personas
        
        }));
        //console.log('Inquilinos top', eventosInquilino);
        pisos.push({
          inmueble: inmueble,
          eventos: eventosInquilino,
          inquilinoId: inquilinos.inquilinos[idInmueble].id,
          estado: inquilinos.inquilinos[idInmueble].estado,
          limpiadores : inquilinos.inquilinos[idInmueble].limpiadores,
          numero_personas : inquilinos.inquilinos[idInmueble].numero_personas,
          comentario : inquilinos.inquilinos[idInmueble].comentario,
         
          });
          
          ids_pisos_asignados_aux[idInmueble] = { // Asignar el id del piso como clave
            inmueble: inmueble,
            eventos: eventosInquilino,
            inquilinoId: inquilinos.inquilinos[idInmueble].id,
            estado: inquilinos.inquilinos[idInmueble].estado,
            limpiadores : inquilinos.inquilinos[idInmueble].limpiadores,
            numero_personas : inquilinos.inquilinos[idInmueble].numero_personas,
            comentario : inquilinos.inquilinos[idInmueble].comentario // Agregar las propiedades adicionales necesarias
        // Agregar otras propiedades asociadas al id del piso si es necesario
      };
        
      } catch (error) {
        console.error("Error al obtener el inmueble para el inquilino con ID:", idInmueble, error);
      }
    }
    //console.log("pisos",pisos)
    //console.log("ids_pisos_asignados_aux",ids_pisos_asignados_aux)
    setpisos_asignados_ids(ids_pisos_asignados_aux);
    setPisos(pisos);

  }

  const abrirPuerta = async (nombre, direccion, dispositivo) => {
    // Aquí puedes implementar la lógica para abrir la puerta utilizando el dispositivo
    //console.log("Abriendo la puerta de:", nombre, "en", direccion, "con el dispositivo", dispositivo);
    let resp = await abrirPuertaUsuarioApi( getAccessTokenApi(),dispositivo);
    if(resp.message){
      //console.log("Hola exito")
      notification["success"]({
        message: "Exito en abrir la puerta en " + nombre
      });
    }else{
      notification["error"]({
        message: "Error"
      });
    }
   
  };

  const handleLimpiarClick = async () => {
    try {
      const token = await getAccessTokenApi();
      const fechaFormateada = moment(selectedDate).format("YYYY-MM-DD");
      const response = await getInquilinosAsignados(fechaFormateada, token);
      obtenerPisosDeInquilinos(response, "NoLimpio");

    } catch (error) {
      console.error("Error en showPisos:", error);
    }
  };


  const renderItem = ({ item, coordenada }) => {
    const tieneSoloEntrar = item.eventos.every(evento => evento.tipo === 'entrada');
    if (tieneSoloEntrar) {
      return null; // No renderizar este piso si solo tiene eventos de tipo "entrar"
    }
    
    const eventoEntrada = item.eventos.find(evento => evento.tipo === 'entrada');
    const eventoSalida = item.eventos.find(evento => evento.tipo === 'salida');
    //console.log("eventoEntrada", eventoEntrada)
    //console.log("eventoSalida", eventoSalida)
    const numero_reserva = item.numero_personas;
    //console.log("QUE TRAES", item)
    const comentario = item.comentario;
    //console.log("ALERTA SOBRE EVENTO SALIDA", eventoSalida);
    const nombre_inquilino = eventoSalida.nombre;
    const hora_entrada = eventoEntrada?.hora_entrada_inquilinos ??"";
    const direccion_inmueble = item.inmueble.direccion_inmueble;
    //console.log("Numero de personas", item);

    return (
      <div className={`tarjeta-piso vista-limpiador`}>
        <h2>Piso: {item.inmueble.name}</h2>
        {direccion_inmueble && <b><p>Dirección: {direccion_inmueble} 

        
        <a href={`https://www.google.es/maps/dir//${item?.inmueble?.latitud},${item?.inmueble?.longitud}/@${item?.inmueble?.latitud},${item?.inmueble?.longitud},15z/data=!4m2!4m1!3e1?entry=ttu`}>
            ( Pincha aquí )
        </a>
             
        </p> 

  </b>}  
        <button className="button" onClick={() => abrirPuerta(coordenada.nombre, coordenada.direccion, coordenada.dispositivo)}>Abrir Puerta</button> 
          
        {<p>Codigo Limpieza Puerta Casa: <b>{item?.inmueble?.codigo_limpieza ?? "PREGUNTAR A ENCARGADO"}</b></p>}
        {<p>Codigo Turista Puerta Casa: <b>{item?.inmueble?.codigo_huespedes ?? "PREGUNTAR A ENCARGADO"}</b></p>}
        {eventoSalida && <p>Hora de salida: {eventoSalida.hora}</p>}
        {hora_entrada && <p>Hora de entrada: {hora_entrada}</p>}
        {<p>Nº personas entran: {eventoSalida.numero_personas ? eventoSalida.numero_personas :"Máximo de personas"}</p>}
        {<p>Nº habitaciones: {eventoSalida.numero_personas ? eventoSalida.numero_personas :"Máximo de personas"}</p>}
        {/* {comentario && <p>Comentario: {comentario}</p>} */}
        <div>
            <button className="boton-estilo" onClick={() => openModal(item, "limpiar")}>Limpieza realizada { }</button>
          <button className="boton-estilo" onClick={() => openModal(item, "informacion")}>Información</button>
        </div>
        <button onClick={()=>{
          window.open(
            "https://api.whatsapp.com/send?text=" +
            `Piso: ${item.inmueble.name} 
            %0d%0a%0d%0aDireccion: ${direccion_inmueble} 
            %0d%0a%0d%0aUbicación enlace: https://www.google.es/maps/dir//${item?.inmueble?.latitud},${item?.inmueble?.longitud}/@${item?.inmueble?.latitud},${item?.inmueble?.longitud},15z/data=!4m2!4m1!3e1?entry=ttu 
            %0d%0a%0d%0aCodigo Limpieza Puerta Casa: ${item?.inmueble?.codigo_limpieza ?? "PREGUNTAR A ENCARGADO"}
            %0d%0a%0d%0aCodigo Turista Puerta Casa: ${item?.inmueble?.codigo_huespedes ?? "PREGUNTAR A ENCARGADO"}
            %0d%0a%0d%0aNº personas entran: ${eventoSalida.numero_personas ? eventoSalida.numero_personas :"Máximo de personas"}
            %0d%0a%0d%0aHora de salida: ${eventoSalida.hora}
            %0d%0a%0d%0aHora de entrada: ${hora_entrada}
            %0d%0a%0d%0aComentario: ${comentario}
            `,
              
            "_blank"
        );
        
        }}>Compartir con Whatsapp</button>
      </div>
    );
  };
  const renderItem2 = ({ item, coordenada }) => {
    const tieneSoloEntrar = item.eventos.every(evento => evento.tipo === 'entrada');
    if (tieneSoloEntrar) {
      return null; // No renderizar este piso si solo tiene eventos de tipo "entrar"
    }
    
    const eventoEntrada = item.eventos.find(evento => evento.tipo === 'entrada');
    const eventoSalida = item.eventos.find(evento => evento.tipo === 'salida');
    console.log("eventoEntrada", eventoEntrada)
    //console.log("eventoSalida", eventoSalida)
    const numero_reserva = item.numero_personas;
    //console.log("QUE TRAES", item)
    const comentario = item.comentario;
    //console.log("ALERTA SOBRE EVENTO SALIDA", eventoSalida);
    const nombre_inquilino = eventoSalida.nombre;
    const hora_entrada = eventoSalida?.hora_entrada_inquilinos ??"-----";
    const direccion_inmueble = item.inmueble.direccion_inmueble;
    //console.log("Numero de personas", item);

    return (
      <div className={`tarjeta-piso vista-limpiador`}>
        <h2>Piso: {item.inmueble.name}</h2>
        {direccion_inmueble && <b><p>Dirección: {direccion_inmueble} 

        
        <a href={`https://www.google.es/maps/dir//${item?.inmueble?.latitud},${item?.inmueble?.longitud}/@${item?.inmueble?.latitud},${item?.inmueble?.longitud},15z/data=!4m2!4m1!3e1?entry=ttu`}>
            ( Pincha aquí )
        </a>
             
        </p> 

  </b>}  
        <button className="button" onClick={() => abrirPuerta(item.inmueble.name, direccion_inmueble, item?.inmueble?.id_electronica_portal)}>Abrir Puerta</button> 
          
        {<p>Codigo Limpieza Puerta Casa: <b>{item?.inmueble?.codigo_limpieza ?? "PREGUNTAR A ENCARGADO"}</b></p>}
        {<p>Codigo Turista Puerta Casa: <b>{item?.inmueble?.codigo_huespedes ?? "PREGUNTAR A ENCARGADO"}</b></p>}
        {eventoSalida && <p>Hora de salida: {eventoSalida.hora}</p>}
        {hora_entrada && <p>Hora de entrada nuevos huespedes:<b> {hora_entrada}</b></p>}
        {<p>Nº personas entran:{eventoSalida.numero_personas ? eventoSalida.numero_personas :"Máximo de personas"}</p>}
        {<p>Nº habitaciones: {item.inmueble.numero_habitaciones ?? "FALTA POR COMPLETAR"}</p>}
        {/* {comentario && <p>Comentario: {comentario}</p>} */}
        <div>
            <button className="boton-estilo" onClick={() => openModal(item, "limpiar")}>Limpieza realizada { }</button>
          <button className="boton-estilo" onClick={() => openModal(item, "informacion")}>Información</button>
        </div>
        <button onClick={()=>{
          window.open(
            "https://api.whatsapp.com/send?text=" +
            `Piso: ${item.inmueble.name} 
            %0d%0a%0d%0aDireccion: ${direccion_inmueble} 
            %0d%0a%0d%0aUbicación enlace: https://www.google.es/maps/dir//${item?.inmueble?.latitud},${item?.inmueble?.longitud}/@${item?.inmueble?.latitud},${item?.inmueble?.longitud},15z/data=!4m2!4m1!3e1?entry=ttu 
            %0d%0a%0d%0aCodigo Limpieza Puerta Casa: ${item?.inmueble?.codigo_limpieza ?? "PREGUNTAR A ENCARGADO"}
            %0d%0a%0d%0aCodigo Turista Puerta Casa: ${item?.inmueble?.codigo_huespedes ?? "PREGUNTAR A ENCARGADO"}
            %0d%0a%0d%0aNº personas entran: ${eventoSalida.numero_personas ? eventoSalida.numero_personas :"Máximo de personas"}
            %0d%0a%0d%0aHora de salida: ${eventoSalida.hora}
            %0d%0a%0d%0aHora de entrada: ${hora_entrada}
            %0d%0a%0d%0aComentario: ${comentario}
            `,
              
            "_blank"
        );
        
        }}>Compartir con Whatsapp</button>
      </div>
    );
  };
  return (
    <>
    <button className="boton-estilo" onClick={handleLimpiarClick}>Pisos a limpiar</button>
    <DatePicker
      defaultValue={moment()} 
      format={'YYYY/MM/DD'}
      onChange={handleDateChange}
    />


    <div>
      <h2>Pisos a limpiar en el día {selectedDate}</h2>
      <h3>Numero de pisos asignados: {num_pisos}</h3>

      {loading ? (
        <Spin size="large" />
      ) : (
        <>
            <MapContainer center={[41.658102, -0.874650]} zoom={13}  scrollWheelZoom={true} className="map-container">
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          


          {coordenadas.map((coordenada, index) => (
            <Marker position={coordenada.punto} key={index}>
              <Popup>
                <div>
                  {renderItem({ item: coordenada.elemento_eventos, estado: "", coordenada })}
                  </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
          {pisos.length === 0 ? (
            <h2>No hay pisos para limpiar este día</h2>
          ) : (
            pisos.map((item) => (
              <div key={item.inmueble._id}>
                {renderItem2({ item, estado: item.estado  })}
              </div>
            ))
          )}
        </>
      )}
    </div>

      <Modal
        title={`Información del Piso: ${currentPiso ? currentPiso.inmueble.name : ''}`}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {currentPiso && modalType === 'informacion' && (
          <div>
            <h3>Información del Piso: {currentPiso.inmueble.name}</h3>
            <VerInformacionLimpiador currentPiso={currentPiso} />
          </div>
        )}
        {currentPiso && modalType === 'limpiar' && (
          <div>
            <h3>Limpieza en: {currentPiso.inmueble.name}</h3>
            <Limpiar token={accessToken} currentPiso={currentPiso} updatePiso={updateInmuebleApi} setIsModalVisible={setIsModalVisible} crearGastoStock={createStockPisoApi} fecha= {selectedDate} piso = {currentPiso.inmueble.id}/>
          </div>
        )}
        

      </Modal>
    </>
  );
}
