import { basePath, apiVersion } from "./config";

export function getFacturacion(accessToken, id, month2, year2) {
  const url = `${basePath}/${apiVersion}/facturacion/${id}`;
  //console.log("getFacturacion dentro:", accessToken);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify({
      year: year2,
      month: month2,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFacturacionDatos(accessToken) {
  const url = `${basePath}/${apiVersion}/group_id/datos_facturacion`;
  // //console.log("getFacturacion dentro:", accessToken);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify({
    //   year: year2,
    //   month: month2,
    // }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}


export function getFacturacionTotalTablaReporte(accessToken, month2, year2) {
  const url = `${basePath}/${apiVersion}/facturacion_total_tabla_reporte`;
  //console.log("getFacturacion dentro:", accessToken);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify({
      year: year2,
      month: month2,
      fechas:["2023-12-1","2023-12-19"]
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function getFacturacionTotal(accessToken, month2, year2) {
  const url = `${basePath}/${apiVersion}/facturacion_total`;
  //console.log("getFacturacion dentro:", accessToken);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify({
      year: year2,
      month: month2,
      fechas:["2023-12-1","2023-12-19"]
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function getFacturacionTotalPorFechas(accessToken, month2, year2, fechas_busqueda) {
  const url = `${basePath}/${apiVersion}/facturacion_total_fechas`;
  //console.log("getFacturacion dentro:", accessToken);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify({
      year: year2,
      month: month2,
      // fechas:["2023-12-1","2023-12-19"]
      fechas: fechas_busqueda
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}