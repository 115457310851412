import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { getPreciosApi } from "../../../api/precios";
import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Radio,
  Space,
  Divider,
} from "antd";


import moment from "moment";
import Boton_peticion_handler from "../../../components/Admin/boton_peticion_handler/boton_peticion_handler";

const { Option } = Select;

export default function Precios() {
  const [preciosData, setpreciosData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [userData, setUserData] = useState({
    fecha_entrada: null,
    fecha_salida: null,
    no_adultos: 1, // Agregamos el estado para no_adultos
    ciudad : "Zaragoza"
  });

  useEffect(() => {
  }, [preciosData]);
  const { RangePicker } = DatePicker;
  // Función para extraer el valor numérico del precio
  
  const getNumeroPrecio = (precio) => {
    // Eliminar espacios al principio y al final de la cadena
    const precioTrimmed = precio.trim();
  
    // Verificar si el símbolo de la moneda es euro (€)
    if (precioTrimmed.includes("€")) {
      // Extraer solo el valor numérico y convertir la cadena a número
      return parseFloat(precioTrimmed.replace("€", "").trim()) || 0;
    } else if (precioTrimmed.includes("US$")) {
      // Eliminar el símbolo de dólar y convertir la cadena a número
      return parseFloat(precioTrimmed.replace("US$", "").replace(",", "")) || 0;
    } else {
      // Si no hay símbolo de moneda reconocido, asumir que es un número
      return parseFloat(precioTrimmed) || 0;
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = getAccessTokenApi();
      const response = await getPreciosApi(token, userData);
      setLoading(false);
      // Parsea la cadena JSON
      const preciosData = JSON.parse(response.precios);

      // Ordenar los precios por valor numérico del precio
      const sortedPrecios = preciosData.sort((a, b) =>
        getNumeroPrecio(a.precio) - getNumeroPrecio(b.precio)
      );

     //console.log(sortedPrecios);
      setpreciosData(sortedPrecios);
    } catch (error) {
      console.error("Error fetching precios data:", error);
    }
  };
  const numeros = Array.from({ length: 100 }, (_, index) => index + 1);
  const handleSelectChange = (value) => {
    // Actualizamos el estado de no_adultos cuando se selecciona un valor en el Select
    setUserData({
      ...userData,
      no_adultos: value,
    });
   
  };

  const handleSelectChange2 = (value) => {
    // Actualizamos el estado de no_adultos cuando se selecciona un valor en el Select
    setUserData({
      ...userData,
      ciudad: value,
    });
   
  };
  return (
    <div>
      <h2>Listado de Precios</h2>
      <RangePicker
            renderExtraFooter={() => "extra footer"}
            value={[
              userData.fecha_entrada ? moment(userData.fecha_entrada) : null,
              userData.fecha_salida ? moment(userData.fecha_salida) : null,
            ]}
            onChange={(dates) => {
              //console.log("onChange");
              //console.log(dates[0]);
              //console.log(dates[1]);
              setUserData({
                ...userData,
                fecha_entrada: dates[0].format("YYYY-MM-DD"),
                fecha_salida: dates[1].format("YYYY-MM-DD"),
              });
            }}
          />
          <br></br>
          Num adultos: 
          <Select
  defaultValue={1}
  style={{ width: 120 }}
  onChange={handleSelectChange}
>
  {[...Array(11)].map((_, index) => (
    <Option key={index + 1} value={index + 1}>
      {index + 1} Adulto{index !== 0 ? "s" : ""}
    </Option>
  ))}
</Select>
<Select
        defaultValue={"Zaragoza"}
        style={{ width: 120 }}
        onChange={handleSelectChange2}
      >
        <Option value={"Zaragoza"}>Zaragoza</Option>
        <Option value={"Cordoba"}>Cordoba</Option>
        <Option value={"Huesca"}>Huesca</Option>
        <Option value={"Castellon"}>Castellon</Option>
        {/* Agrega más opciones según tus necesidades */}
      </Select>
    <Boton_peticion_handler
          isLoading={loading}
          cuerpo="Buscar"
          handler={fetchData}
        />
         
      <table>
        <thead>
          <tr>
            <th>Número</th>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Tipo alojamiento</th>
            <th>Puntuacion</th>
          </tr>
        </thead>
        <tbody>
          {preciosData.map((precio, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: precio.nombre.includes("ComoTuCasa") ? "green" : "white",
                
              }}
            >
              <td>{index + 1}</td>
              <td>{precio.nombre}</td>
              <td>{precio.precio}</td>
              <td>{precio.tipo_alojamiento}</td>
              <td>{precio.puntuacion}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
