import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, notification } from 'antd';
import { obtener_inquilino_ha_pedido_factura, postInquilinoInformacionGenerarFactura } from '../../../api/inquilino';
import { getAccessTokenApi } from '../../../api/auth';

const { Title, Text } = Typography;

const BillingForm = ({ match }) => {
  const [form] = Form.useForm();
  const [seHaSolicitadoFactura, setseHaSolicitadoFactura] = useState(null);
  const [reload, setReload] = useState(false);

  const handleSubmit = async (values) => {
    console.log("Datos de facturación:", values);
    let resp = await postInquilinoInformacionGenerarFactura(getAccessTokenApi(), match.params.id, values);
    if (resp.message) {
      notification["success"]({
        message: "Factura creada en breve se te enviará por correo electrónico."
      });
      setReload(true);
      form.resetFields();
    } else {
      notification["error"]({
        message: "Error en la creación de la factura. Mensaje de error: " + resp?.error
      });
    }
  };

  useEffect(() => {
    const fetchInquilinoData = async () => {
      let resp = await obtener_inquilino_ha_pedido_factura(getAccessTokenApi(), match.params.id);
      if (resp.message) {
        setseHaSolicitadoFactura(resp.inquilino_factura_solicitada);

      } else {
        setseHaSolicitadoFactura(null);
      }
    };
    setReload(false);
    fetchInquilinoData();
  }, [match.params.id,reload]);

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      {seHaSolicitadoFactura === null ? (
        <div>
          <Title level={2}>Error</Title>
          <Text type="danger">El inquilino no existe.</Text>
        </div>
      ) : seHaSolicitadoFactura === true ? (
        <div>
          <Title level={2}>Información</Title>
          <Text>La factura ya ha sido solicitada para este inquilino.</Text>
        </div>
      ) : (
        <div>
          <Title level={2}>Formulario de Facturación</Title>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              nombre_factura: '',
              nif_cif_factura: '',
              direccion_factura: '',
              telefono_contacto_factura: '',
              email_factura: '',
            }}
          >
            <Form.Item
              label="Razón Social o Nombre de Facturación"
              name="nombre_factura"
              rules={[{ required: true, message: 'Por favor, ingrese su nombre de facturación' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="NIF/CIF"
              name="nif_cif_factura"
              rules={[{ required: true, message: 'Por favor, ingrese su NIF o CIF' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Dirección de Facturación"
              name="direccion_factura"
              rules={[{ required: true, message: 'Por favor, ingrese su dirección de facturación' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Teléfono de Contacto"
              name="telefono_contacto_factura"
              rules={[{ required: true, message: 'Por favor, ingrese su teléfono de contacto' }]}
            >
              <Input type="tel" />
            </Form.Item>

            <Form.Item
              label="Correo Electrónico para Emitir la Factura"
              name="email_factura"
              rules={[
                { required: true, message: 'Por favor, ingrese su correo electrónico' },
                { type: 'email', message: 'Por favor, ingrese un correo electrónico válido' }
              ]}
            >
              <Input type="email" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default BillingForm;
