import React, { useEffect, useState } from 'react';
import {
  Tooltip,
  Button,
  Menu,
  Dropdown,
  Avatar,
  Row,
  Modal
} from 'antd';
import {
  EditOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  CommentOutlined,
  HomeOutlined,
  AliyunOutlined
} from '@ant-design/icons';
import CopyToClipboard from "../../../CopyToClipboard";
import NoAvatar from '../../../../assets/img/png/no-avatar.png';
import './Listinquilinos.scss';
import Boton_peticion_handler_v2 from '../../boton_peticion_handler_v2';
import { actualizarEstadoEntradaInquilino, actualizarUsoEnlaceInquilino } from '../../../../api/inquilino';

const UserTooltip = ({ user, editUser, desactivateUser, basePathFrontend, iconoEdad, renderDateIcon , reloadUsers,setReloadUsers}) => {
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [auz, setau2] = useState(false);
  useEffect(()=>{
    console.log("cambio en user")
  },[user,reloadUsers])
  const handleMenuClick = ({ key }) => {
    if (key === 'edit') {
      editUser(user);
    } else if (key === 'delete') {
      setIsModalVisible(true);
    } else if (key === 'whatsapp') {
      window.open(
        "https://api.whatsapp.com/send?phone=" + user.telefono + "&text=.",
        "_blank"
      );
    }
    setVisible(false);
  };

  const handleOk = () => {
    desactivateUser(user);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="whatsapp">
        <CommentOutlined /> Whatsapp
      </Menu.Item>
      <Menu.Item key="edit">
        <EditOutlined /> Editar
      </Menu.Item>
      <Menu.Item key="delete">
        <CloseCircleOutlined /> Borrar
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Tooltip
        title={
          <div>
            <Row>
              {user.name || 'Nombre del Inquilino'}
              <CopyToClipboard text={user.name || 'Nombre del Inquilino'} onCopy={() => { /* mensaje de éxito */ }}>
                <button style={{ marginLeft: 8 }}>Copiar</button>
              </CopyToClipboard>
            </Row>
          </div>
        }
      >
       <h3>
  <Boton_peticion_handler_v2
    cuerpo={<HomeOutlined />}
    handler={async () => {
      const nuevaEntradaRealizada = !user.entrada_realizada;
      console.log("Estado actual: ", user.entrada_realizada, "Nuevo estado: ", nuevaEntradaRealizada);

      await new Promise((resolve) =>
        setTimeout(() => {
           actualizarEstadoEntradaInquilino(user.code, nuevaEntradaRealizada);
          resolve();
        }, 500)
      );

      setReloadUsers(true);
      console.log("Estado después: ", user.entrada_realizada, "Nuevo estado: ", nuevaEntradaRealizada);
    }}
    style={{
      background: user.entrada_realizada ? "yellow" : "grey",
      border: user.entrada_realizada ? "yellow" : "grey",
      color: user.entrada_realizada ? "black" : "white",
    }}
  />
  -
  <Boton_peticion_handler_v2
    cuerpo={<AliyunOutlined />}
    handler={async () => {
      const primera_apertura_enlace = !(user.primera_apertura_enlace?? false) ;
      console.log("Estado actual: ", user.primera_apertura_enlace, "Nuevo estado: ", primera_apertura_enlace);

      await new Promise((resolve) =>
        setTimeout(() => {
          actualizarUsoEnlaceInquilino(user.code, primera_apertura_enlace);
          resolve();
        }, 500)
      );

      setReloadUsers(true);
      console.log("Estado después: ", user.primera_apertura_enlace, "Nuevo estado: ", primera_apertura_enlace);
    }}
    style={{
      background: user.primera_apertura_enlace ? "yellow" : "grey",
      border: user.primera_apertura_enlace ? "yellow" : "grey",
      color: user.primera_apertura_enlace ? "black" : "white",
    }}
  />
  <>{renderDateIcon(user)}</>

  <Avatar className="avatar" src={user.avatar || NoAvatar} />

  <a
    target="_blank"
    rel="noopener noreferrer"
    href={`https://app.avaibook.com/reservas_editar.php?id=${user.id_avaibook}`}
    style={{ color: 'blue', textDecoration: 'underline' }}
  >
    {user.name ? (user.name.length <= 7 ? user.name : `${user.name.slice(0, 7)}...`) : 'Nombre del Inquilino'}
  </a>

  {iconoEdad}

  <div className="boton-flujo">
    <Dropdown overlay={menu} trigger={['click']} visible={visible} onVisibleChange={setVisible}>
      <Button type="link" icon={<MoreOutlined style={{ fontSize: '20px' }} />} />
    </Dropdown>
  </div>
</h3>

      </Tooltip>
      <Modal
        title="Confirmación"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Sí"
        cancelText="No"
      >
        <p>¿Está seguro de que desea desactivar al usuario {user.name}?</p>
      </Modal>
    </>
  );
};

export default UserTooltip;
