import React, { useEffect, useState } from 'react';
import { getInmueblesApiBooking, getBookingPuntuaciones } from '../../../api/inmueble';
import { Button, Progress } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import DesplegableMultiple from '../../../components/DesplegableMultiple';
import "./Puntuacion.scss";

export default function Puntuaciones() {
    const [inmuebleSelected, setInmuebleSelected] = useState([]);
    const [reloadUsers, setReloadUsers] = useState(false);
    const [num_tours_v, set_num_tours_v] = useState([]);
    const [puntuaciones, setPuntuaciones] = useState();

    useEffect(() => {
        const accesToken = getAccessTokenApi();
        getInmueblesApiBooking(accesToken, true).then((response) => {
            const num_tours_v2 = response.Pisos.map(piso => ({
                name: piso.name,
                url: piso.url_booking
            }));
            set_num_tours_v(num_tours_v2);
        });
        setReloadUsers(false);
    }, [reloadUsers]);

    const handleSearch = async () => {
        const accesToken = getAccessTokenApi();
        if (inmuebleSelected && inmuebleSelected.length > 0) {
            const puntuacionesRequest = inmuebleSelected.reduce((obj, item) => {
                obj[item.name] = item.url ? item.url : ""; 
                return obj;
            }, {});
            try {
                const puntuacionesRes = await getBookingPuntuaciones(accesToken, puntuacionesRequest);
                setPuntuaciones(puntuacionesRes.resultados);
            } catch (error) {
                console.error('Error al obtener las puntuaciones:', error);
            }
        }
    };

    const getColorForPercentage = (percentage) => {
        if (percentage > 80) return '#3f8600'; // Verde
        if (percentage > 65) return '#faad14'; // Amarillo
        if (percentage > 50) return '#fa8c16'; // Naranja
        return '#cf1322'; // Rojo
    };

    return (
        <>
            <DesplegableMultiple
                valores={num_tours_v.map(item => item.name)}
                onChange={(selected) => {
                    setInmuebleSelected(num_tours_v.filter(item => selected.includes(item.name)));
                }}
            />
            <Button onClick={handleSearch}>Buscar</Button>

            {puntuaciones ? (
                <div>
                    {puntuaciones.map((puntuacion, index) => {
                        const percent = Number(puntuacion.puntuacion.replace(',', ".")) * 10;
                        const color = getColorForPercentage(percent);
                        return (
                            <div key={index} className='tarjeta'>
                                <h3>{puntuacion.name}</h3>
                                <p>Puntuación: {puntuacion.puntuacion}</p>
                                <Progress type="circle" percent={percent} strokeColor={color} className='progress'/>
                                <p>Comentarios: {puntuacion.comentarios}</p>
                            </div>
                        );
                    })}
                </div>
            ) : <></>}
        </>
    );
}
