// /* eslint-disable react/jsx-pascal-case */
// import React, { useState, useEffect } from "react";
// import { Steps } from 'antd';
// import Formulario_Clientes from "../Formulario_Clientes/Formulario_Clientes";
// import {
//   Button,
//   DatePicker,
//   Input,
//   Tabs
// } from "antd";

// import {
//   InquilinoInformacionApi,
// } from "../../../api/inquilino";
// import "./DNI_upload.scss";
// import Formulario_dni from "./Formulario_dni";

// import Informacion_llegada from "../Informacion_llegada";
// const { RangePicker } = DatePicker;
// const { TextArea } = Input;

// const normFile = (e) => {
//   if (Array.isArray(e)) {
//     return e;
//   }
//   return e?.fileList;
// };

// export default function DNI_upload({ match }) {
//   const [code2, setCode] = useState("");
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   useEffect(async () => {
//     let code = match.params.id;
//     //console.log("El codigo es:", code);
//     setCode(code);
//   }, []);

//   //Para mas info de esto venir aqui: https://stackblitz.com/edit/react-router-como-pasar-id-a-pagina?file=index.js

//   //Creamos unos hooks para guardar el estado del formulario
//   return (
//     <div className="vic">
//       <div className="sign-in">
//         <div className="background">
//           <div className="background-pictureAndLogo">
//             <h1>Auto Check-In</h1>
//           </div>
//           {//console.log("EL CODIGO QUE LE LLEGA A FORMULARIO",code2)}
//           <Formulario_Clientes code={code2}/>
//           {/* <Button
//             onClick={() => {
//               if (pagina_numero === 1) {
//                 setpagina_numero(0);
//               } else {
//                 setpagina_numero(1);
//               }
//             }}
//           >Cambiar pagina</Button>

//           {pagina_numero === 1 ?
//             <Informacion_llegada code={code2} setpagina_numero={setpagina_numero}></Informacion_llegada>
//             :
//             <div className="pedro">
//               <Tabs type="card">

//                 {formularios_vector}

//               </Tabs>
//             </div>
//           } */}
//         </div>
//       </div>
//     </div>
//   );
// }
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { Steps, Button, Select, Tabs, Input, Tooltip, Checkbox } from 'antd';
import moment from 'moment';
import FormularioDni from './Formulario_dni';
import {
  InquilinoInformacionApi,
  actualizarStepNumber,
  actualizarHoraDeEntradaSalida,
  actualizarEmailInquilino,
  enviarEmailFacturayDatos,
  postNewEstadoFlujo
} from "../../../api/inquilino";
import { socketPath } from "../../../api/config";
import io from "socket.io-client";
// import "./DNI_upload.scss";
import './DNIUpload.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import dayjs from 'dayjs';

const { Step } = Steps;
const { Option } = Select;
const { TabPane } = Tabs;

export default function DNI_upload({ match }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [arrivalTime, setArrivalTime] = useState(moment("16:00", 'HH:mm'));
  const [transportMode, setTransportMode] = useState('');
  const [clientes, setClientes] = useState([]);
  const [formulariosDNI, setFormulariosDNI] = useState([]);
  const [id, setId] = useState(match.params.id);
  const [wantsInvoice, setWantsInvoice] = useState(false);
  const [emailForInvoice, setEmailForInvoice] = useState('');
  const [selectedInquilino, setSelectedInquilino] = useState(null);
  const [dniesFaltantes, setDniesFaltantes] = useState(0);

  const handleStepChange = (newStep) => {
    setCurrentStep(newStep);
  };


  useEffect(() => {
    const socket = io(socketPath);
    socket.on("formDniActualizado", async (data) => {
      //console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMMMMM')
      if (data._id === id) {
        const clienteInfo = await InquilinoInformacionApi(id);
        //console.log('STEPP DE BBDD', clienteInfo);
        const numeroClientes = clienteInfo.numero_personas;
        setDniesFaltantes(numeroClientes);
        const step = clienteInfo.check_in_step;
        setCurrentStep(step);
        // Crear un array de clientes (puedes obtener esta información de tu API)
        const clientesArray = Array.from({ length: numeroClientes }, (_, i) => i + 1);

        setClientes(clientesArray);
      }
    });
    return () => socket.disconnect();
  }, []);

  useEffect(async () => {
    // Obtener el número de clientes y otra información del cliente con el ID
    const clienteInfo = await InquilinoInformacionApi(id);
    //console.log('STEPP DE BBDD', clienteInfo);
    const numeroClientes = clienteInfo.numero_personas;
    const step = clienteInfo.check_in_step;
    setCurrentStep(step);
    //console.log(clienteInfo);
    setDniesFaltantes(numeroClientes);
    // Crear un array de clientes (puedes obtener esta información de tu API)
    const clientesArray = Array.from({ length: numeroClientes }, (_, i) => i + 1);
    setClientes(clientesArray);
  }, [id]);

  function esCorreoValido(email) {
    const patronEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return patronEmail.test(email);
  }

  const updateStepInDatabase = async () => {
    let newStep;
    newStep = currentStep < 4 ? currentStep + 1 : 4; // En cualquier otro caso, incrementa en 1, a menos que ya esté en el paso 5
    try {
      const resp = await actualizarStepNumber(id, newStep); // Asumiendo que actualizarStepNumber toma el ID y el nuevo paso como argumentos
      if (resp.message) {
        setCurrentStep(newStep);
        //console.log('SE ACTUALIZA BIEN');
        // Puedes añadir aquí cualquier otra lógica necesaria después de actualizar el paso
      } else {
        console.error('Error al actualizar el paso en la base de datos');
      }
    } catch (error) {
      console.error('Error al llamar a actualizarStepNumber', error);
    }
  };

  const updateEmail = async (email) => {
    try {
      const resp = await actualizarEmailInquilino(id, email); // Asumiendo que actualizarStepNumber toma el ID y el nuevo paso como argumentos
      if (resp && resp.success) {
        //console.log('ACTUALIZADA HORA DE ENTRADA', resp);
      } else {
        console.error('Error al actualizar email en la base de datos');
      }
    } catch (error) {
      console.error('Error al llamar a actualizarStepNumber', error);
    }
  };

  const updateHoradeLLegada = async (horaEntrada) => {
    try {
      //console.log("hora llegada : ", horaEntrada);
      let resp;
      try {
        resp = await actualizarHoraDeEntradaSalida(id, horaEntrada, null, true); // Asumiendo que actualizarStepNumber toma el ID y el nuevo paso como argumentos
        //console.log('RESPUESTA   ', resp);
      } catch (error) {
        //console.log(error);
      }
      if (resp.message) {
        //console.log('ACTUALIZADA HORA DE ENTRADA');
        // Puedes añadir aquí cualquier otra lógica necesaria después de actualizar el paso
      } else {
        console.error('Error al actualizar el paso en la base de datos');
      }
    } catch (error) {
      console.error('Error al llamar a actualizarStepNumber', error);
    }
  };

  const nextStep = () => {
    //console.log('PASO ACTUAL', currentStep);
    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
      updateStepInDatabase();
    } else if (currentStep === 1 && transportMode) {
      setCurrentStep(currentStep + 1);
      updateStepInDatabase();
    } else if (currentStep == 2) {
      setCurrentStep(currentStep + 1);
      updateStepInDatabase();
    }
  };

  const handleFinal = () => {
    //console.log('MAIL', emailForInvoice)
    //console.log('ID', id)
    if (esCorreoValido(emailForInvoice)) {
      updateEmail(emailForInvoice);
      try {
        enviarEmailFacturayDatos(id, emailForInvoice);
        setCurrentStep(4);
        updateStepInDatabase();
      } catch (error) {
        //console.log(error);
      }
    } else {
      // Muestra un mensaje de error o maneja el error como prefieras
      console.error("Por favor, introduce un correo electrónico válido.");
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleTimeChange = (time) => {
    //console.log("QOIHIJ");
    //console.log("hora handle : ", time);
    setArrivalTime(time.format('HH:mm'));
  };

  const handleTransportChange = (value) => {
    setTransportMode(value);
  };

  const submitForm = () => {
    //console.log('Datos Enviados:', arrivalTime, transportMode, formulariosDNI);
    // Aquí puedes agregar la lógica para enviar los datos a tu backend

    // Una vez enviado el primer DNI, eliminarlo del array de formulariosDNI
    const nuevosFormularios = [...formulariosDNI];
    nuevosFormularios.shift();
    setFormulariosDNI(nuevosFormularios);
    setCurrentStep(3);
    // Reiniciar el formulario o realizar cualquier otra acción que necesites
  };

  return (
    <div className="div-background-check-in">
      <div className="div-content-check-in">
        <div className="check-in-title">
          <h1>Auto Check-In</h1>
        </div>
        <div className="formularioClientes">
          <Steps className="formulario-steps-index" current={currentStep}>
            <Step className="step-name" title="Hora de Llegada" />
            <Step className="step-name" title="Información viaje" />
            <Step className="step-name" title="Datos necesareos" />
          </Steps>

          <div className="steps-content-form">
            {currentStep === 0 && (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Selecciona una hora"
                    onChange={handleTimeChange}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" InputLabelProps={{ shrink: true }} />
                    )}
                    minutesStep={30} // Solo permite seleccionar minutos en incrementos de 30
                    minTime={dayjs().hour(15).minute(0)} // Hora mínima a las 15:00
                    maxTime={dayjs().hour(23).minute(30)} // Hora máxima a las 23:59
                    ampm={false}
                    inputFormat="HH:mm"
                  />
                </LocalizationProvider>
                {/* <TimePicker
                  format="HH:mm"
                  defaultValue={arrivalTime ? moment(arrivalTime, 'HH:mm') : moment("15:30", 'HH:mm')}
                  disabledHours={() => {
                    const hours = [];
                    for (let i = 0; i < 15; i++) {
                      hours.push(i);
                    }
                    for (let i = 24; i > 23; i--) {
                      hours.push(i);
                    }
                    return hours;
                  }}
                  minuteStep={30}
                  onChange={(time, timeString) => {
                    // Aquí se puede manejar la actualización de la hora
                    //console.log("Hora cambiada:", time, timeString);
                    // Actualizar la variable de arrivalTime o realizar otras acciones necesarias
                    // arrivalTime = timeString;
                  }}
                  onCalendarChange={(time) => {
                    // Aquí puedes actualizar la hora en otro lugar cuando cambia el calendario
                    //console.log("Cambio en el calendario:", time);
                    // Actualizar la variable de arrivalTime u otras acciones necesarias
                    // arrivalTime = time.format('HH:mm');
                  }}
                /> */}

                <Button type="primary" onClick={() => { updateHoradeLLegada(arrivalTime); nextStep() }}>
                  Siguiente
                </Button>
              </>
            )}

            {currentStep === 1 && (
              <>
                <h2>¿A que se debe su viaje?</h2>
                <Select defaultValue={transportMode} style={{ width: 120 }} onChange={handleTransportChange}>
                  <Option value="ocio">Ocio</Option>
                  <Option value="trabajo">Trabajo</Option>
                </Select>
                <Button onClick={prevStep}>
                  Anterior
                </Button>
                <Button type="primary" onClick={nextStep}>
                  Siguiente
                </Button>
              </>
            )}

            {currentStep === 2 && (
              <div>
                {clientes.length === 0 ? (
                  <>
                    <p>Esperemos que tengan una estancia acogedora</p>
                    <Button disabled style={{ backgroundColor: "green" }}>
                      COMPLETADO
                    </Button>
                    <div>
                      <h2>Introduzca su correo para recibir los datos de su reserva y su factura</h2>
                      <Input placeholder="Correo electrónico" value={emailForInvoice} onChange={e => setEmailForInvoice(e.target.value)} />
                      <Button type="primary" onClick={() => handleFinal(emailForInvoice)}>
                        Pulse para recibir la informacion en su correo
                      </Button>
                      <Checkbox style={{margin:"10px"}} defaultChecked={true}>
                        ¿Desea recbir información acerca de futuras ofertas?
                      </Checkbox>
                    </div>
                    {/* await enviarMailConLaInformacion(id); */}
                  </>
                ) : (
                  <div className="content-inquilinos">
                    <h2>Faltan {dniesFaltantes} documentos por completar
                      <Tooltip title="Completa los datos de cada huesped / Complete the details for each guest ">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </h2>
                    {clientes.map((cliente, index) => (
                      <Button
                        key={index}
                        onClick={() => { setSelectedInquilino(cliente); setCurrentStep(3) }}
                        className="inquilinos"
                      >
                        Completar registro
                      </Button>
                    ))}
                  </div>
                )}
                <Button onClick={prevStep} className="button-prev">
                  Anterior
                </Button>
              </div>
            )}
            <>
              {currentStep === 3 && (
                <div>
                  {selectedInquilino !== null ? (
                    <>
                      <FormularioDni
                        numero_ids={clientes.length}
                        mi_numero={selectedInquilino}
                        onFinish={() => submitForm()}
                        subido={false}
                        texto_boton={"A"}
                        code={id}
                        onStepChange={handleStepChange}
                      />
                      <Button onClick={prevStep}>
                        Anterior
                      </Button>
                    </>
                  ) : (
                    <>
                      <h2>Completado</h2>
                      <p>Ha completado el check-in de forma correcta, los datos de su reserva se encuentran en su correo</p>
                      <p>Que tenga una agradable estancia</p>
                    </>
                  )}
                </div>
              )}
            </>
            <>
              {currentStep === 4 && (
                <div>
                  <>
                    <h2>Completado</h2>
                    <p>Ha completado el check-in de forma correcta, los datos de su reserva se encuentran en su correo</p>
                    <p>Que tenga una agradable estancia</p>
                  </>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}