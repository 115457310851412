// src/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  // Estilos en línea
  const containerStyle = {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  };

  const headingStyle = {
    color: '#333',
  };

  const listStyle = {
    listStyleType: 'disc',
    margin: '10px 0',
    paddingLeft: '20px',
  };

  const linkStyle = {
    color: '#007bff',
    textDecoration: 'none',
  };

  const linkHoverStyle = {
    textDecoration: 'underline',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Términos y Condiciones</h1>
      <p>
        Bienvenido a DPD RENTAL MANAGEMENT SL. Estos Términos y Condiciones rigen el uso de nuestro sitio web
        y nuestros servicios. Al acceder y utilizar nuestro sitio web, aceptas cumplir con estos términos. Si no
        estás de acuerdo con estos términos, por favor no utilices nuestro sitio web.
      </p>
      <h2 style={headingStyle}>1. Uso del Sitio Web</h2>
      <p>
        El acceso y uso de nuestro sitio web están sujetos a estos Términos y Condiciones. Te comprometes a utilizar
        el sitio web solo para fines legales y de acuerdo con todas las leyes y regulaciones aplicables.
      </p>
      <h2 style={headingStyle}>2. Propiedad Intelectual</h2>
      <p>
        Todo el contenido del sitio web, incluyendo pero no limitado a textos, gráficos, logotipos, imágenes y
        software, es propiedad de DPD RENTAL MANAGEMENT SL o de sus licenciantes y está protegido por las leyes de
        propiedad intelectual. No se permite la reproducción, distribución o modificación del contenido sin nuestro
        permiso expreso por escrito.
      </p>
      <h2 style={headingStyle}>3. Limitación de Responsabilidad</h2>
      <p>
        Nuestro sitio web se proporciona "tal cual" y "según disponibilidad". No garantizamos que el sitio web
        estará libre de errores o interrupciones, ni que el acceso será continuo o ininterrumpido. En la medida
        máxima permitida por la ley, no seremos responsables de ningún daño directo, indirecto, incidental o
        consecuente que resulte del uso o la imposibilidad de uso del sitio web.
      </p>
      <h2 style={headingStyle}>4. Modificaciones</h2>
      <p>
        Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Cualquier cambio
        se publicará en esta página, y el uso continuado del sitio web después de la publicación de cambios
        constituirá tu aceptación de dichos cambios.
      </p>
      <h2 style={headingStyle}>5. Enlaces a Sitios de Terceros</h2>
      <p>
        Nuestro sitio web puede contener enlaces a sitios web de terceros. No somos responsables del contenido de
        dichos sitios y la inclusión de un enlace no implica nuestra aprobación del sitio enlazado.
      </p>
      <h2 style={headingStyle}>6. Legislación Aplicable</h2>
      <p>
        Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de España.
        Cualquier disputa que surja en relación con estos términos estará sujeta a la jurisdicción exclusiva de los
        tribunales competentes de Zaragoza.
      </p>
      <h2 style={headingStyle}>7. Contacto</h2>
      <p>
        Si tienes alguna pregunta sobre estos Términos y Condiciones, por favor contáctanos a
        <a href="mailto:direccion@turisteasuites.es" style={linkStyle} onMouseOver={e => e.target.style.textDecoration = linkHoverStyle.textDecoration} onMouseOut={e => e.target.style.textDecoration = linkStyle.textDecoration}>direccion@turisteasuites.es</a>.
      </p>
    </div>
  );
};

export default TermsAndConditions;
