import React, { useState, useEffect, useCallback } from "react";

import { obtenerListaInmuebles } from "../../../../api/inmueble";

import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  
  Divider,
  Radio,
  Space,
  Rate,
  DatePicker
} from "antd";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import {
  updateInquilinoApi,
  uploadAvatarApi,
  getAvatarApi,
} from "../../../../api/inquilino";
import { getAccessTokenApi } from "../../../../api/auth";
import "./EditInquilinoForm.scss";
import TimepickerLimpieza from "../../../TimepickerLimpieza";

export default function EditInquilinoForm(props) {
  const { user, setIsVisibleModal, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  const [userData, setUserData] = useState({});
  //console.log("EditUserForm");
  //console.log(user.fecha_entrada, user.fecha_salida);
  useEffect(() => {
    //console.log("mi_user: ", user)
    setUserData({
      name: user.name,
      telefono: user.telefono,
      email: user.email,
      inmueble: user.inmueble,
      role: user.role,
      avatar: user.avatar,
      fecha_entrada: user.fecha_entrada,
      fecha_salida: user.fecha_salida,
      tipo_inquilino: user.tipo_inquilino,
      pago: user.pago,
      plataforma: user.plataforma,
      forma_pago: user.forma_pago,
      comision: user.comision,
      pago_limpieza: user.pago_limpieza,
      group_id: user.group_id,
      idioma_mensaje: user.idioma_mensaje ?? "ES",
      numero_personas: user.numero_personas,
      numero_ids_enviados: user.numero_ids_enviados,
      taxes_for_invoicing: user.taxes_for_invoicing,
      tax_percentage: user.tax_percentage ?? "0",
      valoracion_limpieza : user.valoracion_limpieza ?? "0",
      valorado : user.valorado ?? false, 
      limpieza: user.limpieza ?? {},
      hora_entrada: user.hora_entrada,
      hora_salida: user.hora_salida,
     
    });
  }, [user]);
  useEffect(() => {
  
  }, [userData]);

  // useEffect(() => {
  //   if (user.avatar) {
  //     getAvatarApi(user.avatar).then((response) => {
  //       setAvatar(response);
  //     });
  //   } else {
  //     setAvatar(null);
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (avatar) {
  //     setUserData({ ...userData, avatar: avatar.file });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [avatar]);



  const updateUser = (e) => {
    //console.log("updateUser");

    const token = getAccessTokenApi();
    let userUpdate = userData;

    if (
      !userUpdate.name ||
      !userUpdate.telefono ||
      !userUpdate.inmueble ||
      !userUpdate.fecha_entrada ||
      !userUpdate.fecha_salida
    ) {
      notification["error"]({
        message: "El nombre, apellidos y email son obligatorios.",
      });
      //setReloadUsers(true);
      return;
    }
    
    if (userData.valorado === "No se va a valorar" || userData.valorado === "Valorado") {
      userData.valorado = true;
    } else {
      userData.valorado = false;
    }
    //console.log("updateUserApi");
    
    updateInquilinoApi(token, userUpdate, user._id).then((response) => {
      //console.log("updateInquilinoApi", response)
      if (response.message) {
        notification["success"]({
          message: "Usuario actualizado correctamente",
        });
        //console.log("setReloadUsers return");
        setIsVisibleModal(false);
        setReloadUsers(true);
      } else if (response.error) {
        //console.log("response.error",response.error)
        notification["error"]({
          message: response.error
    
        });
        
        //console.log("response.error3")
      } else {
        //console.log("response.error2")
        // Aquí puedes agregar alguna lógica adicional si es necesario
        notification["error"]({
          message: "Error al actualizar el usuario"
          
        });
      }
     
    });
    // }
  };

  return (
    <div className="edit-user-form">
      {/* <UploadAvatar avatar={avatar} setAvatar={setAvatar} /> */}
      <EditForm
        userData={userData}
        setUserData={setUserData}
        updateUser={updateUser}
        setReloadUsers={setReloadUsers}
      />
    </div>
  );
}

function EditForm(props) {
  const { userData, setUserData, updateUser, setReloadUsers } = props;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [value, setValue] = useState(1);
  const onChange = (dates) => {
    setUserData({
      ...userData,
      fecha_entrada: dates[0].format("YYYY-MM-DD"),

      fecha_salida: dates[1].format("YYYY-MM-DD"),
    });
    setReloadUsers(true);
  };
  const [num_tours_v, set_num_tours_v] = useState([]);
  const [lista_id_piso, set_lista_id_piso] = useState({});
  useEffect(() => {
    const accessToken = getAccessTokenApi();
    obtenerListaInmuebles(accessToken).then((response) => {
      //console.log(response.Pisos[0].id);
      var num_tours_v2 = [];
      //console.log("1");
      //console.log("LONGITUD", response.Pisos.length);
      for (let i = 0; i < response.Pisos.length; i++) {
        //console.log("1.1");
        //console.log(response.Pisos[i]);
        num_tours_v2.push(
          <Option value={response.Pisos[i].id}>
            {response.Pisos[i].nombre}
          </Option>
        );
      }
      set_num_tours_v(num_tours_v2);
      set_lista_id_piso(response.Pisos2);
      //console.log(userData);
      //console.log(lista_id_piso);
    });
  }, []);
  return (
    <Form className="form-edit" onFinish={updateUser}>
      <span>Group ID: {userData.group_id}</span>
      <Divider>Fecha de entrada</Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item label="Date Range">
            {moment(userData.fecha_entrada, "YYYY-MM-DD").isValid() ? (
              <RangePicker
                renderExtraFooter={() => "extra footer"}
                onChange={onChange}
                value={[
                  userData.fecha_entrada
                    ? moment(userData.fecha_entrada)
                    : null,
                  userData.fecha_salida ? moment(userData.fecha_salida) : null,
                ]}
              />
            ) : (
              <></>
            )}
          </Form.Item>
        </Col>
        <div className="fechas">
                  Hora de entrada: {userData.hora_entrada}
                  <TimepickerLimpieza hora_actual={userData.hora_entrada} onOkHandler={async (token, my_hora) => {
                    //console.log("TIMEPICKER PRUEBA", my_hora)
                    const resp = await updateInquilinoApi(token, { hora_entrada: my_hora }, userData._id);
                    //console.log((resp));
                    if (resp.message) {
                      notification["success"]({
                        message: "Actualizado",
                      });

                    } else {
                      notification["error"]({
                        message: "Problema al actualizar",
                      });
                    }
                  }}>
                  </TimepickerLimpieza>
                </div>
                <div className="fechas">
                  Hora de salida:
                  <TimepickerLimpieza hora_actual={userData.hora_salida} onOkHandler={async (token, my_hora) => {
                    const resp = await updateInquilinoApi(token, { hora_salida: my_hora }, userData._id);
                    //console.log((resp));
                    if (resp.message) {
                      notification["success"]({
                        message: "Actualizado",
                      });

                    } else {
                      notification["error"]({
                        message: "Problema al actualizar",
                      });
                    }
                  }}>
                  </TimepickerLimpieza>
                </div>
        <Divider>Sobre el inquilino</Divider>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="user" />}
              placeholder="Nombre y apellidos Inquilino"
              value={userData.name}
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="user" />}
              placeholder="Email"
              value={userData.email}
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Telefono"
              value={userData.telefono}
              onChange={(e) =>
                setUserData({ ...userData, telefono: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
   

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            <Select
              placeholder="Seleciona un inmueble"
              onChange={(e) => setUserData({ ...userData, inmueble: e })}
              value={userData.inmueble}
            >
              {num_tours_v}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            <Input
              //  prefix={<Icon type="lock" />}
              type="password"
              placeholder="Contraseña"
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //  prefix={<Icon type="lock" />}
              type="password"
              placeholder="Repetir contraseña"
              onChange={(e) =>
                setUserData({ ...userData, repeatPassword: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Divider>Reserva</Divider>
      <Row gutter={24}>
        <Col span={12}>Numero de personas</Col>
        <Col span={12}>
          <Form.Item>
            <Input
              placeholder="Numero de personas"
              onChange={(e) =>
                setUserData({ ...userData, numero_personas: e.target.value })
              }
              value={userData.numero_personas}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>IDs pendientes de enviar</Col>
        <Col span={12}>
          <Form.Item>
            <Input
              placeholder="Numero de personas"
              onChange={(e) =>
                setUserData({ ...userData, numero_ids_enviados: e.target.value })
              }
              value={userData.numero_ids_enviados}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>Fecha Limpieza</Col>
        <Col span={12}>
          <Form.Item>
          <DatePicker
            renderExtraFooter={() => "extra footer"}
            value={moment(userData.limpieza?.fecha_limpieza ?? "2024-01-01")}
            onChange={(date, dateString) => {
              //console.log("onChange", date, dateString);

              setUserData({
                ...userData,
                limpieza: { ...userData.limpieza, fecha_limpieza: dateString },
              });
            }}
          />

          </Form.Item>
        </Col>
      </Row>
      <Divider>Pagos</Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Select
              placeholder="Plataforma"
              onChange={(e) =>
                setUserData({
                  ...userData,
                  plataforma: e,
                  comision: e === "Referido" ? "0" : "",
                })
              }
              value={userData.plataforma}
            >
              <Option value="Airbnb">Airbnb</Option>
              <Option value="Booking">Booking</Option>
              <Option value="Vrbo">Vrbo</Option>
              <Option value="Web">Web</Option>
              <Option value="Referido">Referido</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Pagado
        </Col>
        <Col span={12}>
        <Form.Item>
          <Input
            //prefix={<Icon type="mail" />}
            placeholder="Pago"
            value={userData.pago}
            onChange={(e) => {
              ////console.log(e.target.value);
              const inputValue = e.target.value.replace(",", ".");
              let comision =
                userData.plataforma === "Airbnb"
                  ? Number((lista_id_piso[userData.inmueble]?.comision_airbnb ?? 0) * inputValue)/100
                  : userData.plataforma === "Booking" || userData.plataforma === "Booking.com"
                  ? Number((lista_id_piso[userData.inmueble]?.comision_booking ?? 0) * inputValue)/100
                  : 0;

              comision = Math.ceil(comision * 100) / 100; // Redondear hacia arriba y dejar solo dos decimales
              setUserData({
                ...userData,
                pago: inputValue,
                comision: comision.toFixed(2)
              });
            }}
          />
        </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Pago Limpieza
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Pago limpieza"
              value={userData.pago_limpieza}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  pago_limpieza: e.target.value.replace(",", "."),
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Comision (%)
        </Col>
        <Col span={12}>
          <Form.Item>
          <Input
              //prefix={<Icon type="mail" />}
              disabled
              placeholder="% Comision"
              value={
                Number((userData.comision / userData.pago) * 100).toFixed(2) + "%"
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Comision (euros)
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="comision"
              value={userData.comision}
              onChange={(e) =>
                setUserData({ ...userData, comision: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider>Factura</Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Radio.Group
              onChange={(e) => {
                //console.log("Dato:", e.target.value);
                setUserData({
                  ...userData,
                  tax_percentage: e.target.value,
                  taxes_for_invoicing:
                    e.target.value === "21"
                      ? Number((e.target.value / 100) * userData.pago).toFixed(
                          2
                        )
                      : e.target.value === "10"
                      ? Number((e.target.value / 100) * userData.pago).toFixed(
                          2
                        )
                      : e.target.value === "0"
                      ? 0
                      : e.target.value,
                });
              }}
              value={userData.tax_percentage}
            >
              <Space direction="horizontal">
                <Radio value={"0"}>Sin Factura</Radio>
                <Radio value={"21"}>Factura 21%</Radio>
                <Radio value={"10"}>Factura 10%</Radio>
                <Radio value={""}>
                  Otra
                  {value === "" ? (
                    <Input
                      value={userData.tax_percentage}
                      onChange={(e) => {
                        //console.log("Dato:", e.target.value);
                        setUserData({
                          ...userData,
                          tax_percentage: e.target.value,
                          taxes_for_invoicing: Number(
                            (e.target.value / 100) * userData.pago
                          ).toFixed(2),
                        });
                      }}
                      style={{ width: 100, marginLeft: 10 }}
                    />
                  ) : null}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {userData.tax_percentage !== "0" ? (
        <Row gutter={24}>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            IVA (euros)
          </Col>

          <Col span={12}>
            <Form.Item>
              <Input
                //prefix={<Icon type="mail" />}
                placeholder="Taxes"
                value={userData.taxes_for_invoicing}
                onChange={(e) => {}}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {/* <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Select
              placeholder="Forma pago"
              onChange={(e) => setUserData({ ...userData, forma_pago: e })}
              value={userData.forma_pago}
            >
              <Option value="Plataforma">Plataforma</Option>
              <Option value="Transferencia">Transferencia</Option>
              <Option value="Mano">Pago en mano</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row> */}
      <Divider>Idioma para el cliente</Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Radio.Group
              onChange={(e) => {
                setUserData({ ...userData, idioma_mensaje: e.target.value });
              }}
              value={userData.idioma_mensaje}
            >
              <Space direction="horizontal">
                <Radio value={"ES"}>Español</Radio>
                <Radio value={"EN"}>Ingles</Radio>
                <Radio value={"FR"}>Frances</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Divider>Tipo de cliente</Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Radio.Group
              onChange={(e) => {
                setUserData({ ...userData, tipo_inquilino: e.target.value });
                setValue(e.target.value);
              }}
              value={userData.tipo_inquilino}
            >
              <Space direction="horizontal">
                <Radio value={"Inquilino"}>Inquilino</Radio>
                <Radio value={"Propietario"}>Propietario</Radio>
                <Radio value={"Limpiador"}>Limpiador</Radio>
                <Radio value={""}>
                  Otro More...
                  {value === "" ? (
                    <Input
                      value={userData.tipo_inquilino}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          tipo_inquilino: e.target.value,
                        })
                      }
                      style={{ width: 100, marginLeft: 10 }}
                    />
                  ) : null}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Divider>Valoracion estancia </Divider>
      <Row gutter={24}>
      <Col span={12}> Limpieza: </Col>
        <Col span={12}>
            <Rate allowHalf count={5} style={{ fontSize: '1.5em', color: 'red'}}
            value={Number(userData.valoracion_limpieza)}
            onChange={ (value) => {
              setUserData({
                ...userData,
                valoracion_limpieza: String(value),
              });
            }}
            />
        </Col>
      </Row>
      <Divider>Limpieza Valoracion</Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item>
            <Radio.Group
              onChange={(e) => {
                setUserData({ ...userData,
                   valorado: e.target.value });
                setValue(e.target.value);
              }}
              value={userData.valorado}
            >
              <Space direction="horizontal">
                <Radio value={"Valorado"}>Valorado</Radio>
                <Radio value={"Sin valorar"}>Sin valorar</Radio>
                <Radio value={"No se va a valorar"}>No se va a valorar</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Divider></Divider>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-submit">
          Actualizar Inquilino
        </Button>
      </Form.Item>
    </Form>
  );
}
