import React, { useState,useEffect } from 'react';
import { TimePicker } from "antd";
import { getAccessTokenApi } from "../../../src/api/auth";
import { updateInquilinoApi } from '../../api/inquilino';
import moment from "moment";
import dayjs from 'dayjs';
import Boton_peticion_handler from '../Admin/boton_peticion_handler/boton_peticion_handler';
const TimepickerLimpieza = ({ hora_actual, onOkHandler, setPageNumber }) => {
  const [horaSeleccionada, setHoraSeleccionada] = useState(hora_actual ?? "00:00");

  useEffect((
  ) => {
    
  }, [horaSeleccionada]);
  useEffect((
  ) => {
    setHoraSeleccionada(hora_actual)
  }, [ hora_actual]);

  const onChange = (hora, horaString) => {
    // Manejar cambios en el TimePicker
    //console.log(horaString);
    setHoraSeleccionada(horaString);
  };

  const token = getAccessTokenApi();

  const onEnviar = async () => {
    const resp = await onOkHandler(token, horaSeleccionada);
    //console.log(resp);
    if(resp.message){
      // Aquí cambias al formulario DNI después del éxito
      setPageNumber(0); // Suponiendo que '0' sea el número de página para el formulario DNI
    }else{
    }
  };

  return (
    <div>
      <TimePicker
        format="HH:mm"
        onChange={onChange}
        onOk={() =>onChange}
        defaultValue={moment(horaSeleccionada, 'HH:mm')}
        minuteStep={30} // Configura el incremento de minutos
      />
     <Boton_peticion_handler
           
            cuerpo={"Enviar"}
            handler={()=>{ onOkHandler(token, horaSeleccionada)}}
          />
    </div>
  );
};

export default TimepickerLimpieza;
