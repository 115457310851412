import React, { useEffect, useState } from "react";

import {
  createInmueble,
  obtenerListaInmuebles,
} from "../../../../../api/inmueble";
import { getAccessTokenApi } from "../../../../../api/auth";
import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Divider,
  Carousel,
  message
} from "antd";

import {
  BorderBottomOutlined,
  BorderTopOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  CheckCircleOutlined, 
  UploadOutlined
} from "@ant-design/icons";
import "./AddGastoForm.scss";
import moment from "moment";
import { asociarGastoUsuario, createGasto } from "../../../../../api/gastos";
import DesplegableMultiple from "../../../../DesplegableMultiple/DesplegableMultiple";
import Dragger from "antd/lib/upload/Dragger";
import jwtDecode from "jwt-decode";
export default function EditGastoForm(props) {
  const { setIsVisibleModal, setReloadUsers } = props;
  const accessToken = getAccessTokenApi();
  const [InmuebleData, setInmuebleData] = useState({
    origen_gasto: "Inmueble",
    id_inmueble_asociado: [],
    nombre_del_gasto: "",
    gasto_empresa: "",
    fecha_creacion: "",
    frecuencia_gasto: "puntual",
    descripcion_del_gasto: "",
    periodo_gasto: "",
    nombre_archivos: [],
    visible: false,
    id_asociado: ""
  });
  const openNotification = () => {
    //console.log("Abierto");
    notification["success"]({
      message: "Nuevo gasto añadido.",
      description: "Se ha añadido un nuevo gasto",
      onClick: () => {
        //console.log("Notification Clicked!");
      },
    });
  };

  const  handleUpload = (fileList) => {
    //console.log("ENTRA EN HANDLE handleUpload");
    if (fileList.length === 0) {
      return;
    }
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file.originFileObj);
    });
    //console.log("entra2")
    let id_asociado;
    //console.log("entra inmueble id",InmuebleData.id_inmueble_asociado);
    if (InmuebleData.id_inmueble_asociado.length !== 0) {
      //console.log("VACIO");
      id_asociado = InmuebleData.id_inmueble_asociado;
    } else {
      //console.log("DECODEA",jwtDecode(accessToken));
      const decodedToken = jwtDecode(accessToken);
      if (decodedToken && decodedToken.id) {
        id_asociado = decodedToken.id;
        //console.log("entra", decodedToken);
      } else {
        //console.log("error");
      }

    }
    //console.log("Aqui esta el id asociado jejeje", id_asociado);
    

     asociarGastoUsuario(accessToken,formData, id_asociado)
      .then((response) => {
        //console.log("Respuesta del servidor:", response);
        if (!response.message) {
          notification["error"]({
            message: response.error,
          });
        } else {
          notification["success"]({
            message: "Creada correctamente",
          });
        }
        message.success("Archivo enviado exitosamente.");
      
      })
      .catch((error) => {
        console.error("Error al enviar el archivo:", error);
        message.error("Error al enviar el archivo.");
      })
      .finally(() => {
        
      });
  };
  const addUser = (handleUpload , fileList) => {
    //console.log("creando");

    if (
      !InmuebleData.nombre_del_gasto ||
      !InmuebleData.gasto_empresa
      //|| !InmuebleData.owner ||
      // !InmuebleData.id_electronica_portal ||
      // !InmuebleData.id_sensor_temperatura ||
      // !InmuebleData.id_actuador_caldera ||
      // !InmuebleData.wifi_ssid ||
      // !InmuebleData.wifi_pass ||
      // !InmuebleData.router_user ||
      // !InmuebleData.telefono ||
      // !InmuebleData.router_pass
    ) {
      //console.log("creando1");
      //console.log(InmuebleData);
      notification["error"]({
        message: "Nombre, fecha y el importe del gasto son obligatorios.",
      });
    } else {
      let id_asociado;
      if (InmuebleData.id_inmueble_asociado.length !== 0) {
        //console.log("VACIO");
        InmuebleData.id_asociado = InmuebleData.id_inmueble_asociado;
      } else {
        //console.log("DECODEA",jwtDecode(accessToken));
        const decodedToken = jwtDecode(accessToken);
        if (decodedToken && decodedToken.id) {
          InmuebleData.id_asociado = decodedToken.id;
          //console.log("entra", decodedToken);
        } else {
          //console.log("error");
        }
  
      }
      
      //console.log("creando3");
      const accesToken = getAccessTokenApi();
      //console.log(InmuebleData);
      createGasto(accesToken, InmuebleData,fileList)
        .then((response) => {
          //console.log("##############Responde: ", response);
          //handleUpload(fileList);
          //console.log("InmuebleData: ", InmuebleData);
          setIsVisibleModal(false);
          setReloadUsers(true);
          openNotification();
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-user-form">
      <AddForm
        InmuebleData={InmuebleData}
        setInmuebleData={setInmuebleData}
        addUser={addUser}
        handleUpload={handleUpload}
      />
    </div>
  );
}

function AddForm(props) {
  const { InmuebleData, setInmuebleData, addUser,handleUpload } = props;
  const { Option } = Select;

  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const handleSelectChange_frecuencia = (values) => {
    setInmuebleData({ ...InmuebleData, frecuencia_gasto: values });
  };

  const handleSelectChange_visible = (values) => {
    setInmuebleData({ ...InmuebleData, visible: values });
  };

  const handleSelectChange_periodo_gasto = (values) => {
    setInmuebleData({ ...InmuebleData, periodo_gasto: values });
  };
  const handleSelectChange_origen = (values) => {
    setInmuebleData({ ...InmuebleData, origen_gasto: values });
  };
  var nombres_pisos_a = [];
  var ids_pisos_a = {};
  var pisos_ids_a = {};
  const [nombres_pisos, setNombres_pisos] = useState([]);
  const [nombres_pisos_asoc, setnombres_pisos_asoc] = useState([]);
  const [id_nombres_pisos, setIdNombres_pisos] = useState({});
  const [nombres_id_pisos, setNombresIds_pisos] = useState({});
  const [defaultVal, setDefaultVal] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const accessToken = getAccessTokenApi();
  

  useEffect(() => {
    //console.log("AddForm1");
    obtenerListaInmuebles(accessToken).then((response) => {
      //console.log("Los pisos segun ID:", response.Pisos[0].id);
      //console.log("obtenerListaInmuebles", response.Pisos);

      for (let i = 0; i < response.Pisos.length; i++) {
        nombres_pisos_a.push(response.Pisos[i].nombre);
        ids_pisos_a[response.Pisos[i].nombre] = response.Pisos[i].id;
        pisos_ids_a[response.Pisos[i].id] = response.Pisos[i].nombre;
      }

      let nombres = [];
      setNombres_pisos(nombres_pisos_a);
      setIdNombres_pisos(ids_pisos_a);
      setNombresIds_pisos(pisos_ids_a);
      setnombres_pisos_asoc(nombres);
      //console.log("nombres_pisos_a", nombres_pisos_a);
      //console.log("ids_pisos_a", ids_pisos_a);
      //console.log("pisos_ids_a:", pisos_ids_a);
      //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
    });
  }, [InmuebleData]);

  const handleFileChange = (info) => {
    let fileList = [...info.fileList];
  
    // Limitar la cantidad de archivos a uno
    fileList = fileList.slice(-1);
  
    // Filtrar archivos inválidos y guardar los nombres
    fileList = fileList.filter((file) => {
      if (file.size > 1024 * 1024 * 5) {
        message.error(`${file.name} es demasiado grande. El tamaño máximo es 5MB.`);
        return false;
      }
      return true;
    });
  
    setFileList(fileList);
    // Actualizar el estado con los nombres de los archivos
    const fileNames = fileList.map(file => file.name);
    setInmuebleData({ ...InmuebleData, nombre_archivos: fileNames });
  };
 

  const handleSelectChange = (values) => {
    //console.log("id_nombres_pisosHANDEL", id_nombres_pisos);
    console.log(
      "id_inmueble_asociado handleSelectChange",
      InmuebleData.id_inmueble_asociado
    );

    setInmuebleData({
      ...InmuebleData,
      id_inmueble_asociado: values.map((x) => {
        return id_nombres_pisos[x] ?? "";
      }),
    });
    //console.log(values);
  };

  useEffect(() => {
    //console.log("He entrado en useEffect para val");
    if (Object.keys(nombres_id_pisos).length > 0) {
      //console.log("PRUEBA", Object.keys(nombres_id_pisos).length);
      //console.log(
      //   " userData.id_inmueble_asociado",
      //   InmuebleData.id_inmueble_asociado
      // );
      //console.log(
      //   "setDefaultVal",
      //   (InmuebleData.id_inmueble_asociado ?? []).map((x) => {
      //     return nombres_id_pisos[x] ?? "";
      //   })
      // );
      setDefaultVal(
        (InmuebleData.id_inmueble_asociado ?? []).map((x) => {
          return nombres_id_pisos[x] ?? "";
        })
      );
    } else {
      setDefaultVal([]);
    }
  }, [InmuebleData, nombres_id_pisos]);

  const onFinish = (values) => {
    //console.log("VEAMOS QUE OCURRE");
    addUser(handleUpload,fileList)
  };

  return (
    <Form className="form-add" onFinish={onFinish}>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nombre del gasto
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Nombre del gasto"
              value={InmuebleData.nombre_del_gasto}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  nombre_del_gasto: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Precio/Coste
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Precio"
              value={InmuebleData.gasto_empresa}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  gasto_empresa: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Form.Item label="Fecha del gasto">
            <DatePicker
              renderExtraFooter={() => "extra footer"}
              onChange={(date, dateString) => {
                //console.log("onChange", date, dateString);

                setInmuebleData({
                  ...InmuebleData,
                  fecha_creacion: dateString,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Frecuencia del gasto
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            value={InmuebleData.frecuencia_gasto}
            // style={{ width: 120 }}
            onChange={handleSelectChange_frecuencia}
            options={[
              { value: "recurrente", label: "Recurrente" },
              { value: "puntual", label: "Puntual" },
              // { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Col>
      </Row>
      {InmuebleData.frecuencia_gasto === "recurrente" ? (
        <Row>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Frecuencia del gasto
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Select
              value={InmuebleData.periodo_gasto}
              // style={{ width: 120 }}
              onChange={handleSelectChange_periodo_gasto}
              options={[
                { value: "mensual", label: "mensual" },
                { value: "semanal", label: "semanal" },
                { value: "diario", label: "diario" },
                // { value: "disabled", label: "Disabled", disabled: true },
              ]}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Origen gasto
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            value={InmuebleData.origen_gasto}
            // style={{ width: 120 }}
            onChange={handleSelectChange_origen}
            options={[
              { value: "Inmueble", label: "Asociado a un piso" },
              { value: "Empresa", label: "Gasto de la empresa" },

              // { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Visible para el propietario
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            value={InmuebleData.visible}
            // style={{ width: 120 }}
            onChange={handleSelectChange_visible}
            options={[
              { value: true, label: "Visible" },
              { value: false, label: "No visible",defaultVal},
              // { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Col>
      </Row>

      <DesplegableMultiple
        valores={nombres_pisos}
        defaultValue2={defaultVal}
        onChange={handleSelectChange}
      />

      <Divider>Descripcion del gasto</Divider>

      <Row>
        <Col span={24}>
          <Form.Item>
            <TextArea
              rows={20}
              placeholder="Describe el gasto. Ejemplo: Compra de ventiladores para solucionar problema con el aire."
              maxLength={4000}
              value={InmuebleData.descripcion_del_gasto}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  descripcion_del_gasto: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item>
            <Dragger
              fileList={fileList}
              onChange={handleFileChange}
              multiple={false}
              customRequest={() => { }}
              showUploadList={{ showRemoveIcon: true }}
              beforeUpload={() => false}
              iconRender={(file, defaultIcon) =>
                file.status === "done" ? <CheckCircleOutlined /> : defaultIcon
              }
            >
              <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
            </Dragger>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-submit">
          Crear Gasto
        </Button>
      </Form.Item>
    </Form>
  );
}
