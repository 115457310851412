import React, { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';

const VerInformacion = ({ informacion }) => {
    return (
        <div style={{ padding: '20px', backgroundColor: 'white' }}>
            Comentario acerca del piso:
            <TextArea
            rows={20}
            maxLength={4000}
            value={informacion}
          />
        </div>
    );
};

export default VerInformacion;
