import React from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import "./LayoutBasic.scss";
import FOOTER_AUX from "../../src/components/Footer"
function LayoutDefault(props) {
  //console.log(props);
  const { routes } = props;
  const { Header, Content, Footer } = Layout;
  return (
   <>
   
      <LoadRouters routes={routes} />
      
      <FOOTER_AUX/>
      </>
  );
}

function LoadRouters({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}

export default LayoutDefault;
