import React, { useState, useEffect } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import "./Dispositivos.scss";
import "leaflet/dist/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { getAccessTokenApi } from "../../../api/auth";
import { getCurrentLocation } from "../../../api/map";
import { abrirPuertaUsuarioApi } from "../../../api/inquilino";
import { Divider, notification } from "antd";
import { encenderApagarCaldera, getInmueblesApi, verTemperaturaCaldera } from "../../../api/inmueble";

// Importar icono personalizado para los marcadores
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

export default function Dispositivos(props) {
  const [inmuebles, setUsersActive] = useState([]);
  const [temperaturaActual, setTemperaturaActual] = useState('');
  useEffect(() => {
      getInmueblesApi(getAccessTokenApi(), 1, true).then((response) => 
      {
        setUsersActive(response.Pisos);
      
      });
   
  }, []);

  
  const [coordenadas, setCoordenadas] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const newCoordenadas = [];
      for (const element of inmuebles) {
        if (element.latitud) {
          // const temperaturaActual = (element.id_sensor_temperatura && element.id_sensor_temperatura != "" ) ? await verTemperaturaCaldera_aux(element.name, element.direccion_inmueble, element.id_sensor_temperatura) : "";
          newCoordenadas.push({
            nombre: element.name,
            color: "rojo",
            punto: [element.latitud ?? 0, element.longitud ?? 0],
            direccion: element.direccion_inmueble,
            dispositivo: element.id_electronica_portal ?? 0,
            caldera: element.id_actuador_caldera ?? '',
            temperatura: element.id_sensor_temperatura ?? '',
            temperaturaActual: temperaturaActual ?? ""
          });
        }
      }
      setCoordenadas(newCoordenadas);
    };
    fetchData();
  }, [inmuebles]);
  const [vistaMapa, setVistaMapa] = useState(true);


  

  const abrirPuerta = async (nombre, direccion, dispositivo) => {
    // Aquí puedes implementar la lógica para abrir la puerta utilizando el dispositivo
    //console.log("Abriendo la puerta de:", nombre, "en", direccion, "con el dispositivo", dispositivo);
    let resp = await abrirPuertaUsuarioApi( getAccessTokenApi(),dispositivo);
    if(resp.message){
      //console.log("Hola exito")
      notification["success"]({
        message: "Exito en abrir la puerta en " + nombre
      });
    }else{
      notification["error"]({
        message: "Error: " + resp.error
      });
    }
   
  };

  const encenderCaldera = async (nombre, direccion, dispositivo, estado) => {
    // Aquí puedes implementar la lógica para abrir la puerta utilizando el dispositivo
    //console.log("Abriendo la puerta de:", nombre, "en", direccion, "con el dispositivo", dispositivo);
    let resp = await encenderApagarCaldera( getAccessTokenApi(),dispositivo, estado);
    if(resp.message){
      //console.log("Hola exito")
      notification["success"]({
        message: "Exito con la caldera"
      });
    }else{
      notification["error"]({
        message: "Error"
      });
    }
   
  };

  const verTemperaturaCaldera_aux = async (nombre, direccion, dispositivo) => {
    try {
      const resp = await verTemperaturaCaldera(getAccessTokenApi(), dispositivo);
      if (resp.message) {
        //console.log("Éxito al obtener la temperatura en", nombre);
        notification["success"]({
          message: "Éxito al obtener la temperatura en " + nombre
        });
        // Actualizar el estado de coordenadas con la nueva temperatura
        setCoordenadas(prevCoordenadas => {
          const newCoordenadas = [...prevCoordenadas];
          const index = newCoordenadas.findIndex(coordenada => coordenada.nombre === nombre);
          if (index !== -1) {
            newCoordenadas[index].temperaturaActual = resp.body.temperatura;
          }
          return newCoordenadas;
        });
        return resp.body.temperatura;
      } else {
        console.error("Error al obtener la temperatura en", nombre);
        notification["error"]({
          message: "Error al obtener la temperatura en " + nombre
        });
        return null;
      }
    } catch (error) {
      console.error("Error al obtener la temperatura:", error);
      notification["error"]({
        message: "Error al obtener la temperatura"
      });
      return null;
    }
  };
  
  return (
    <>
      <div className="switch">
        <label>
          
          <input type="checkbox" checked={vistaMapa} onChange={() => setVistaMapa(!vistaMapa)} />
          <span className="lever"> </span>
          Mapa Botones
         
        </label>
      </div>
      {vistaMapa ? (
        <MapContainer center={[41.658102, -0.874650]} zoom={13}  scrollWheelZoom={true} className="map-container">
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {coordenadas.map((coordenada, index) => (
            <Marker position={coordenada.punto} key={index}>
              <Popup>
                <div>
                  <h3>{coordenada.nombre}</h3>
                  <p>Dirección: {coordenada.direccion}</p>
                  <button className="button" onClick={() => abrirPuerta(coordenada.nombre, coordenada.direccion, coordenada.dispositivo)}>Abrir Puerta</button>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      ) : (
        <div className="grid-container">
          {coordenadas.map((coordenada, index) => (
            <div className="grid-item" key={index}>
              <h3>{coordenada.nombre}</h3>
              <p>Dirección: {coordenada.direccion}</p>
             
              <Divider>Puerta</Divider>
              <button className="button" onClick={() => abrirPuerta(coordenada.nombre, coordenada.direccion, coordenada.dispositivo)}>Abrir Puerta</button>
              
              
              
              {coordenada.temperatura ?
              <>
                <Divider>Caldera</Divider>

                {coordenadas[index]?.temperaturaActual ? `Tº ${coordenadas[index]?.temperaturaActual}` : ''}

                <button className="button" onClick={() => verTemperaturaCaldera_aux(coordenada.nombre, coordenada.direccion, coordenada.temperatura, true)}>Ver Tº</button>
                <button className="button" onClick={() => encenderCaldera(coordenada.nombre, coordenada.direccion, coordenada.temperatura, true)}>Encender Caldera</button>
                <button className="button" onClick={() => encenderCaldera(coordenada.nombre, coordenada.direccion, coordenada.temperatura, false)}>Apagar Caldera</button>
              </>:<></>
              }
            </div>
          ))}
        </div>
      )}
    </>
  );
}