// // Layout
// import LayoutAdmin from "../layouts/LayoutAdmin";
// import LayoutBasic from "../layouts/LayoutBasic";

// // Admin Pages
// import AdminHome from "../pages/Admin"; //Al ser exportacion default puedo llamarlo AdminHome o como quiera, si en este archivo hubiera una exportacion default y otra no default sería poner AdminHome, {funciones no defaul}
// import AdminSingIn from "../pages/Admin/SignIn";
// import AdminUsers from "../pages/Admin/Users";
// import Calendario from "../pages/Admin/Calendario";
// import Mantenimiento from "../pages/Admin/Incidencia/Incidencia_Por_Mantenimiento";
// // import Home from "../pages/Admin/Home";

// import inquilinos from "../pages/Admin/inquilinos";
// import AdminOutputs from "../pages/Admin/Outputs";
// import uploadFile from "../components/uploadFile";

// import Lavanderia from "../pages/Admin/Lavanderia";
// import Inmuebles from "../pages/Admin/Inmuebles";
// import Gastos from "../pages/Admin/Gastos";
// import FacturacionMes from "../pages/Admin/FacturacionMes";
// import FacturacionMes_total from "../pages/Admin/FacturacionMes_total";

// // Pages
// import Home from "../pages/Home";
// import Garaje from "../pages/Users/Garaje";

// // Other
// import Error404 from "../pages/Error404";
// import DNI_upload from "../pages/Users/DNI_upload/DNI_upload";
// import Administrar_facturas from "../pages/Admin/Modulo_Facturas/Administrar_facturas/Administrar_facturas";
// import FormularioAltaPisos from "../pages/Alta_pisos/Formulario_incio_piso/Formulario_incio_piso";
// import Alta_piso from "../pages/Alta_pisos/Alta_piso";
// import Recuperar_password from "../pages/Users/Recuperar_password";
// import Limpieza from "../pages/Admin/Limpieza";
// import { getAccessTokenApi, logout } from "../api/auth";
// import jwtDecode from "jwt-decode";
// import HomeBoard from "../pages/Admin/HomeBoard/HomeBoard";
// import Precios from "../pages/Admin/Precios";
// import Incidencia from "../pages/Admin/Incidencia";
// import Dispositivos from "../components/Admin/Dispositivos";
// import Cancelaciones from "../pages/Admin/Cancelaciones"
// //Las routes se llaman desde App

// let componente;

// const token = getAccessTokenApi();
// let role_general = null;

// try {
//   role_general = jwtDecode(token).role ?? null;
// } catch (e) {
//   console.log("Error decoding token:", e);
// }

// if (token) {
//   try {
    
//     console.log("TU ROL", role_general)
//     if (role_general === "Limpiador") {
//       componente = Limpieza;
//     } else if(role_general ==="Gestor") {
//       componente = HomeBoard;
//     }else if(role_general === "Propietario"){
//       componente = HomeBoard;
//     }else if(role_general === "Mantenimiento"){
//       componente = Incidencia;
//     }
//     else if(role_general === "Administrador"){
//       componente = HomeBoard;
//     }else{
//       logout();
//       // window.location.href = "/admin"
//       componente = AdminSingIn;
//     }
//   } catch (e) {
//     console.error("Error decodificando el token:", e);
//     componente = AdminSingIn; // Asigna un componente por defecto en caso de error al decodificar el token
//   }
// } else {
//   componente = AdminSingIn; // Asigna FacturacionMes si no hay token
// }

// function redirigir_desde_a(role_general, roles_componentes){
//     try{
//       // console.log("roles_componentes[role_general]",token, role_general)
//       let my_componente = AdminSingIn;
//       if(role_general){
//         if (role_general === "Gestor" || role_general === "Propietario" || role_general === "Administrador"|| role_general === "Mantenimiento"|| role_general === "Limpiador") {
//           my_componente= roles_componentes[role_general];
//         }
//       }
//     return my_componente;
//     }catch(e){
//       return AdminSingIn;
//     }
// }
// const routes = [
//   {
//     path: "/admin", //Siempre que pongan /admin se les cargara Layaout Admin y una de las rutas siguientes
//     // /admin/contact cargaría el componente LayoutAdmin
//     component: LayoutAdmin,
//     exact: false,
//     routes: [
//       {
//         path: "/admin",
//         // component: componente,
        
//         component: redirigir_desde_a(role_general, {"Administrador":HomeBoard,"Limpiador":Limpieza, "Gestor": HomeBoard, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
//         exact: true,
//       },
//       {
//         path: "/admin/inquilinos",
//         // component: inquilinos,
//         component: redirigir_desde_a(role_general, {"Administrador":inquilinos, "Limpiador":Limpieza, "Gestor": inquilinos, "Propietario": inquilinos, "Mantenimiento": Mantenimiento }),
//         exact: true,
//       },
//       {
//         path: "/admin/calendario",
//         // component: Calendario,
//         component: redirigir_desde_a(role_general, {"Administrador":Calendario,"Limpiador":Limpieza, "Gestor": Calendario, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
       
//         exact: true,
//       },
//       {
//         path: "/admin/limpieza",
//         // component: Limpieza,
//         component: redirigir_desde_a(role_general, {"Administrador":Limpieza,"Limpiador":Limpieza, "Gestor": Limpieza, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
       
//         exact: true,
//       },
//       {
//         path: "/admin/precios",
//         // component: Precios,
//         component: redirigir_desde_a(role_general, {"Administrador":Precios,"Limpiador":Limpieza, "Gestor": Precios, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
       
//         exact: true,
//       },
//       {
//         path: "/admin/Dispositivos",
//         // component: Dispositivos,
//         component: redirigir_desde_a(role_general, {"Administrador":Dispositivos,"Limpiador":Limpieza, "Gestor": Dispositivos, "Propietario": HomeBoard, "Mantenimiento": Dispositivos }),
       
//         exact: true,
//       },
//       {
//         path: "/admin/Mantenimiento",
//         // component: Dispositivos,
//         component: redirigir_desde_a(role_general, {"Administrador":Incidencia,"Limpiador":Limpieza, "Gestor": Incidencia, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
       
//         exact: true,
//       },
//       {
//         path: "/admin/incidencia",
//         component: redirigir_desde_a(role_general, {"Administrador":Incidencia,"Limpiador":Limpieza, "Gestor": Incidencia, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
//         exact: true,
//       },
//       // {
//       //   path: "/admin/lavanderia",
//       //   component: Lavanderia,
//       //   exact: true,
//       // },
//       {
//         path: "/admin/login",
//         component: AdminSingIn,
//         exact: true,
//       },
//       {
//         path: "/admin/users",
//         component: redirigir_desde_a(role_general, {"Administrador":AdminUsers,"Limpiador":Limpieza, "Gestor": AdminUsers, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
        
//         exact: true,
//       },
//       {
//         path: "/admin/home",
//         component: redirigir_desde_a(role_general, {"Administrador":HomeBoard,"Limpiador":Limpieza, "Gestor": HomeBoard, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
        
//         exact: true,
//       },
//       {
//         path: "/admin/gastos",
//         component: redirigir_desde_a(role_general, {"Administrador":Gastos,"Limpiador":Limpieza, "Gestor": Gastos, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
//       },
//       {
//         path: "/admin/graph",
        
//         component: redirigir_desde_a(role_general, {"Administrador":FacturacionMes,"Limpiador":Limpieza, "Gestor": FacturacionMes, "Propietario": FacturacionMes, "Mantenimiento": Mantenimiento }),
        
//         exact: true,
//       },
//       {
//         path: "/admin/graph_total",
//         component: redirigir_desde_a(role_general, {"Administrador":FacturacionMes_total,"Limpiador":Limpieza, "Gestor": FacturacionMes_total, "Propietario": FacturacionMes, "Mantenimiento": Mantenimiento }),
        
//         exact: true,
//       },
//       {
//         path: "/admin/inmuebles",
//         component: redirigir_desde_a(role_general, {"Administrador":Inmuebles,"Limpiador":Limpieza, "Gestor": Inmuebles, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
//         exact: true,
//       },
//       {
//         path: "/admin/facturas",
//         component: redirigir_desde_a(role_general, {"Administrador":Administrar_facturas,"Limpiador":Limpieza, "Gestor": Administrar_facturas, "Propietario": Administrar_facturas, "Mantenimiento": Mantenimiento }),
//         exact: true,
//       },   {
//         path: "/admin/Cancelaciones",
//         component: redirigir_desde_a(role_general, {"Administrador":Cancelaciones,"Limpiador":Limpieza, "Gestor": Cancelaciones, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
//         exact: true,
//       },
   
//       {
//         path: "/admin/alta_piso",
//         component: redirigir_desde_a(role_general, {"Administrador":Alta_piso,"Limpiador":Limpieza, "Gestor": Alta_piso, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
//         exact: true,
//       },
//       {
//         path: "/admin/alta_pisos_form",
//         component: redirigir_desde_a(role_general, {"Administrador":FormularioAltaPisos,"Limpiador":Limpieza, "Gestor": FormularioAltaPisos, "Propietario": HomeBoard, "Mantenimiento": Mantenimiento }),
//         exact: true,
//       },
    

//       {
//         component: Error404,
//       },
//     ],
//   },
//   {
//     path: "/",
//     /* component: LayoutBasic,*/
//     component: AdminSingIn,
//     exact: true,
//   },
//   {
//     path: "/home",
//     component: Home,
//     exact: true,
//   },
//   {
//     path: "/id_upload/:id",
//     component: DNI_upload,
//     exact: true,
//   },
//   {
//     path: "/reset-password/:token",
//     component: Recuperar_password,
//     exact: false,
//   },
//   {
//     path: "/acceder/:id",
//     component: Garaje,
//     exact: false,
//   },


//   // {
//   //   path: "/acceder",
//   //   component: Garaje,
//   //   exact: true,
//   // },
// ];

// export default routes;


import LayoutAdmin from "../layouts/LayoutAdmin";
import LayoutBasic from "../layouts/LayoutBasic";

// Admin Pages
import AdminHome from "../pages/Admin";
import AdminSignIn from "../pages/Admin/SignIn";
import AdminUsers from "../pages/Admin/Users";
import Calendario from "../pages/Admin/Calendario";
import Mantenimiento from "../pages/Admin/Incidencia/Incidencia_Por_Mantenimiento";
import Inquilinos from "../pages/Admin/inquilinos";
import AdminOutputs from "../pages/Admin/Outputs";
import UploadFile from "../components/uploadFile";
import Lavanderia from "../pages/Admin/Lavanderia";
import Inmuebles from "../pages/Admin/Inmuebles";
import Gastos from "../pages/Admin/Gastos";
import FacturacionMes from "../pages/Admin/FacturacionMes";
import FacturacionMesTotal from "../pages/Admin/FacturacionMes_total";

// Pages
import Home from "../pages/Home";
import Garaje from "../pages/Users/Garaje";

// Other
import Error404 from "../pages/Error404";
import DNIUpload from "../pages/Users/DNI_upload/DNI_upload";
import AdministrarFacturas from "../pages/Admin/Modulo_Facturas/Administrar_facturas/Administrar_facturas";
import FormularioAltaPisos from "../pages/Alta_pisos/Formulario_incio_piso/Formulario_incio_piso";
import AltaPiso from "../pages/Alta_pisos/Alta_piso";
import RecuperarPassword from "../pages/Users/Recuperar_password";
import Limpieza from "../pages/Admin/Limpieza";
import { getAccessTokenApi, logout } from "../api/auth";
import jwtDecode from "jwt-decode";
import HomeBoard from "../pages/Admin/HomeBoard/HomeBoard";
import Precios from "../pages/Admin/Precios";
import Incidencia from "../pages/Admin/Incidencia";
import Dispositivos from "../components/Admin/Dispositivos";
import Bancos from "../pages/Admin/Bancos";
import Cancelaciones from "../pages/Admin/Cancelaciones";
import politicaPrivacidad from "../pages/General/politica_privacidad";
import terminosCondiciones from "../pages/General/terminos_condiciones";
import BillingForm from "../pages/Users/BillingForm";
// Obtener el token y el rol del usuario
const token = getAccessTokenApi();
let roleGeneral = null;
console.log("roleGeneral",token)
if (token) {
  try {
    roleGeneral = jwtDecode(token).role ?? null;
  } catch (e) {
    console.log("Error decoding token:", e);
  }
}else{
  
}

const redirigirDesdeA = (role, rolesComponentes) => {
  try {
    console.log("roleGeneral",role)
    if (role) {
      console.log("roleGeneral 2",role)
      return rolesComponentes[role] || AdminSignIn;
    }
    console.log("roleGeneral3 ",role)
    logout();
    return AdminSignIn;
  } catch (e) {
    console.error("Error redirigiendo desde el rol:", e);
    return AdminSignIn;
  }
};

// Definición de rutas
const routes = [
  
  {
    path: "/admin",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/admin",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": HomeBoard,
          "Limpiador": Limpieza,
          "Gestor": HomeBoard,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/inquilinos",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Inquilinos,
          "Limpiador": Limpieza,
          "Gestor": Inquilinos,
          "Propietario": Inquilinos,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/calendario",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Calendario,
          "Limpiador": Limpieza,
          "Gestor": Calendario,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/limpieza",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Limpieza,
          "Limpiador": Limpieza,
          "Gestor": Limpieza,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/precios",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Precios,
          "Limpiador": Limpieza,
          "Gestor": Precios,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/bancos",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Bancos,
          "Limpiador": Limpieza,
          "Gestor": Bancos,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/dispositivos",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Dispositivos,
          "Limpiador": Limpieza,
          "Gestor": Dispositivos,
          "Propietario": HomeBoard,
          "Mantenimiento": Dispositivos
        }),
        exact: true,
      },
      {
        path: "/admin/mantenimiento",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Incidencia,
          "Limpiador": Limpieza,
          "Gestor": Incidencia,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/incidencia",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Incidencia,
          "Limpiador": Limpieza,
          "Gestor": Incidencia,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/login",
        component: AdminSignIn,
        exact: true,
      },
      {
        path: "/admin/users",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": AdminUsers,
          "Limpiador": Limpieza,
          "Gestor": AdminUsers,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/home",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": HomeBoard,
          "Limpiador": Limpieza,
          "Gestor": HomeBoard,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/gastos",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Gastos,
          "Limpiador": Limpieza,
          "Gestor": Gastos,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/graph",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": FacturacionMes,
          "Limpiador": Limpieza,
          "Gestor": FacturacionMes,
          "Propietario": FacturacionMes,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/graph_total",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": FacturacionMesTotal,
          "Limpiador": Limpieza,
          "Gestor": FacturacionMesTotal,
          "Propietario": FacturacionMes,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/inmuebles",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Inmuebles,
          "Limpiador": Limpieza,
          "Gestor": Inmuebles,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/facturas",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": AdministrarFacturas,
          "Limpiador": Limpieza,
          "Gestor": AdministrarFacturas,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      {
        path: "/admin/cancelaciones",
        component: redirigirDesdeA(roleGeneral, {
          "Administrador": Cancelaciones,
          "Limpiador": Limpieza,
          "Gestor": Cancelaciones,
          "Propietario": HomeBoard,
          "Mantenimiento": Mantenimiento
        }),
        exact: true,
      },
      // {
      //   path: "/admin/alta_piso",
      //   component: redirigirDesdeA(roleGeneral, {
      //     "Administrador": AltaPiso,
      //     "Limpiador": Limpieza,
      //     "Gestor": AltaPiso,
      //     "Propietario": HomeBoard,
      //     "Mantenimiento": Mantenimiento
      //   }),
      //   exact: true,
      // },
      // {
      //   path: "/admin/alta_pisos_form",
      //   component: redirigirDesdeA(roleGeneral, {
      //     "Administrador": FormularioAltaPisos,
      //     "Limpiador": Limpieza,
      //     "Gestor": FormularioAltaPisos,
      //     "Propietario": HomeBoard,
      //     "Mantenimiento": Mantenimiento
      //   }),
      //   exact: true,
      // },
      {
        component: Error404,
      },
    ],
  },
  {
    path: "/",
    component: LayoutBasic,
    exact: false,
    routes: [
      {
        path: "/",
        component: Home,
        exact: true,
      },
      {
        path: "/home",
        component: Home,
        exact: true,
      },
      {
        path: "/id_upload/:id",
        component: DNIUpload,
        exact: true,
      },
      {
        path: "/reset-password/:token",
        component: RecuperarPassword,
        exact: false,
      },
      {
        path: "/acceder/:id",
        component: Garaje,
        exact: false,
      },
      {
        path: "/factura-cliente/:id",
        component: BillingForm,
        exact: false,
      },
      {
        path: "/politicaPrivacidad",
        component: politicaPrivacidad,
      },
      {
        path: "/terminosCondiciones",
        component: terminosCondiciones,
      },
    ],
  },
  {
    path: "*",
    component: Error404,
  },
];

export default routes;
