import { basePath, apiVersion } from "./config";

// Crear una nueva noticia
export function createNewApi(token, newInfo) {
  const url = `${basePath}/${apiVersion}/new`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(newInfo)
  };

  return fetch(url, params)
    .then(response => response.json())
    .then(result => result)
    .catch(err => err.message);
}

// Obtener todas las noticias
export function getNewsApi(token) {
  const url = `${basePath}/${apiVersion}/news`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  return fetch(url, params)
    .then(response => response.json())
    .then(result => result)
    .catch(err => err.message);
}

// Obtener una noticia específica
export function getNewApi(token, newId) {
  const url = `${basePath}/${apiVersion}/new/${newId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  return fetch(url, params)
    .then(response => response.json())
    .then(result => result)
    .catch(err => err.message);
}

// Actualizar una noticia
export function updateNewApi(token, newId, newInfo) {
  const url = `${basePath}/${apiVersion}/new/${newId}`;

  const params = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(newInfo)
  };

  return fetch(url, params)
    .then(response => response.json())
    .then(result => result)
    .catch(err => err.message);
}

// Eliminar una noticia
export function deleteNewApi(token, newId) {
  const url = `${basePath}/${apiVersion}/new/${newId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  return fetch(url, params)
    .then(response => response.json())
    .then(result => result)
    .catch(err => err.message);
}
