import React, { useEffect, useRef } from "react";

const LectorDni = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true }) // Solicita acceso a la cámara
        .then((stream) => {
          // Establece el flujo de video en la etiqueta <video>
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
          }
        })
        .catch((error) => {
          console.error("Error al acceder a la cámara: ", error);
        });
    }
  }, []);

  return (
    <div>
      video
      <video ref={videoRef} autoPlay />
    </div>
  );
};

export default LectorDni;
