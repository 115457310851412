import React, { useState, useEffect } from "react";
import { Spin, Button } from "antd";

export default function Boton_peticion_handler_v2(props) {
  const { cuerpo, handler, style } = props;
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {}, [isLoading]);

  const content = isLoading ? (
    <><Spin size="large"></Spin></>
    
  ) : (
    <Button
      onClick={async ()=>{
        setisLoading(true)
        await handler();
        setisLoading(false)
      }}
      type="primary"
      label="Abrir enlace en nueva pestaña"
      style={style}
    >
      {cuerpo}
    </Button>
  );

  return content;
}
