
// let environment = "localhost"; // Puedes cambiar este valor según el entorno deseado: "localhost", "production" o "development"
let environment = "production"; // Puedes cambiar este valor según el entorno deseado: "localhost", "production" o "development"

let ip, ipfront, basePath, basePathFrontend, socketPath;

switch (environment) {
  case "localhost":
    ip = "localhost";
    basePath = `http://${ip}:3978/api`;
    ipfront = "localhost";
    basePathFrontend = `http://${ipfront}:3000`;
    socketPath = `http://${ip}:3977`;
    break;
    
  case "development":
      ip = "development.comotucasa.online";
      basePath = `https://${ip}:3978/api`;
      ipfront = "pruebas.comotucasa.online";
      basePathFrontend = `http://${ipfront}`;
      socketPath = `https://${ip}:3978`;
      break;
  
  case "production":
    ip = "api.comotucasa.online";
    basePath = `https://${ip}:3977/api`;
    ipfront = "comotucasa.online";
    basePathFrontend = `https://${ipfront}`;
    socketPath = `https://${ip}:3977`;
    break;

  
  default:
    throw new Error("Entorno no válido");
}

export { ip, basePath, socketPath, basePathFrontend };

export const apiVersion = "v1";
