import { basePath, apiVersion } from "./config";

export function signUpApi(data) {
  const url = `${basePath}/${apiVersion}/sign-up`;
  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  ////console.log(data);

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (result.user) {
        return { ok: true, message: "Usuario creado correctamente" };
      }
      return { ok: false, message: result.message };
    })
    .catch((err) => {
      return { ok: false, message: err.message };
    });
}

//Logeo del usuario en la web
export function signInApi(data) {
  //console.log(basePath);
 

  const url = `${basePath}/${apiVersion}/sign-in`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      ////console.log(result);
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function createInquilino(accessToken, data) {
  try {
    //console.log("formData");
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    //console.log(formData);
    const url = `${basePath}/${apiVersion}/inquilino`;
    const params = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    };

    const response = await fetch(url, params);
    const result = await response.json();

    if (response.status !== 201) throw result;

    return result;
  } catch (error) {
    //console.log(error);
  }
}
//El token es necesario para poder atacar al backend
export function getUsersApi(token) {
  const url = `${basePath}/${apiVersion}/users`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getInquilinosActiveApi(token, status) {
  const url = `${basePath}/${apiVersion}/inquilinos-active?active=${status}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function guardarComentarioLimpieza(token, comentario, inmuebleSelected, reserva_seleccionada) {
 
  const url = `${basePath}/${apiVersion}/update-comentarios-por-piso`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ comentario: comentario, inmuebleSelected: inmuebleSelected, reserva_seleccionada: reserva_seleccionada }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}


export function getInquilinosByTlf(token, tlf) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/inquilino/getByTlf`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ tlf: tlf }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}     

export function postInquilinoInformacionGenerarFactura(token, code, body) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/updateInquilinoInformacionGenerarFactura/${code}`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body ),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}     

export function obtener_inquilinos_a_los_que_generar_factura(token) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/obtener_inquilinos_a_los_que_generar_factura
`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}     

export function obtener_inquilino_ha_pedido_factura(token, code) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/InquilinoFacturaYaSolicitada/${code}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}     

export function eliminar_inquilino_ha_pedido_factura(token, code) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/InquilinoEliminarFacturaSolicitada/${code}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}    


export function queryInquilinosApi(token, status, roles) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/inquilinos?active=${status}`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ roles: roles }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}                                               
export function queryInquilinosApiInactivosFecha(token, status, roles, fecha_seleccionada) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/inquilinos-inactivos-fecha`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ roles: roles, fecha_seleccionada: fecha_seleccionada }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
export function queryInquilinosCanceladosApi(token) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/inquilinos-cancelados`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    }
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
export function queryInquilinosApiDNI(token, status, roles, dni_check) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/inquilinos?active=${status}&dni=${dni_check}`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ roles: roles }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function queryInquilinosApiFecha(token, status, roles, fechas) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/inquilinos?fecha=${fechas}&active=${status}`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ roles: roles }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function queryInquilinosApiFechaSegunEstado(token, status, roles, fechas, tipo) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/inquilinos?fecha=${fechas}&active=${status}&estado_entrada_salida=${tipo}`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ roles: roles }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function queryInquilinosApiSinValorar(token, roles, fechasAnteriores, valorado) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/inquilinos?fechasAnteriores=${fechasAnteriores}&valorado=${valorado}`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ roles: roles }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getInquilinosApi(token, status, roles) {
  //console.log(roles);
  const url = `${basePath}/${apiVersion}/inquilinos?active=${status}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
export function uploadAvatarApi(token, avatar, userId) {
  const url = `${basePath}/${apiVersion}/upload-avatar/${userId}`;

  const formData = new FormData();
  formData.append("avatar", avatar, avatar.name);

  const params = {
    method: "PUT",
    body: formData,
    headers: {
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getAvatarApi(avatarName) {
  const url = `${basePath}/${apiVersion}/get-avatar/${avatarName}`;

  return fetch(url)
    .then((response) => {
      return response.url;
    })
    .catch((err) => {
      return err.message;
    });
}


export async  function getInquilinosById(accessToken, id) {
  const url = `${basePath}/${apiVersion}/inquilinoById`;

   
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken
        },
        body: JSON.stringify({ id_inquilino:id }),
      });
  

  
      const data = await response.json();
      return data;
      //console.log('Respuesta del servidor:', data);
      // Maneja la respuesta del servidor según tus necesidades
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
    }  
}
//Te devuelve todas las entradas y salidas que hay en un piso en un día
export async  function getInquilinosOneDay(selectedDate, accessToken) {
  const url = `${basePath}/${apiVersion}/get-inquilinos-salen-entra-un-dia-en-concreto`;

    const fecha = selectedDate;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken
        },
        body: JSON.stringify({ fecha:fecha }),
      });
  

  
      const data = await response.json();
      return data;
      //console.log('Respuesta del servidor:', data);
      // Maneja la respuesta del servidor según tus necesidades
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
    }  
}

export async  function getLimpiezasOneDay(selectedDate, accessToken) {
  const url = `${basePath}/${apiVersion}/get-limpiezas-un-dia-en-concreto`;

    const fecha = selectedDate;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken
        },
        body: JSON.stringify({ fecha:fecha }),
      });
  

  
      const data = await response.json();
      return data;
      //console.log('Respuesta del servidor:', data);
      // Maneja la respuesta del servidor según tus necesidades
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
    }  
}
//Te devuelve todas las entradas y salidas que hay en un piso en un día
export async  function getInquilinosOneDayList(selectedDate, accessToken) {
  const url = `${basePath}/${apiVersion}/get-inquilinos-salen-entra-un-dia-en-concreto-list`;

    const fecha = selectedDate;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken
        },
        body: JSON.stringify({ fecha:fecha }),
      });
  

  
      const data = await response.json();
      return data;
      //console.log('Respuesta del servidor:', data);
      // Maneja la respuesta del servidor según tus necesidades
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
    }  
}

export async  function getInquilinosOneDayDetailed(selectedDate, accessToken) {
  const url = `${basePath}/${apiVersion}/get-inquilinos-salen-entra-un-dia-en-concreto_detailed`;

    const fecha = selectedDate;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken
        },
        body: JSON.stringify({ fecha:fecha }),
      });
  

  
      const data = await response.json();
      return data;
      //console.log('Respuesta del servidor:', data);
      // Maneja la respuesta del servidor según tus necesidades
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
    }  
}

export async  function getInquilinosAsignados(selectedDate, accessToken) {
  const url = `${basePath}/${apiVersion}/get-pisos-asignados-por-dias-by-limpiador`;

    const fecha = selectedDate;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken
        },
        body: JSON.stringify({ fecha:fecha }),
      });
  

  
      const data = await response.json();
      return data;
      //console.log('Respuesta del servidor:', data);
      // Maneja la respuesta del servidor según tus necesidades
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
    }  
}


export async  function getComentarioLimpiezaPorPiso(accesToken,monthQuery,yearQuery,inmuebleSelected) {
  const url = `${basePath}/${apiVersion}/get-comentarios-por-piso`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accesToken
        },
        body: JSON.stringify({ monthQuery:monthQuery, yearQuery:yearQuery, idPiso:inmuebleSelected }),
      });
  

  
      const data = await response.json();
      return data;
      //console.log('Respuesta del servidor:', data);
      // Maneja la respuesta del servidor según tus necesidades
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
    }  
}





export async function getPisosLimpiosPorMes(selectedDate, idInmueble, accessToken) {
  const url = `${basePath}/${apiVersion}/get-pisos-limpios-por-mes`;

    const fecha = selectedDate;
    const id_Inmueble = idInmueble;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken
        },
        body: JSON.stringify({ fecha:fecha , idInmueble:id_Inmueble }),
      });
  

  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
    }  
}

export async function getPisosLimpiosPorMesLimpiadorById(selectedDate, idLimpiador, accessToken) {
  const url = `${basePath}/${apiVersion}/get-pisos-limpios-por-mes-by-limpiador-id`;

    const fecha = selectedDate;
    const id_limpiador = idLimpiador;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken
        },
        body: JSON.stringify({ fecha:fecha , id_limpiador:id_limpiador }),
      });
  

  
      const data = await response.json();
      return data;
      //console.log('Respuesta del servidor:', data);
      // Maneja la respuesta del servidor según tus necesidades
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
    }  
}

export async function getPisosLimpiosPorMesLimpiador(selectedDate, accessToken) {
  const url = `${basePath}/${apiVersion}/get-pisos-limpios-por-mes-by-limpiador`;

    const fecha = selectedDate;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken
        },
        body: JSON.stringify({ fecha:fecha}),
      });
  

  
      const data = await response.json();
      return data;
      //console.log('Respuesta del servidor:', data);
      // Maneja la respuesta del servidor según tus necesidades
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      // Maneja el error según tus necesidades
    }  
}


export async  function updateInquilinoLimpiezaApi(token, inquilino_id, limpiador_id) {
  const url = `${basePath}/${apiVersion}/inquilino/${inquilino_id}/limpiadorUpdate`;

  const params = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({limpiador_id:limpiador_id}),
  };

  try {
    const response = await fetch(url, params);
    const data = await response.json();

    return data;
  } catch (error) {
   //console.log(error);
  }

}

export async  function updateInquilinoApi(token, user, userId) {
  const url = `${basePath}/${apiVersion}/inquilino/${userId}`;

  const params = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(user),
  };

  try {
    const response = await fetch(url, params);
    const data = await response.json();

    return data;
  } catch (error) {
   //console.log(error);
  }

}

export function activateInquilinoApi(token, userId, status) {
  const url = `${basePath}/${apiVersion}/activate-inquilino/${userId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      active: status,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}



export function deleteUserApi(token, userId) {
  const url = `${basePath}/${apiVersion}/user/${userId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function signUpAdminApi(token, data) {
  const url = `${basePath}/${apiVersion}/sign-up-admin`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export function deleteInquilinoApi(token, userId) {
  const url = `${basePath}/${apiVersion}/inquilino/${userId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result.message;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function abrirPuertaUsuarioApi(token,id) {
  const url = `${basePath}/${apiVersion}/usuario-abrir`;
  //console.log("abrirPuertaInquilinoApi", id);

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      id: id
    }),
  };

  return await fetch(url, params)
    .then((response) => {
      //console.log("abrirPuertaInquilinoApi", response);
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export async function encenderCalderaApi(token,id) {
  const url = `${basePath}/${apiVersion}/usuario-abrir`;
  //console.log("abrirPuertaInquilinoApi", id);

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      id: id
    }),
  };

  return await fetch(url, params)
    .then((response) => {
      //console.log("abrirPuertaInquilinoApi", response);
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}



export function abrirPuertaInquilinoApi(code) {
  const url = `${basePath}/${apiVersion}/inquilino-abrir`;
  //console.log("abrirPuertaInquilinoApi", code);

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code: code,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      //console.log("abrirPuertaInquilinoApi", response);
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
export function abrirPuertaNukiInquilinoApi(code, code_nuki, entrar) {
  const url = `${basePath}/${apiVersion}/inquilino-abrir-puerta-casa`;
  //console.log("abrirPuertaInquilinoApi", code);
  //console.log("code_nuki", code_nuki);
  //console.log("entrar", entrar);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code: code,
      code_nuki: code_nuki,
      entrar: entrar,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      //console.log("abrirPuertaInquilinoApi", response);
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function abrirPuertaYCERRInquilinoApi(code, cerradura_id) {
  const url = `${basePath}/${apiVersion}/inquilino-abrir-puerta-casa`;
  //console.log("abrirPuertaInquilinoApi", code);
  //console.log("code_nuki", cerradura_id);

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code: code,
      cerradura_id: cerradura_id,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      //console.log("abrirPuertaInquilinoApi", response);
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function postNewEstadoFlujo(token, id) {
  const url = `${basePath}/${apiVersion}/inquilino-new-estado-flujo/${id}`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      //console.log("abrirPuertaInquilinoApi", response);
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function numeroPuertasInquilinoApi(code) {
  const url = `${basePath}/${apiVersion}/numero-puertas-casa`;
  //console.log("abrirPuertaInquilinoApi", code);

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code: code,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      //console.log("abrirPuertaInquilinoApi", response);
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function InquilinoInformacionApi(code) {
  const url = `${basePath}/${apiVersion}/inquilino-informacion`;
  //console.log("InquilinoInformacionApi", code);

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code: code,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      //console.log("abrirPuertaInquilinoApi", response);
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function Inquilino_subir_id_police(id_data, code, signatureImage) {
  const url = `${basePath}/${apiVersion}/subir-id-police`;
  //console.log("InquilinoInformacionApi", code);

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code: code,
      id_data: id_data,
      imageData: signatureImage,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      //console.log("abrirPuertaInquilinoApi", response);
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}


export function actualizarHoraDeEntradaSalida(code, hora_entrada, hora_salida,hora_entrada_modificada_por_cliente) {
  
  const url = `${basePath}/${apiVersion}/inquilino-actualiza-hora-entrada-salida/${code}`;
  let params;
  if(hora_entrada && hora_salida){
    params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        hora_entrada: hora_entrada,
        hora_salida: hora_salida,
        hora_entrada_modificada_cliente: hora_entrada_modificada_por_cliente,
      }),
    };
  }else{
  if(hora_entrada){
   params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      hora_entrada: hora_entrada,
      hora_entrada_modificada_cliente: hora_entrada_modificada_por_cliente,
    }),
  };
  }
  if(hora_salida){
   params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      hora_salida: hora_salida,
    }),
  };
  }
}
  //console.log('InquilinoInformacionApi', code);

  return fetch(url, params)
    .then((response) => {
      //console.log('abrirPuertaInquilinoApi', response);
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}

export function actualizarStepNumber(code, check_in_step) {

  const url = `${basePath}/${apiVersion}/inquilino-actualiza-step-number/${code}`;
  let params;
  params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      check_in_step: check_in_step,
    }),
  };
//console.log('InquilinoInformacionApi', code);

return fetch(url, params)
  .then((response) => {
    //console.log('Actualizar Step Number', response);
    return response.json();
  })
  .catch((err) => {
    return err.message;
  });
}

export function actualizarEmailInquilino(code, email) {

  const url = `${basePath}/${apiVersion}/inquilino-actualiza-email/${code}`;
  let params;
  params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: email,
    }),
  };
//console.log('InquilinoInformacionApi', code);

return fetch(url, params)
  .then((response) => {
    //console.log('Actualizar Step Number', response);
    return response.json();
  })
  .catch((err) => {
    return err.message;
  });
}
export async function  actualizarEstadoEntradaInquilino(code, entrada_realizada) {

  const url = `${basePath}/${apiVersion}/inquilino-actualiza-estado-entrada/${code}`;
  let params;
  params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      entrada_realizada: entrada_realizada,
    }),
  };
//console.log('InquilinoInformacionApi', code);

return fetch(url, params)
  .then((response) => {
    //console.log('Actualizar Step Number', response);
    return response.json();
  })
  .catch((err) => {
    return err.message;
  });
}
export async function  actualizarUsoEnlaceInquilino(code, primera_apertura_enlace) {

  const url = `${basePath}/${apiVersion}/inquilino-actualiza-enlace-inquilino/${code}`;
  let params;
  params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      primera_apertura_enlace:primera_apertura_enlace,
      
    }),
  };
//console.log('InquilinoInformacionApi', code);

return fetch(url, params)
  .then((response) => {
    //console.log('Actualizar Step Number', response);
    return response.json();
  })
  .catch((err) => {
    return err.message;
  });
}
export function enviarEmailFacturayDatos(id, email) {

  const url = `${basePath}/${apiVersion}/enviarMailConFacturayDatos`;
  let params;
  params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      code: id,
      email: email
    }),
  };
//console.log('InquilinoMailSend', id);

return fetch(url, params)
  .then((response) => {
    //console.log('Enviar Mail', response);
    return response.json();
  })
  .catch((err) => {
    return err.message;
  });
}


export function getProximaFechaDisponiblev1(token, fechaBusqueda, inmuebleId) {
 
  const url = `${basePath}/${apiVersion}/getProximaFechaPisoDisponiblev1`;
  let params;
  params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    
    body: JSON.stringify({
      fechaBusqueda: fechaBusqueda,
      inmuebleId: inmuebleId
    }),
  };
//console.log('InquilinoMailSend', id);

return fetch(url, params)
  .then((response) => {
    //console.log('Enviar Mail', response);
    return response.json();
  })
  .catch((err) => {
    return err.message;
  });
}




export function avaibookCargarInquilinos(token) {

  const url = `${basePath}/${apiVersion}/avaibook-cron`;
  let params;
  params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
       Authorization: token
    },
  }

return fetch(url, params)
  .then((response) => {
    //console.log('Correcto', response);
    return response.json();
  })
  .catch((err) => {
    return err.message;
  });
}