
import { basePath, apiVersion } from "./config";


export function ObtenerBancos(accesToken) {
    const url = `${basePath}/${apiVersion}/bancos`;
    //console.log("InquilinoInformacionApi", code);
  
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accesToken
      },
      body: "",
    };
  
    return fetch(url, params)
      .then((response) => {
        //console.log("abrirPuertaInquilinoApi", response);
        return response.json();
      })
      .catch((err) => {
        return err.message;
      });
  }

  export function enviarFicheroABancos(accesToken, formData) {
    const url = `${basePath}/${apiVersion}/bancos-enviarFichero`;
    //console.log("InquilinoInformacionApi", code);
  
    const params = {
      method: "POST",
      headers: {
        Authorization: accesToken
      },
      body: formData,
    };
  
    return fetch(url, params)
      .then((response) => {
        //console.log("abrirPuertaInquilinoApi", response);
        return response.json();
      })
      .catch((err) => {
        return err.message;
      });
  }