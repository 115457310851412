import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';

const { Title } = Typography;

const AirbnbReservas = ({ datos_Airbnb, inmueble }) => {
  const nombresPisos = Object.keys(datos_Airbnb).sort();
  const [pisoSeleccionado, setPisoSeleccionado] = useState(inmueble ?? null);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setPisoSeleccionado(inmueble);
  }, [inmueble]);

  useEffect(() => {
    if (pisoSeleccionado) {
      const reservasAgosto = obtenerReservasAgosto(pisoSeleccionado);
      setSelectedRows(reservasAgosto.map((_, index) => index));
    }
  }, [pisoSeleccionado]);

  const obtenerFechasReservas = () => {
    const fechas = [];
    Object.values(datos_Airbnb).forEach(piso => {
      Object.keys(piso).forEach(fecha => {
        fechas.push(fecha);
      });
    });
    return fechas.sort();
  };

  const fechasReservas = obtenerFechasReservas();
  const fechaMasTemprana = fechasReservas[0];
  const fechaMasReciente = fechasReservas[fechasReservas.length - 1];

  const obtenerReservasAgosto = (piso) => {
    if (datos_Airbnb[piso]) {
      const reservas = datos_Airbnb[piso];
      const reservasAgosto = Object.entries(reservas);
      return reservasAgosto;
    }
    return [];
  };

  const calcularSuma = (reservasAgosto) => {
    const sumaPagos = reservasAgosto.reduce((total, reserva) => 
      selectedRows.includes(reserva.key) ? total + parseFloat(reserva.pago) : total, 0
    );
    const sumaComisiones = reservasAgosto.reduce((total, reserva) => 
      selectedRows.includes(reserva.key) ? total + parseFloat(reserva.comision) : total, 0
    );
    return { sumaPagos, sumaComisiones };
  };

  const reservasAgosto = pisoSeleccionado ? obtenerReservasAgosto(pisoSeleccionado) : [];
  const dataSource = reservasAgosto.map(([fecha, reserva], index) => ({
    key: index,
    fecha_entrada: reserva.fecha_entrada,
    fecha_salida: reserva.fecha_salida,
    pago: reserva.pago,
    comision: reserva.comision,
    codigo: reserva.codigo,
    estado: reserva.estado,
    nombre:reserva.nombre
  }));

  const { sumaPagos, sumaComisiones } = calcularSuma(dataSource);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: 'Fecha Entrada',
      dataIndex: 'fecha_entrada',
      key: 'fecha_entrada'
    },
    {
      title: 'Fecha Salida',
      dataIndex: 'fecha_salida',
      key: 'fecha_salida'
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre'
    },
    {
      title: 'Pago',
      dataIndex: 'pago',
      key: 'pago'
    },
    {
      title: 'Comisión',
      dataIndex: 'comision',
      key: 'comision'
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado'
    },
    {
      title: 'ID',
      dataIndex: 'codigo',
      key: 'codigo'
    }
  ];

  return (
    <div>
      <Title level={2}>Reservas desde {fechaMasTemprana} hasta {fechaMasReciente}</Title>

      {pisoSeleccionado && (
        <>
          <div>
            Suma de Pagos: <strong>€{sumaPagos.toFixed(2)}</strong>
            <br />
            Suma de Comisiones: <strong>€{sumaComisiones.toFixed(2)}</strong>
          </div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </>
      )}
    </div>
  );
};

export default AirbnbReservas;
