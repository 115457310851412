import React, { useState,useEffect } from "react";

import { createInmueble } from "../../../../../api/inmueble";
import { getAccessTokenApi } from "../../../../../api/auth";
import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Divider,
  Carousel,
  Collapse,
  Checkbox
} from "antd";

import {
  BorderBottomOutlined,
  BorderTopOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
} from "@ant-design/icons";
import "./AddInmuebleForm.scss";
import moment from "moment";
import InventoryList from "../EditInmuebleForm/InventoryList";
import { getUsersByRoleApi } from "../../../../../api/user";
export default function EditInmuebleForm(props) {
  const { setIsVisibleModal, setReloadUsers } = props;
  const [InmuebleData, setInmuebleData] = useState({});
  
  const openNotification = () => {
    //console.log("Abierto");
    notification["success"]({
      message: "Nuevo inmueble añadido.",
      description: "Se ha añadido un nuevo inmueble",
      onClick: () => {
        //console.log("Notification Clicked!");
      },
    });
  };

  async function handleCreateInmueble(accesToken, InmuebleData) {
    try {
      const response = await createInmueble(accesToken, InmuebleData);
      console.log(response);
      if (response.error) {
        notification["error"]({
          message: "Error al crear el inmueble: \n -- " + response.error,
        });
      } else {
        setInmuebleData({});
        setIsVisibleModal(false);
        setReloadUsers(true);
        openNotification();
      }
    } catch (err) {
      notification["error"]({
        message: err,
      });
    }
  }
  const addUser = (event) => {
    //console.log("creando");

    if (
      !InmuebleData.name
      //|| !InmuebleData.owner ||
      // !InmuebleData.id_electronica_portal ||
      // !InmuebleData.id_sensor_temperatura ||
      // !InmuebleData.id_actuador_caldera ||
      // !InmuebleData.wifi_ssid ||
      // !InmuebleData.wifi_pass ||
      // !InmuebleData.router_user ||
      // !InmuebleData.telefono ||
      // !InmuebleData.router_pass
    ) {
      //console.log("creando1");
      //console.log(InmuebleData);
      notification["error"]({
        message: "Todos los campos son obligatorios.",
      });
    } else {
     
     // Llamada a la función
     handleCreateInmueble(getAccessTokenApi(), InmuebleData);
    }
  };

  return (
    <div className="add-user-form">
      <AddForm
        InmuebleData={InmuebleData}
        setInmuebleData={setInmuebleData}
        addUser={addUser}
      />
    </div>
  );
}

function AddForm(props) {
  const { InmuebleData, setInmuebleData, addUser } = props;
  const { Option } = Select;

  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const handleSelectChange = (values) => {
    setInmuebleData({ ...InmuebleData, tipo_gestion: values });
  };
  function handleInventoryChange(values){
    setInmuebleData({ ...InmuebleData, inventario: values });
  }
  const [usuarios_limpieza, set_usuarios_limpieza] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accesToken = getAccessTokenApi();
        const response = await getUsersByRoleApi(accesToken,"Limpiador");
        set_usuarios_limpieza(response.users.map((user) => ({ value: user.id, label: user.name })));
      } catch (error) {
        console.error('Error al obtener la lista de inmuebles:', error);
      }
    };
  
    fetchData();
  }, []);
  return (
    <Form className="form-add" onFinish={addUser}>
      <Collapse >
     
      <Collapse.Panel header="Tipo de gestion" key="Gestion">
     
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Tipo de gestión
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            value={InmuebleData.tipo_gestion}
            // style={{ width: 120 }}
            onChange={handleSelectChange}
            options={[
              { value: "Gestion_neto", label: "Gestion_neto" },
              { value: "Gestion_bruta", label: "Gestion_bruta" },
              { value: "Propio", label: "Propio" },
              { value: "Subarriendo", label: "Subarriendo" },
              // { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Comisión por gestión
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Comision por gestión"
              value={InmuebleData.comision}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, comision: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Alquiler subarriendo con IVA
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Gastos fijos subarriendo"
              value={InmuebleData.gastos_fijos_subarriendo}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, gastos_fijos_subarriendo: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
           Suministros al mes con IVA
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="gastos_estimados_suministros"
              value={InmuebleData.gastos_estimados_suministros}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, gastos_estimados_suministros: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>


      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Gasto por limpieza
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Gasto limpieza"
              value={InmuebleData.coste_limpieza}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  coste_limpieza: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Gasto personal limpieza
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Gasto personal limpieza"
              value={InmuebleData.coste_personal_limpieza}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  coste_personal_limpieza: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Gasto material limpieza
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Gasto productos limpieza"
              value={InmuebleData.coste_productos_limpieza}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  coste_productos_limpieza: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Suplemento por dia festivo
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder=" Suplemento por dia festivo"
              value={InmuebleData.coste_festivo_limpieza}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  coste_festivo_limpieza: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item label="Inicio gestion">
            <RangePicker
              renderExtraFooter={() => "extra footer"}
              onChange={(dates) => {
                //console.log("onChange");
                //console.log(dates[0]);
                //console.log(dates[1]);
                setInmuebleData({
                  ...InmuebleData,
                  fecha_inicio_gestion: dates[0].format("YYYY-MM-DD"),
                  fecha_fin_gestion: dates[1].format("YYYY-MM-DD"),
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
     
      </Collapse.Panel>
      <Collapse.Panel header="Datos del Inmueble" key="datos">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nombre Inmueble
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Nombre Inmueble"
              value={InmuebleData.name}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Dirección Inmueble
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Direccion inmueble"
              value={InmuebleData.direccion_inmueble}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, direccion_inmueble: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>     
 <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          rules={[{ required: true, message: 'Por favor ingrese la ciudad' }]}
        >
          Ciudad
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Ciudad"
              value={InmuebleData.ciudad}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, ciudad: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>   
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          
        >
          Nº licencia turistica
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Licencia"
              value={InmuebleData.numero_licencia_turistica}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, numero_licencia_turistica: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>   
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Latitud
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Direccion inmueble"
              value={InmuebleData.latitud}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, latitud: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row> 

      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Longitud
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Direccion inmueble"
              value={InmuebleData.longitud}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, longitud: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>   

      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Url Booking
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Direccion inmueble"
              value={InmuebleData.url_booking}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, url_booking: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>     
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Telefono
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Telefono propietario"
              value={InmuebleData.telefono}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, telefono: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Propietario Nombre
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Dueño"
              value={InmuebleData.owner}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, owner: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Usuario Hospederias
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Usuario hospederias"
              value={InmuebleData.user_hospederias}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  user_hospederias: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Password Hospederias
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Password hospederias"
              value={InmuebleData.password_hospederias}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  password_hospederias: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Comisiones" key="Comisiones">  
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Comisiones Airbnb
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Comisiones Airbnb"
              value={InmuebleData.comision_airbnb}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  comision_airbnb: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Comisiones Booking
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Comisiones Booking"
              value={InmuebleData.comision_booking}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  comision_booking: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Limpiezas" key="Limpiezas">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nombre de limpiador@ (Es el limpiador que se asignará por defecto cuando entre reserva)
        </Col>
        <Col span={12}>
          <Form.Item>
            <Select

              onChange={(value) =>  setInmuebleData({
                ...InmuebleData,
                limpiador_nombre: value,
              })}
              placeholder="Selecciona un inmueble"
            >
              {usuarios_limpieza?.map((piso) => (
                <Option key={piso?.value} value={piso?.value}>
                  {piso?.label}
                </Option>
              ))}
            </Select>

            {/* <Input
              //prefix={<Icon type="mail" />}
              placeholder="Nombre del limpiador@"
              value={InmuebleData.limpiador_nombre}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  limpiador_nombre: e.target.value,
                })
              }
            /> */}
          </Form.Item>
        </Col>
      </Row>
      {/* <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Telefono de limpiador
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Telefono del limpiador"
              value={InmuebleData.limpiador_telefono}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  limpiador_telefono: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row> */}
      </Collapse.Panel>
      <Collapse.Panel header="WIFI" key="WIFI">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Wifi SSID
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Wifi SSID"
              value={InmuebleData.wifi_ssid}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, wifi_ssid: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Wifi Pass
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Wifi Pass"
              value={InmuebleData.wifi_pass}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, wifi_pass: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Router user
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Router user"
              value={InmuebleData.router_user}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  router_user: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Router pass
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Router pass"
              value={InmuebleData.router_pass}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  router_pass: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Camas" key="Camas">
      
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº habitaciones
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Numero habitaciones"
              value={InmuebleData.numero_habitaciones}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  numero_habitaciones: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº Camas de 90
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Numero camas 90"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  numero_camas_90: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº Camas de 150
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Numero camas 150"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  numero_camas_150: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº Sofas cama de 90
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Numero camas 90"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  numero_sofas_cama_90: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nº Sofas cama de 150
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Numero sofas cama 150"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  numero_sofas_cama_150: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Inventario" key="Inventario">

<InventoryList initialData={InmuebleData.inventario} onInventoryChange={handleInventoryChange}/>
</Collapse.Panel>
      <Collapse.Panel header="Stock Sabanas" key="StockSabanas">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          stock_sabanas_90
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="stock_sabanas_90"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  stock_sabanas_90: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Sabanas de 150
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock sabanas 150"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  stock_sabanas_150: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          stock_edredones_90

        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="
              stock_edredones_90"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  stock_edredones_90: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          stock_edredones_150

        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="stock_edredones_150"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  stock_edredones_150: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock almohadas
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock almohadas"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  stock_almohadas: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Toallas Cuerpo
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock toallas cuerpo"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  stock_toallas_cuerpo: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Toallas Mano
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock toallas mano"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  stock_toallas_mano: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Stock Alfombrines
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Stock alfombrines"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  stock_alfombrines: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      
      </Collapse.Panel>
      <Collapse.Panel header="Codigos y Acceso" key="Codigos y Acceso">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Codigo Limpieza
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Limpieza abra la puerta"
              value={InmuebleData?.codigo_limpieza ?? ""}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  codigo_limpieza: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Codigo Huespedes
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Huespedes abran puerta"
              value={InmuebleData?.codigo_huespedes ?? ""}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  InmuebleData: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ID Nuki
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ID Nuki Puerta Casa"
              value={InmuebleData.nuki_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  nuki_id: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ID cerradura
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ID Puerta Casa"
              value={InmuebleData.cerradura_id}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  cerradura_id: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ID portal
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ID portal"
              value={InmuebleData.id_electronica_portal}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  id_electronica_portal: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ID temperatura
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ID  temperatura"
              value={InmuebleData.id_sensor_temperatura}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  id_sensor_temperatura: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ID potencia
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ID potencia"
              value={InmuebleData.id_actuador_caldera}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  id_actuador_caldera: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      <Collapse.Panel header="Facturacion" key="facturacion">
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ¿Hacer factura a final de mes?
        </Col>

        <Col span={12}>

        <Form.Item>
            <Checkbox
              // style={{ border: '1px solid #d9d9d9', borderRadius: '5px', padding: '10px', display: 'inline-block' }}
              checked={InmuebleData.generar_factura_propietario ?? false}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, generar_factura_propietario: e.target.checked })
              }
            >   
            </Checkbox>
            
          </Form.Item>
         
        </Col>
      </Row>
      <Divider>Enviar facturas al propietario</Divider>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Enviar Correo al propietarios tras cada factura
        </Col>

        <Col span={12}>
        <Form.Item>
            <Checkbox
              // style={{ border: '1px solid #d9d9d9', borderRadius: '5px', padding: '10px', display: 'inline-block' }}
              checked={InmuebleData.enviar_correo_a_propietario_tras_generar_cada_factura ?? false}
              onChange={(e) =>
                setInmuebleData({ ...InmuebleData, enviar_correo_a_propietario_tras_generar_cada_factura: e.target.checked })
              }
            >   
            </Checkbox>
            
          </Form.Item>
         
         
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Correo del propietario para enviar las facturas
        </Col>
        <Col span={12}>

        
    
        <Form.Item>
        <Input
              //prefix={<Icon type="mail" />}
              placeholder="Ej: pedrio@gmail.com"
              value={InmuebleData.correo_electronico_propietario_enviar_facturas}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  correo_electronico_propietario_enviar_facturas: e.target.value,
                })
              }
            />
            
          </Form.Item>
         
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          NIF, CIF, VAT
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Ej: DNI o CIF empresa"
              value={InmuebleData.id_facturacion}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  id_facturacion: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nombre empresa/persona fisica
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Ej: Amazon S.L. / Pedro Jimenez"
              value={InmuebleData.nombre_facturacion}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  nombre_facturacion: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Direccion de facturacion
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Ej: Avenida ... Nº .."
              value={InmuebleData.direccion_facturacion}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  direccion_facturacion: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Iban
        </Col>
        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="ES94XXXXX"
              value={InmuebleData.iban}
              onChange={(e) =>
                setInmuebleData({
                  ...InmuebleData,
                  iban: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      </Collapse.Panel>
      
      <Collapse.Panel header="Mensajes Huespedes" key="mensajes"> 
      <Collapse>
      <Collapse.Panel header="Mensaje Pre entrada" key="pre-entrada"> 
      
      <span>
        Palabras claves: @nombre_inquilino, @fecha_entrada, @fecha_salida,
        @formulario_ids
      </span>
      <Carousel class="Carousel">
        <div>
          <TextArea
            rows={20}
            placeholder="Ejemplo: Debes enviar tu dni para poder entrada"
            maxLength={4000}
            value={InmuebleData.mensaje_pre_entrada}
            onChange={(e) =>
              setInmuebleData({
                ...InmuebleData,
                mensaje_pre_entrada: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={20}
            placeholder="Example: You must send your ID to be able to enter"
            maxLength={4000}
            value={InmuebleData.mensaje_pre_entrada_ingles}
            onChange={(e) =>
              setInmuebleData({
                ...InmuebleData,
                mensaje_pre_entrada_ingles: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={20}
            placeholder="Exemple : Vous devez envoyer votre pièce d'identité pour pouvoir entrer"
            maxLength={4000}
            value={InmuebleData.mensaje_pre_entrada_frances}
            onChange={(e) =>
              setInmuebleData({
                ...InmuebleData,
                mensaje_pre_entrada_frances: e.target.value,
              })
            }
          />
        </div>
      </Carousel>
      </Collapse.Panel>
      <Collapse.Panel header="Instrucciones" key="Instrucciones">
      <span>
        Palabras claves: @enlace_inquilino, @codigo_nuki, @nombre_inquilino, @codigo_huespedes
      </span>
      <Carousel
      // afterChange={(value) => {
      //   //console.log(value);
      // }}
      >
        <div>
          <TextArea
            rows={20}
            placeholder="Describe el piso y sus alrededores en 4000 caracteres"
            maxLength={4000}
            value={InmuebleData.description}
            onChange={(e) =>
              setInmuebleData({
                ...InmuebleData,
                description: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={20}
            placeholder="Describe the apartment and its sourroundings in 4000 caracters"
            maxLength={4000}
            value={InmuebleData.description_ingles}
            onChange={(e) =>
              setInmuebleData({
                ...InmuebleData,
                description_ingles: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={30}
            placeholder="Décrivez l'appartement et ses environs en 4000 caractères"
            maxLength={4000}
            value={InmuebleData.description_frances}
            onChange={(e) =>
              setInmuebleData({
                ...InmuebleData,
                description_frances: e.target.value,
              })
            }
          />
        </div>
      </Carousel>
      </Collapse.Panel>
      <Collapse.Panel header="Post-salida" key="Post-salida">
      <span>
        Palabras claves: @nombre_inquilino, @fecha_entrada, @fecha_salida
      </span>
      <Carousel class="Carousel">
        <div>
          <TextArea
            rows={20}
            placeholder="Ejemplo: ¿Ha ido bien la salida? ¿Qué tal la estancia? Si no te importa valoranos."
            maxLength={4000}
            value={InmuebleData.mensaje_post_salida}
            onChange={(e) =>
              setInmuebleData({
                ...InmuebleData,
                mensaje_post_salida: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={20}
            placeholder="Example: Did the start go well? How was the stay? If you don't mind rate us."
            maxLength={4000}
            value={InmuebleData.mensaje_post_salida_ingles}
            onChange={(e) =>
              setInmuebleData({
                ...InmuebleData,
                mensaje_post_salida_ingles: e.target.value,
              })
            }
          />
        </div>
        <div>
          <TextArea
            rows={20}
            placeholder="Exemple : Le démarrage s'est-il bien passé ? Comment s'est passé le séjour ? Si cela ne vous dérange pas, évaluez-nous."
            maxLength={4000}
            value={InmuebleData.mensaje_post_salida_frances}
            onChange={(e) =>
              setInmuebleData({
                ...InmuebleData,
                mensaje_post_salida_frances: e.target.value,
              })
            }
          />
        </div>
      </Carousel>
      </Collapse.Panel>
      </Collapse>
      </Collapse.Panel>
      
      </Collapse>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-submit">
          Crear Inmueble
        </Button>
      </Form.Item>
    </Form>
  );
}
