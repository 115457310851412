import React, { useState, useEffect } from 'react';
import { Select, DatePicker, Button, Table } from 'antd';
import moment from 'moment';
import { getAccessTokenApi } from '../../../api/auth';
import jwtDecode from 'jwt-decode';
import { getPisosLimpiosPorMesLimpiadorById } from '../../../api/inquilino';
import { getUsersByRoleApi } from '../../../api/user';
import { getInmuebleName, getPrecioLimpiadorInmueble } from '../../../api/inmueble';
import "./registroLimpiezas.css";
import GraphLine from '../../../components/Admin/Graphs/GraficaLinea/GraphLine';
import Boton_peticion_handler_v2 from '../../../components/Admin/boton_peticion_handler_v2/boton_peticion_handler_v2';


const { Option } = Select;
const { MonthPicker } = DatePicker;

const RegistroLimpiezasPorLimpiador = () => {
    const accessToken = getAccessTokenApi();
    const [selectedLimpiador, setSelectedLimpiador] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(moment());
    const [limpiadores, setLimpiadores] = useState([]);
    const [pisos, setPisos] = useState([]);
    const [tablaDatos, setTablaDatos] = useState([]);
    const [totalPago, setTotalPago] = useState(0);
    const [nota_media_limpieza, setnota_media_limpieza] = useState(0);
    const [datosGrafico, setDatosGrafico] = useState([]);
    const [numLimp, setnumLimp] = useState(0);

    useEffect(() => {
        const obtenerLimpiadores = async () => {
            try {
                const response = await getUsersByRoleApi(accessToken, 'Limpiador');
                if (response && response.users) {
                    setLimpiadores(response.users);
                }
            } catch (error) {
                console.error("Error al obtener limpiadores:", error);
            }
        };
        obtenerLimpiadores();
    }, [accessToken]);

    const handleLimpiadorChange = value => {
        setSelectedLimpiador(value);
    };

    const handleDateChange = (date, dateString) => {
        setSelectedMonth(dateString);
    };

    const handleBuscarClick = async () => {
        try {
            const token = await getAccessTokenApi();
            const fechaFormateada = moment(selectedMonth).format("YYYY-MM");
            const response = await getPisosLimpiosPorMesLimpiadorById(fechaFormateada, selectedLimpiador, token);
            //console.log("2222222222222222222222222",response.inquilinos.keys)
            // setnumLimp(Object.keys(response.inquilinos).length)
            if (response && response.inquilinos) {
                await procesarDatosParaTabla(response.inquilinos);
            }
        } catch (error) {
            console.error("Error en buscar pisos:", error);
        }
    };

    const procesarDatosParaTabla = async (datos) => {
        const datosTabla = [];
        let total = 0;
        let nota_media_limpieza = 0;
        let contador = 0;
        let agrupacionPorFecha = {};
        let contador_media = 0;
    
        for (const idInmueble of Object.keys(datos)) {
            for (const reserva of datos[idInmueble]) {
                contador++;
                const fecha = moment(reserva.fecha_salida).format('YYYY-MM-DD');
                //console.log("RESERVA   DD ", reserva.valoracion_limpieza);
                // Acumular datos para el gráfico
                if (reserva.valoracion_limpieza) {
                    //console.log("Entra");
                    if (!agrupacionPorFecha[fecha]) {
                        agrupacionPorFecha[fecha] = { sumaPuntuacion: 0, contador: 0 };
                    }
                    agrupacionPorFecha[fecha].sumaPuntuacion += Number(reserva.valoracion_limpieza*2);
                    agrupacionPorFecha[fecha].contador++;
                }
    
                // Procesar datos para la tabla
                const precios_limpieza = await getPrecioLimpiadorInmueble(idInmueble, accessToken);
                let precio_limpiador = Number(precios_limpieza.precio_limpiador);
                const precio_suplemento = Number(precios_limpieza.suplemento_festivo);
                const cantidadLimpiadores = reserva.limpieza.limpiadores.length;
                let pagoDividido = cantidadLimpiadores > 0 ? (precio_limpiador / cantidadLimpiadores).toFixed(2) : precio_limpiador;
    
                const fechaSalida = moment(reserva.fecha_salida);
                const esDomingo = fechaSalida.day() === 0;
                if (esDomingo) {
                    pagoDividido = (parseFloat(pagoDividido) + precio_suplemento).toFixed(2);
                    precio_limpiador = precio_limpiador + precio_suplemento;
                }
                //console.log("PAGO LIMPIADOR ",precio_limpiador);
                const nombreInmueble = await getInmuebleName(idInmueble, accessToken);
                
                datosTabla.push({
                    key: reserva._id,
                    inmueble: nombreInmueble.message,
                    fechaSalida: fechaSalida.format('dddd, DD-MM-YYYY'),
                    esDomingo,
                    cantidadLimpiadores,
                    pagoPorLimpiador: pagoDividido,
                    comentario: reserva.limpieza.comentario_limpiador,
                    pagoLimpieza: precio_limpiador,
                    valoracion_limpieza: Number(reserva.valoracion_limpieza*2) ?? "No encuestado"
                });
    
                total += parseFloat(pagoDividido);
                if (reserva.valoracion_limpieza>=0) {
                    nota_media_limpieza += Number(reserva.valoracion_limpieza)*2;
                    contador_media++;
                }
            }
        }
        setnumLimp(contador);
    
        // Calcular la media de las puntuaciones para el gráfico

        //console.log("__________________________DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD", agrupacionPorFecha);
        const datosParaGrafico = Object.keys(agrupacionPorFecha).map(fecha => {
            const media = agrupacionPorFecha[fecha].sumaPuntuacion / agrupacionPorFecha[fecha].contador;
            return { tiempo: fecha, puntuacion: media };
        });
        
        setDatosGrafico(datosParaGrafico);
        setTablaDatos(datosTabla);
        setTotalPago(total.toFixed(2));
        if (contador_media > 0) {
            setnota_media_limpieza((nota_media_limpieza / contador_media).toFixed(2));
        }
    };

    // const procesarDatosParaTabla = async (datos) => {
    //     const datosTabla = [];
    //     let total = 0;
    //     let nota_media_limpieza = 0;
    //     let contador = 0;
      

    //     for (const idInmueble of Object.keys(datos)) {
    //         for (const reserva of datos[idInmueble]) {
    //             // Calcular el pago dividido
    //             //console.log("QUE TIENES", reserva.valoracion_limpieza);
    //             const precios_limpieza = await getPrecioLimpiadorInmueble(idInmueble,accessToken);
    //             let precio_limpiador = Number(precios_limpieza.precio_limpiador);
    //             const precio_suplemento = Number(precios_limpieza.suplemento_festivo);
    //             const cantidadLimpiadores = reserva.limpieza.limpiadores.length;
    //             let pagoDividido = cantidadLimpiadores > 0 ? (precio_limpiador / cantidadLimpiadores).toFixed(2) : precio_limpiador;

    //             // Formatear la fecha y verificar si es domingo
    //             const fechaSalida = moment(reserva.fecha_salida);
    //             const esDomingo = fechaSalida.day() === 0; // 0 es domingo
    //             if (esDomingo) {
    //                 pagoDividido = (parseFloat(pagoDividido) + precio_suplemento).toFixed(2);
    //                 precio_limpiador = precio_limpiador + precio_suplemento; 
    //             }

    //             // Obtener el nombre del inmueble
    //             const nombreInmueble = await getInmuebleName(idInmueble, accessToken);
                

    //             datosTabla.push({
    //                 key: reserva._id,
    //                 inmueble: nombreInmueble.message, // Usar el nombre del inmueble o el ID si no se encuentra
    //                 fechaSalida: fechaSalida.format('dddd, DD-MM-YYYY'),
    //                 esDomingo,
    //                 cantidadLimpiadores: cantidadLimpiadores,
    //                 pagoPorLimpiador: pagoDividido,
    //                 comentario: reserva.limpieza.comentario_limpiador,
    //                 pagoLimpieza: precio_limpiador,
    //                 valoracion_limpieza :  Number(reserva.valoracion_limpieza*2) ?? "No encuestado"
    //             });
    //             total += parseFloat(pagoDividido);
    //             if (reserva.valoracion_limpieza) {
    //                 nota_media_limpieza = nota_media_limpieza + (reserva.valoracion_limpieza*2);
    //                 contador++;
    //             }
    //         }
    //     }
       
    //     setnota_media_limpieza(nota_media_limpieza / contador);
    //     setTablaDatos(datosTabla);
    //     setTotalPago(total.toFixed(2));
    // };

    // Usar rowClassName para cambiar el color de fondo
    const rowClassName = (record) => {
        return record.esDomingo ? 'fila-domingo' : '';
    };

    const columnas = [
        {
            title: 'Inmueble',
            dataIndex: 'inmueble',
            key: 'inmueble',
        },
        {
            title: 'Fecha de Salida',
            dataIndex: 'fechaSalida',
            key: 'fechaSalida',
        },
        {
            title: 'Cantidad de Limpiadores',
            dataIndex: 'cantidadLimpiadores',
            key: 'cantidadLimpiadores',
        },
        {
            title: 'Pago por Limpiador',
            dataIndex: 'pagoPorLimpiador',
            key: 'pagoPorLimpiador',
        },
        {
            title: 'Comentario',
            dataIndex: 'comentario',
            key: 'comentario',
        },
        {
            title: 'Pago Total de Limpieza',
            dataIndex: 'pagoLimpieza',
            key: 'pagoLimpieza',
        },
        {
            title: 'Puntuacion limpieza',
            dataIndex: 'valoracion_limpieza',
            key: 'valoracion_limpieza',
        }
    ];

    return (
        <div>
            <h2>Numero de pisos limpiados: {numLimp}</h2>
             <div className="total-pago">
                Total a Pagar: {totalPago} €
            </div>
            <div className="total-pago">
                Nota Media: {nota_media_limpieza}
            </div>
            <br></br>
            <Select
                style={{ width: 200 }}
                placeholder="Selecciona un limpiador"
                onChange={handleLimpiadorChange}
                allowClear
            >
                {limpiadores.map(limpiador => (
                    <Option key={limpiador.id} value={limpiador.id}>{limpiador.name}</Option>
                ))}
            </Select>

            <MonthPicker
                placeholder="Selecciona el mes y año"
                onChange={handleDateChange}
            />
            <Boton_peticion_handler_v2 cuerpo={"Buscar"} handler={handleBuscarClick}/>
            {/* <Button type="primary" onClick={handleBuscarClick}>
                Buscar
            </Button> */}
            <div>
                <GraphLine datos={datosGrafico} />
            </div>

            <Table columns={columnas} dataSource={tablaDatos} rowClassName={rowClassName} />
        </div>
    );
};

export default RegistroLimpiezasPorLimpiador;