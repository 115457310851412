import React, { useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import "./UserInquilinosDni.scss";
const UserInquilinosDni = ({ user }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const inquilinosPorPagina = 1; // Número de inquilinos por página
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const paginarInquilinos = () => {
    const offset = currentPage * inquilinosPorPagina;
    return user.id_de_los_inquilinos.slice(offset, offset + inquilinosPorPagina);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  const calcularEdadMedia = (inquilinos) => {
    const edades = inquilinos.map(inquilino => {
      const hoy = new Date();
      const fechaNacimiento = new Date(inquilino.fechaNacimiento);
      let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
      const m = hoy.getMonth() - fechaNacimiento.getMonth();
      if (m < 0 || (m === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
        edad--;
      }
      return edad;
    });
    return edades.reduce((a, b) => a + b, 0) / edades.length;
  };

  const edadMedia = useMemo(() => calcularEdadMedia(user.id_de_los_inquilinos), [user.id_de_los_inquilinos]);

  const obtenerColorBoton = (edadMedia) => {
    if (edadMedia >= 18 && edadMedia < 20) return 'red';
    if (edadMedia >= 20 && edadMedia < 25) return 'orange';
    if (edadMedia >= 25 && edadMedia < 35) return 'yellow';
    return 'lightgreen';
  };

  const colorBoton = obtenerColorBoton(edadMedia);

  return (
        <div>
          {paginarInquilinos().map((inquilino, index) => (
            <div key={index} className="accordion-item">
              
              <button onClick={() => toggleAccordion(index)} className="accordion-button"  style={{ backgroundColor: colorBoton }}>
                {inquilino.nombre} {inquilino.apellido1} {inquilino.apellido2}
              </button>
              {openAccordionIndex === index && (
                <div className="accordion-content">
                  <p>Nacionalidad: {inquilino.nacionalidadStr}</p>
                  <p>Tipo de Documento: {inquilino.tipoDocumentoStr}</p>
                  <p>Número de Identificación: {inquilino.numIdentificacion}</p>
                  <p>Fecha de nacimiento: {inquilino.fechaNacimiento}</p>                  
                </div>
              )}
            </div>
          ))}

      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Siguiente'}
        breakLabel={'...'}
        pageCount={Math.ceil(user.id_de_los_inquilinos.length / inquilinosPorPagina)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
        className='pagination'
      />
    </div>
  );
};

export default UserInquilinosDni;
