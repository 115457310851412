import React, { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';

const VerInformacionLimpiador = ({ currentPiso }) => {
    const currentInquilino = currentPiso;
    const eventoSalida = currentInquilino.eventos.find(evento => evento.tipo === 'salida');
    //console.log(eventoSalida);
    let comentario = eventoSalida.comentario ?? '';
    return (
        <div style={{ padding: '20px', backgroundColor: 'white' }}>
            Comentario acerca del piso:
            <TextArea
            rows={20}
            maxLength={4000}
            value={comentario}
          />
        </div>
    );
};

export default VerInformacionLimpiador;
