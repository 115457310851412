import React from 'react';
import { Table, Divider } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Boton_peticion_handler_v2 from '../../../components/Admin/boton_peticion_handler_v2';
import './Exportar_pdf_limpiezas.css';

const exportPDF = async () => {
    const input = document.getElementById('hola'); // Cambia 'content' por el ID de tu elemento HTML
    const margin = 10;
    const pdf = new jsPDF('l', 'pt', 'a4'); // 'l' para landscape
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
  
    await html2canvas(input, { scrollY: -window.scrollY }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = pageWidth - 2 * margin;
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
  
      if (imgHeight > pageHeight - 2 * margin) {
        let heightLeft = imgHeight;
        let position = 0;
  
        pdf.addImage(imgData, 'PNG', margin, margin, imgWidth, imgHeight);
        heightLeft -= (pageHeight - 2 * margin);
  
        while (heightLeft > 0) {
          pdf.addPage();
          position = imgHeight - heightLeft;
  
          pdf.addImage(
            imgData,
            'PNG',
            margin,
            margin - position,
            imgWidth,
            imgHeight
          );
  
          heightLeft -= pageHeight - 2 * margin;
        }
      } else {
        pdf.addImage(imgData, 'PNG', margin, margin, imgWidth, imgHeight);
      }
  
      pdf.save('tabla.pdf');
    });
};

const Exportar_pdf_limpiezas = ({ dataSource, pagination,columns, rowClassName, data2 }) => {
  // Función para convertir la hora a minutos desde la medianoche
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  // Ordenar los datos: primero aquellos con persona_entra, luego el resto por hora_entrada
  const sortedData = [...dataSource].sort((a, b) => {
    const aPersonaEntra = data2[a?.inmueble_id]?.eventos.find(evento => evento.tipo === "entrada");
    const bPersonaEntra = data2[b?.inmueble_id]?.eventos.find(evento => evento.tipo === "entrada");

    if (aPersonaEntra && bPersonaEntra) {
      return timeToMinutes(aPersonaEntra.hora) - timeToMinutes(bPersonaEntra.hora);
    }

    if (aPersonaEntra && !bPersonaEntra) {
      return -1;
    }
    if (!aPersonaEntra && bPersonaEntra) {
      return 1;
    }
    return 0;
  });

  // Encontrar el índice donde empieza el segundo bloque
  const separationIndex = sortedData.findIndex(record => !data2[record?.inmueble_id]?.eventos.find(evento => evento.tipo === "entrada"));

  // Insertar una fila de separación
  if (separationIndex !== -1) {
    sortedData.splice(separationIndex, 0, { separator: true, key: 'separator' });
  }

  const pdfColumns = [
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "Tipo",
      render: (text, record) => {
        if (record.separator) return { children: <Divider style={{ backgroundColor: 'black', margin: 0 }} />, props: { colSpan: 5 } };
        if (record.fecha_salida === record.fecha_limpieza) {
          return <>Normal</>;
        } else {
          return <>Pendiente</>;
        }
      },
    },
    {
      title: "NOMBRE PISO",
      dataIndex: "piso_name",
      key: "piso_name",
      render: (text, record) => {
        if (record.separator) return { props: { colSpan: 0 } };
        return text;
      },
    },
    {
      title: "Fecha_limpieza",
      dataIndex: "fecha_limpieza",
      key: "fecha_limpieza",
      render: (text, record) => {
        if (record.separator) return { props: { colSpan: 0 } };
        return text;
      },
    },
    {
      title: "Limpiador",
      dataIndex: "limpiador",
      key: "limpiador",
      render: (text, record) => {
        if (record.separator) return { props: { colSpan: 0 } };
        return text;
      },
    },
    {
      title: "fecha_entrada",
      dataIndex: "fecha_entrada",
      key: "fecha_entrada",
      render: (text, record) => {
        if (record.separator) return { props: { colSpan: 0 } };
        let persona_entra = data2[record?.inmueble_id]?.eventos.find(evento => evento.tipo === "entrada");
        return (
          <>
           {persona_entra ? <>
              {persona_entra.nombre} ({persona_entra.numero_personas} huespedes) <b>{persona_entra.hora}</b><RightOutlined style={{ color: "green" }} />  {persona_entra.fecha_entrada}
            </> : null}
            <Divider />
            {record?.nombre} ({record.numero_personas} huespedes) <b>{record.hora_salida}<LeftOutlined style={{ color: "red" }} /></b>{record.fecha_salida}
          
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Boton_peticion_handler_v2 cuerpo={"Descargar PDF"} handler={exportPDF} style={{ marginTop: 10 }} />
      <div>
        <Table
          id="hola"
        //   columns={pdfColumns}
          columns={columns}
          dataSource={sortedData}
          pagination={pagination}
          rowClassName={(record, index) => {
            if (record.separator) {
              return 'separator-row';
            }
            return index % 2 === 0 ? 'even-row' : 'odd-row';
          }}
        />
      </div>
    </div>
  );
};

export default Exportar_pdf_limpiezas;
