import React, { useState, useEffect } from 'react';
import { Button, Divider, Input, Row } from 'antd';
import { CheckOutlined, EditOutlined, CommentOutlined, StopOutlined } from '@ant-design/icons';
import StockCheckbox from '../../../components/StockCheckbox';

const Tabla_stock = ({
  sortedUsers,
  selectedCheckboxes,
  handleCheckboxChange,
  handleEdit,
  handleUpdate,
  handleCancelEdit,
  handleVerComentarios,
  editedRowIndex,
  editedData,
  handleChange,
  requestSort,
  renderStock2,
  renderStock,
  isStockOptimal,
}) => {
  const [totales, setTotales] = useState({
    sabanas150: 0,
    sabanas90: 0,
    almohadas: 0,
    edredones150: 0,
    edredones90: 0,
    toallasCuerpo: 0,
    toallasMano: 0,
    alfombrines: 0,
  });

  const [multiplicador, setMultiplicador] = useState(1.5); // Nuevo estado para el multiplicador

  const calcularStockFaltante = (stockActual, stockOptimo) => {
    if(stockActual > stockOptimo) {
      return 0;
    }
    return stockOptimo - stockActual;
  };

  const calcularTotales = () => {
    let totalSabanas150 = 0;
    let totalSabanas90 = 0;
    let totalAlmohadas = 0;
    let totalEdredones150 = 0;
    let totalEdredones90 = 0;
    let totalToallasCuerpo = 0;
    let totalToallasMano = 0;
    let totalAlfombrines = 0;

    sortedUsers.forEach((piso) => {
      const totalSabanas150_a_sumar = calcularStockFaltante(piso.stock_sabanas_150, piso.stock_optimo_sabanas_150);
      const totalSabanas90_a_sumar = calcularStockFaltante(piso.stock_sabanas_90, piso.stock_optimo_sabanas_90);
      const totalAlmohadas_a_sumar = calcularStockFaltante(piso.stock_almohadas, piso.stock_optimo_almohadas);
      const totalEdredones150_a_sumar = calcularStockFaltante(piso.stock_edredones_150, piso.stock_optimo_edredones_150);
      const totalEdredones90_a_sumar = calcularStockFaltante(piso.stock_edredones_90, piso.stock_optimo_edredones_90);
      const totalToallasCuerpo_a_sumar = calcularStockFaltante(piso.stock_toallas_cuerpo, piso.stock_optimo_toallas_cuerpo);
      const totalToallasMano_a_sumar = calcularStockFaltante(piso.stock_toallas_mano, piso.stock_optimo_toallas_mano);
      const totalAlfombrines_a_sumar = calcularStockFaltante(piso.stock_alfombrines, piso.stock_optimo_alfombrines);

      totalSabanas150 += totalSabanas150_a_sumar || 0;
      totalSabanas90 += totalSabanas90_a_sumar || 0;
      totalAlmohadas += totalAlmohadas_a_sumar || 0;
      totalEdredones150 += totalEdredones150_a_sumar || 0;
      totalEdredones90 += totalEdredones90_a_sumar || 0;
      totalToallasCuerpo += totalToallasCuerpo_a_sumar || 0;
      totalToallasMano += totalToallasMano_a_sumar || 0;
      totalAlfombrines += totalAlfombrines_a_sumar || 0;
    });

    setTotales({
      sabanas150: totalSabanas150,
      sabanas90: totalSabanas90,
      almohadas: totalAlmohadas,
      edredones150: totalEdredones150,
      edredones90: totalEdredones90,
      toallasCuerpo: totalToallasCuerpo,
      toallasMano: totalToallasMano,
      alfombrines: totalAlfombrines,
    });
  };

  useEffect(() => {
    calcularTotales();
  }, [sortedUsers]);

  const [localStock, setLocalStock] = useState({
    sabanas150: 0,
    sabanas90: 0,
    almohadas: 0,
    edredones150: 0,
    edredones90: 0,
    toallasCuerpo: 0,
    toallasMano: 0,
    alfombrines: 0,
  });

  const handleInputChange = (e, key) => {
    const value = e.target.value;
    setLocalStock({
      ...localStock,
      [key]: value === '' ? 0 : parseInt(value, 10),
    });
  };

  const handleMultiplicadorChange = (e) => {
    const value = e.target.value;
    setMultiplicador(value === '' ? 1 : parseFloat(value));
  };

  return (
    <>
     
      <table className="table-stock" id="managerTable_2">
        <thead className="thead-stock">
          <tr>
            <th className="th-stock">Hay que pedir</th>
            <th className="th-stock">Sábana 150</th>
            <th className="th-stock">Sábana 90</th>
            <th className="th-stock">Almohada</th>
            <th className="th-stock">Edredon 150</th>
            <th className="th-stock">Edredon 90</th>
            <th className="th-stock">Toalla cuerpo</th>
            <th className="th-stock">Toallas mano</th>
            <th className="th-stock">Alfombrin</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ textAlign: "center", border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}><strong>Totales necesarios</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{totales.sabanas150}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{totales.sabanas90}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{totales.almohadas}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{totales.edredones150}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{totales.edredones90}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{totales.toallasCuerpo}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{totales.toallasMano}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{totales.alfombrines}</strong></td>
            <td style={{ border: "1px solid black" }}></td>
          </tr>
          <tr style={{ textAlign: "center", border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}><strong>Tengo en el local</strong></td>
            <td style={{ border: "1px solid black" }}>
              <input
                type="number"
                value={localStock.sabanas150}
                onChange={(e) => handleInputChange(e, 'sabanas150')}
                style={{ width: '150px', justifyContent:"center", textAlign:'center' }}
              />
            </td>
            <td style={{ border: "1px solid black" }}>
              <input
                type="number"
                value={localStock.sabanas90}
                onChange={(e) => handleInputChange(e, 'sabanas90')}
                style={{ width: '150px', justifyContent:"center", textAlign:'center' }}
              />
            </td>
            <td style={{ border: "1px solid black" }}>
              <input
                type="number"
                value={localStock.almohadas}
                onChange={(e) => handleInputChange(e, 'almohadas')}
                style={{ width: '150px', justifyContent:"center", textAlign:'center' }}
              />
            </td>
            <td style={{ border: "1px solid black" }}>
              <input
                type="number"
                value={localStock.edredones150}
                onChange={(e) => handleInputChange(e, 'edredones150')}
                style={{ width: '150px', justifyContent:"center", textAlign:'center' }}
              />
            </td>
            <td style={{ border: "1px solid black" }}>
              <input
                type="number"
                value={localStock.edredones90}
                onChange={(e) => handleInputChange(e, 'edredones90')}
                style={{ width: '150px', justifyContent:"center", textAlign:'center' }}
              />
            </td>
            <td style={{ border: "1px solid black" }}>
              <input
                type="number"
                value={localStock.toallasCuerpo}
                onChange={(e) => handleInputChange(e, 'toallasCuerpo')}
                style={{ width: '150px', justifyContent:"center", textAlign:'center' }}
              />
            </td>
            <td style={{ border: "1px solid black" }}>
              <input
                type="number"
                value={localStock.toallasMano}
                onChange={(e) => handleInputChange(e, 'toallasMano')}
                style={{ width: '150px', justifyContent:"center", textAlign:'center' }}
              />
            </td>
            <td style={{ border: "1px solid black" }}>
              <input
                type="number"
                value={localStock.alfombrines}
                onChange={(e) => handleInputChange(e, 'alfombrines')}
                style={{ width: '150px', justifyContent:"center", textAlign:'center' }}
              />
            </td>
            <td style={{ border: "1px solid black" }}></td>
          </tr>
          <tr style={{ textAlign: "center", border: "1px solid black" }}>
            <td style={{ border: "1px solid black" }}><strong>Pido CS: x<Input
              type="number"
              value={multiplicador}
              onChange={handleMultiplicadorChange}
              placeholder="Multiplicador"
              style={{ marginBottom: '10px' }}
            /></strong></td>
            <td style={{ border: "1px solid black" }}><strong>{(totales.sabanas150 - localStock.sabanas150) * multiplicador}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{(totales.sabanas90 - localStock.sabanas90) * multiplicador}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{(totales.almohadas - localStock.almohadas) * multiplicador}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{(totales.edredones150 - localStock.edredones150) * multiplicador}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{(totales.edredones90 - localStock.edredones90) * multiplicador}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{(totales.toallasCuerpo - localStock.toallasCuerpo) * multiplicador}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{(totales.toallasMano - localStock.toallasMano) * multiplicador}</strong></td>
            <td style={{ border: "1px solid black" }}><strong>{(totales.alfombrines - localStock.alfombrines) * multiplicador}</strong></td>
            <td style={{ border: "1px solid black" }}></td>
          </tr>
        </tbody>
      </table>
      <br />
    </>
  );
};

export default Tabla_stock;
