import React, { useState, useEffect } from "react";

import QRCode from "qrcode.react";
import {
  Switch,
  List,
  Avatar,
  Button,
  Modal as ModalAntd,
  notification,
  Calendar,
  DatePicker,
  Space,
  Row,
  Col,
  Divider,
  Pagination,
  Collapse,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import dayjs from "dayjs";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  StopOutlined,
  CheckOutlined,
  CalendarOutlined,
  CommentOutlined,
  LineChartOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import NoAvatar from "../../../../../assets/img/png/no-avatar.png";
import Modal from "../../../../Modal";
import EditInmuebleForm from "../EditInmuebleForm";
import AddInmuebleForm from "../AddInmuebleForm";
import {
  getAvatarApi,
  updateInmuebleApi,
  
  deleteInmuebleApi,
 
  desactivateInmuebleApi,
} from "../../../../../api/inmueble";
import { getAccessTokenApi } from "../../../../../api/auth";

import "./ListInmueble.scss";
import { basePathFrontend } from "../../../../../api/config";
import TextArea from "antd/lib/input/TextArea";

const { confirm } = ModalAntd;

export default function ListInmueble(props) {
  const {
    usersActive,
    usersInactive,
    setReloadUsers,
    viewUsersActives,
    setViewUsersActives,
  } = props;

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const addUserModal = () => {
    setIsVisibleModal(true);

    setModalTitle("Creando nuevo Inmueble");
    setModalContent(
      <AddInmuebleForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
  };

  return (
    <div className="list-users">
      <div className="list-users__header">
        <div className="list-users__header-switch">
          <Switch
            defaultChecked
            onChange={() => setViewUsersActives(!viewUsersActives)}
          />

          <span>
            {viewUsersActives ? "Inmuebles Activos" : "Inmuebles Inactivos"}
          </span>
        </div>

        <Button type="primary" onClick={addUserModal}>
          Nuevo Inmueble
        </Button>
      </div>
      {viewUsersActives ? (
        <UsersActive
          usersActive={usersActive}
          setIsVisibleModal={setIsVisibleModal}
          setModalTitle={setModalTitle}
          setModalContent={setModalContent}
          setReloadUsers={setReloadUsers}
        />
      ) : (
        <UsersInactive
          usersInactive={usersInactive}
          setReloadUsers={setReloadUsers}
        />
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function UsersActive(props) {
  const {
    usersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadUsers,
  } = props;

  const editUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.name ? user.name : "..."} ${
        user.lastname ? user.lastname : "..."
      }`
    );
    setModalContent(
      <EditInmuebleForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
    
  };
  const editUserCollapse = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.name ? user.name : "..."} ${
        user.lastname ? user.lastname : "..."
      }`
    );
    setModalContent(
      <EditInmuebleForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
    
  };
  const seeCalendar = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.name ? user.name : "..."} ${
        user.lastname ? user.lastname : "..."
      }`
    );

    setModalContent();
  };

  return (
    <>
      
        <List
          className="users-active"
          itemLayout="horizontal"
          dataSource={usersActive}
          renderItem={(user) => (
            <Collapse>
            <Collapse.Panel
              header={
              <UserActive
                seeCalendar={seeCalendar}
                user={user}
                editUser={editUser}
                setReloadUsers={setReloadUsers}
                setIsVisibleModal={setIsVisibleModal}
                setModalContent={setModalContent}
              />}
            >
              
            
              <EditInmuebleForm
                user={user}
                setIsVisibleModal={true}
                setReloadUsers={setReloadUsers}
              />
            </Collapse.Panel>
            </Collapse>
          )}
        />
      
    </>
  );
  
}

function UserActive(props) {
  const {
    user,
    editUser,
    setReloadUsers,
    seeCalendar,
    setModalContent,
    setIsVisibleModal,
  } = props;
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const desactivateUser = () => {
    const accesToken = getAccessTokenApi();

    updateInmuebleApi(accesToken, user._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accesToken = getAccessTokenApi();

    confirm({
      title: "Desactivar inmueble",
      content: `¿Estas seguro que quieres desactivar a ${user.name}?`,
      okText: "Desactivar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        // deleteInmuebleApi(accesToken, user._id)
        //   .then((response) => {
        //     notification["success"]({
        //       message: response,
        //     });
        //     setReloadUsers(true);
        //   })
        //   .catch((err) => {
        //     notification["error"]({
        //       message: err,
        //     });
        //   });
        desactivateInmuebleApi(accesToken, user._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadUsers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const generarQR = (qrData, nombre_Piso, ssid, password) => {
    let url = basePathFrontend + "/limpieza/inmueble/" + qrData;

    const wifiInfo = `WIFI:T:WPA2;S:${ssid};P:${password};;`;
    const qrStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    setModalContent(
      <Col>
        <Divider>{nombre_Piso}</Divider>
        <Divider>Limpieza</Divider>

        <Row style={qrStyle}>
          <QRCode value={url} level="L" />
          {/* <QRCode value={url} size={256} level="L" /> */}
        </Row>
        <Divider>Wifi</Divider>
        <Row style={qrStyle}>
          <QRCode
            value={wifiInfo}
            icon="../../../../../assets/img/png/logo512-blanco.png"
          />
        </Row>
      </Col>
    );
    setIsVisibleModal(true);
  };

  const showStatus = async () => {
    const accesToken = await getAccessTokenApi();

   
   
    //console.log("accesToken", accesToken);
    confirm({
      title: "Status de la casa",
      content: `Code: ${user.code}
      Dueño: ${user.owner}
     
      `,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteInmuebleApi(accesToken, user._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadUsers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };
  const { RangePicker } = DatePicker;
  return (
    <>
    
      
      <List.Item
        actions={[
          // <span>{user.code}</span>,
          // <a
          //   target="_blank"
          //   rel="noopener noreferrer"
          //   href={
          //     "https://api.whatsapp.com/send?phone=" +
          //     user.telefono +
          //     "&text=http://comotucasaplatform.s3-website.eu-west-3.amazonaws.com/acceder/" +
          //     user.code
          //   }
          // >
          //   <Button
          //     type="primary"
          //     style={{ backgroundColor: "green", borderColor: "green" }}
          //   >
          //     <CommentOutlined />
          //   </Button>
          // </a>,
          // <span>{user.owner}</span>,
          
          <div>
          {user.tipo_gestion === "" || !user.tipo_gestion
            ? "Falta tipo_gestion"
            : user.tipo_gestion}
            <Divider type="vertical"></Divider>
            {user.gastos_fijos_subarriendo === "" || !user.gastos_fijos_subarriendo
            ? "gastos_fijos_subarriendo"
            : user.gastos_fijos_subarriendo}
            <Divider type="vertical"></Divider>
            <Divider type="vertical"></Divider>
            {user.ciudad === "" || !user.ciudad
            ? "Falta ciudad"
            : user.ciudad}
            <Divider type="vertical"></Divider>
            {user.comision_airbnb === "" || !user.comision_airbnb
            ? "Falta comision_airbnb"
            : user.comision_airbnb}
            <Divider type="vertical"></Divider>
            {user.comision_booking === "" || !user.comision_booking
            ? "Falta comision_booking"
            : user.comision_booking}
            <Divider type="vertical"></Divider>


{user.coste_limpieza === "" || !user.coste_limpieza
            ? "Gasto de limpieza NULO"
            : "Gasto limpieza: " + user.coste_limpieza}
        </div>,
          <div
            style={{
              color:
                user.tipo_gestion === "" || !user.user_hospederias
                  ? "red"
                  : "green",
            }}
          >
            {user.user_hospederias === "" || !user.user_hospederias
              ? "Falta usuario policia"
              : user.user_hospederias}
          </div>,
          <Button
            type="primary"
            onClick={() =>
              generarQR(user._id, user.name, user.wifi_ssid, user.wifi_pass)
            }
            style={{ backgroundColor: "grey", borderColor: "grey" }}
          >
            <QrcodeOutlined />
          </Button>,
          // <Button
          //   type="primary"
          //   style={{ backgroundColor: "green", borderColor: "green" }}
          //   onClick={showStatus}
          // >
          //   <LineChartOutlined />
          //   Status
          // </Button>,
          // <RangePicker
          //   renderExtraFooter={() => "extra footer"}

          //   disabled
          //   defaultValue={[
          //     moment(user.fecha_entrada, "YYYY-MM-DD"),
          //     moment(user.fecha_salida, "YYYY-MM-DD"),

          //     // moment("2022-09-12 13:00"),
          //     // moment("2022-09-14 16:00"),
          //   ]}
          // />,
          // <Button type="primary" onClick={() => seeCalendar(user)}>
          //   <CalendarOutlined />
          // </Button>,
          <Button type="primary" onClick={() => editUser(user)}>
            <EditOutlined />
            Editar
          </Button>,

          // <Button type="danger" onClick={desactivateUser}>
          //   <StopOutlined />
          // </Button>,
          <Button type="danger" onClick={showDeleteConfirm}>
            <DeleteOutlined /> Desactivar
          </Button>,
        ]}
      >


        <List.Item.Meta
          avatar={
            <Avatar src={
              user?.latitud && user?.longitud ? (
                <a
                  href={`https://www.google.es/maps/dir//${user.latitud},${user.longitud}/@${user.latitud},${user.longitud},15z/data=!4m2!4m1!3e1?entry=ttu`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'blue', textDecoration: 'underline' }}
                >
                  Click📍
                </a>
              ) : null
            } />
          }
          title={
            <>
              {user.url_booking ? (
                <a
                  href={user.url_booking}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'blue', textDecoration: 'underline' }}
                >
                  {user.name || "..."}
                </a>
              ) : (
                user.name || "..."
              )}
              {" "}({user.direccion_inmueble || "..."})
            </>
          }
        description={user.email}
      />
    
      </List.Item>
      
    </>
  );
}

function UsersInactive(props) {
  const { usersInactive, setReloadUsers } = props;

  return (
    <List
      className="users-active"
      itemLayout="horizontal"
      dataSource={usersInactive}
      renderItem={(user) => (
        <UserInactive user={user} setReloadUsers={setReloadUsers} />
      )}
    />
  );
}

function UserInactive(props) {
  const { user, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const activateUser = () => {
    const accesToken = getAccessTokenApi();

    const user_aux = { ...user, active: true };
    updateInmuebleApi(accesToken, user_aux, user._id)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = () => {
    const accesToken = getAccessTokenApi();

    confirm({
      title: "Eliminando usuario",
      content: `¿Estas seguro que quieres eliminar a ${user.name}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteInmuebleApi(accesToken, user._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadUsers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  return (
    <List.Item
      actions={[
        <Button type="primary" onClick={activateUser}>
          <CheckOutlined /> Activar
        </Button>,
        <Button type="danger" onClick={showDeleteConfirm}>
          <DeleteOutlined /> Eliminar inmueble
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
        title={`
                    ${user.name ? user.name : "..."} 
                    ${user.lastname ? user.lastname : "..."}
                `}
        description={user.email}
      />
    </List.Item>
  );
}
