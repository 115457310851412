import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Divider,
  Carousel,
  message,
} from "antd";
import { useDropzone } from "react-dropzone";

import {
  obtenerListaInmuebles,
  updateInmuebleApi,
} from "../../../../../api/inmueble";
import jwtDecode from "jwt-decode";
import moment from "moment";
import NoAvatar from "../../../../../assets/img/png/no-avatar.png";
import {
  updateUserApi,
  uploadAvatarApi,
  getAvatarApi,
} from "../../../../../api/user";
import { getAccessTokenApi } from "../../../../../api/auth";
import "./EditGastoForm.scss";
import { updateGastoApi } from "../../../../../api/gastos";
import DesplegableMultiple from "../../../../DesplegableMultiple/DesplegableMultiple";
import Dragger from "antd/lib/upload/Dragger";
import { CheckCircleOutlined, UploadOutlined } from "@ant-design/icons";

export default function EditInmuebleForm(props) {
  const { user, setIsVisibleModal, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  const [userData, setUserData] = useState({});
  const accessToken = getAccessTokenApi();
  //console.log("EditUserForm", userData);
  useEffect(() => {
    setUserData({
      _id: user._id,
      nombre_del_gasto: user.nombre_del_gasto,
      descripcion_del_gasto: user.descripcion_del_gasto,
      frecuencia_gasto: user.frecuencia_gasto,
      periodo_gasto: user.periodo_gasto,
      gasto_empresa: user.gasto_empresa,
      fecha_creacion: user.fecha_creacion,
      origen_gasto: user.origen_gasto,
      id_inmueble_asociado: user.id_inmueble_asociado,
      gasto_propietario: user.gasto_propietario,
      id_asociado: user.id_asociado
      // id_usuario: user.id_inmueble_asociado
    });
  }, [user]);

  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  useEffect(() => {
    if (avatar) {
      setUserData({ ...userData, avatar: avatar.file });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  const updateUser = (filesList) => {
    //console.log("updateUser", filesList);
    //e.preventDefault();
    const token = getAccessTokenApi();
    //console.log(userData);
    if (!userData.nombre_del_gasto) {
      notification["error"]({
        message: "Falta el nombre",
      });
      return;
    }

    let id_asociado;
      if (userData.id_inmueble_asociado.length !== 0) {
        //console.log("VACIO");
        userData.id_asociado = userData.id_inmueble_asociado;
      } else {
        //console.log("DECODEA",jwtDecode(accessToken));
        const decodedToken = jwtDecode(accessToken);
        if (decodedToken && decodedToken.id) {
          userData.id_asociado = decodedToken.id;
          //console.log("entra", decodedToken);
        } else {
          //console.log("error");
        }
  
      }

    //console.log("VEAMOS",userData);
    updateGastoApi(token, userData, user._id, filesList).then((result) => {
      notification["success"]({
        message: "Inmueble actualizado",
      });
      setIsVisibleModal(false);
      setReloadUsers(true);
    });
    // if (typeof userUpdate.avatar === "object") {
    //   //console.log("updateUserApi3");
    //   uploadAvatarApi(token, userUpdate.avatar, user._id).then((response) => {
    //     //console.log("updateUserApi2");
    //     userUpdate.avatar = response.avatarName;
    //     updateUserApi(token, userUpdate, user._id).then((result) => {
    //       notification["success"]({
    //         message: result.message,
    //       });
    //       setIsVisibleModal(false);
    //       setReloadUsers(true);
    //     });
    //   });
    // } else {
    //   //console.log("updateUserApi");
    //   updateUserApi(token, userUpdate, user._id).then((result) => {
    //     notification["success"]({
    //       message: result.message,
    //     });
    //     setIsVisibleModal(false);
    //     setReloadUsers(true);
    //   });
    // }
    // };
    //console.log("EditUserForm return");
  };
  return (
    <div className="edit-user-form">
      <UploadAvatar avatar={avatar} setAvatar={setAvatar} />
      <EditForm
        userData={userData}
        setUserData={setUserData}
        updateUser={updateUser}
      />
    </div>
  );
}

function UploadAvatar(props) {
  const { avatar, setAvatar } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (avatar) {
      if (avatar.preview) {
        setAvatarUrl(avatar.preview);
      } else {
        setAvatarUrl(avatar);
      }
    } else {
      setAvatarUrl(null);
    }
  }, [avatar]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setAvatar({ file, preview: URL.createObjectURL(file) });
    },
    [setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop,
  });

  return (
    <div className="upload-avatar" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Avatar size={150} src={NoAvatar} />
      ) : (
        <Avatar size={150} src={avatarUrl ? avatarUrl : NoAvatar} />
      )}
    </div>
  );
}

function EditForm(props) {
  const { userData, setUserData, updateUser,handleUpload } = props;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  //console.log("FORMULARIO");
  //console.log(userData);
  //console.log(moment(userData.fecha_entrada, "YYYY-MM-DD"));
  const onChange = (dates) => {
    //console.log("onChange");
    //console.log(dates[0]);
    //console.log(dates[1]);
    setUserData({
      ...userData,
      fecha_entrada: dates[0].format("YYYY-MM-DD"),

      fecha_salida: dates[1].format("YYYY-MM-DD"),
    });
  };
  const { TextArea } = Input;
  const [tipo_gestion, settipo_gestion] = useState("Gestion_neto");
  const handleSelectChange_frecuencia = (values) => {
    setUserData({ ...userData, frecuencia_gasto: values });
  };

  const handleSelectChange_periodo_gasto = (values) => {
    setUserData({ ...userData, periodo_gasto: values });
  };
  const handleSelectChange_origen = (values) => {
    setUserData({ ...userData, origen_gasto: values });
  };

  const handleSelectChange_visible = (values) => {
    setUserData({ ...userData, gasto_propietario: values });
  };

  var nombres_pisos_a = [];
  var ids_pisos_a = {};
  var pisos_ids_a = {};
  const [nombres_pisos, setNombres_pisos] = useState([]);
  const [nombres_pisos_asoc, setnombres_pisos_asoc] = useState([]);
  const [id_nombres_pisos, setIdNombres_pisos] = useState({});
  const [nombres_id_pisos, setNombresIds_pisos] = useState({});
  const [defaultVal, setDefaultVal] = useState([]);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const accessToken = getAccessTokenApi();
    //console.log("AddForm1");
    obtenerListaInmuebles(accessToken).then((response) => {
      //console.log("Los pisos segun ID:", response.Pisos[0].id);
      //console.log("obtenerListaInmuebles", response.Pisos);

      for (let i = 0; i < response.Pisos.length; i++) {
        nombres_pisos_a.push(response.Pisos[i].nombre);
        ids_pisos_a[response.Pisos[i].nombre] = response.Pisos[i].id;
        pisos_ids_a[response.Pisos[i].id] = response.Pisos[i].nombre;
      }

      let nombres = [];
      setNombres_pisos(nombres_pisos_a);
      setIdNombres_pisos(ids_pisos_a);
      setNombresIds_pisos(pisos_ids_a);
      setnombres_pisos_asoc(nombres);
      //console.log("nombres_pisos_a", nombres_pisos_a);
      //console.log("ids_pisos_a", ids_pisos_a);
      //console.log("pisos_ids_a:", pisos_ids_a);
      //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
    });
  }, [userData]);

  const handleFileChange = (info) => {
    let fileList = [...info.fileList];
  
    // Limitar la cantidad de archivos a uno
    fileList = fileList.slice(-1);
  
    // Filtrar archivos inválidos y guardar los nombres
    fileList = fileList.filter((file) => {
      if (file.size > 1024 * 1024 * 5) {
        message.error(`${file.name} es demasiado grande. El tamaño máximo es 5MB.`);
        return false;
      }
      return true;
    });
  
    setFileList(fileList);
    // Actualizar el estado con los nombres de los archivos
    const fileNames = fileList.map(file => file.name);
    setUserData({ ...userData, nombre_archivos: fileNames });
  };

  const handleSelectChange = (values) => {
    //console.log("id_nombres_pisosHANDEL", id_nombres_pisos);
    //console.log(
    //   "id_inmueble_asociado handleSelectChange",
    //   userData.id_inmueble_asociado
    // );

    setUserData({
      ...userData,
      id_inmueble_asociado: values.map((x) => {
        return id_nombres_pisos[x] ?? "";
      }),
    });
    //console.log(values);
  };

  useEffect(() => {
    //console.log("He entrado en useEffect para val");
    if (Object.keys(nombres_id_pisos).length > 0) {
      //console.log("PRUEBA", Object.keys(nombres_id_pisos).length);
      //console.log(
      //   " userData.id_inmueble_asociado",
      //   userData.id_inmueble_asociado
      // );
      //console.log(
      //   "setDefaultVal",
      //   (userData.id_inmueble_asociado ?? []).map((x) => {
      //     return nombres_id_pisos[x] ?? "";
      //   })
      // );
      setDefaultVal(
        (userData.id_inmueble_asociado ?? []).map((x) => {
          return nombres_id_pisos[x] ?? "";
        })
      );
    } else {
      setDefaultVal([]);
    }
  }, [userData, nombres_id_pisos]);

  const onFinish = (values) => {
    //console.log("VEAMOS QUE OCURRE");
    updateUser(fileList)
  };

  return (
    <Form className="form-edit" onFinish={onFinish}>
      <span>Mi data{userData._id}</span>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Nombre del gasto
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Nombre del gasto"
              value={userData.nombre_del_gasto}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  nombre_del_gasto: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Precio/Coste
        </Col>

        <Col span={12}>
          <Form.Item>
            <Input
              //prefix={<Icon type="mail" />}
              placeholder="Precio"
              value={userData.gasto_empresa}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  gasto_empresa: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Form.Item label="Fecha del gasto">
            <DatePicker
              renderExtraFooter={() => "extra footer"}
              value={moment(userData.fecha_creacion, "YYYY-MM-DD")} // Aquí se establece el valor
              onChange={(date, dateString) => {
                //console.log("onChange", date, dateString);

                setUserData({
                  ...userData,
                  fecha_creacion: dateString,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Frecuencia del gasto
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            value={userData.frecuencia_gasto}
            // style={{ width: 120 }}
            onChange={handleSelectChange_frecuencia}
            options={[
              { value: "recurrente", label: "Recurrente" },
              { value: "puntual", label: "Puntual" },
              // { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Col>
      </Row>
      {userData.frecuencia_gasto === "recurrente" ? (
        <Row>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Frecuencia del gasto
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Select
              value={userData.periodo_gasto}
              // style={{ width: 120 }}
              onChange={handleSelectChange_periodo_gasto}
              options={[
                { value: "mensual", label: "mensual" },
                { value: "semanal", label: "semanal" },
                { value: "diario", label: "diario" },
                // { value: "disabled", label: "Disabled", disabled: true },
              ]}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Origen gasto
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            value={userData.origen_gasto}
            // style={{ width: 120 }}
            onChange={handleSelectChange_origen}
            options={[
              { value: "Inmueble", label: "Asociado a un piso" },
              { value: "Empresa", label: "Gasto de la empresa" },

              // { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Visible para el propietario
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            value={userData.visible}
            // style={{ width: 120 }}
            onChange={handleSelectChange_visible}
            options={[
              { value: true, label: "Visible" },
              { value: false, label: "No visible",defaultVal},
              // { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Col>
      </Row>
      {userData.origen_gasto === "Inmueble" ? (
        <DesplegableMultiple
          valores={nombres_pisos}
          defaultValue2={defaultVal}
          onChange={handleSelectChange}
        />
      ) : (
        <></>
      )}
      <Divider>Descripcion del gasto</Divider>

      <Row>
        <Col span={24}>
          <Form.Item>
            <TextArea
              rows={20}
              placeholder="Describe el gasto. Ejemplo: Compra de ventiladores para solucionar problema con el aire."
              maxLength={4000}
              value={userData.descripcion_del_gasto}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  descripcion_del_gasto: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item>
            <Dragger
              fileList={fileList}
              onChange={handleFileChange}
              multiple={false}
              customRequest={() => { }}
              showUploadList={{ showRemoveIcon: true }}
              beforeUpload={() => false}
              iconRender={(file, defaultIcon) =>
                file.status === "done" ? <CheckCircleOutlined /> : defaultIcon
              }
            >
              <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
            </Dragger>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-submit">
          Editar gasto
        </Button>
      </Form.Item>
    </Form>
  );
}
