import React, { useState, useEffect } from 'react';
import { Select, Input, Button, message, notification } from 'antd';
import { getUsersByRoleApi } from '../../../../api/user';
import { updateInquilinoApi } from '../../../../api/inquilino';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

const AsignarLimpiador = ({ token, inquilinoId, setIsModalVisible, eventos}) => {
  const [limpiadores, setLimpiadores] = useState([]);
  const [selectedLimpiadores, setSelectedLimpiadores] = useState([]);
  const [descripcionTarea, setDescripcionTarea] = useState("");
  //console.log("LLEGARA EVENTOS ", eventos);

  useEffect(() => {
    const obtenerLimpiadores = async () => {
      try {
        const response = await getUsersByRoleApi(token, 'Limpiador');
        //console.log("Users by Role",response)
        if (response && response.users) {
          setLimpiadores(response.users);
        }
      } catch (error) {
        console.error("Error al obtener limpiadores:", error);
      }
    };

    obtenerLimpiadores();
  }, [token]);

  const handleLimpiadoresChange = (values) => {
    setSelectedLimpiadores(values);
  };

  const eventoEntrada = () => {
    //console.log("HORA ENTRADA PERO EN ASIGNAR",eventos);
    const eventoEntrada = eventos.find(evento => evento.tipo === 'entrada');
    if (eventoEntrada){
      return eventoEntrada.hora;
    }else{
      return "";
    }
  }

  const eventoEntradaFechaSalida = () => {
    //console.log("HORA ENTRADA PERO EN ASIGNAR",eventos);
    const eventoSalida = eventos.find(evento => evento.tipo === 'salida');
    if (eventoSalida){
      return eventoSalida.fecha_limpieza;
    }else{
      return "";
    }
  }
  

  const handleCrearTarea = async () => {
    if (selectedLimpiadores.length === 0) {
      message.error("Por favor, selecciona al menos un limpiador.");
      return;
    }

    try {
      const data = {
        limpieza: {
          limpiadores: selectedLimpiadores,
          estado: "Asignado",  
          comentario: descripcionTarea,
          hora_entrada : eventoEntrada(),
          fecha_limpieza: eventoEntradaFechaSalida()
        }
      };
      //console.log('Veamos',inquilinoId);
      const response = await updateInquilinoApi(token,data,inquilinoId);
      //console.log("Respuesta",response)
      if (response.message) {
        notification.success({
          message: 'Éxito',
          description: 'Tarea creada con exito',
        });
        setIsModalVisible(false);
      } else {
        console.error("Error al enviar el correo de recuperación");
        notification.error({
          message: 'Error',
          description: 'Error ' + response.error,
        });
      }
      
    } catch (error) {
      console.error("Error al crear la tarea fdsa:", error);
      message.error("Error al crear la tarea");
    }
  };

  return (
    <div>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Selecciona limpiadores"
        onChange={handleLimpiadoresChange}
      >
        {limpiadores.map(limpiador => (
          <Option key={limpiador.id} value={limpiador.id}>{limpiador.name}</Option>
        ))}
      </Select>

      <TextArea
            rows={20}
            placeholder="Falta Mopa y limpia cristales"
            maxLength={4000}
            onChange={(e) =>
              setDescripcionTarea(
                   e.target.value,
              )
            }
          />    
      <Button type="primary" onClick={handleCrearTarea}>Crear Tarea</Button>
    </div>
  );
};

export default AsignarLimpiador;
