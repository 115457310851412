import { apiVersion, basePath } from "./config";

  export function getCalculatorApi(accessToken) {
    //TODO: modificar el access token
  
    const url = `${basePath}/${apiVersion}/calculator`;
    const params = {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken
        }};
  
    return fetch(url, params)
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        return err.message;
      });
  }
export function saveCalculatorApi(accessToken, calculatorData) {
    //TODO: modificar el access token
  
    const url = `${basePath}/${apiVersion}/calculator`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },

      body: JSON.stringify(calculatorData)
    };
  
    return fetch(url, params)
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        return err.message;
      });
  }
  