import React, { useState, useEffect } from "react";
import { Select, Space } from "antd";

export default function DesplegableMultiple(props) {
  const { valores, defaultValue2, onChange } = props;
  const [defaultVal, setDefaultVal] = useState(defaultValue2);

  let options = valores?.map((value) => ({
    label: value,
    value: value,
  }));

  const handleChange = (value) => {
    onChange(value);
  };

  useEffect(() => {
    setDefaultVal(defaultValue2);
  }, [defaultValue2]);

  const content =
    defaultVal === undefined ? (
      <Space style={{ width: "100%" }} direction="vertical">
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          onChange={handleChange}
          options={options}
        />
      </Space>
    ) : (
      <Space style={{ width: "100%" }} direction="vertical">
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          value={defaultVal}
          onChange={handleChange}
          options={options}
        />
      </Space>
    );

  return content;
}
