import React, { useState } from 'react';
import { Button, Col, Collapse, Divider, message, notification } from 'antd';
import { updateInquilinoApi } from '../../../../api/inquilino';
import TextArea from 'antd/lib/input/TextArea';
import { comprobarStockPiso } from '../../../../api/inmueble';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Boton_peticion_handler from '../../../../components/Admin/boton_peticion_handler/boton_peticion_handler';
import Boton_peticion_handler_v2 from '../../../../components/Admin/boton_peticion_handler_v2/boton_peticion_handler_v2';

const Limpiar = ({ token, currentPiso, updatePiso , setIsModalVisible, crearGastoStock, fecha, pisoId }) => {
    const currentInquilino = currentPiso;
    const [comentarioLimpiador, setComentarioLimpiador] = useState('');
    const [step, setStep] = useState(1);
    const [cantidadUsada, setCantidadUsada] = useState({
        stock_sabanas_150: 0,
        stock_sabanas_90: 0,
        stock_edredones_90: 0,
        stock_edredones_150: 0,
        stock_toallas_cuerpo: 0,
        stock_toallas_mano: 0,
        stock_alfombrines: 0,
        stock_almohadas: 0
    });
    const [cantidadUsada2, setCantidadUsada2] = useState({
      stock_sabanas_150: 0,
      stock_sabanas_90: 0,
      stock_almohadas: 0,
      stock_edredones_150: 0,
      stock_edredones_90: 0,
      stock_toallas_cuerpo: 0,
      stock_toallas_mano: 0,
      stock_alfombrines: 0,
      
  });
    const incrementar = (articulo) => {
        setCantidadUsada(prev => ({ ...prev, [articulo]: prev[articulo] + 1 }));
    };

    const decrementar = (articulo) => {
        setCantidadUsada(prev => ({
            ...prev,
            [articulo]: prev[articulo] > 0 ? prev[articulo] - 1 : 0
        }));
    };

    const incrementar2 = (articulo) => {
      setCantidadUsada2(prev => ({ ...prev, [articulo]: prev[articulo] + 1 }));
  };

  const decrementar2 = (articulo) => {
      setCantidadUsada2(prev => ({
          ...prev,
          [articulo]: prev[articulo] > 0 ? prev[articulo] - 1 : 0
      }));
  };
    // const mostrarConfirmacion = () => {
    //     const cantidadFinal = calcularCantidadFinal();
    //     //console.log(cantidadFinal);
    //     //console.log("currentPiso.inmueble", currentPiso.inmueble)
    //     if (Object.values(cantidadFinal).some(cantidad => cantidad < 0)) {
    //         message.error('Ha introducido una cantidad no correcta en'+ cantidadFinal  + '. Por favor, vuelva a introducir las cantidades.');
    //     } else {
    //             guardarLimpieza(cantidadFinal);
    //     }
    // };

    const mostrarConfirmacion = () => {
        const cantidadFinal = calcularCantidadFinal();
        //console.log(cantidadFinal);
        //console.log("currentPiso.inmueble", currentPiso.inmueble);
    
        let keyWithError = null;
    
        for (const key in cantidadFinal) {
            if (cantidadFinal.hasOwnProperty(key) && cantidadFinal[key] < 0) {
                keyWithError = key;
                break; // Si encontramos una clave con un valor < 0, podemos salir del bucle
            }
        }
    
        if (keyWithError !== null) {
            message.error('Ha introducido una cantidad no correcta en ' + keyWithError + '. Por favor, vuelva a introducir las cantidades.');
        } else {
            guardarLimpieza(cantidadFinal, cantidadUsada2);
        }
    };
    

    const guardarLimpieza = async (cantidadFinal, cantidadArmarios) => {
        try {
            const accessToken = token;
            //console.log("Datos update piso id : ", currentInquilino.eventos);
            const eventoSalida = currentInquilino.eventos.find(evento => evento.tipo === 'salida');
            let idInquilino = eventoSalida.idInquilino;
            //console.log('INQUILINO ID QUE SACAMOS DE TIPO SALIDA', idInquilino);
            let idPiso = currentInquilino.inmueble._id;
            // let resp = await comprobarStockPiso(accessToken, idPiso, cantidadArmarios);
            // if(resp.error){
            //   notification["error"]({
            //     message: 'Error con los items del piso' + resp?.error
            //   });
     
            //   return;
            // }
            
            await updatePiso(accessToken, cantidadFinal, idPiso);
            const data = {
                "limpieza.estado": "Limpio",
                "limpieza.comentario_limpiador": comentarioLimpiador,
              };
            const response = await updateInquilinoApi(token, data, idInquilino);
            //console.log("Respuesta", response)
            const response2 = await crearGastoStock(token,cantidadFinal, idPiso,fecha );
            //console.log("Respuesta", response2)
            message.success('Los datos de limpieza se han actualizado correctamente.');
            setComentarioLimpiador('');
            setIsModalVisible(false);
        } catch (error) {
            message.error('Error al actualizar el stock del piso');
            console.error('Error al actualizar el stock del piso:', error);
        }
            
        
    };

    const calcularCantidadFinal = () => {
        return Object.keys(cantidadUsada).reduce((acc, key) => {
            acc[key] = (currentInquilino.inmueble[key] || 0) - cantidadUsada[key];
            return acc;
        }, {});
    };
    const calcularCantidadFinal2 = () => {
      return Object.keys(cantidadUsada2).reduce((acc, key) => {
          acc[key] = (currentInquilino.inmueble[key] || 0) - cantidadUsada2[key];
          return acc;
      }, {});
  };

    const renderControl = (articulo, descripcion, caracteristica, nombre_campo, otro) => (<>
        <span style={{ display: 'block', textAlign: 'center' }}>
          {nombre_campo} <b>{otro ?? "NO ASIGNADO"}</b>
        </span><br></br>
        <Divider style={{ textAlign: 'center' }}>¿Cuánto has gastado? {caracteristica}</Divider>
        <br></br>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px' }}>
        <button onClick={() => decrementar(articulo)} style={{ fontSize: '1.5em' }}>-1</button>
        <span style={{ margin: '0 10px', fontSize: '1.5em' }}>{cantidadUsada[articulo]} {descripcion}</span>
        <button onClick={() => incrementar(articulo)} style={{ fontSize: '1.5em' }}>+1</button>

        </div>
      </>
      
    );
    const renderControl2 = (articulo, descripcion, caracteristica, nombre_campo, otro) => (<>
      <span style={{ display: 'block', textAlign: 'center' }}>
        {nombre_campo} 
      </span><br></br>
      <Divider style={{ textAlign: 'center' }}>¿Cuánto hay? {caracteristica}</Divider>
      <br></br>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px' }}>
      {cantidadUsada2[articulo]} - {descripcion}
      </div>
      <br></br>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px' }}>

      <button onClick={() => decrementar2(articulo)} style={{ fontSize: '1.5em' }}>-1</button>
      <input
          type="number"
          value={cantidadUsada2[articulo]}
        //   onFocus={ (e)=>{
        //     if (e.target.value === "0") {
        //     setCantidadUsada2(prev => ({ ...prev, [articulo]: "" }));
        //   }}
        // }
          onChange={(e) => {
            const value = e.target.value;
              setCantidadUsada2(prev => ({ ...prev, [articulo]: value === "" ? 0 : parseInt(value, 10) }));
          }}
          style={{ margin: '0 6px', fontSize: '1.5em', textAlign: 'center', width:"auto" }}
        />
      {/* <span style={{ margin: '0 10px', fontSize: '1.5em' }}>{cantidadUsada2[articulo]} {descripcion}</span> */}
      
      <button onClick={() => incrementar2(articulo)} style={{ fontSize: '1.5em' }}>+1</button>
    
      </div>
    </>
    
  );

    return (
      <div style={{ padding: '20px', backgroundColor: 'white' }}>
      <Collapse>
     
       
       
        <CollapsePanel display header="Restar lo usado" collapsible={step >= 1 ? "enabled" : "disabled"}>
          <Divider>Gastado</Divider>
          <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>
 
          {renderControl('stock_sabanas_150', 'Sábanas grandes', '(Doble hilo Verde Fino)', 'stock_sabanas_150 ACTUAL:', currentPiso.inmueble.stock_sabanas_150)}
        </div>
        <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>
        
          {renderControl('stock_sabanas_90', 'Sábanas pequeñas', '(Hilo Naranja fino)', 'stock_sabanas_90 ACTUAL:', currentPiso.inmueble.stock_sabanas_90)}
        </div>
        <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>
        
          {renderControl('stock_almohadas', 'Almohadas', '(Hilo Naranja Doble)', 'stock_almohadas ACTUAL:', currentPiso.inmueble.stock_almohadas)}
        </div>
        <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>

          {renderControl('stock_edredones_90', 'Edredones pequeños', '(Hilo Verde Grueso)', 'stock_edredones_90 ACTUAL:', currentPiso.inmueble.stock_edredones_90)}
        </div>
        <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>
        
          {renderControl('stock_edredones_150', 'Edredones grandes', '(Hilo Dorado Grueso)', 'stock_edredones_150 ACTUAL:', currentPiso.inmueble.stock_edredones_150)}
        </div>
        <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>

          {renderControl('stock_toallas_cuerpo', 'Toallas de cuerpo', '(Toallas más grandes)', 'stock_toallas_cuerpo ACTUAL:', currentPiso.inmueble.stock_toallas_cuerpo)}
        </div>
        <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>
        
          {renderControl('stock_toallas_mano', 'Toallas de mano', '(Toalla Pequeña)', 'stock_toallas_mano ACTUAL:', currentPiso.inmueble.stock_toallas_mano)}
        </div>
        <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>
          
          {renderControl('stock_alfombrines', 'Alfombrines', '', 'stock_alfombrines ACTUAL:', currentPiso.inmueble.stock_alfombrines)}
        </div>
        <Button type="primary" onClick={()=>{setStep(2)}} style={{ margin: '10px' }}>Información registrada</Button>
       
        </CollapsePanel>  
            
        <CollapsePanel header="Observaciones" collapsible={step >= 2 ? "enabled" : "disabled"}>
            Observaciones:
            <TextArea
            rows={20}
            placeholder="ESCRIBE LO QUE FALTA. Productos de limpieza, desperfectos que veas."
            maxLength={4000}
            onChange={(e) =>
              setComentarioLimpiador(
                   e.target.value,
              )
            }
          />
           <Button type="primary" disabled={!(comentarioLimpiador.length>0)}onClick={()=>{if(comentarioLimpiador.length>0){setStep(3)}}} style={{ margin: '10px' }}>Comentario terminado</Button>
        </CollapsePanel>
        <CollapsePanel header="Enviar Whatsapp" collapsible={step >= 3 ? "enabled" : "disabled"}>
          <Divider>¿Cuánto queda en el armario?</Divider>
          <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>
          
          {renderControl2('stock_sabanas_150', 'Sábanas grandes', '(Doble hilo Verde Fino)', 'stock_sabanas_150 ACTUAL:', currentPiso.inmueble.stock_sabanas_150)}
          </div>
          <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>

          {renderControl2('stock_sabanas_90', 'Sábanas pequeñas', '(Hilo Naranja fino)', 'stock_sabanas_90 ACTUAL:', currentPiso.inmueble.stock_sabanas_90)}
          </div>
          <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>

          {renderControl2('stock_almohadas', 'Almohadas', '(Hilo Naranja Doble)', 'stock_almohadas ACTUAL:', currentPiso.inmueble.stock_almohadas)}
          </div>
          <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>

          {renderControl2('stock_edredones_90', 'Edredones pequeños', '(Hilo Verde Grueso)', 'stock_edredones_90 ACTUAL:', currentPiso.inmueble.stock_edredones_90)}
          </div>
          <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>

          {renderControl2('stock_edredones_150', 'Edredones grandes', '(Hilo Dorado Grueso)', 'stock_edredones_150 ACTUAL:', currentPiso.inmueble.stock_edredones_150)}
          </div>
          <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>

          {renderControl2('stock_toallas_cuerpo', 'Toallas de cuerpo', '(Toallas más grandes)', 'stock_toallas_cuerpo ACTUAL:', currentPiso.inmueble.stock_toallas_cuerpo)}
          </div>
          <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>

          {renderControl2('stock_toallas_mano', 'Toallas de mano', '(Toalla Pequeña)', 'stock_toallas_mano ACTUAL:', currentPiso.inmueble.stock_toallas_mano)}
          </div>
          <div style={{ alignItems: 'center', marginBottom: '15px', border: '1px solid black' }}>
          
          {renderControl2('stock_alfombrines', 'Alfombrines', '', 'stock_alfombrines ACTUAL:', currentPiso.inmueble.stock_alfombrines)}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px' }}>
          <Boton_peticion_handler_v2
              cuerpo="Enviar whatsapp"
              handler={() => {
                // console.log(currentInquilino, currentPiso, "esto: " + currentPiso.inmueble.name);
            
                // Construir el mensaje
                const mensaje = `
                ${currentPiso.inmueble.name}: *${fecha}* 
                 
                *${cantidadUsada2.stock_sabanas_150}* - Sábana 150  
                *${cantidadUsada2.stock_sabanas_90}* - Sábana 90  
                *${cantidadUsada2.stock_almohadas}* - Almohada  
                *${cantidadUsada2.stock_edredones_150}* - Edredon 150  
                *${cantidadUsada2.stock_edredones_90}* - Edredon 90  
                *${cantidadUsada2.stock_toallas_cuerpo}* - Toallas cuerpo  
                *${cantidadUsada2.stock_toallas_mano}* - Toallas mano  
                *${cantidadUsada2.stock_alfombrines}* - Alfombrin`
                console.log("https://api.whatsapp.com/send?phone=34676790727&text=" +
                  encodeURIComponent(mensaje))
                // Abrir la URL de WhatsApp con el mensaje codificado
                window.open(
                  "https://api.whatsapp.com/send?phone=34676790727&text=" +
                  encodeURIComponent(mensaje),
                  "_blank"
                );

                setStep(4)
              }}
            />

          </div>
        </CollapsePanel>

        

           
      
        </Collapse>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '15px' }}>
        <Button  disabled={step >= 4 ? false : true}type="primary" onClick={mostrarConfirmacion} style={{ margin: '10px' }}>Terminar y enviar informacion</Button>
        </div>
        </div>
    );
};

export default Limpiar;
