import { basePath, apiVersion } from "./config";

export async function getPreciosApi(token, userData) {
  const url = `${basePath}/${apiVersion}/precios`;
  //console.log("Fechas de búsqueda:", userData);

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    // Cambié 'params' a 'body' y utilicé JSON.stringify para convertir el objeto userData en JSON.
    body: JSON.stringify(userData)
  };

  try {
    const response = await fetch(url, params);
    const data = await response.json();
    return data;
  } catch (err) {
    return err.message;
  }
}
