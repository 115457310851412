import { Pie } from "@ant-design/charts";

export default function DemoPie(props) {
  const { data_real, my_title } = props;

  // Calcular el total de los valores en data_real
  const totalValue = data_real.reduce((acc, item) => acc + item.value, 0);

  const config = {
    appendPadding: 3,
    data: data_real,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6, // Hacer el gráfico en forma de dona

    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: totalValue.toFixed(2), // Mostrar el total facturado en el centro
        align: "center",
      },
    },
  };

  return <Pie {...config} />;
}
