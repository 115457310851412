import { basePath, apiVersion } from "./config";

export const createIncidencia = async (incidenciaData, accesToken) => {
  try {
    const url = `${basePath}/${apiVersion}/incidencia`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accesToken
      },
      body: JSON.stringify(incidenciaData),
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const getAllIncidencias = async (accesToken) => {
  try {
    const url = `${basePath}/${apiVersion}/incidencia`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accesToken
      },
      
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};
export const deleteIncidencia = async (id,accesToken) => {
  try {
    const url = `${basePath}/${apiVersion}/incidencia/${id}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accesToken
      },
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateIncidencia = async (id,data,accesToken) => {
  try {
    const url = `${basePath}/${apiVersion}/incidencia/${id}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accesToken
      },
      body:JSON.stringify(data)
    });
    return response.json();
    
     
  } catch (error) {
    throw error;
  }
};


export function getIncidenciasMesYear(accessToken, month, year) {
  const url = `${basePath}/${apiVersion}/incidenciasMesYear`;
  //console.log("getFacturacion dentro:", accessToken);
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify({
      year: year,
      month: month,
    }),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return err.message;
    });
}
