import React from 'react';
import { Table, Typography } from 'antd';

const { Title } = Typography;

const AirbnbResumen = ({ datos_Airbnb }) => {
    const obtenerResumenPisos = () => {
        // Ordenar los nombres de los pisos
        const nombresPisosOrdenados = Object.keys(datos_Airbnb ?? {}).sort();
    
        // Calcular los totales de pagos y comisiones para cada piso ordenado
        return nombresPisosOrdenados.map((piso) => {
          const reservas = datos_Airbnb[piso];
          const sumaPagos = Object.values(reservas).reduce((total, reserva) => total + parseFloat(reserva.pago || 0), 0);
          const sumaComisiones = Object.values(reservas).reduce((total, reserva) => total + parseFloat(reserva.comision || 0), 0);
          
          return {
            key: piso,
            nombre: piso,
            sumaPagos: sumaPagos.toFixed(2),
            sumaComisiones: sumaComisiones.toFixed(2),
          };
        });
      };

  const resumenData = obtenerResumenPisos();

  const columns = [
    {
      title: 'Nombre del Piso',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Suma de Pagos (€)',
      dataIndex: 'sumaPagos',
      key: 'sumaPagos',
    },
    {
      title: 'Suma de Comisiones (€)',
      dataIndex: 'sumaComisiones',
      key: 'sumaComisiones',
    },
  ];

  return (
    <div>
      <Title level={2}>Resumen de Pagos y Comisiones de Todos los Pisos</Title>
      <Table columns={columns} dataSource={resumenData} pagination={false} />
    </div>
  );
};

export default AirbnbResumen;
