import React, { useState,  useEffect } from "react";
import {
  Divider,
  Row,
  Col,
  Option,
  Upload,
  Select,
  Button,
  message,
  notification,
  Checkbox,
} from "antd";
import Boton_peticion_handler from "../../../../components/Admin/boton_peticion_handler/boton_peticion_handler";
import { CheckCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { asociarFacturaUsuario } from "../../../../api/series";
import { getAccessTokenApi } from "../../../../api/auth";
import DesplegableMultiple from "../../../../components/DesplegableMultiple";
import Dragger from "antd/lib/upload/Dragger";
// const { Dragger } = Upload;
import { getInmueblesApi } from "../../../../api/inmueble";
import { enviarCorreoPropietarioInmobiliario } from "../../../../api/user";

export default function Factura_propietario_subir({inmueble}) {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inmuebles, setInmuebles] = useState([]);
  const [enviar_correo, setenviar_correo] = useState(false);
  const [correo_propietario_enviar_factura, setcorreo_propietario_enviar_factura] = useState("");
  const [selectedInmueble, setSelectedInmueble] = useState(inmueble ?? null);
  const accesToken = getAccessTokenApi();

  useEffect(() => {
    async function fetchData(){
    await getInmueblesApi(accesToken, 1, true).then(response => {
      if(response.Pisos) {
      
        if(inmueble){
          response.Pisos.forEach(element => {
            if(element._id == inmueble){
              setenviar_correo(element.enviar_correo_a_propietario_tras_generar_cada_factura??false);
              setcorreo_propietario_enviar_factura(element?.correo_electronico_propietario_enviar_facturas ?? "NO ESTABLECIDO EL CORREO DEL PROPIETARIO TODAVIA EN INMUEBLES")
              return;
            }
          });
        }
        setInmuebles(response.Pisos);
      } else {
        //console.log("NOOOOOO")
      }
    });}
    fetchData();
  }, []);

  const handleFileChange = (info) => {
    let fileList = [...info.fileList];

    // Limitar la cantidad de archivos a uno
    fileList = fileList.slice(-1);

    // Filtrar archivos inválidos
    fileList = fileList.filter((file) => {
      if (file.size > 1024 * 1024 * 5) {
        message.error(
          `${file.name} es demasiado grande. El tamaño máximo es 5MB.`
        );
        return false;
      }
      return true;
    });

    setFileList(fileList);
  };

  const handleUpload = () => {
    
    
    if (!selectedInmueble) {
      message.error("Por favor, selecciona un inmueble antes de enviar.");
      return;
    }
    if (fileList.length === 0) {
      message.error("Por favor, selecciona un archivo.");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file.originFileObj);
    });

    
    // Lógica para enviar formData a una URL
    // Reemplaza 'URL_DE_TU_ENDPOINT' con la URL correcta
    // fetch("/factura-usuario", {
    //   method: "POST",
    //   body: formData,
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     //console.log("Respuesta del servidor:", data);
    //     message.success("Archivo enviado exitosamente.");
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.error("Error al enviar el archivo:", error);
    //     message.error("Error al enviar el archivo.");
    //   })
    //   .finally(() => {
    //     setFileList([]); // Limpiar la lista de archivos después de la carga

    //   });

    asociarFacturaUsuario(accesToken, formData, selectedInmueble)
      .then((response) => {
        //console.log("Respuesta del servidor:", response);
        if (!response.message) {
          notification["error"]({
            message: response.error,
          });
        } else {
          notification["success"]({
            message: "Creada correctamente",
          });
        }
        message.success("Archivo enviado exitosamente.");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error al enviar el archivo:", error);
        message.error("Error al enviar el archivo.");
      })
      .finally(() => {
        setFileList([]); // Limpiar la lista de archivos después de la carga
      });
      // if(enviar_correo){
      //   message.success("Correo enviado");
      //   enviarCorreoPropietarioInmobiliario(selectedInmueble, "Turistea - ¡Ya tienes disponible la factura de este mes!", "Aqui te dejo la factura de este mes: ")
      // }

      if(enviar_correo){
        formData.append("subject", "Turistea - ¡Ya tienes disponible la factura de este mes!");
        formData.append("body_email", "Aqui te dejo la factura de este mes:");
        formData.append("id", selectedInmueble);
        
        enviarCorreoPropietarioInmobiliario(formData)
          .then((response) => {
            if (response.message) {
              message.success("Correo enviado");
            } else {
              message.error("Error al enviar el correo");
            }
          })
          .catch((error) => {
            console.error("Error al enviar el correo:", error);
            message.error("Error al enviar el correo."+error);
          });
      }

   
  };
  const { Option } = Select;
  return (
    <div>
      <Row>
        <Col span={6}>
          <Select
            placeholder="Selecciona un propietario: "
            onChange={(value) => {
              console.log("value", value, "inmueble",inmuebles)
              setSelectedInmueble(value)

              inmuebles.forEach(element => {
                if(element._id == value){
                  setenviar_correo(element.enviar_correo_a_propietario_tras_generar_cada_factura??false);
                  setcorreo_propietario_enviar_factura(element?.correo_electronico_propietario_enviar_facturas ?? "NO ESTABLECIDO EL CORREO DEL PROPIETARIO TODAVIA EN INMUEBLES")
                  return;
                }
              });
              
            
            }}
            value={selectedInmueble}
          >
            {inmuebles.map((inmueble) => (
              <Option key={inmueble._id} value={inmueble._id}>
                {inmueble.name} 
              </Option>
            ))}
          </Select>
        </Col>
      
      </Row>

      <Row >
            Correo propietario: 
            <b>{ correo_propietario_enviar_factura}</b>
        </Row>  
        <Row >
            Enviar correo propietario: 
            <Checkbox
          name="privacyPolicy"
          onChange={(e)=>{
            setenviar_correo(e.target.checked)
          }}
          checked={enviar_correo}
        >
          
        </Checkbox>
        </Row>  
      <Divider />
      <Dragger
        fileList={fileList}
        onChange={handleFileChange}
        multiple={false}
        customRequest={() => {}}
        showUploadList={{ showRemoveIcon: true }}
        beforeUpload={() => false}
        iconRender={(file, defaultIcon) =>
          file.status === "done" ? <CheckCircleOutlined /> : defaultIcon
        }
      >
        <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
      </Dragger>
      {fileList.length > 0 ? (
        <Boton_peticion_handler
          isLoading={loading}
          cuerpo="Enviar"
          handler={handleUpload}
        />
      ) : (
        <Button disabled={true}>Enviar</Button>
      )}
    </div>
  );
}
