import React, { useState, useEffect } from "react";
import { enviarCorreoUsuario, getUsersByRoleApi } from "../../../../api/user";
import { getAccessTokenApi } from "../../../../api/auth";
import { Table, Tag, Button, notification } from "antd";
import './TablaLimpiadores.css'; // Importa el archivo CSS donde tienes las clases de estilo
import Boton_peticion_handler_v2 from "../../../../components/Admin/boton_peticion_handler_v2";
import { CommentOutlined, MailOutlined } from "@ant-design/icons";
import { basePath, basePathFrontend } from "../../../../api/config";

export default function TablaLimpiadores({ pisos_limpiar_hoy, lista_inmuebles }) {
  const [limpiadores, setLimpiadores] = useState([]);
  const [showLimpiadoresMenosDeTres, setShowLimpiadoresMenosDeTres] = useState(false);

  useEffect(() => {
    const fetchLimpiadores = async () => {
      try {
        const limpiadoresData = await getUsersByRoleApi(getAccessTokenApi(), "Limpiador");
        const limpiadoresConPisosAsignados = limpiadoresData?.users.map(limpiador => {
          const pisosAsignadosIds = Object.keys(pisos_limpiar_hoy).filter(key => pisos_limpiar_hoy[key].limpiador === limpiador.name);
          const nombresPisosAsignados = pisosAsignadosIds.map(id => lista_inmuebles[id]).join(' -- ');
          return { ...limpiador, pisosAsignados: pisosAsignadosIds.length, nombres_pisos: nombresPisosAsignados };
        });
        setLimpiadores(limpiadoresConPisosAsignados);
      } catch (error) {
        console.error("Error al obtener los limpiadores:", error);
      }
    };

    fetchLimpiadores();
  }, [pisos_limpiar_hoy, lista_inmuebles]);
  
  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? 'white-row' : 'grey-row';
  };

  const limpiadoresColumns = [
    {
      title: 'Accion',
      dataIndex: 'accion',
      key: 'accion',
      render: (text, record) => {
        console.log("accion",record);
      
        return  <>
        {record.telefono ?
        <Boton_peticion_handler_v2  style = {{background:"green"}} cuerpo={<>Enviar Whatsapp<CommentOutlined /></>} handler={()=>{   
          window.open(
          "https://api.whatsapp.com/send?phone=" +
          record.telefono +
          "&text=" +
          "Limpiezas disponibles mañana: "+ (record.pisosAsignados??"XX")+" Entra en la plataforma para verlos, por ahora son: *" + record.nombres_pisos + "*",
          "_blank"
        )}}/>:<></>}
        <Boton_peticion_handler_v2 cuerpo={ <>  <MailOutlined/> Notificar limpiador</>} 
        handler={async ()=>{
          const resp = await enviarCorreoUsuario(record.id, "Limpiezas disponibles mañana:"+(record.pisosAsignados??"XX")+"!", "Entra en la platadorma "+basePathFrontend+" para verlo" + record.nombres_pisos);
          if(resp.message){
            notification["success"]({
              message: resp.message
            });
          }else{
            notification["error"]({
              message: resp.error
            });
          }
        }} style={{ marginTop: 10 }} 
        />
        
        
        </>
      },
    },
    {
      title: 'Limpiador',
      dataIndex: 'name',
      key: 'name',
    },
   
    {
      title: 'Cantidad de Pisos Asignados',
      dataIndex: 'pisosAsignados',
      key: 'pisosAsignados',
      render: (text, record) => (
        <Tag color={ record.pisosAsignados >= 3 ? "red" : (record.pisosAsignados >= 1 ? "green" : undefined)}>{text}</Tag>
      ),
    },
    {
      title: 'Nombres de Pisos Asignados',
      dataIndex: 'nombres_pisos',
      key: 'nombres_pisos',
    }
  ];

  const limpiadoresFiltrados = showLimpiadoresMenosDeTres ? limpiadores : limpiadores.filter(limpiador => limpiador.pisosAsignados >= 1);

  return (
    <div>
     
     <Table
        columns={limpiadoresColumns}
        dataSource={limpiadoresFiltrados}
        rowClassName={getRowClassName}
        pagination={false} // Eliminar los botones de paginación
      />
       <Button onClick={() => setShowLimpiadoresMenosDeTres(!showLimpiadoresMenosDeTres)}>
        {showLimpiadoresMenosDeTres ? "Ver menos" : "Ver más"}
      </Button>
    </div>
  );
}
