import React, { useState, useEffect } from "react";
import moment from "moment";
import { getInmuebleDatosFacturacion, obtenerListaInmuebles } from "../../../../api/inmueble";
import { getAccessTokenApi } from "../../../../api/auth";
import TablePlot from "../../../../components/Admin/Graphs/Table";
import "antd/dist/antd.css";
import dayjs from "dayjs";
import FacturasProductos from "../../../../components/FacturasProductos/FacturasProductos";

import {
  Avatar,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Radio,
  Space,
  Divider,
  Card,
  Table,
  Checkbox,
} from "antd";

import Modal from "../../../../components/Modal";
import { DeleteOutlined, EditOutlined, FilePdfOutlined } from "@ant-design/icons";
import {
  getFacturacion,
  getFacturacionTotal,
  getFacturacionDatos,
} from "../../../../api/facturacion";
import Listinquilinosfacturacion from "../../../../components/Admin/Users/Listinquilinosfacturacion";
import DemoPie from "../../../../components/Admin/Graphs/Pie";

import Facturas from "../../../../components/Facturas";
import LectorDni from "../../../../components/LectorDni";
import { useReactTable } from "@tanstack/react-table";
import {
  createFactura,
  createFacturaProforma,
  createSerie,
  getSeries,
  getSeriesInmueble,
} from "../../../../api/series";
import Boton_peticion_handler from "../../../../components/Admin/boton_peticion_handler/boton_peticion_handler";
import Factura_propietario_subir from "../Factura_propietario_subir/Factura_propietario_subir";
import Factura_inquilino_subir from "../Factura_inquilino_subir/Factura_inquilino_subir";
const { Option } = Select;

export function ModalCrearSerie({ setIsVisibleModal, setReloadUsers }) {
  const [cargando_crear_serie, set_cargando_crear_serie] = useState(false);
  const [serie_nombre, set_serie_nombre] = useState("");
  const [serie_numero_inicial, set_serie_numero_inicial] = useState("");
  const [serie_lomo, set_serie_lomo] = useState("");
  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Row gutter={24}>
            Nombre serie:
            <Input
              type="nombre_nueva_serie"
              name="nombre_nueva_serie"
              placeholder="nombre_nueva_serie "
              className="login-form__input"
              value={serie_nombre}
              onChange={(e) => set_serie_nombre(e.target.value)}
            />
          </Row>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Row gutter={24}>
            Numero inicio nueva serie:
            <Input
              type="numero_inicio_nueva_serie"
              name="numero_inicio_nueva_serie"
              placeholder="numero_inicio_nueva_serie "
              className="login-form__input"
              value={serie_numero_inicial}
              onChange={(e) => set_serie_numero_inicial(e.target.value)}
            />
          </Row>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Row gutter={24}>
            Lomo serie:
            <Input
              type="lomo_nueva_serie"
              name="lomo_nueva_serie / Pie de la factura"
              placeholder="lomo_nueva_serie "
              className="login-form__input"
              value={serie_lomo}
              onChange={(e) => set_serie_lomo(e.target.value)}
            />
          </Row>
        </Col>
      </Row>
      <Divider></Divider>
      <Row gutter={24}>
        <Col span={24}>
          <Boton_peticion_handler
            isLoading={cargando_crear_serie}
            cuerpo={"Crear nueva serie: "}
            handler={async () => {
              set_cargando_crear_serie(true);
              const accessToken = getAccessTokenApi();
              await createSerie(
                accessToken,
                serie_nombre,
                serie_numero_inicial,
                serie_lomo
              ).then((response) => {
                if (response.error) {
                  notification["error"]({
                    message: response.error,
                  });
                } else {
                  notification["success"]({
                    message: "Creada correctamente",
                  });
                  setIsVisibleModal(false);
                }
                set_cargando_crear_serie(false);

                setReloadUsers(true);
              });
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default function CrearFacturas() {
  const currentUrl = window.location.href;


  // Crear un objeto URL para manipular la URL actual
  const url = new URL(currentUrl);
  
  // Obtener los parámetros de consulta actuales
  const queryParams = new URLSearchParams(url.search);
  const [piso_obtener_data_facturacion, set_piso_obtener_data_facturacion] = useState([]);
  
  const [inmuebleSelected, setInmuebleSelected] = useState(queryParams.get('inmueble') ?? "");
  const [enviar_propietario, setenviar_propietario] = useState(queryParams.get('enviar_propietario') ?? "");
  
  
  const [enviar_correo_concreto, setenviar_correo_concreto] = useState(queryParams.get('correo_envio') ?? "");
  const [datosFacturacion, setDatosFacturacion] = useState([]);

  const [datosFacturacionAdministrador, setDatosFacturacionAdministrador] =
    useState([]);

  const [datosAccum, setDatosAccum] = useState([]);
  const [metricasAccum, setMetricasAccum] = useState([]);

  const [inmueble_facturacion, setinmueble_facturacion] = useState({});

  const [metricasAccumCol, setMetricasAccumCol] = useState([]);
  const [monthQuery, setMonthQuery] = useState(0);
  const [yearQuery, setYearQuery] = useState(0);

  const [usersActive, setUsersActive] = useState([]); //Todos los usuarios
  const [usersInactive, setUsersInactive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [selectedValues2, setSelectedValues2] = useState(["Inquilino"]);

  function onChange(date, dateString) {
    setMonthQuery(date.month());
    setYearQuery(date.year());
    //console.log(monthQuery, dateString);
    //console.log(yearQuery, dateString);
  }


  const [series_data, set_series_data] = useState([]);
  
  const [columnasFacturacion, setColumnasFacturacion] = useState([]);
  const [columnasContabilidad, setColumnasContabilidad] = useState([]);
  const [datosPie, setdatosPie] = useState([]);
  const [Factura_proforma_visible, setFactura_proforma_visible] =
    useState(true);
  const [Factura_visible, setFactura_visible] = useState(false);
  const [datosPiePorcentages, setdatosPiePorcentages] = useState([]);
  const [series, setseries] = useState({});
  const [numero_factura, setnumero_factura] = useState("");
  const [nombre_serie, setnombre_serie] = useState("");
  const [serie_id, setserie_id] = useState(queryParams.get('serie_id') ||"" );
  
  useEffect(()=>{

  },[serie_id])
  const mesActual = dayjs(); // Obtiene la fecha actual con dayjs
  useEffect(() => {
    console.log(queryParams.get('serie_id') ||"")
    const accesToken = getAccessTokenApi();
    obtenerListaInmuebles(accesToken).then((response) => {
      //console.log(response.Pisos[0].id);
      var piso_obtener_facturacion = [];

      let pisos_ordenados = response.Pisos.sort((a, b) => a.nombre.localeCompare(b.nombre));

      for (let i = 0; i < response.Pisos.length; i++) {
        piso_obtener_facturacion.push(
          <Option value={pisos_ordenados[i].id}>
            {pisos_ordenados[i].nombre}
          </Option>
        );
      }
      set_piso_obtener_data_facturacion(piso_obtener_facturacion);
    });
    getSeries(accesToken).then((response) => {
      var num_tours_v2 = [];
      var numeros_series = {};
      //console.log(response);
      for (let i = 0; i < response.series.length; i++) {
        num_tours_v2.push(
          <Option value={response.series[i]._id}>
            {response.series[i].nombre_serie}
          </Option>
        );
        numeros_series[response.series[i]._id] = {
          nombre: response.series[i].nombre_serie,
          numero_factura: response.series[i].numero_serie_global,
          nombre_lomo: response.series[i].nombre_lomo,
        };
      }
      //console.log(numeros_series);
      setseries(numeros_series);
      set_series_data(num_tours_v2);
      setnumero_factura((numeros_series[queryParams.get('serie_id')]?.numero_factura));
      setnombre_serie((numeros_series[queryParams.get('serie_id')]?.nombre))
    });

    setReloadUsers(false);
  }, [reloadUsers]);
  useEffect(() => {

    
  }, [Factura_proforma_visible]);
  useEffect(() => {

    setserie_id(queryParams.get('serie_id') ||"")
   
  }, []);

  //VARIABLES NUEVAS:
  const [elemento_factura, setelemento_factura] = useState({
    referencia: "",
    descripcion: "",

    unidades: "",
    precio_unidad: "",

    tax_percentage: "",
    tax_value: "",
  });

  const dateString = moment().format("YYYY-MM-DD");
  
  // const dateString = "2024-09-30";
  const [textToCopy, setTextToCopy] = useState(`
    Necesito de usted sus datos fiscales:
      1- Nombre completo
      2- Dirección
      3- NIF/CIF
    
    Sobre el Apartamento:
      1- Fecha de llegada y salida
      2- Importe de la reserva
      3- Nombre del apartamento.
  `);
  
  const [elementos_factura, setelementos_factura] = useState([]);
  // Variables de estado para los campos de entrada
  const [nombreEmisor, setNombreEmisor] = useState(queryParams.get('nombreEmisor') || "");
  const [nifEmisor, setNifEmisor] = useState(queryParams.get('nifEmisor') || "");
  const [direccionEmisor, setDireccionEmisor] = useState(queryParams.get('direccionEmisor') || "");
  const [nombreReceptor, setNombreReceptor] = useState(queryParams.get('nombreReceptor') || "");
  const [nifReceptor, setNifReceptor] = useState(queryParams.get('nifReceptor') || "");
  const [direccionReceptor, setDireccionReceptor] = useState(queryParams.get('direccionReceptor') || "");



  const [descripcionItem, setDescripcionItem] = useState( "");
  const [cantidad, setCantidad] = useState("");
  const [precioUd, setPrecioUd] = useState("");
  const [precioUdConIVA, setPrecioUdIVA] = useState("");

  const [taxPercentage, setTaxPercentage] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [retencion, setRetencion] = useState( Number(queryParams.get('retencion')).toFixed(2) ?? 0);

  const [fecha_creacion, setfecha_creacion] = useState(dateString);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  // Variable de estado para elementos_factura
  let numero_referencia = 0;
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(value);
  };

  const elementosIniciales = queryParams.get('descripcionItem') ? [
    {
      referencia: 0,
      descripcion_item: queryParams.get('descripcionItem') || "",
      cantidad: queryParams.get('cantidad') || "",
      precio_ud: queryParams.get('precioUd') || "",
      tax_percentage: queryParams.get('taxPercentage') || "",
      tax_number: queryParams.get('taxNumber') || "",
      total: Number(Number(queryParams.get('cantidad')) * Number(queryParams.get('precioUd')) + Number(queryParams.get('taxNumber'))).toFixed(2),
    }
  ] : [];
  
  const [elementosFactura, setElementosFactura] = useState(elementosIniciales);
  
  useEffect(() => {}, [elementosFactura]);

  const borrarElementoFactura = (ref) => {
    //console.log("Ref", ref);
    //console.log("elementosFactura.lenght", elementosFactura.length);

    setElementosFactura((prevElementos) =>
      prevElementos.filter((elemento) => elemento.referencia !== ref)
    );
    setElementosFactura((prevElementos) =>
      prevElementos.map((elemento, index) => ({
        ...elemento,
        referencia: index,
      }))
    );
  };
  // Función para manejar el clic en el botón "Añadir"
  const agregarElementoFactura = () => {
    // Crear un objeto con la información de los campos
    // Función para manejar el clic en el botón "Borrar" de una fila

    const nuevoElementoFactura = {
      referencia: elementosFactura.length ?? 0,
      descripcion_item: descripcionItem,
      cantidad: cantidad,
      precio_ud: precioUd,
      tax_percentage: taxPercentage,
      tax_number: taxNumber,
      total: Number(cantidad) * Number(precioUd) + Number(taxNumber),
    };
    // Agregar el botón "Borrar" a la fila del nuevo elemento
    nuevoElementoFactura.eliminar = (
      <Button
        type="primary"
        style={{ backgroundColor: "red", borderColor: "red" }}
        onClick={() => {
          borrarElementoFactura(nuevoElementoFactura.referencia);
        }}
      >
        <DeleteOutlined/> Borrar
      </Button>
    );
    // Agregar el objeto a elementos_factura
    setElementosFactura([...elementosFactura, nuevoElementoFactura]);
    //console.log("elementosFactura", elementosFactura);
    // Limpiar los campos de entrada después de agregarlos

    setDescripcionItem("");
    setCantidad("");
    setPrecioUd("");
    setTaxPercentage("");
    setTaxNumber("");
  };

  const [editRowKey, setEditRowKey] = useState(null); // Para manejar la fila que se está editando

  const edit = (record) => {
    setEditRowKey(record.referencia); // Activa el modo de edición para la fila seleccionada
  };
  
  const save = (key) => {
    setEditRowKey(null); // Desactiva el modo de edición
  };
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isEditing = (record) => record.referencia === editRowKey;
  
  // Función para actualizar los valores de la fila editada
  const handleSave = (record, field, value) => {
    const updatedElements = elementosFactura.map((item) => {
      if (item.referencia === record.referencia) {
        return { ...item, [field]: value }; // Actualiza el valor del campo editado
      }
      return item;
    });
    setElementosFactura(updatedElements); // Actualiza el estado
  };
  
  // Columnas de la tabla con campos editables
  const columns = [
    {
      title: "Referencia",
      dataIndex: "referencia",
      key: "referencia",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion_item",
      key: "descripcion_item",
      render: (text, record) => {
        return isEditing(record) ? (
          <Input
            value={record.descripcion_item}
            onChange={(e) => handleSave(record, "descripcion_item", e.target.value)}
          />
        ) : (
          text
        );
      },
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      render: (text, record) => {
        return isEditing(record) ? (
          <Input
            value={record.cantidad}
            onChange={(e) => handleSave(record, "cantidad", e.target.value)}
          />
        ) : (
          text
        );
      },
    },
    {
      title: "Precio con IVA",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return isEditing(record) ? (
          <Input
            value={record.total}
            onChange={(e) => {
              handleSave(record, "total", e.target.value)
              
            }
          }
          />
        ) : (
          text
        );
      },
    },
    {
      title: "Tax Percentage",
      dataIndex: "tax_percentage",
      key: "tax_percentage",
      render: (text, record) => {
        return isEditing(record) ? (
          <select
            value={record.tax_percentage}
            onChange={(e) => {
              let tasas_porcentaje = Number(e.target.value)
              handleSave(record, "tax_percentage", e.target.value)
              // handleSave(record, "precio_ud", record.precio_ud/tasas_porcentaje)
              // handleSave(record, "tax_number", e.target.value)

            }}
          >
            <option value="0">0%</option>
            <option value="10">10%</option>
            <option value="21">21%</option>
          </select>

        ) : (
          text
        );
      },
    },
    {
      title: "Precio Unidad (BI)",
      dataIndex: "precio_ud",
      key: "precio_ud",
      render: (text, record) => {
        return isEditing(record) ? (
          <Input
            value={record.precio_ud}
            onChange={(e) => handleSave(record, "precio_ud", e.target.value)}
          />
        ) : (
          text
        );
      },
    },
  
    {
      title: "Taxes",
      dataIndex: "tax_number",
      key: "tax_number",
      render: (text, record) => {
        return isEditing(record) ? (
          <Input
          value={record.tax_number}
          onChange={(e) => handleSave(record, "tax_number", e.target.value)}
        />

        ) : (
          text
        );
      },
    },
   
    {
      title: "Eliminar",
      dataIndex: "eliminar",
      key: "eliminar",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record.referencia)} style={{ marginRight: 8 }}>
              Guardar
            </Button>
            <Button onClick={() => setEditRowKey(null)}>Cancelar</Button>
          </span>
        ) : (
          <Button style={{type:"primary"}} onClick={() => edit(record)}><EditOutlined/>Editar</Button>
        );
      },
    },
  ];
  const addUserModal = () => {
    setIsVisibleModal(!isVisibleModal);
    setModalTitle("Creando nueva serie");
    setModalContent(<ModalCrearSerie setIsVisibleModal={setIsVisibleModal} />);
    //console.log("addUserModal");
  };

  const crearFactura = async () => {
    const accessToken = getAccessTokenApi();

    const factura_elemento = {
      fecha_emision_factura: fecha_creacion,
      // group_id: String,
      data_emisor: {
        nombre: nombreEmisor,
        direccion_emisor: direccionEmisor,
        numero_identificacion_fiscal: nifEmisor,
      },
      data_receptor: {
        nombre: nombreReceptor,
        direccion_emisor: direccionReceptor,
        numero_identificacion_fiscal: nifReceptor,
      },
      conceptos_facturar: elementosFactura,
      serie_facturacion: serie_id,
      retencion: retencion
      // factura_o_proforma: Boolean, //Puede ser o recibo o factura -> Si es proforma solo datos del receptor
      // total_facturado: String,
      // total_tasas: String,
      // base_imponible_total: String,
      // pie_de_factura: String,
    };
    //console.log("factura_elemento", factura_elemento);

    let resp = await createFactura(
      accessToken,
      serie_id,
      factura_elemento
    ).then((response) => {
      if (response.error) {
        notification["error"]({
          message: response.error,
        });
      } else {
        notification["success"]({
          message: "Creada correctamente",
        });
        setFactura_proforma_visible(false);
        setFactura_visible(true);
      }
    });
  };

  const crearFacturaProforma = async () => {
    const accessToken = getAccessTokenApi();

    const factura_elemento = {
      fecha_emision_factura: fecha_creacion,
      // group_id: String,
      data_emisor: {
        nombre: nombreEmisor,
        direccion_emisor: direccionEmisor,
        numero_identificacion_fiscal: nifEmisor,
      },
      data_receptor: {
        nombre: nombreReceptor,
        direccion_emisor: direccionReceptor,
        numero_identificacion_fiscal: nifReceptor,
      },
      conceptos_facturar: elementosFactura,
      serie_facturacion: serie_id,
      retencion: retencion
      // factura_o_proforma: Boolean, //Puede ser o recibo o factura -> Si es proforma solo datos del receptor
      // total_facturado: String,
      // total_tasas: String,
      // base_imponible_total: String,
      // pie_de_factura: String,
    };
    //console.log("factura_elemento", factura_elemento);

    let resp = await createFacturaProforma(
      accessToken,
      serie_id,
      factura_elemento
    ).then((response) => {
      if (response.error) {
        notification["error"]({
          message: response.error,
        });
      } else {
        notification["success"]({
          message: "Creada correctamente",
        });

        setFactura_proforma_visible(true);
        setFactura_visible(false);
      }
    });
  };


  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      notification["success"]({
        message: "Texto copiado al portapapeles",
      });
    }).catch((err) => {
      notification["error"]({
        message: "Error al copiar el texto",
      });
    });
  };
  const [map_series, setmap_series] = useState({});
  const [series_elementos_select, setseries_elementos_select] = useState([]);

  useEffect(() => {
    getSeriesInmueble(getAccessTokenApi(), inmuebleSelected).then((response) => {
      console.log("RESPONSE getSeriesInmueble", response);
  
      var series_elementos = [];
      var series_elementos_aux = response?.message ?? {};
  
      // Comprobamos si `response.message` existe antes de usarlo
      if (series_elementos_aux && typeof series_elementos_aux === "object") {
        Object.keys(series_elementos_aux).forEach((element, index) => {
          console.log("CAMBIOS", element);
          // Nos aseguramos de que el valor y el texto dentro del Option sean válidos para renderizar
         
          let value = series_elementos_aux[element];
          if(!value){
            return;
          }
          let label = element;
  
          // Si `value` es un objeto, convierte a una cadena válida o usa una propiedad adecuada
          if (typeof value === "object") {
            value = JSON.stringify(value); // O puedes elegir una propiedad específica si es más útil
          }
  
          series_elementos.push(
            <Option key={index} value={value}>
              {label}
            </Option>
          );
        });
      }
  
      // Establecer el estado con las series obtenidas
      setmap_series(series_elementos_aux);
      setseries_elementos_select(series_elementos);
    });
  }, [inmuebleSelected]);
  



  function handleSelectChange(value, option){
   
    setRetencion(value)
  }
  const handleCrearFacturaClick = () => {
    setIsModalVisible(true);
  };
  const [checkedList, setCheckedList] = useState([false, false, false]);

  const canConfirm = checkedList.every((checked) => checked);

  const handleCheckboxChange = (index) => {
    const updatedCheckedList = [...checkedList];
    updatedCheckedList[index] = !updatedCheckedList[index];
    setCheckedList(updatedCheckedList);
  };

  return (
    <>
     <Row gutter={24}>
        <Col span={24}>
          <Button type="primary" onClick={copyToClipboard}>
            Copiar solicitud de datos
          </Button>
        </Col>
      </Row>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      >
        {modalContent}
      </Modal>

      {!Factura_visible ? < Card>
      
            <Row gutter={24}>
              <Select
              style={{minWidth:"200px"}}
                value={serie_id}
                onChange={(e) => {
                  //console.log("eeeeeeeeeeeeeeeeeeeeeee", e);
                  //console.log("eee", series);
                  setnumero_factura(series[e].numero_factura);
                  setserie_id(e);
                  setnombre_serie(series[e].nombre)
                }}
                placeholder={"Selecciona una serie"}
              >
                {series_data}
              </Select>
              <Divider type="vertical"></Divider>
              <Col>
                <span>Numero Factura: </span>
              </Col>
              <Col>
                <span>{numero_factura} </span>
              </Col>
              <Divider type="vertical"></Divider>
              <Button type="primary" onClick={addUserModal}>
                Añadir serie
              </Button>
              <Divider type="vertical"></Divider>
              <Row>
                Fecha Factura:
                <DatePicker
                  renderExtraFooter={() => "extra footer"}
                  value={moment(fecha_creacion, "YYYY-MM-DD")} // Aquí se establece el valor
                  onChange={(date, dateString) => {
                    //console.log("onChange", dateString);

                    setfecha_creacion(dateString);
                  }}
                />
              </Row>
              <Row>
                Retencion
                <Select

                  id="retencion"
                  name="Retencion"
                  data-obligatorio="true"
                  placeholder="Seleccionar Retencion..."
                  defaultValue={retencion}
                  onSelect={handleSelectChange}
                >
                  <Option value={0} name="Sin retencion"/>
                  <Option value={0.19} name="19%"/>

                  </Select>
              </Row>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Divider></Divider>
                Emisor
                <Select
                style={{minWidth:"200px"}}
                  value={inmuebleSelected} // <== Añadir esta línea
                  onChange={async(e) => {
                    setInmuebleSelected(e)
                    let response = await getInmuebleDatosFacturacion(getAccessTokenApi(), e);
                    if(!response.message){
                      notification["error"]({
                        message: "Error"
                      });
                    }else{

                      setNifEmisor(response?.datos?.id_facturacion)
                      setDireccionEmisor(response?.datos?.direccion_facturacion)
                      setNombreEmisor(response?.datos?.nombre_facturacion)

                    }
                  }}
                  placeholder={"Selecciona un inmueble"}
                >
              {piso_obtener_data_facturacion}
            </Select>
            <Select
            style={{minWidth:"300px"}}
              onChange={(e) => {
                try {
                  // Verificamos que `series[e]` no sea `undefined` o `null`
                  const selectedSeries = series?.[e];

                  if (selectedSeries) {
                    // Si existe, establecemos los valores
                    setnumero_factura(selectedSeries.numero_factura ?? "N/A"); // Si no tiene numero_factura, "N/A" como fallback
                    setserie_id(e);
                    setnombre_serie(selectedSeries.nombre ?? "Nombre desconocido"); // Valor por defecto si no hay nombre
                  } else {
                    console.warn("Serie seleccionada no encontrada:", e);
                    // Opcionalmente puedes realizar alguna acción, como mostrar un mensaje o limpiar los campos.
                  }
                } catch (error) {
                  console.error("Error al seleccionar la serie:", error);
                  // Aquí podrías manejar el error, mostrando un mensaje de alerta o logueando el problema.
                }
              }}
              placeholder={"Selecciona una serie"}
            >
              {series_elementos_select}
            </Select>
 
            <Divider></Divider>
                <Input
                  //prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="nombre_emisor"
                  name="nombre_emisor"
                  placeholder="nombre_emisor "
                  className="login-form__input"
                  onChange={(e) => setNombreEmisor(e.target.value)}
                  value={nombreEmisor}
                />
                <Input
                  //prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="nif_emisor"
                  name="nif_emisor"
                  placeholder="nif_emisor "
                  className="login-form__input"
                  onChange={(e) => setNifEmisor(e.target.value)}
                  value={nifEmisor}
                />
                <Input
                  //prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="direccion_emisor"
                  name="direccion_emisor"
                  placeholder="direccion_emisor "
                  className="login-form__input"
                  onChange={(e) => setDireccionEmisor(e.target.value)}
                  value={direccionEmisor}
                />
              </Col>
              <Col>
                <Divider></Divider>
                  Receptor<Select
            //  defaultValue={inmuebleSelected} // <== Añadir esta línea
                  onChange={async(e) => {
                    let response = await getInmuebleDatosFacturacion(getAccessTokenApi(), e);
                    if(!response.message){
                      notification["error"]({
                        message: "Error"
                      });
                    }else{
                      setNifReceptor(response?.datos?.id_facturacion)
                      setDireccionReceptor(response?.datos?.direccion_facturacion)
                      setNombreReceptor(response?.datos?.nombre_facturacion)
                    }
                  }}
                  placeholder={"Selecciona un inmueble"}
                >
              {piso_obtener_data_facturacion}
            </Select>
            <Divider></Divider>
                <Input
                  //prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="nombre_receptor"
                  name="nombre_receptor"
                  placeholder="nombre_receptor "
                  className="login-form__input"
                  onChange={(e) => setNombreReceptor(e.target.value)}
                  value={nombreReceptor}
                />
                <Input
                  //prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="nif_receptor"
                  name="nif_receptor"
                  placeholder="nif_receptor "
                  className="login-form__input"
                  onChange={(e) => setNifReceptor(e.target.value)}
                  value={nifReceptor}
                />
                <Input
                  //prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="direccion_receptor"
                  name="direccion_receptor"
                  placeholder="direccion_receptor "
                  className="login-form__input"
                  onChange={(e) => setDireccionReceptor(e.target.value)}
                  value={direccionReceptor}
                />
              </Col>
            </Row>

            <Divider></Divider>
            
            <Table
              dataSource={elementosFactura}
              columns={columns}
            />
            
            <Divider></Divider>
            <Row gutter={24}>
              <Col span={4}>
                <Input
                  type="descripcion_item"
                  name="descripcion_item"
                  placeholder="descripcion_item "
                  className="login-form__input"
                  value={descripcionItem}
                  onChange={(e) => setDescripcionItem(e.target.value)}
                />
              </Col>
              <Col span={4}>
                <Input
                  type="cantidad"
                  name="cantidad"
                  placeholder="cantidad "
                  className="login-form__input"
                  value={cantidad}
                  onChange={(e) => setCantidad(e.target.value)}
                />
              </Col>
              <Col span={4}>
                <Input
                  type="PRECIO CON IVA"
                  name="PRECIO CON IVA"
                  placeholder="precio/Ud con iva "
                  className="login-form__input"
                  value={precioUdConIVA}
                  onChange={(e) => {
                    setPrecioUdIVA(e.target.value);
                   

                  }}
                />
              </Col>
              <Col span={4}>
                {/* <Input
                  type="tax_percentage"
                  name="tax_percentage"
                  placeholder="tax_percentage: Es lo que quieres que salga escrito Ejemplo: 21% "
                  className="login-form__input"
                  value={taxPercentage}
                  onChange={(e) => setTaxPercentage(e.target.value)}
                /> */}
                <select
                  name="tax_percentage"
                  className="login-form__input"
                  value={taxPercentage}
                  onChange={(e) => {
                    setTaxPercentage(e.target.value)
                    setPrecioUd(Number(precioUdConIVA/(1+(Number(e.target.value)/100))).toFixed(2))
                    setTaxNumber(Number(precioUdConIVA - precioUdConIVA/(1+(Number(e.target.value)/100))).toFixed(2))
                  }}
                >
                  <option value="0">0%</option>
                  <option value="10">10%</option>
                  <option value="21">21%</option>
                </select>

              </Col>
              <Col span={4}>
                <Input
                  type="precio_ud"
                  name="precio_ud"
                  placeholder="precio_ud "
                  className="login-form__input"
                  value={precioUd}
                  onChange={(e) => setPrecioUd(e.target.value)}
                />
              </Col>
             
              <Col span={4}>
                <Input
                  type="tax_number"
                  name="tax_number"
                  placeholder="tax_number Es el valor sin el %:  Ejemplo: 21"
                  className="login-form__input"
                  value={taxNumber}
                  onChange={(e) => setTaxNumber(e.target.value)}
                />
              </Col>
              <Col span={4}>
                <Button type="primary" onClick={agregarElementoFactura}>
                  Añadir
                </Button>
              </Col>
            </Row>
            <Divider></Divider>
            <Row>
              {!Factura_proforma_visible ? (
                !Factura_visible ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      // setFactura_proforma_visible(!Factura_proforma_visible);
                      crearFacturaProforma();
                    }}
                  >
                    Guardar Proforma
                    <FilePdfOutlined />
                  </Button>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              
              <Modal
        title="Confirmación de Creación de Factura"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancelar
          </Button>,
          <Button
            key="confirm"
            type="primary"
            disabled={!canConfirm}
            onClick={crearFactura}
          >
            Confirmar y Crear
          </Button>,
        ]}
      >
        <Checkbox checked={checkedList[0]} onChange={() => handleCheckboxChange(0)}>
          ¿Hay alguna factura a inquilino concreto?
        </Checkbox>
        <Checkbox checked={checkedList[1]} onChange={() => handleCheckboxChange(1)}>
          ¿Has revisado la fecha?
        </Checkbox>
        <Checkbox checked={checkedList[2]} onChange={() => handleCheckboxChange(2)}>
          Vuelve a leer todo
        </Checkbox>
      </Modal>

              {/* <Divider type="vertical"></Divider> */}
              {Factura_proforma_visible ? (
                <Button
                  style={{
                    backgroundColor: "green",
                    borderColor: "green",
                    color: "white",
                  }}
                  onClick={handleCrearFacturaClick}
                >
                  Crear Factura
                </Button>
              ) : (
                <></>
              )}
            </Row>
            
            <Row>
              {!Factura_visible ? (
                <Row gutter={24}>
                  <Col span={20}>
                    <FacturasProductos
                      retencion={retencion}
                      serie_nombre={nombre_serie}
                      numero_factura={numero_factura ?? 1}
                      nombre_lomo={
                        serie_id === "" ? "" : series[serie_id]?.nombre_lomo ?? ""
                      }
                      proforma={true}
                      nombre_emisor={nombreEmisor ?? ""}
                      direccionEmisor={direccionEmisor ?? ""}
                      nifEmisor={nifEmisor ?? ""}
                      nombreReceptor={nombreReceptor ?? ""}
                      direccionReceptor={direccionReceptor ?? ""}
                      nifReceptor={nifReceptor ?? ""}
                      elementosFactura={elementosFactura ?? ""}
                      fecha_creacion={fecha_creacion ?? "0000-00-01"}
                      datos_facturacion_administrador={
                        datosFacturacionAdministrador ?? []
                      }
                      datos_mes={datosAccum ?? []}
                      inquilinos={usersActive ?? []}
                      datos_facturacion={inmueble_facturacion ?? {}}
                    />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Row>
      </Card> : <></> 
      }

      <Row>
        {Factura_visible ? (
          <Row gutter={24}>
            <Col span={20}>
            <Card>
             
              {enviar_propietario === "si"?<>
              ¿SUBIR FACTURA A PROPIETARIO?
              <Factura_propietario_subir inmueble={queryParams.get('inmueble') }/>
              </>:<> 
              <span>No hace falta enviar factura a propietario. 
              Si aun con todo  quieres enviarla pulsa esta boton:
              </span>
                <Button onClick={()=>{setenviar_propietario("si")}} > Mostrar el sistema de envio de facturas</Button>
              </>}
              </Card>
              <Divider></Divider>
              <Card>
              {enviar_correo_concreto !== ""?
              <>
              ¿Enviar Factura a inquilino con correo? {enviar_correo_concreto}
              <Factura_inquilino_subir correo_envio={enviar_correo_concreto }/>
              </>:<> 
              </>


              }
              </Card>
              <Divider></Divider>
              <FacturasProductos
                retencion={retencion}
                serie_nombre={nombre_serie}
                numero_factura={numero_factura}
                proforma={false}
                nombre_emisor={nombreEmisor}
                direccionEmisor={direccionEmisor}
                nifEmisor={nifEmisor}
                nombreReceptor={nombreReceptor}
                direccionReceptor={direccionReceptor}
                nifReceptor={nifReceptor}
                elementosFactura={elementosFactura}
                fecha_creacion={fecha_creacion}
                datos_facturacion_administrador={
                  datosFacturacionAdministrador ?? []
                }
                datos_mes={datosAccum ?? []}
                inquilinos={usersActive ?? []}
                datos_facturacion={inmueble_facturacion ?? {}}
                nombre_lomo={
                  serie_id === "" ? "" : series[serie_id]?.nombre_lomo ?? ""
                }
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Row>
    </>
  );
}
