import React, { useState, useEffect } from "react";
import { Modal, Button, Input } from "antd";
import { createNewApi, getNewsApi, updateNewApi, deleteNewApi } from "../../../api/new";
import "./Dashboard.scss";
import { getAccessTokenApi } from "../../../api/auth";
import moment from "moment";
import jwtDecode from "jwt-decode";


export default function HomeBoard() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [news, setNews] = useState([]);
    const [currentNews, setCurrentNews] = useState({ title: "", content: "" });
    const [editMode, setEditMode] = useState(false);
    const [editNewsId, setEditNewsId] = useState(null);
    const token = getAccessTokenApi();
    const role = (jwtDecode(token)).role;
    const canEdit = ['Administrador', 'Gestor'].includes(role);

    useEffect(() => {
        // Cargar noticias al iniciar
        getNewsApi(token) // Asegúrate de pasar el token correcto
            .then(data => setNews(data));
    }, []);

    const showModal = () => {
        setIsModalVisible(true);
        setEditMode(false);
        setCurrentNews({ title: "", content: "" });
    };

    const handleOk = () => {
        if (editMode) {
            updateNewApi(token, editNewsId, currentNews) // Asegúrate de pasar el token correcto
                .then(() => {
                    // Recargar noticias
                    getNewsApi(token).then(data => setNews(data));
                });
        } else {
            createNewApi(token, currentNews) // Asegúrate de pasar el token correcto
                .then(() => {
                    // Recargar noticias
                    getNewsApi(token).then(data => setNews(data));
                });
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onInputChange = (e) => {
        setCurrentNews({ ...currentNews, [e.target.name]: e.target.value });
    };

    const editNews = (id) => {
        const newsToEdit = news.find(item => item._id === id);
        setCurrentNews({ title: newsToEdit.title, content: newsToEdit.content });
        setEditMode(true);
        setEditNewsId(id);
        setIsModalVisible(true);
    };

    const deleteNews = (id) => {
        deleteNewApi(token, id) // Asegúrate de pasar el token correcto
            .then(() => {
                // Recargar noticias
                getNewsApi(token).then(data => setNews(data));
            });
    };

    
    
    
    return (
        <>
            <h1 className="dashboard-title">Noticias</h1>
            <div className="dashboard">
                {canEdit && (
                    <div className="add-news-button">
                        <Button type="primary" onClick={showModal}>
                            Añadir Noticia
                        </Button>
                    </div>
                )}
                <div className="news-section">
                    {news.length > 0 ? (
                        news.map((newsItem, index) => (
                            <div key={index} className="news-post-it">
                                <div className="news-header">
                                    <h2>{newsItem.title}</h2>
                                    <h3>{moment(newsItem.date).format('DD MMM YYYY')}</h3>
                                </div>
                                <p>{newsItem.content}</p>
                                {canEdit && (
                                    <div className="news-buttons">
                                        <Button type="primary" onClick={() => editNews(newsItem._id)}>Editar</Button>
                                        <Button type="danger" onClick={() => deleteNews(newsItem._id)}>Eliminar</Button>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No hay noticias para mostrar.</p>
                    )}
                </div>
            </div>

            {canEdit && (
                <Modal title={editMode ? "Editar Noticia" : "Añadir Noticia"} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Input placeholder="Título" name="title" value={currentNews.title} onChange={onInputChange} />
                    <Input.TextArea maxLength={1000} rows={4} placeholder="Contenido" name="content" value={currentNews.content} onChange={onInputChange} />
                </Modal>
            )}
        </>
    );
}
