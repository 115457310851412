import React, { useState, useEffect } from "react";
import { Spin, Button } from "antd";

export default function Boton_peticion(props) {
  const { isLoading, texto } = props;

  useEffect(() => {}, [isLoading]);

  const content = isLoading ? (
    <Spin />
  ) : (
    <Button type="primary" htmlType="submit" className="btn-submit">
      {texto}
    </Button>
  );

  return content;
}
