import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Space, notification, Spin } from 'antd';
import IncidenciaForm from './IncidenciaFormVistaMantenimiento';
import { getAllIncidencias, deleteIncidencia } from '../../../../../src/api/incidencia';
import { obtenerListaInmuebles } from '../../../../api/inmueble';
import { getAccessTokenApi } from '../../../../api/auth';
import { getUsersByRoleApi } from '../../../../api/user';
import IncidenciaEditVistaMantenimiento from './IncidenciaEditVistaMantenimiento';
import moment from "moment";
import { getProximaFechaDisponiblev1 } from '../../../../api/inquilino';
const IncidenciasPage = () => {
  const [incidencias, setIncidencias] = useState([]);
  const [lista_pisos, setlista_pisos] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [lista_mantenimiento, set_lista_mantenimiento] = useState({});
  const [elemento_editar_selected, setelemento_editar_selected] = useState({});
  const [filteredValue, setFilteredValue] = useState(['no_asignado', 'pendiente', 'por_iniciar']);
  const [fechasDisponibles, setFechasDisponibles] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log(filters)
    setFilteredValue(filters.estado);
  };
  const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false);

  const handleOpenModalEdit = (record) => {
    console.log(record)
    setelemento_editar_selected(record);
    setModalIsOpenEdit(true);
  };
  
  const handleCloseModalEdit = () => {
    setModalIsOpenEdit(false);
  };
  useEffect(() => {
    const fetchFechasDisponibles = async () => {
      try {
        const accesToken = getAccessTokenApi();
        const fechasDisponibles_aux = {};
  
        const fechasDisponiblesPromises = incidencias?.filter(incidencia => incidencia.estado !== "resuelto").map(async (incidencia) => {
          const fechaDisponible = await getProximaFechaDisponiblev1(accesToken, moment().format("YYYY-MM-DD"), incidencia.id_apartamento);
          fechasDisponibles_aux[incidencia.id_apartamento] = fechaDisponible;
        });
  
        await Promise.all(fechasDisponiblesPromises);
  
        setFechasDisponibles(fechasDisponibles_aux);
      } catch (error) {
        console.error('Error al obtener las fechas disponibles:', error);
      }
    };
  
    fetchFechasDisponibles();
  }, [incidencias]);
  useEffect(()=>{},[lista_mantenimiento, fechasDisponibles])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllIncidencias(getAccessTokenApi());
        if (Array.isArray(data.message) && data.message.every(item => typeof item === 'object')) {
          setIncidencias(data.message);
        } else {
          console.error('El resultado de getAllIncidencias() no es un array de objetos:', data);
        }
      } catch (error) {
        console.error('Error al obtener las incidencias:', error);
      }
    };
  
    fetchData();
    setReload(false)
  }, [reload]);
  useEffect(() => {
    const obtenerMantenimiento = async () => {
        try {
            const response = await getUsersByRoleApi(getAccessTokenApi(), 'Mantenimiento');
            if (response && response.users) {
             
              let var_aux = {}
              response?.users.forEach(element => {
                var_aux[element.id] = element.name??""
              });
              set_lista_mantenimiento(var_aux)
            }
        } catch (error) {
            console.error("Error al obtener limpiadores:", error);
        }
    };
    obtenerMantenimiento();
}, []);
  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accesToken = getAccessTokenApi();
        const response = await obtenerListaInmuebles(accesToken);
        let var_aux = {}
        response?.Pisos.forEach(element => {
          var_aux[element.id] = element.nombre??""
        });
        setlista_pisos(var_aux);
      } catch (error) {
        console.error('Error al obtener la lista de inmuebles:', error);
      }
    };
  
    fetchData();
  }, []);
  const handleDeleteRow = async(id) => {
    
      try {
        const response = await deleteIncidencia(id,getAccessTokenApi());
        if (response.status === 200) {
          notification["success"]({
            message: "Exito creando incidencia"
          });
          setReload(true);
        } else {
          notification["error"]({
            message: "Error al crear la incidencia"
          });
          // message.error('Error al eliminar la incidencia');
          setReload(true);
        }
      } catch (error) {
        console.error('Error al eliminar la incidencia:', error);
        // message.error('Error al eliminar la incidencia');
      }
    
  };
  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: '_id',
    //   key: '_id',
    //   sorter: (a, b) => a._id.localeCompare(b._id),
    //   sortDirections: ['ascend', 'descend'],
    // },
    {
      title: 'Piso',
      dataIndex: 'id_apartamento',
      key: 'id_apartamento',
      render: (id_apartamento) => lista_pisos[id_apartamento] ?? 'No disponible',
      sorter: (a, b) => a.id_apartamento.localeCompare(b.id_apartamento),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Descripción de la tarea',
      dataIndex: 'descripcion_tarea',
      key: 'descripcion_tarea',
      sorter: (a, b) => a.descripcion_tarea.localeCompare(b.descripcion_tarea),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Fechas de Creación',
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      sorter: (a, b) => new Date(a.fecha_creacion) - new Date(b.fecha_creacion),
      sortDirections: ['ascend', 'descend'],
      render: (text) => {
        // Formatea la fecha en el formato que desees, por ejemplo:
        return new Date(text).toLocaleDateString(); // Esto mostrará la fecha en formato 'DD/MM/AAAA'
      }
    },
    {
      title: 'Fechas Disponibles',
      dataIndex: 'id_apartamento',
      key: 'id_apartamento',
      // sorter: (a, b) => new Date(a.fechaDisponible) - new Date(b.fechaDisponible),
      // sortDirections: ['ascend', 'descend'],
      render: (id_apartamento) => {

        // Aquí puedes formatear la fecha como prefieras
        console.log(fechasDisponibles[id_apartamento])
        return <>
        {!fechasDisponibles[id_apartamento]?.proximaFechaSame && !fechasDisponibles[id_apartamento]?.proximaFecha ? 
       
        ( <Spin></Spin>)
        :
        (
          <>
          {fechasDisponibles[id_apartamento]?.proximaFechaSame ? moment(fechasDisponibles[id_apartamento]?.proximaFechaSame).format("YYYY-MM-DD"):"No hay fecha"}
          <br></br><br></br>
          <b>{fechasDisponibles[id_apartamento]?.proximaFecha ? moment(fechasDisponibles[id_apartamento]?.proximaFecha).format("YYYY-MM-DD") : "No hay reservas futuras"}</b>
          </>
        )
        }
         </>
        
      }
    },
    // {
    //   title: 'Fechas Disponibles',
    //   dataIndex: 'fechas_disponibles',
    //   key: 'fechas_disponibles',
    //   sorter: (a, b) => new Date(a.fechas_disponibles) - new Date(b.fechas_disponibles),
    //   sortDirections: ['ascend', 'descend'],
    //   render: (text) => {
    //     // Formatea la fecha en el formato que desees, por ejemplo:
    //     return new Date(text).toLocaleDateString(); // Esto mostrará la fecha en formato 'DD/MM/AAAA'
    //   }
    // },
    {
      title: 'Urgente',
      dataIndex: 'urgente',
      key: 'urgente',
      render: (urgente) => (urgente ? 'Sí' : 'No'),
      filters: [
        { text: 'Sí', value: true },
        { text: 'No', value: false },
      ],
      onFilter: (value, record) => record.urgente === value,
    },
    {
      title: 'Nivel de Prioridad',
      dataIndex: 'nivel_prioridad',
      key: 'nivel_prioridad',
      sorter: (a, b) => a.nivel_prioridad.localeCompare(b.nivel_prioridad),
      sortDirections: ['ascend', 'descend'],
    },
  
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      filters: [
        { text: 'No asignado', value: "no_asignado" },
        { text: 'Pendiente', value: "pendiente" },
        { text: 'por_iniciar', value: "por_iniciar" },
        { text: 'Resuelto', value: "resuelto" },
      ],
      onFilter: (value, record) => record.estado === value,
       // Aplicamos el valor filtrado desde el estado
       filteredValue: filteredValue || null,
    },
    
    // {
    //   title: 'Fecha de Resolución',
    //   dataIndex: 'fecha_resolucion',
    //   key: 'fecha_resolucion',
    //   sorter: (a, b) => new Date(a.fecha_resolucion) - new Date(b.fecha_resolucion),
    //   sortDirections: ['ascend', 'descend'],
    // },
   
    {
      title: 'comentario_fin_tarea',
      dataIndex: 'comentario_fin_tarea',
      key: 'comentario_fin_tarea',
      sorter: (a, b) => new Date(a.comentario_fin_tarea) - new Date(b.comentario_fin_tarea),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Compras hechas',
      dataIndex: 'coste_materiales_usados_reparacion',
      key: 'coste_materiales_usados_reparacion',
      render: (value) => `${new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(value)}`, // Formatea el valor con símbolo €
      sorter: (a, b) => a.coste_materiales_usados_reparacion - b.coste_materiales_usados_reparacion, // Comparación numérica
      sortDirections: ['ascend', 'descend'],
    },
    
    {
      title: 'Acciones',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
           <Button type="primary" onClick={()=>handleOpenModalEdit(record)}>Editar</Button>
         
        </Space>
      ),
    },
  ];

  return (
    <div>
        <h2>Incidencias pendientes: {incidencias ? incidencias.filter(incidencia => incidencia.estado !== "resuelto").length : ""}</h2>
     
      {/* <Button type="primary" onClick={handleOpenModal}>Añadir Incidencia</Button> */}
      <Table 
        dataSource={incidencias??[]} 
        columns={columns??[]} 
        rowKey="_id" 
        onChange={handleChange}
      />
      <Modal visible={modalIsOpen} onCancel={handleCloseModal} footer={null}>
        <IncidenciaForm setModalIsOpen={setModalIsOpen} setReload={setReload}/>
        <Button onClick={handleCloseModal}>Cerrar</Button>
      </Modal>
      <Modal visible={modalIsOpenEdit} onCancel={handleCloseModalEdit} footer={null}>
        <IncidenciaEditVistaMantenimiento setModalIsOpen={setModalIsOpenEdit} setReload={setReload} data={elemento_editar_selected} />
        <Button onClick={handleCloseModalEdit}>Cerrar</Button>
      </Modal>
    </div>
  );
};

export default IncidenciasPage;
