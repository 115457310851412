import { basePath, apiVersion } from "./config";

// Crear una nueva noticia
export function createStockPisoApi(token,StockPisoInfo, idPiso,fecha ) {
  const url = `${basePath}/${apiVersion}/StockPiso`;
  let variable = {fecha_uso: fecha, ...StockPisoInfo};
  variable = {piso: idPiso,  ...variable};
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(variable)
  };

  return fetch(url, params)
    .then(response => response.json())
    .then(result => result)
    .catch(err => err.message);
}

// Obtener todas las noticias
export function getStockPisosApi(token) {
  const url = `${basePath}/${apiVersion}/StockPisos`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  return fetch(url, params)
    .then(response => response.json())
    .then(result => result)
    .catch(err => err.message);
}

// Obtener una noticia específica
export function getStockPisoApi(token, StockPisoId) {
  const url = `${basePath}/${apiVersion}/StockPiso/${StockPisoId}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };

  return fetch(url, params)
    .then(response => response.json())
    .then(result => result)
    .catch(err => err.message);
}

// // Actualizar una noticia
// export function updateStockPisoApi(token, StockPisoId, StockPisoInfo) {
//   const url = `${basePath}/${apiVersion}/StockPiso/${StockPisoId}`;

//   const params = {
//     method: "PATCH",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`
//     },
//     body: JSON.stringify(StockPisoInfo)
//   };

//   return fetch(url, params)
//     .then(response => response.json())
//     .then(result => result)
//     .catch(err => err.message);
// }

// // Eliminar una noticia
// export function deleteStockPisoApi(token, StockPisoId) {
//   const url = `${basePath}/${apiVersion}/StockPiso/${StockPisoId}`;

//   const params = {
//     method: "DELETE",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`
//     }
//   };

//   return fetch(url, params)
//     .then(response => response.json())
//     .then(result => result)
//     .catch(err => err.message);
// }
