import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Tabs,
  Row,
  Option,
  notification,
  Divider,
  Spin,
  Tooltip
} from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import {
  Inquilino_subir_id_police,
  abrirPuertaInquilinoApi,
  abrirPuertaNukiInquilinoApi,
  numeroPuertasInquilinoApi,
} from "../../../api/inquilino";
// import "./DNI_upload.scss";
import './FormularioDNI.scss';
import { BrowserRouter } from "react-router-dom";
import { getAccessTokenApi } from "../../../api/auth";
import Modal from "../../../components/Modal";

import FirmaDigital from "./../../../components/Admin/Users/FirmaDigital";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
export default function Formulario_dni({
  numero_ids,
  mi_numero,
  code,
  onFinish,
  subido,
  texto_boton,
  onStepChange
}) {
  const { Option } = Select;
  //Para la firma:
  const [signatureImage, setSignatureImage] = useState("");
  const [form] = Form.useForm();
  const [currentGroup, setCurrentGroup] = useState(1);
  const url = "https://sede.agenciatributaria.gob.es/Sede/ayuda/consultas-informaticas/firma-digital-sistema-clave-pin-tecnica/incidencias-tecnicas-clave-pin/obtencion-fecha-dni.html#:~:text=DNI%203.0,mover%20la%20tarjeta%20del%20DNI%20.";

  const handleSignatureComplete = (imageData) => {
    setSignatureImage(imageData);
    setCurrentGroup(5);
    //console.log("Signature: ", imageData);
  };
  const handleSignatureClean = (imageData) => {
    setSignatureImage("");
    //console.log("Signature: ", imageData);
  };
  //console.log("Formulario_dni estado_formulario");

  const [formulario, setFormulario] = useState({
    jsonHiddenComunes: "",
    nombre: "",
    apellido1: "",
    apellido2: "",
    tipoDocumento: "",
    tipoDocumentoStr: "",
    numIdentificacion: "",
    nacionalidad: "",
    nacionalidadStr: "", //Ojito
    fechaExpedicionDoc: "",
    fechaNacimiento: "",
    sexo: "",
    sexoStr: "",
  });
  const changeForm = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };
  function removeHTMLTags(str) {
    return str.replace(/<[^>]*>/g, "");
  }
  const [isVisibleLoader, setisVisibleLoader] = useState(false);
  const handleFinish = async () => {
    if (!signatureImage || signatureImage === "") {
      notification["error"]({
        message: "Falta la firma. | Signature is needed.",
      });
      return;
    }
    setisVisibleLoader(true);
    let respuesta = await Inquilino_subir_id_police(
      formulario,
      code,
      signatureImage
    );
    setisVisibleLoader(false);
    //console.log(respuesta);
    if (!respuesta.error) {
      if (!respuesta.message) {
        notification["error"]({
          message: "Problema en Servidor, prueba en un rato.",
        });
      } else {
        notification["success"]({
          message: "ID enviado / ID sent",
        });
        onFinish(mi_numero);

        // onStepChange(1);
        // //console.log('ChangeStep');
        // //window.location.reload();
      }
    } else {
      notification["error"]({
        description: removeHTMLTags(respuesta.error),
      });
    }
    //onFinish(mi_numero);
    // Aquí puedes realizar las acciones necesarias para enviar el estado
  };
  const handleSelectChange = (value, option) => {
    const optionText = option.children; // Obtener el texto de la opción seleccionada
    //console.log(optionText);
    // eslint-disable-next-line default-case
    switch (option.name) {
      case "sexo":
        if (value === "F") {
          setFormulario({
            ...formulario,
            [option.name]: value,
            sexoStr: "Femenino".toUpperCase(),
          });
        } else {
          setFormulario({
            ...formulario,
            [option.name]: value,
            sexoStr: "Masculino".toUpperCase(),
          });
        }
        break;
      case "nacionalidad":
        setFormulario({
          ...formulario,
          [option.name]: value,
          nacionalidadStr: option.children,
        });
        break;
      case "tipoDocumentoStr":
        let tipoDocumento_aux = "";

        //console.log(option.name);
        // eslint-disable-next-line default-case
        switch (option.children) {
          case "DNI":
            tipoDocumento_aux = "D";
            break;
          case "PASAPORTE":
            tipoDocumento_aux = "P";
            break;
          case "PERMISO CONDUCIR ESPAÑA":
            tipoDocumento_aux = "W";
            break;
          case "NIE O TARJETA ESPAÑOLA DE EXTRANJEROS":
            tipoDocumento_aux = "N";
            break;
          case "CARTA DE IDENTIDAD EXTRANJERA":
            tipoDocumento_aux = "I";
            break;
        }
        setFormulario({
          ...formulario,
          [option.name]: value,
          tipoDocumento: tipoDocumento_aux,
        });
        break;
    }
  };
  const handleDateChangeExpedicion = (date, dateString) => {
    setFormulario({
      ...formulario,
      fechaExpedicionDoc: moment(dateString).format("DD/MM/yyyy"),
    });
  };
  const handleDateChangeNacimiento = (date, dateString) => {
    setFormulario({
      ...formulario,
      fechaNacimiento: moment(dateString).format("DD/MM/yyyy"),
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validator_mayus_tildes = (_, value) => {
    if (value && !/^[A-ZÑ\s]*$/.test(value)) {
      return Promise.reject("Solo se permiten letras mayúsculas y sin tilde");
    }
    return Promise.resolve();
  };
  const validator_mayus_tildes_numeros = (_, value) => {
    if (value && !/^[A-ZÑ0-9\s]*$/.test(value)) {
      return Promise.reject(
        "Solo se permiten letras mayúsculas, números y espacios"
      );
    }
    return Promise.resolve();
  };
  const eliminar_mayusculas_y_tildes = (e) => {
    let value = e.target.value.toUpperCase();
    const accents = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
    };
    value = value.replace(/[áéíóúÁÉÍÓÚ]/g, (match) => accents[match]);
    e.target.value = value;
  };

  const controlForm = (fields, nextGroup) => {
    form
      .validateFields(fields)
      .then(() => {
        // Si la validación es exitosa, avanza al siguiente grupo
        setCurrentGroup(nextGroup);
      })
      .catch((errorInfo) => {
        // Manejar el error si la validación falla
        console.error('Validation failed:', errorInfo);
      });
  };

  return (
    <div className="formulario-dni">
      <Form
        form={form}
        className="login-form"
        onChange={changeForm}
        onFinish={handleFinish}
      >
        {currentGroup === 1 && (
          <Form.Item
            label="Primer apellido / Surname"
            name="apellido1"
            rules={[
              {
                required: true,
                message: "Por favor escribe tu nombre",
              },
              {
                validator: validator_mayus_tildes,
              },
            ]}
          >
            <Input
              //prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="Primer Apellido"
              name="apellido1"
              placeholder="Primer Apellido"
              className="login-form__input"
              onInput={eliminar_mayusculas_y_tildes}
            />
          </Form.Item>
        )}
        {currentGroup === 1 && (
          <Form.Item
            label="Segundo apellido / Second surname"
            name="apellido2"
            rules={[
              {
                required: true,
                message: "Por favor escribe tu apellido",
              },
              {
                validator: validator_mayus_tildes,
              },
            ]}
          >
            <Input
              //prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="Segundo Apellido"
              name="apellido2"
              placeholder="Segundo Apellido"
              className="login-form__input"
              onInput={eliminar_mayusculas_y_tildes}
            />
          </Form.Item>
        )}
        {currentGroup === 1 && (
          <Form.Item
            label="Nombre / Name"
            name="nombre"
            rules={[
              {
                required: true,
                message: "Por favor escribe tu nombre",
              },
              {
                validator: validator_mayus_tildes,
              },
            ]}
          >
            <Input
              //prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="Nombre"
              name="nombre"
              placeholder="Nombre"
              className="login-form__input"
              onInput={eliminar_mayusculas_y_tildes}
            />
          </Form.Item>
        )}
        {currentGroup === 1 && (
          <Form.Item>
            <Button onClick={() => controlForm(['nombre', 'apellido1', 'apellido2'], 2)}>
              Continuar
            </Button>
          </Form.Item>
        )}
        {/* {//console.log('Estado actual segundo grupo', currentGroup === 2)} */}
        {currentGroup === 2 && (
          <>
            <Row>
              <Form.Item
                label="Tipo de documento / Document type"
                name="tipoDocumentoStr"
                rules={[
                  {
                    required: true,
                    message: "Por favor el tipo de documento",
                  },
                ]}
              >
                <Select
                  id="tipoDocumentoStr"
                  name="tipoDocumentoStr"
                  className="form-control input-sm loadedBlur"
                  data-obligatorio="true"
                  placeholder="SELECCIONAR..."
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona el tipo de documento",
                    },
                  ]}
                  onSelect={handleSelectChange}
                >
                  <Option name="tipoDocumentoStr" value="DNI">
                    DNI
                  </Option>
                  <Option name="tipoDocumentoStr" value="PASAPORTE">
                    PASAPORTE
                  </Option>
                  <Option
                    name="tipoDocumentoStr"
                    value="CARTA DE IDENTIDAD EXTRANJERA"
                  >
                    CARTA DE IDENTIDAD EXTRANJERA
                  </Option>
                  <Option
                    name="tipoDocumentoStr"
                    value="NIE O TARJETA ESPAÑOLA DE EXTRANJEROS"
                  >
                    NIE O TARJETA ESPAÑOLA DE EXTRANJEROS
                  </Option>
                  <Option name="tipoDocumentoStr" value="PERMISO">
                    PERMISO CONDUCIR ESPAÑA
                  </Option>
                </Select>
              </Form.Item>
            </Row>
          </>
        )}

        {currentGroup === 2 && (
          <Form.Item
            label="Numero de documento / Document Number"
            name="numIdentificacion"
            rules={[
              {
                required: true,
                message: "Por favor selecciona el numero del ID",
              },
              {
                validator: validator_mayus_tildes_numeros,
              },
            ]}
          >
            <Input
              //prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="numIdentificacion"
              name="numIdentificacion"
              placeholder="Numero documento"
              className="login-form__input"
              onInput={eliminar_mayusculas_y_tildes}
            />
          </Form.Item>
        )}
        {currentGroup === 2 && (
          <>
            <Row>
              <Form.Item
                label="Nacionalidad / Nationality"
                name="nacionalidad"
                rules={[
                  {
                    required: true,
                    message: "Por favor pon tu año de nacimiento",
                  },
                ]}
              >
                <Select
                  id="nacionalidad"
                  name="nacionalidad"
                  className="form-control input-sm loadedBlur"
                  data-obligatorio="true"
                  placeholder="SELECCIONAR..."
                  onSelect={handleSelectChange}
                >
                  <Option name="nacionalidad" value="A9401AAAAA">
                    AFGANISTAN
                  </Option>
                  <Option name="nacionalidad" value="A9399AAAAA">
                    AFRICA
                  </Option>
                  <Option name="nacionalidad" value="A9102AAAAA">
                    ALBANIA
                  </Option>
                  <Option name="nacionalidad" value="A9103AAAAA">
                    ALEMANIA
                  </Option>
                  <Option name="nacionalidad" value="A9299AAAAA">
                    AMERICA
                  </Option>
                  <Option name="nacionalidad" value="A9133AAAAA">
                    ANDORRA
                  </Option>
                  <Option name="nacionalidad" value="A9301AAAAA">
                    ANGOLA
                  </Option>

                  <Option name="nacionalidad" value="A9255AAAAA">
                    ANTIGUA BARBUDA
                  </Option>

                  <Option name="nacionalidad" value="A9200AAAAA">
                    ANTILLAS NEERLANDESAS
                  </Option>

                  <Option name="nacionalidad" value="A9600AAAAA">
                    APATRIDA
                  </Option>

                  <Option name="nacionalidad" value="A9403AAA1A">
                    ARABIA SAUDITA
                  </Option>

                  <Option name="nacionalidad" value="A9304AAAAA">
                    ARGELIA
                  </Option>

                  <Option name="nacionalidad" value="A9202AAAAA">
                    ARGENTINA
                  </Option>

                  <Option name="nacionalidad" value="A9142AAAAA">
                    ARMENIA
                  </Option>

                  <Option name="nacionalidad" value="A9257AAAAA">
                    ARUBA
                  </Option>

                  <Option name="nacionalidad" value="A9499AAAAA">
                    ASIA
                  </Option>

                  <Option name="nacionalidad" value="A9500AAAAA">
                    AUSTRALIA
                  </Option>

                  <Option name="nacionalidad" value="A9104AAAAA">
                    AUSTRIA
                  </Option>

                  <Option name="nacionalidad" value="A9143AAA2A">
                    AZERBAYAN
                  </Option>

                  <Option name="nacionalidad" value="A9203AAAAA">
                    BAHAMAS
                  </Option>

                  <Option name="nacionalidad" value="A9405AAAAA">
                    BAHREIN
                  </Option>

                  <Option name="nacionalidad" value="A9432AAAAA">
                    BANGLADESH
                  </Option>

                  <Option name="nacionalidad" value="A9205AAAAA">
                    BARBADOS
                  </Option>

                  <Option name="nacionalidad" value="A9105AAAAA">
                    BELGICA
                  </Option>

                  <Option name="nacionalidad" value="A9207AAAAA">
                    BELICE
                  </Option>

                  <Option name="nacionalidad" value="A9407AAAAA">
                    BHUTAN
                  </Option>

                  <Option name="nacionalidad" value="A9144AAAAA">
                    BIELORRUSIA
                  </Option>

                  <Option name="nacionalidad" value="A9204AAAAA">
                    BOLIVIA
                  </Option>

                  <Option name="nacionalidad" value="A9156AAAAA">
                    BOSNIA HERZEGOVINA
                  </Option>

                  <Option name="nacionalidad" value="A9305AAAAA">
                    BOTSWANA
                  </Option>

                  <Option name="nacionalidad" value="A9206AAAAA">
                    BRASIL
                  </Option>

                  <Option name="nacionalidad" value="A9409AAAAA">
                    BRUNEI
                  </Option>

                  <Option name="nacionalidad" value="A9134AAAAA">
                    BULGARIA
                  </Option>

                  <Option name="nacionalidad" value="A9303AAAAA">
                    BURKINA FASO
                  </Option>

                  <Option name="nacionalidad" value="A9321AAAAA">
                    BURUNDI
                  </Option>

                  <Option name="nacionalidad" value="A9315AAAAA">
                    CABO VERDE
                  </Option>

                  <Option name="nacionalidad" value="A9402AAAAA">
                    CAMBOYA
                  </Option>

                  <Option name="nacionalidad" value="A9308AAAAA">
                    CAMERUN
                  </Option>

                  <Option name="nacionalidad" value="A9208AAAAA">
                    CANADA
                  </Option>

                  <Option name="nacionalidad" value="A9372AAAAA">
                    CHAD
                  </Option>

                  <Option name="nacionalidad" value="A9210AAAAA">
                    CHILE
                  </Option>

                  <Option name="nacionalidad" value="A9406AAAAA">
                    CHINA
                  </Option>

                  <Option name="nacionalidad" value="A9107AAAAA">
                    CHIPRE
                  </Option>

                  <Option name="nacionalidad" value="A9212AAAAA">
                    COLOMBIA
                  </Option>

                  <Option name="nacionalidad" value="A9311AAAAA">
                    COMORES
                  </Option>

                  <Option name="nacionalidad" value="A9460AAAAA">
                    COREA NORTE
                  </Option>

                  <Option name="nacionalidad" value="A9410AAAAA">
                    COREA SUR
                  </Option>

                  <Option name="nacionalidad" value="A9314AAAAA">
                    COSTA MARFIL
                  </Option>

                  <Option name="nacionalidad" value="A9214AAAAA">
                    COSTA RICA
                  </Option>

                  <Option name="nacionalidad" value="A9140AAAAA">
                    CROACIA
                  </Option>

                  <Option name="nacionalidad" value="A9216AAAAA">
                    CUBA
                  </Option>

                  <Option name="nacionalidad" value="A9258AAAAA">
                    CURAÇAO
                  </Option>

                  <Option name="nacionalidad" value="A9108AAAAA">
                    DINAMARCA
                  </Option>

                  <Option name="nacionalidad" value="A9317AAAAA">
                    DJIBOUTI
                  </Option>

                  <Option name="nacionalidad" value="A9217AAAAA">
                    DOMINICA
                  </Option>

                  <Option name="nacionalidad" value="A9222AAAAA">
                    ECUADOR
                  </Option>

                  <Option name="nacionalidad" value="A9300AAAAA">
                    EGIPTO
                  </Option>

                  <Option name="nacionalidad" value="A9429AAAAA">
                    EMIRATOS ARABES UNIDOS
                  </Option>

                  <Option name="nacionalidad" value="A9384AAAAA">
                    ERITREA
                  </Option>

                  <Option name="nacionalidad" value="A9158AAAAA">
                    ESLOVAQUIA
                  </Option>

                  <Option name="nacionalidad" value="A9141AAAAA">
                    ESLOVENIA
                  </Option>

                  <Option name="nacionalidad" value="A9109AAAAA" selected>
                    ESPAÑA
                  </Option>

                  <Option name="nacionalidad" value="A9525AAA1A">
                    ESTADOS FEDERADOS MICRONESIA
                  </Option>

                  <Option name="nacionalidad" value="A9224AAA1A">
                    ESTADOS UNIDOS AMERICA
                  </Option>

                  <Option name="nacionalidad" value="A9137AAAAA">
                    ESTONIA
                  </Option>

                  <Option name="nacionalidad" value="A9318AAAAA">
                    ETIOPIA
                  </Option>

                  <Option name="nacionalidad" value="A9199AAAAA">
                    EUROPA
                  </Option>

                  <Option name="nacionalidad" value="A9396AAAAA">
                    FERNANDO POO
                  </Option>

                  <Option name="nacionalidad" value="A9550AAAAA">
                    FIDJI
                  </Option>

                  <Option name="nacionalidad" value="A9411AAAAA">
                    FILIPINAS
                  </Option>

                  <Option name="nacionalidad" value="A9110AAAAA">
                    FINLANDIA
                  </Option>

                  <Option name="nacionalidad" value="A9111AAAAA">
                    FRANCIA
                  </Option>

                  <Option name="nacionalidad" value="A9320AAAAA">
                    GABON
                  </Option>

                  <Option name="nacionalidad" value="A9323AAAAA">
                    GAMBIA
                  </Option>

                  <Option name="nacionalidad" value="A9145AAAAA">
                    GEORGIA
                  </Option>

                  <Option name="nacionalidad" value="A9322AAAAA">
                    GHANA
                  </Option>

                  <Option name="nacionalidad" value="A9113AAAAA">
                    GRECIA
                  </Option>

                  <Option name="nacionalidad" value="A9228AAAAA">
                    GUATEMALA
                  </Option>

                  <Option name="nacionalidad" value="A9325AAA3A">
                    GUINEA
                  </Option>

                  <Option name="nacionalidad" value="A9328AAA1A">
                    GUINEA BISSAU
                  </Option>

                  <Option name="nacionalidad" value="A9324AAAAA">
                    GUINEA ECUATORIAL
                  </Option>

                  <Option name="nacionalidad" value="A9225AAAAA">
                    GUYANA
                  </Option>

                  <Option name="nacionalidad" value="A9230AAAAA">
                    HAITI
                  </Option>

                  <Option name="nacionalidad" value="A9232AAAAA">
                    HONDURAS
                  </Option>

                  <Option name="nacionalidad" value="A9462AAAAA">
                    HONG KONG CHINO
                  </Option>

                  <Option name="nacionalidad" value="A9114AAAAA">
                    HUNGRIA
                  </Option>

                  <Option name="nacionalidad" value="A9395AAAAA">
                    IFNI
                  </Option>

                  <Option name="nacionalidad" value="A9412AAAAA">
                    INDIA
                  </Option>

                  <Option name="nacionalidad" value="A9414AAAAA">
                    INDONESIA
                  </Option>

                  <Option name="nacionalidad" value="A9413AAAAA">
                    IRAK
                  </Option>

                  <Option name="nacionalidad" value="A9415AAAAA">
                    IRAN
                  </Option>

                  <Option name="nacionalidad" value="A9115AAAAA">
                    IRLANDA
                  </Option>

                  <Option name="nacionalidad" value="A9116AAAAA">
                    ISLANDIA
                  </Option>

                  <Option name="nacionalidad" value="A9518AAAAA">
                    ISLAS MARIANAS NORTE
                  </Option>

                  <Option name="nacionalidad" value="A9520AAAAA">
                    ISLAS MARSHALL
                  </Option>

                  <Option name="nacionalidad" value="A9551AAA1A">
                    ISLAS SALOMON
                  </Option>

                  <Option name="nacionalidad" value="A9417AAAAA">
                    ISRAEL
                  </Option>

                  <Option name="nacionalidad" value="A9117AAAAA">
                    ITALIA
                  </Option>

                  <Option name="nacionalidad" value="A9233AAAAA">
                    JAMAICA
                  </Option>

                  <Option name="nacionalidad" value="A9416AAAAA">
                    JAPON
                  </Option>

                  <Option name="nacionalidad" value="A9419AAAAA">
                    JORDANIA
                  </Option>

                  <Option name="nacionalidad" value="A9465AAAAA">
                    KAZAJSTAN
                  </Option>

                  <Option name="nacionalidad" value="A9336AAAAA">
                    KENIA
                  </Option>

                  <Option name="nacionalidad" value="A9501AAAAA">
                    KIRIBATI
                  </Option>

                  <Option name="nacionalidad" value="A9421AAAAA">
                    KUWAIT
                  </Option>

                  <Option name="nacionalidad" value="A9418AAAAA">
                    LAOS
                  </Option>

                  <Option name="nacionalidad" value="A9337AAAAA">
                    LESOTHO
                  </Option>

                  <Option name="nacionalidad" value="A9138AAAAA">
                    LETONIA
                  </Option>

                  <Option name="nacionalidad" value="A9423AAAAA">
                    LIBANO
                  </Option>

                  <Option name="nacionalidad" value="A9342AAAAA">
                    LIBERIA
                  </Option>

                  <Option name="nacionalidad" value="A9344AAAAA">
                    LIBIA
                  </Option>

                  <Option name="nacionalidad" value="A9118AAAAA">
                    LIECHTENSTEIN
                  </Option>

                  <Option name="nacionalidad" value="A9139AAAAA">
                    LITUANIA
                  </Option>

                  <Option name="nacionalidad" value="A9119AAAAA">
                    LUXEMBURGO
                  </Option>

                  <Option name="nacionalidad" value="A9463AAAAA">
                    MACAO
                  </Option>

                  <Option name="nacionalidad" value="A9159AAAAA">
                    MACEDONIA
                  </Option>

                  <Option name="nacionalidad" value="A9354AAAAA">
                    MADAGASCAR
                  </Option>

                  <Option name="nacionalidad" value="A9425AAAAA">
                    MALASIA
                  </Option>

                  <Option name="nacionalidad" value="A9346AAAAA">
                    MALAWI
                  </Option>

                  <Option name="nacionalidad" value="A9436AAAAA">
                    MALDIVAS
                  </Option>

                  <Option name="nacionalidad" value="A9347AAAAA">
                    MALI
                  </Option>

                  <Option name="nacionalidad" value="A9120AAAAA">
                    MALTA
                  </Option>

                  <Option name="nacionalidad" value="A9348AAAAA">
                    MARRUECOS
                  </Option>

                  <Option name="nacionalidad" value="A9349AAAAA">
                    MAURICIO
                  </Option>

                  <Option name="nacionalidad" value="A9350AAAAA">
                    MAURITANIA
                  </Option>

                  <Option name="nacionalidad" value="A9234AAA1A">
                    MEXICO
                  </Option>

                  <Option name="nacionalidad" value="A9148AAAAA">
                    MOLDAVIA
                  </Option>

                  <Option name="nacionalidad" value="A9121AAAAA">
                    MONACO
                  </Option>

                  <Option name="nacionalidad" value="A9427AAAAA">
                    MONGOLIA
                  </Option>

                  <Option name="nacionalidad" value="A9160AAAAA">
                    MONTENEGRO
                  </Option>

                  <Option name="nacionalidad" value="A9351AAAAA">
                    MOZAMBIQUE
                  </Option>

                  <Option name="nacionalidad" value="A9400AAAAA">
                    MYANMAR
                  </Option>

                  <Option name="nacionalidad" value="A9353AAAAA">
                    NAMIBIA
                  </Option>

                  <Option name="nacionalidad" value="A9541AAAAA">
                    NAURU
                  </Option>

                  <Option name="nacionalidad" value="A9420AAAAA">
                    NEPAL
                  </Option>

                  <Option name="nacionalidad" value="A9236AAAAA">
                    NICARAGUA
                  </Option>

                  <Option name="nacionalidad" value="A9360AAAAA">
                    NIGER
                  </Option>

                  <Option name="nacionalidad" value="A9352AAAAA">
                    NIGERIA
                  </Option>

                  <Option name="nacionalidad" value="A9122AAAAA">
                    NORUEGA
                  </Option>

                  <Option name="nacionalidad" value="A9540AAAAA">
                    NUEVA ZELANDA
                  </Option>

                  <Option name="nacionalidad" value="A9599AAAAA">
                    OCEANIA
                  </Option>

                  <Option name="nacionalidad" value="A9444AAAAA">
                    OMAN
                  </Option>

                  <Option name="nacionalidad" value="A9123AAA1A">
                    PAISES BAJOS
                  </Option>

                  <Option name="nacionalidad" value="A9424AAA1A">
                    PAKISTAN
                  </Option>

                  <Option name="nacionalidad" value="A9440AAAAA">
                    PALESTINA
                  </Option>

                  <Option name="nacionalidad" value="A9238AAAAA">
                    PANAMA
                  </Option>

                  <Option name="nacionalidad" value="A9542AAAAA">
                    PAPUA NUEVA GUINEA
                  </Option>

                  <Option name="nacionalidad" value="A9240AAAAA">
                    PARAGUAY
                  </Option>

                  <Option name="nacionalidad" value="A9242AAAAA">
                    PERU
                  </Option>

                  <Option name="nacionalidad" value="A9124AAAAA">
                    POLONIA
                  </Option>

                  <Option name="nacionalidad" value="A9125AAAAA">
                    PORTUGAL
                  </Option>

                  <Option name="nacionalidad" value="A9244AAAAA">
                    PUERTO RICO
                  </Option>

                  <Option name="nacionalidad" value="A9431AAAAA">
                    QATAR
                  </Option>

                  <Option name="nacionalidad" value="A9112AAA1A">
                    REINO UNIDO
                  </Option>

                  <Option name="nacionalidad" value="A9302AAA1A">
                    REPUBLICA BENIN
                  </Option>

                  <Option name="nacionalidad" value="A9310AAA1A">
                    REPUBLICA CENTROAFRICANA
                  </Option>

                  <Option name="nacionalidad" value="A9157AAAAA">
                    REPUBLICA CHECA
                  </Option>

                  <Option name="nacionalidad" value="A9312AAA1A">
                    REPUBLICA CONGO
                  </Option>

                  <Option name="nacionalidad" value="A9380AAAAA">
                    REPUBLICA DEMOCRATICA CONGO
                  </Option>

                  <Option name="nacionalidad" value="A9218AAA1A">
                    REPUBLICA DOMINICANA
                  </Option>

                  <Option name="nacionalidad" value="A9229AAAAA">
                    REPUBLICA GRANADA
                  </Option>

                  <Option name="nacionalidad" value="A9466AAA1A">
                    REPUBLICA KIRGUISTAN
                  </Option>

                  <Option name="nacionalidad" value="A9369AAAAA">
                    REPUBLICA SUDAN SUR
                  </Option>

                  <Option name="nacionalidad" value="A9397AAAAA">
                    RIO MUNI
                  </Option>

                  <Option name="nacionalidad" value="A9306AAAAA">
                    RUANDA
                  </Option>

                  <Option name="nacionalidad" value="A9127AAAAA">
                    RUMANIA
                  </Option>

                  <Option name="nacionalidad" value="A9149AAAAA">
                    RUSIA
                  </Option>

                  <Option name="nacionalidad" value="A9398AAAAA">
                    SAHARA
                  </Option>

                  <Option name="nacionalidad" value="A9256AAA1A">
                    SAINT KITTS NEVIS
                  </Option>

                  <Option name="nacionalidad" value="A9220AAAAA">
                    SALVADOR
                  </Option>

                  <Option name="nacionalidad" value="A9552AAAAA">
                    SAMOA OCCIDENTAL
                  </Option>

                  <Option name="nacionalidad" value="A9135AAAAA">
                    SAN MARINO
                  </Option>

                  <Option name="nacionalidad" value="A9259AAAAA">
                    SAN MARTIN
                  </Option>

                  <Option name="nacionalidad" value="A9254AAA1A">
                    SAN VICENTE GRANADINAS
                  </Option>

                  <Option name="nacionalidad" value="A9253AAAAA">
                    SANTA LUCIA
                  </Option>

                  <Option name="nacionalidad" value="A9136AAA2A">
                    SANTA SEDE
                  </Option>

                  <Option name="nacionalidad" value="A9361AAAAA">
                    SANTO TOME PRINCIPE
                  </Option>

                  <Option name="nacionalidad" value="A9362AAAAA">
                    SENEGAL
                  </Option>

                  <Option name="nacionalidad" value="A9155AAAAA">
                    SERBIA
                  </Option>

                  <Option name="nacionalidad" value="A9363AAAAA">
                    SEYCHELLES
                  </Option>

                  <Option name="nacionalidad" value="A9364AAAAA">
                    SIERRA LEONA
                  </Option>

                  <Option name="nacionalidad" value="A9426AAAAA">
                    SINGAPUR
                  </Option>

                  <Option name="nacionalidad" value="A9433AAAAA">
                    SIRIA
                  </Option>

                  <Option name="nacionalidad" value="A9365AAAAA">
                    SOMALIA
                  </Option>

                  <Option name="nacionalidad" value="A9404AAAAA">
                    SRI LANKA
                  </Option>

                  <Option name="nacionalidad" value="A9367AAAAA">
                    SUDAFRICA
                  </Option>

                  <Option name="nacionalidad" value="A9368AAAAA">
                    SUDAN
                  </Option>

                  <Option name="nacionalidad" value="A9369AAA1A">
                    SUDAN SUR
                  </Option>

                  <Option name="nacionalidad" value="A9128AAAAA">
                    SUECIA
                  </Option>

                  <Option name="nacionalidad" value="A9129AAAAA">
                    SUIZA
                  </Option>

                  <Option name="nacionalidad" value="A9250AAAAA">
                    SURINAM
                  </Option>

                  <Option name="nacionalidad" value="A9371AAAAA">
                    SWAZILANDIA
                  </Option>

                  <Option name="nacionalidad" value="A9469AAAAA">
                    TADJIKISTAN
                  </Option>

                  <Option name="nacionalidad" value="A9428AAA1A">
                    TAILANDIA
                  </Option>

                  <Option name="nacionalidad" value="A9408AAA3A">
                    TAIWAN TAIPEI
                  </Option>

                  <Option name="nacionalidad" value="A9370AAAAA">
                    TANZANIA
                  </Option>

                  <Option name="nacionalidad" value="A9464AAAAA">
                    TIMOR ORIENTAL
                  </Option>

                  <Option name="nacionalidad" value="A9374AAAAA">
                    TOGO
                  </Option>

                  <Option name="nacionalidad" value="A9554AAAAA">
                    TONGA
                  </Option>

                  <Option name="nacionalidad" value="A9245AAAAA">
                    TRINIDAD TOBAGO
                  </Option>

                  <Option name="nacionalidad" value="A9378AAAAA">
                    TUNEZ
                  </Option>

                  <Option name="nacionalidad" value="A9467AAA1A">
                    TURKMENISTAN
                  </Option>

                  <Option name="nacionalidad" value="A9130AAAAA">
                    TURQUIA
                  </Option>

                  <Option name="nacionalidad" value="A9560AAAAA">
                    TUVALU
                  </Option>

                  <Option name="nacionalidad" value="A9152AAAAA">
                    UCRANIA
                  </Option>

                  <Option name="nacionalidad" value="A9358AAAAA">
                    UGANDA
                  </Option>

                  <Option name="nacionalidad" value="A9190AAA1A">
                    UNION EUROPEA
                  </Option>

                  <Option name="nacionalidad" value="A9246AAAAA">
                    URUGUAY
                  </Option>

                  <Option name="nacionalidad" value="A9468AAAAA">
                    UZBEKISTAN
                  </Option>

                  <Option name="nacionalidad" value="A9565AAAAA">
                    VANUATU
                  </Option>

                  <Option name="nacionalidad" value="A9248AAAAA">
                    VENEZUELA
                  </Option>

                  <Option name="nacionalidad" value="A9430AAAAA">
                    VIETNAM
                  </Option>

                  <Option name="nacionalidad" value="A9434AAAAA">
                    YEMEN
                  </Option>

                  <Option name="nacionalidad" value="A9382AAAAA">
                    ZAMBIA
                  </Option>

                  <Option name="nacionalidad" value="A9357AAAAA">
                    ZIMBABWE
                  </Option>
                </Select>
              </Form.Item>
            </Row>
          </>
        )}
        {currentGroup === 2 && (
          <Form.Item>
            <Button type="default" onClick={() => setCurrentGroup(1)}>
              Volver
            </Button>
            <Button onClick={() => controlForm(['numIdentificacion', 'nacionalidad'], 3)}>
              Continuar
            </Button>
          </Form.Item>
        )}
        {currentGroup === 3 && (
          <Form.Item
          label={
            <span>
              Fecha Expedición / Expedition date&nbsp;
              <Tooltip title="¿No encuentras el numero de expedición del dni?">
                <QuestionCircleOutlined onClick={()=>window.open(url,'_blank', 'noopener,noreferrer')} />
              </Tooltip>
            </span>
          }
            name="fechaExpedicionDoc"
            rules={[
              {
                required: true,
                message: "Por favor selecciona la fecha de expedición",
              },
            ]}
          >
            <DatePicker
              name="fechaExpedicionDoc"
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              onChange={handleDateChangeExpedicion}
            />
          </Form.Item>
        )}
        {currentGroup === 3 && (
          <Form.Item
            label="Fecha Nacimiento / Birth date"
            name="fechaNacimiento"
            rules={[
              {
                required: true,
                message: "Por favor selecciona la fecha de nacimiento.",
              },
            ]}
          >
            <DatePicker
              onChange={handleDateChangeNacimiento}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </Form.Item>
        )}
        {currentGroup === 3 && (
          <Row>
            <Form.Item
              label="Sexo / Sex"
              name="sexoStr"
              rules={[
                {
                  required: true,
                  message: "Por favor pon tu año de nacimiento",
                },
              ]}
            >
              <Select
                id="sexoStr"
                name="sexoStr"
                className="form-control input-sm loadedBlur"
                data-obligatorio="true"
                placeholder="SELECCIONAR..."
                onSelect={handleSelectChange}
              >
                <Option name="sexo" value="M">
                  MASCULINO
                </Option>
                <Option name="sexo" value="F">
                  FEMENINO
                </Option>
              </Select>
            </Form.Item>
          </Row>
        )}
        {currentGroup === 3 && (
          <Form.Item>
            <Button type="default" onClick={() => setCurrentGroup(2)}>
              Volver
            </Button>
            <Button onClick={() => controlForm(['fechaExpedicionDoc','fechaNacimiento', 'sexoStr'], 4)}>
              Continuar
            </Button>
          </Form.Item>
        )}
        {currentGroup === 4 && (
          <Divider>Firmar / Signature</Divider>
        )}
        {currentGroup === 4 && (
          <Row justify="center" align="middle">
            <FirmaDigital
              onSignatureComplete={handleSignatureComplete}
              handleSignatureClean={handleSignatureClean}
            />
            <div style={{ width: '100%' }}>
              <Button style={{ display: 'block', margin: 'auto' }} type="default" onClick={() => setCurrentGroup(3)}>
                Volver
              </Button>
            </div>
          </Row>
        )}
        <Divider></Divider>
        {currentGroup === 5 && (
          <>
            {isVisibleLoader ? (
              <Spin />
            ) : (
              <Form.Item>
                {signatureImage && (
                  <>
                    <div>
                      <h2>Final Firma / Signature</h2>
                      <img src={signatureImage} alt="Firma" />
                    </div>
                  </>
                )}
                <div style={{ width: '100%' }}>
                  <Button style={{ display: 'block', margin: 'auto' }} type="default" onClick={() => setCurrentGroup(4)}>
                    Volver
                  </Button>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form__button"
                  disabled={subido}
                  style={{
                    backgroundColor: subido ? "green" : null,
                    color: subido ? "white" : null,
                  }}
                >
                  {!subido ? `Enviar ID ${mi_numero} / ${numero_ids}` : texto_boton}
                </Button>
              </Form.Item>
            )}
          </>
        )}

      </Form>
    </div>
  );
}
