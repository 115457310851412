import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const GraphLine = ({ datos }) => {
    //console.log("DATOS QUE LLEGAN", datos);

    return (
        <div style={{ margin: '20px', maxWidth: '600px' }}>
            <LineChart
                width={500}
                height={300}
                data={datos}
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="tiempo" label={{ value: 'Fecha', position: 'insideBottomRight', offset: -10 }} />
                <YAxis label={{ value: 'Puntuación', angle: -90, position: 'insideLeft' }} domain={[0, 10]} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="puntuacion" stroke="#8884d8" activeDot={{ r: 10 }} />
            </LineChart>
        </div>
    );
};

export default GraphLine;