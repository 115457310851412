import { basePath, apiVersion } from "./config";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  EMAIL,
  KEYROCK_TOKEN,
  FUNCIONALIDAES,
} from "../utils/constants";
import jwtDecode from "jwt-decode";
import { Result } from "antd";

// function willExpireToken(token) {
//   //para saber si en el proximo minuto caduca
//   const seconds = 3600 * 5;
//   const miliseconds = seconds * 1000;
//   const metaToken = jwtDecode(token);
//   const { exp } = metaToken;
//   const now = Date.now() + miliseconds;
//   //console.log("willExpireToken now", now);
//   //console.log("willExpireToken exp", exp);
//   return now > exp;
// }

// function willExpireRefreshToken(token) {
//   //para saber si en el proximo minuto caduca
//   const seconds = 3600 * 1;
//   const miliseconds = seconds * 1000;
//   const metaToken = jwtDecode(token);
//   const { exp } = metaToken;
//   const now = Date.now() + miliseconds;
//   //console.log("willExpireRefreshToken now", now);
//   //console.log("willExpireRefreshToken exp", exp);
//   return now > exp;
// }

import moment from "moment";

//Me devuelve null si ha expirado el token o no existe
export function getRefreshTokenApi() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  // //console.log("getRefreshTokenApi");
  if (!refreshToken || refreshToken === "undefined") {
    return null;
  }

  return willExpireRefreshToken(refreshToken) ? null : refreshToken;
}


export function getFuncionalidadesApi() {
  const funcionalidades = localStorage.getItem(FUNCIONALIDAES);
  // console.log(" getFuncionalidadesApi funcionalidades", funcionalidades);
  if (!funcionalidades || funcionalidades === "undefined") {
    return null;
  }

  return funcionalidades ? funcionalidades : null ;
}

//Me devuelve null si ha expirado el token
export function getAccessTokenApi() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  ////console.log("El AT es " + accessToken);
  // //console.log("getAccessTokenApi");
  if (!accessToken || accessToken === "undefined") {
    return null;
  }

  return willExpireToken(accessToken) ? null : accessToken;
}

export function getEmail() {
  const email = localStorage.getItem(EMAIL);
  ////console.log("El AT es " + accessToken);
  if (!email || email === "undefined") {
    return null;
  }

  return email;
}




export async function refreshAccessTokenApi(refreshToken) {
  try {
    //Accedo al endpoint con su url
    const url = `${basePath}/${apiVersion}/refresh-access-token`;
    const bodyObj = {
      refreshToken: refreshToken,
    };
    const params = {
      method: "POST",
      body: JSON.stringify(bodyObj),
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, params);

    return response;

    /* fetch(url, params)
      .then((response) => {
        if (response.status !== 200) {
          //El backend ha devuelto un error
          return null;
        }
        //console.log('okey');
        //console.log(response);
        return response.json(); //Ha habido exito y actualizo los tokens
      })
       .then((result) => {
        
        //console.log(result);
        if (!result) {
          logout();
        } else {
          //console.log('okey2')
          const { accessToken, refreshToken } = result;
          //console.log(accessToken)
          localStorage.setItem(ACCESS_TOKEN, accessToken);
          localStorage.setItem(REFRESH_TOKEN, refreshToken);
        }
      }); */
  } catch (e) {
    //console.log("Error gordo");
    return "";
  }
}

//Desloguea
export function logout() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(EMAIL);
  localStorage.removeItem(FUNCIONALIDAES);
  
}






function willExpireToken(token) {
  try{
  const seconds = 3600 * 5; //En 5h muere
  const expirationTime = moment.unix(jwtDecode(token).exp);
  const currentTime = moment();
  // //console.log("currentTime", currentTime);
  // //console.log("expirationTime", expirationTime);
  return expirationTime.diff(currentTime, "seconds") <= seconds;
  }catch(e){
    return true;
  }
}

function willExpireRefreshToken(token) {
  const seconds = 3600 * 1; //En 1h muere
  const expirationTime = moment.unix(jwtDecode(token).exp);
  const currentTime = moment();
  // //console.log("currentTime", currentTime);
  // //console.log("expirationTime", expirationTime);
  return expirationTime.diff(currentTime, "seconds") <= seconds;
}
