
import { Select, Button, notification } from "antd";
import React, { useState, useEffect } from "react";
import { updateInquilinoLimpiezaApi } from "../../../api/inquilino";
import { getAccessTokenApi } from "../../../api/auth";

const Desplegable_activable = ({setreload, record, limpiadores_id_desplegable, inquilino_id, }) => {
  const [editMode, setEditMode] = useState(false); // Estado para controlar si el modo de edición está habilitado
  const [selectedValue, setSelectedValue] = useState(record.limpiador); // Estado para almacenar el valor seleccionado
  useEffect(( )=>{
    setSelectedValue(record.limpiador);
    
    //console.log("inquilino_id",inquilino_id)

  },[record, limpiadores_id_desplegable, inquilino_id])




  // Función para manejar el cambio en el Select
  const handleSelectChange = (value) => {
    
    setSelectedValue(value); // Actualiza el estado del valor seleccionado
  };

  // Función para alternar entre habilitar y deshabilitar el Select
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  // Función para guardar los cambios
  const handleSaveChanges = async() => {
    // Aquí puedes manejar la lógica para guardar los cambios si es necesario
    // //console.log("El inmueble seleccionado es: ", record)
    const resp = await updateInquilinoLimpiezaApi(getAccessTokenApi(),inquilino_id,selectedValue);
    if(resp.message){
      notification["success"]({
        message: "Limpiador actualizado"
      });
      
      
    }else{
      notification["error"]({
        message: "Error: -" + resp.error
      });

    }
    toggleEditMode(); // Desactiva el modo de edición después de guardar los cambios
    setreload(true);
    // //console.log("Valor seleccionado:", selectedValue);
    

  };

  return (
    <>
      <Select
        disabled={!editMode} // El Select estará deshabilitado si editMode es false
        onChange={handleSelectChange}
        value={selectedValue}
        placeholder={"Selecciona un limpiador"}
        options={limpiadores_id_desplegable}
      />
      
      <Button onClick={editMode ? handleSaveChanges : toggleEditMode}>
        {editMode ? "Guardar" : "Editar"} {/* Cambia el texto del botón dependiendo del modo */}
       
      </Button>
    </>
  );
};

export default Desplegable_activable;
